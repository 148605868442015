import {
  RegistrationState,
  RegistrationType,
  GetRegistrationsResult,
  Registration,
} from '../interfaces/registration.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import myAxios from '../utils/my-axios';

export const emailTaken = async (email: string): Promise<boolean> => {
  const { data } = await myAxios.get('/registrations/email-taken', {
    params: { email },
  });

  return data.data;
};
export const walletAddressTaken = async (
  walletAddress: string
): Promise<boolean> => {
  const { data } = await myAxios.get('/registrations/wallet-address-taken', {
    params: { walletAddress },
  });

  return data.data;
};

export interface CreateRegistrationData {
  email: string;
  password: string;
  contactEmail: string;
  phoneNumber?: string;
  website?: string;
  description: string;
  file?: string;
  walletAddress: string;
  lang: string;
  signature: string;
  tags: string[];
  magicToken?: string;
}

export interface CreateRegistrationDataCompany extends CreateRegistrationData {
  name: string;
  sme: boolean;
}

export interface CreateRegistrationDataIndividual
  extends CreateRegistrationData {
  firstName: string;
  lastName: string;
  organization?: string;
}

export const sendCompanyRegistration = async (
  params: CreateRegistrationDataCompany
): Promise<void> => {
  const { data } = await myAxios.post('/registrations/company', params);

  return data.data;
};
export const sendIndividualRegistration = async (
  params: CreateRegistrationDataIndividual
): Promise<void> => {
  const { data } = await myAxios.post('/registrations/individual', params);

  return data.data;
};

export const requestUploadForRegistration = async (
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post('/registrations/request-upload', {
    fileName,
  });

  return data.data;
};

export interface GetRegistrationsFilter {
  search?: string;
  state?: RegistrationState;
  type?: RegistrationType;
}
export const getRegistrations = async (
  page: number,
  filter: GetRegistrationsFilter
): Promise<GetRegistrationsResult> => {
  const { data } = await myAxios.get('/registrations', {
    params: { page, ...filter },
  });

  return data.data;
};
export const getRegistration = async (id: string): Promise<Registration> => {
  const { data } = await myAxios.get(`/registrations/${id}`);

  return data.data;
};

export const rejectRegistration = async (
  id: string,
  reason: string
): Promise<string> => {
  const { data } = await myAxios.post(`/registrations/${id}/reject`, {
    reason,
  });

  return data.data;
};

export const acceptRegistration = async (
  id: string,
  tx: string,
  endorsedTags: string[]
): Promise<string> => {
  const { data } = await myAxios.post(`/registrations/${id}/accept`, {
    tx,
    endorsedTags,
  });

  return data.data;
};

export const requestRegistrationFileDownload = async (
  id: string
): Promise<string> => {
  const { data } = await myAxios.get(`/registrations/${id}/request-download`);

  return data.data.url;
};
