import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardMyProjectsIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.762 3.341a1.031 1.031 0 0 0-.356.784v14.438a1.031 1.031 0 0 0 2.063 0v-3.634c1.963-1.471 3.618-.784 6.074.43 1.395.687 2.985 1.478 4.71 1.478 1.264 0 2.601-.425 3.985-1.623a1.03 1.03 0 0 0 .356-.776V4.125a1.031 1.031 0 0 0-1.707-.78c-2.123 1.84-3.827 1.144-6.43-.144C9.04 2 6.03.511 2.762 3.341Zm14.77 10.603c-1.964 1.472-3.619.783-6.075-.43-1.99-.988-4.382-2.17-6.988-.978V4.614c1.963-1.471 3.618-.784 6.074.43 1.395.687 2.985 1.478 4.71 1.478a5.414 5.414 0 0 0 2.278-.5v7.922Z"
    />
  </svg>
);
export default DashboardMyProjectsIcon;
