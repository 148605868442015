import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import DashboardBox from '../box';
import RequestEndorsementsModal from './modal';
import Button from '../../../shared/button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function DashboardRequestEndorsement(props: Props) {
  const { t } = useTranslation('dashboard');
  const [showModal, setShowModal] = useState(false);

  return (
    <DashboardBox {...props}>
      <div className="mb-2">{t('requestEndorsement.text')}</div>
      <Button size="small" onClick={() => setShowModal(true)}>
        {t('requestEndorsement.request')}
      </Button>
      {showModal && (
        <RequestEndorsementsModal onClose={() => setShowModal(false)} />
      )}
    </DashboardBox>
  );
}

export default DashboardRequestEndorsement;
