import { ProjectCollaboratorResponseFormData } from '../components/projects/collaborators/responses/form';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import {
  ProjectCollaboratorResponseDetail,
  ProjectCollaboratorResponseState,
  GetProjectCollaboratorResponsesResult,
} from '../interfaces/project-collaborator-response.interface';
import {
  ProjectState,
  ResponseFormProject,
} from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import myAxios from '../utils/my-axios';

export const getProjectCollaboratorResponseDataHash = async (
  projectId: string,
  formData: ProjectCollaboratorResponseFormData
): Promise<string> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/projects/${projectId}/responses/data-hash`,
    formData
  );

  return data.data;
};

export const getProjectCollaboratorResponseForwardRequest = async (
  projectId: string,
  formData: ProjectCollaboratorResponseFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/responses/forward-request`,
    formData
  );

  return data.data;
};

export const createProjectCollaboratorResponse = async (
  projectId: string,
  formData: ProjectCollaboratorResponseFormData,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/projects/${projectId}/responses`, {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export interface GetProjectCollaboratorInvitesFilter {
  search?: string;
  state?: ProjectState;
}

export const getProjectCollaboratorResponse = async (
  responseId: string
): Promise<ProjectCollaboratorResponseDetail> => {
  const { data } = await myAxios.get(
    `/project-collaborator-responses/${responseId}`
  );

  return data.data;
};

export const rejectProjectCollaboratorResponse = async (
  responseId: string,
  reason: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/project-collaborator-responses/${responseId}/reject`,
    { reason }
  );

  return data.data;
};

export const getAcceptProjectCollaboratorResponseForwardRequest = async (
  responseId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/project-collaborator-responses/${responseId}/accept-forward-request`
  );

  return data.data;
};

export const acceptProjectCollaboratorResponse = async (
  responseId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/project-collaborator-responses/${responseId}/accept`,
    { tx, signature }
  );

  return data.data;
};

export const requestProjectCollaboratorResponseFileDownload = async (
  responseId: string,
  path: string
): Promise<string> => {
  const { data } = await myAxios.get(
    `/project-collaborator-responses/${responseId}/request-download`,
    {
      params: { path },
    }
  );

  return data.data.url;
};

export const getProjectCollaboratorResponseFormData = async (
  projectId: string
): Promise<ResponseFormProject> => {
  const { data } = await myAxios.get(`/projects/${projectId}/response-form`);

  return data.data;
};

export const requestUploadForProjectCollaboratorResponse = async (
  projectId: string,
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/responses/request-upload`,
    {
      fileName,
    }
  );

  return data.data;
};

export interface GetProjectCollaboratorResponseFilter {
  search?: string;
  state?: ProjectCollaboratorResponseState;
}

export const getProjectCollaboratorResponses = async (
  page: number,
  projectId: string,
  filter: GetProjectCollaboratorResponseFilter
): Promise<GetProjectCollaboratorResponsesResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/responses`, {
    params: { page, ...filter },
  });

  return data.data;
};
