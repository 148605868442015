import axios from 'axios';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getProject,
  requestUploadForProject,
  requestProjectFileDownload,
} from '../../../api/projects';
import DashboardBox from '../../../components/core/dashboard-layout/box';
import DashboardFiles from '../../../components/core/dashboard-layout/files';
import DashboardGroup from '../../../components/core/dashboard-layout/group';
import DashboardGroupLabel from '../../../components/core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../components/core/dashboard-layout/group/value';
import DashboardUserProfile from '../../../components/core/dashboard-layout/profile';
import DashboardTitle from '../../../components/core/dashboard-layout/title';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import ProjectDetailApproveCompletion from '../../../components/projects/approve';
import ProjectDetailCancelCompletion from '../../../components/projects/cancel-completion';
import ProjectDetailClaimReward from '../../../components/projects/claim-reward';
import ProjectDetailRate from '../../../components/projects/rate';
import ProjectDetailStart from '../../../components/projects/start';
import ProjectDetailStartCompletion from '../../../components/projects/start-completion';
import Button from '../../../components/shared/button';
import Loading from '../../../components/shared/loading';
import ProjectStateEl from '../../../components/shared/project-state';
import Tags from '../../../components/shared/tags';
import AuthContext from '../../../context/auth-context';
import { ProjectDetail } from '../../../interfaces/project.interface';
import { formatShortDate } from '../../../utils/date';
import {
  myProjectsRoute,
  otherProjectsRoute,
  userProfileRoute,
  collaboratorResponseFormRoute,
  collaboratorRecommendationFormRoute,
  projectMilestonesRoute,
  projectCollaboratorsRoute,
  editProjectRoute,
} from '../../../utils/routes';
import { getDisplayName } from '../../../utils/utils';
import Notification from '../../../components/shared/notification';
import ProjectDetailUploadCollaboratorFiles from '../../../components/projects/attach-collaborator-files';
import { requestProjectCollaboratorFileDownload } from '../../../api/projects-collaborators';

export default function ProjectDetailPage() {
  const { t } = useTranslation('project-detail');
  const commonTranslations = useTranslation('common');
  const [success, setSuccess] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<ProjectDetail>();
  // const [waitingFor, setWaitingFor] = useState<
  //   | undefined
  //   | 'start'
  //   | 'startCompletion'
  //   | 'cancelCompletion'
  //   | 'approveCompletion'
  //   | 'rate'
  //   | 'claimReward'
  // >();
  const { user, fetchDashboardData } = useContext(AuthContext);
  const { projectId } = useParams();
  const pollTimeout = useRef<any>();

  const isOwner = project && user && project.user.id === user.id;
  const isOwnerOrCollaborator = isOwner || (project && project.isCollaborator);

  async function fetchProject() {
    setError(undefined);
    try {
      const result = await getProject(projectId! as string);
      console.log('project loaded', result);

      if (project) {
        handleStateChange(result);
      }

      setProject(result);
      setLoading(false);
    } catch (err) {
      console.error('failed to get project', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'projectNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  function handleStateChange(newProject: ProjectDetail) {
    if (isOwner && project!.state === 'waitingForStart') {
      if (newProject.state === 'idle') {
        setError(t('start.errors.failedToStart'));
        stopPolling();
      } else if (newProject.state === 'started') {
        setSuccess(t('start.started'));
        stopPolling();
      }
    } else if (isOwner && project!.state === 'waitingForCompletionApproval') {
      if (newProject.state === 'started') {
        setError(t('startCompletion.errors.failedToStart'));
        stopPolling();
      } else if (newProject.state === 'completionApproval') {
        stopPolling();
      }
    } else if (
      isOwner &&
      project!.state === 'waitingForCompletionCancellation'
    ) {
      if (newProject.state === 'completionApproval') {
        setError(t('cancelCompletion.errors.failedToCancel'));
        stopPolling();
      } else if (newProject.state === 'started') {
        setSuccess(t('cancelCompletion.cancelled'));
        stopPolling();
      }
    } else if (project!.approval && project!.approval.state === 'waiting') {
      if (!newProject.approval) {
        setError(t('approve.errors.failedToApprove'));
        stopPolling();
      } else if (
        newProject.approval &&
        newProject.approval.state === 'approved'
      ) {
        stopPolling();
        fetchDashboardData();
      }
    } else if (project!.userRating && project!.userRating.state === 'waiting') {
      if (!newProject.userRating) {
        setError(t('rate.errors.failedToRate'));
        stopPolling();
      } else if (
        newProject.userRating &&
        newProject!.userRating.state === 'rated'
      ) {
        stopPolling();
      }
    } else if (
      project!.rewardClaim &&
      project!.rewardClaim.state === 'waiting'
    ) {
      if (!newProject.rewardClaim) {
        setError(t('claimReward.errors.failedToClaim'));
        stopPolling();
      } else if (
        newProject.rewardClaim &&
        newProject!.rewardClaim.state === 'claimed'
      ) {
        stopPolling();
        fetchDashboardData();
      }
    }
  }

  useEffect(() => {
    fetchProject();
  }, [projectId!]);

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      console.log('poll');
      fetchProject();
    }, 10000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  function onStart() {
    setProject({ ...project!, state: 'waitingForStart' });
  }

  function onStartCompletion() {
    setProject({ ...project!, state: 'waitingForCompletionApproval' });
  }
  function onCancelCompletion() {
    setProject({ ...project!, state: 'waitingForCompletionCancellation' });
  }

  function onApproveCompletion(tx: string) {
    setProject({
      ...project!,
      approval: { id: '', tx, state: 'waiting' },
    });
  }

  function onRate(tx: string) {
    setProject({
      ...project!,
      userRating: { id: 'rated', tx, state: 'waiting' },
    });
  }
  function onClaimReward(tx: string) {
    setProject({
      ...project!,
      rewardClaim: { id: 'claimed', tx, state: 'waiting' },
    });
  }

  useEffect(() => {
    if (!project || pollTimeout.current) return;

    if (
      (isOwner &&
        (project.state === 'waitingForStart' ||
          project.state === 'waitingForCompletionApproval' ||
          project.state === 'waitingForCompletionCancellation')) ||
      (project.approval && project.approval.state === 'waiting') ||
      (project.userRating && project.userRating.state === 'waiting') ||
      (project.rewardClaim && project.rewardClaim.state === 'waiting')
    ) {
      startPolling();
      setSuccess(undefined);
    }
  }, [project]);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label:
            user && project && project!.user.id === user.id
              ? commonTranslations.t('dashboard.pathLabels.myProjects')
              : commonTranslations.t('dashboard.pathLabels.otherProjects'),
          path:
            user && project && project!.user.id === user.id
              ? myProjectsRoute
              : otherProjectsRoute,
        },
        {
          label: project ? project.title : '',
        },
      ]}
      selectedPath={
        user && project && project!.user.id === user.id
          ? myProjectsRoute
          : otherProjectsRoute
      }
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!project && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {project && (
        <div className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1">
          <div className="flex flex-col">
            {success && <Notification color="success">{success}</Notification>}
            {isOwner && project.state === 'waiting' && (
              <Notification color="neutral">{t('waitingMsg')}</Notification>
            )}
            {isOwner && project.state === 'waitingForStart' && (
              <Notification color="neutral">
                {t('waitingForStartMsg')}
              </Notification>
            )}
            {isOwner && project.state === 'waitingForCompletionApproval' && (
              <Notification color="neutral">
                {t('waitingForCompletionApprovalMsg')}
              </Notification>
            )}
            {isOwner && project.state === 'completionApproval' && (
              <Notification color="blue">
                {t('completionApprovalMsg')}
              </Notification>
            )}
            {project.state === 'completionApproval' &&
              project.isCollaborator &&
              !project.approval && (
                <ProjectDetailApproveCompletion
                  project={project}
                  onApprove={onApproveCompletion}
                  setError={setError}
                  setLoading={setLoading}
                  loading={loading}
                />
              )}
            {project.state === 'completionApproval' &&
              project.approval &&
              project.approval.state === 'waiting' && (
                <Notification color="neutral">{t('approvingMsg')}</Notification>
              )}
            {project.state === 'completionApproval' &&
              project.approval &&
              project.approval.state === 'approved' && (
                <Notification color="success">{t('approvedMsg')}</Notification>
              )}

            {project.state === 'waitingForCompletionCancellation' &&
              isOwner && (
                <Notification color="neutral">
                  {t('completionAppovalCancellationOwnerMsg')}
                </Notification>
              )}
            {project.state === 'waitingForCompletionCancellation' &&
              !isOwner && (
                <Notification color="neutral">
                  {t('completionAppovalCancellationMsg')}
                </Notification>
              )}

            {project.state === 'rating' && isOwnerOrCollaborator && (
              <ProjectDetailRate
                project={project}
                onRate={onRate}
                loading={loading}
              />
            )}

            {project.state === 'waitingForRatingDeadline' &&
              isOwnerOrCollaborator && (
                <Notification color="success">
                  {t('ratingDeadlineMsg')}
                </Notification>
              )}

            {project.state === 'completed' && isOwnerOrCollaborator && (
              <ProjectDetailClaimReward
                project={project}
                onClaim={onClaimReward}
                loading={loading}
                setError={setError}
                setLoading={setLoading}
              />
            )}

            {(project.state === 'rating' ||
              project.state === 'waitingForRatingDeadline' ||
              project.state === 'completed') &&
              !isOwner &&
              !project.isCollaborator && (
                <Notification color="success">{t('completedMsg')}</Notification>
              )}

            <DashboardBox>
              <DashboardTitle>{t('details')}</DashboardTitle>
              <DashboardGroup className="hidden max-xl:flex">
                <DashboardGroupLabel>{t('createdBy')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <Link
                    to={userProfileRoute(project.user.id)}
                    className="text-secondary underline"
                  >
                    {getDisplayName(project.user)}
                  </Link>
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('title')}</DashboardGroupLabel>
                <DashboardGroupValue>{project.title}</DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('state')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <ProjectStateEl state={project.state} />
                </DashboardGroupValue>
              </DashboardGroup>
              {project.deadline && (
                <DashboardGroup>
                  <DashboardGroupLabel>{t('deadline')}</DashboardGroupLabel>
                  <DashboardGroupValue>
                    {!project.extendedDeadline &&
                      formatShortDate(project.deadline)}
                    {project.extendedDeadline && (
                      <>
                        <span className="mr-1">
                          {formatShortDate(project.extendedDeadline)}
                        </span>
                        <span className="text-xs text-font-grey">
                          {t('extendedDeadline', {
                            deadline: formatShortDate(project.deadline),
                          })}
                        </span>
                      </>
                    )}
                  </DashboardGroupValue>
                </DashboardGroup>
              )}
              <DashboardGroup>
                <DashboardGroupLabel>{t('description')}</DashboardGroupLabel>
                <DashboardGroupValue>{project.description}</DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('tags')}</DashboardGroupLabel>
                {project.tags.length > 0 && (
                  <Tags className="mt-1" tags={project.tags} />
                )}
                {project.tags.length < 1 && (
                  <DashboardGroupValue>-</DashboardGroupValue>
                )}
              </DashboardGroup>
            </DashboardBox>
            {project.files && project.files.length > 0 && (
              <DashboardFiles
                className="mt-6"
                title={t('files')}
                addText={t('addFile')}
                maxSize={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                defaultFiles={project.files}
                getUploadUrlApi={(fileName: string) =>
                  requestUploadForProject(fileName)
                }
                requestDownloadApi={(path) =>
                  requestProjectFileDownload(project.id, path, 'files')
                }
              />
            )}

            {project.collaboratorFiles.map((collaboratorFile) => (
              <DashboardFiles
                key={'collab-file-' + collaboratorFile.id}
                className="mt-6"
                title={t('collaboratorFiles', {
                  name: getDisplayName(collaboratorFile.user),
                })}
                addText={t('addFile')}
                maxSize={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                defaultFiles={collaboratorFile.files}
                onChangeFormFiles={() => {}}
                getUploadUrlApi={(fileName: string) =>
                  requestUploadForProject(fileName)
                }
                requestDownloadApi={(path) =>
                  requestProjectCollaboratorFileDownload(
                    collaboratorFile.id,
                    path
                  )
                }
              />
            ))}

            {project.closingFiles && project.closingFiles.length > 0 && (
              <DashboardFiles
                className="mt-6"
                title={t('closingFiles')}
                addText={t('addFile')}
                maxSize={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                defaultFiles={project.closingFiles}
                getUploadUrlApi={(fileName: string) =>
                  requestUploadForProject(fileName)
                }
                requestDownloadApi={(path) =>
                  requestProjectFileDownload(project.id, path, 'closingFiles')
                }
              />
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <DashboardUserProfile
              className="max-xl:hidden"
              user={project.user!}
            />

            {!isOwner &&
              !project.isCollaborator &&
              (project.state === 'idle' ||
                project.state === 'waitingForStart' ||
                project.state === 'started') && (
                <Link
                  to={collaboratorResponseFormRoute(project.id)}
                  className={clsx(
                    project.collaboratorResponse !== undefined
                      ? 'pointer-events-none'
                      : undefined
                  )}
                >
                  <Button
                    className="w-full"
                    disabled={project.collaboratorResponse !== undefined}
                  >
                    {t('apply')}
                  </Button>
                </Link>
                // <Button color="secondary">{t('start')}</Button>
              )}

            {!isOwner &&
              (project.state === 'idle' ||
                project.state === 'waitingForStart' ||
                project.state === 'started') && (
                <Link to={collaboratorRecommendationFormRoute(project.id)}>
                  <Button className="w-full">{t('recommend')}</Button>
                </Link>
                // <Button color="secondary">{t('start')}</Button>
              )}

            {isOwnerOrCollaborator &&
              project.state !== 'waiting' &&
              project.state !== 'idle' &&
              project.state !== 'waitingForStart' && (
                <Link to={projectMilestonesRoute(project.id)}>
                  <Button className="w-full" disabled={loading}>
                    {t('milestones')}
                  </Button>
                </Link>
              )}

            <Link to={projectCollaboratorsRoute(project.id)}>
              <Button
                className="w-full"
                disabled={project.state === 'waiting' || loading}
              >
                {t('collaborators')}
              </Button>
            </Link>

            {isOwner &&
              (project.state === 'idle' ||
                project.state === 'waitingForStart') && (
                <ProjectDetailStart
                  project={project}
                  onStart={onStart}
                  loading={loading}
                />
                // <Button color="secondary">{t('start')}</Button>
              )}

            {isOwner && project.state === 'started' && (
              <ProjectDetailStartCompletion
                project={project}
                onStart={onStartCompletion}
                // setError={setError}
                // setLoading={setLoading}
                loading={loading}
              />
            )}
            {isOwner && project.state === 'completionApproval' && (
              <ProjectDetailCancelCompletion
                project={project}
                onCancel={onCancelCompletion}
                setError={setError}
                setLoading={setLoading}
                loading={loading}
              />
            )}
            {isOwner &&
              (project.state === 'idle' ||
                project.state === 'waitingForStart' ||
                project.state === 'started') && (
                <Link to={editProjectRoute(project.id)}>
                  <Button
                    className="w-full"
                    variant="outline"
                    color="secondary"
                    disabled={loading}
                  >
                    {t('edit')}
                  </Button>
                </Link>
              )}
            {project.isCollaborator &&
              project.state === 'started' &&
              project.service && (
                <ProjectDetailUploadCollaboratorFiles
                  project={project}
                  onUpload={fetchProject}
                  loading={loading}
                />
              )}
          </div>
        </div>
      )}
    </UserDashboard>
  );
}
