import clsx from 'clsx';
import { ProjectState } from '../../../../../interfaces/project.interface';
import ProjectStateEl from '../../../../shared/project-state';

interface Props {
  state: ProjectState;
}

const stateColors: { [key in ProjectState]: string } = {
  waiting: 'text-black bg-grey-2',
  idle: 'text-black bg-grey-2',
  waitingForStart: 'text-black bg-grey-2',
  started: 'text-white bg-success',
  waitingForCompletionApproval: 'text-white bg-success',
  completionApproval: 'text-white bg-success',
  waitingForCompletionCancellation: 'text-black bg-grey-2',
  rating: 'text-white bg-success',
  waitingForRatingDeadline: 'text-white bg-success',
  completed: 'text-white bg-success',
};

function ProjectItemElState(props: Props) {
  return (
    <p
      className={clsx(
        'text-[0.5rem] px-2 py-1 rounded-full',
        stateColors[props.state]
      )}
    >
      <ProjectStateEl state={props.state} />
    </p>
  );
}

export default ProjectItemElState;
