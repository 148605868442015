import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import Web3Context from '../../../../../context/web3-context';
import {
  acceptProjectCollaboratorResponse,
  getAcceptProjectCollaboratorResponseForwardRequest,
} from '../../../../../api/projects-collaborator-responses';
import AuthContext from '../../../../../context/auth-context';
import { ProjectCollaboratorResponseDetail } from '../../../../../interfaces/project-collaborator-response.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import Button from '../../../../shared/button';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../../utils/utils';

export default function AcceptProjectCollaboratorResponse(props: {
  response: ProjectCollaboratorResponseDetail;
  onError: (error?: string) => void;
  onAccept: () => void;
}) {
  const { t } = useTranslation('project-collaborator-response');
  const [loading, setLoading] = useState(false);
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onSubmit() {
    props.onError(undefined);
    setLoading(true);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.addProjectCollaborator(
            props.response.project.contractId,
            props.response.user.walletAddress,
            new Date(),
            ''
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchAcceptForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await acceptProjectCollaboratorResponse(
            props.response.id,
            tx,
            signature
          );
          props.onAccept();
        } catch (err: any) {
          console.error('failed to accept response', err);
          props.onError(t('accept.errors.accept'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.onError(t('accept.errors.transaction'));
        setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .addProjectCollaborator(
      //     user!.walletAddress,
      //     props.response.project.contractId,
      //     props.response.user.walletAddress,
      //     new Date(),
      //     ''
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('accepted', tx);
      //     try {
      //       await acceptProjectCollaboratorResponse(props.response.id, tx);
      //       props.onAccept();
      //     } catch (err: any) {
      //       console.error('failed to accept response', err);
      //       props.onError(t('accept.errors.accept'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.onError(t('accept.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function fetchAcceptForwardRequest() {
    try {
      let hash = await getAcceptProjectCollaboratorResponseForwardRequest(
        props.response.id
      );
      return hash;
    } catch (err) {
      props.onError(t('accept.errors.accept'));

      throw err;
    }
  }

  return (
    <>
      <Button onClick={onSubmit} disabled={loading}>
        {t('accept.button')}
      </Button>
    </>
  );
}
