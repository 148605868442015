import clsx from 'clsx';
import ProjectCollaboratorRecommendationItemElState from './state';
import { Link } from 'react-router-dom';
import { ProjectCollaboratorRecommendationItem } from '../../../../../interfaces/project-collaborator-recommendation.interface';
import { projectCollaboratorRecommendationRoute } from '../../../../../utils/routes';
import UserProfileItemEl from '../../../../users/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projectId: string;
  item: ProjectCollaboratorRecommendationItem;
}

function ProjectCollaboratorRecommendationItemEl(props: Props) {
  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(props.className, 'relative'),
      }}
    >
      <Link
        to={projectCollaboratorRecommendationRoute(
          props.projectId,
          props.item.id
        )}
      >
        <UserProfileItemEl item={props.item.recommended} />
      </Link>
      <ProjectCollaboratorRecommendationItemElState state={props.item.state} />
    </div>
  );
}

export default ProjectCollaboratorRecommendationItemEl;
