import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ProjectMilestoneForm, { ProjectMilestoneFormData } from '../../form';
import Web3Context from '../../../../../context/web3-context';
import {
  updateProjectMilestone,
  getProjectMilestoneDataHashForUpdate,
  getUpdateProjectMilestoneForwardRequest,
} from '../../../../../api/project-milestones';
import AuthContext from '../../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
  ProjectMilestoneUpdate,
} from '../../../../../interfaces/project-milestones.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import DashboardTitle from '../../../../core/dashboard-layout/title';
import Notification from '../../../../shared/notification';
import Modal from '../../../../shared/modal';
import { loadEDIHProjectMilestonesContract } from '../../../../../utils/contracts/edih-project-milestones';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../utils/web3';
import { sleep } from '../../../../../utils/utils';

function ProjectMilestoneEditModal(props: {
  data: GetProjectMilestonesResult;
  milestone: ProjectMilestone;
  onClose: () => void;
  onUpdate: (update: ProjectMilestoneUpdate) => void;
}) {
  const { t } = useTranslation('project-milestone-form');
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { connect } = useContext(Web3Context);

  async function onSubmit(formData: ProjectMilestoneFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setError(undefined);
    try {
      // props.onUpdate({
      //   id: '1',
      //   title: formData.title,
      //   description: formData.description,
      //   files: props.milestone.files,
      // } as any);
      // return;
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchProjectMilestoneDataHash(formData);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectMilestonesContract =
            await loadEDIHProjectMilestonesContract(signer);
          const result =
            await edihProjectMilestonesContract.updateProjectMilestone(
              props.data.project.contractId,
              props.milestone.contractId!,
              hash
            );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchUpdateProjectMilestoneForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await updateProjectMilestone(
            props.milestone.id,
            formData,
            tx,
            signature
          );
          props.onUpdate(result);
          props.onClose();
        } catch (err: any) {
          console.error('failed to updated milestone', err);
          setError(t('errors.update.create'));
        }
        setSubmitting(false);
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.update.transaction'));
        setSubmitting(false);
      }

      // const edihProjectsContract = await loadEDIHProjectMilestonesContract();
      // edihProjectsContract
      //   .updateProjectMilestone(
      //     user!.walletAddress,
      //     props.data.project.contractId,
      //     props.milestone.contractId!,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('updated', tx);
      //     try {
      //       const result = await updateProjectMilestone(
      //         props.milestone.id,
      //         formData,
      //         tx
      //       );
      //       props.onUpdate(result);
      //       props.onClose();
      //     } catch (err: any) {
      //       console.error('failed to updated milestone', err);
      //       setError(t('errors.update.create'));
      //     }
      //     setSubmitting(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.update.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchProjectMilestoneDataHash(
    formData: ProjectMilestoneFormData
  ) {
    try {
      let hash = await getProjectMilestoneDataHashForUpdate(
        props.milestone.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.update.fetchHash'));

      throw err;
    }
  }

  async function fetchUpdateProjectMilestoneForwardRequest(
    formData: ProjectMilestoneFormData
  ) {
    try {
      return getUpdateProjectMilestoneForwardRequest(
        props.milestone.id,
        formData
      );
    } catch (err) {
      setError(t('errors.update.fetchHash'));

      throw err;
    }
  }

  function onClose() {
    if (!submitting || props.milestone.updates.length > 0) {
      props.onClose();
    }
  }

  return (
    <Modal
      className="w-full max-w-[430px]"
      contentClassName="!p-3 !pt-0"
      closeClassName="!p-0 !pt-3 !pr-3"
      onClose={onClose}
    >
      <DashboardTitle>{t('editTitle')}</DashboardTitle>
      {error && <Notification color="danger">{error}</Notification>}
      {props.milestone.updates.length > 0 && (
        <Notification color="success">{t('updating')}</Notification>
      )}
      <ProjectMilestoneForm
        data={props.data}
        milestone={props.milestone}
        onSubmit={onSubmit}
        disabled={submitting || props.milestone.updates.length > 0}
      />
    </Modal>
  );
}

export default ProjectMilestoneEditModal;
