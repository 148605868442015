import { useTranslation } from 'react-i18next';
import { ProjectCollaboratorResponseState } from '../../../interfaces/project-collaborator-response.interface';

function ProjectCollaboratorResponseStateEl(props: {
  state: ProjectCollaboratorResponseState;
}) {
  const { t } = useTranslation('project-collaborator-response-states');

  return <>{t(props.state)}</>;
}

export default ProjectCollaboratorResponseStateEl;
