import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import ProjectCollaboratorResponseItemEl from './item';
import { GetProjectCollaboratorResponsesResult } from '../../../../interfaces/project-collaborator-response.interface';
import DashboardBox from '../../../core/dashboard-layout/box';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projectId: string;
  result: GetProjectCollaboratorResponsesResult;
  notFoundText: string;
  onPageChange?: (page: number) => void;
  loading?: boolean;
}

function ProjectCollaboratorResponseList(props: Props) {
  const els = props.result.results.map((item) => (
    <ProjectCollaboratorResponseItemEl
      key={'collab-response-' + item.id}
      item={item}
      projectId={props.projectId}
    />
  ));

  return (
    <DashboardBox
      className={clsx(
        props.loading ? 'opacity-50 pointer-events-none' : undefined
      )}
    >
      <div className="grid grid-cols-5 gap-6 max-[1370px]:grid-cols-4 max-[1150px]:grid-cols-3 max-lg:grid-cols-4 max-[870px]:grid-cols-3 max-[680px]:grid-cols-2 max-[540px]:grid-cols-1">
        {els}
      </div>

      {props.result.results.length < 1 && (
        <div className="py-4 text-sm">{props.notFoundText}</div>
      )}

      {props.result.pages > 1 && props.onPageChange && (
        <div className="flex justify-center mt-8">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex space-x-4 text-xs text-font-grey"
            activeClassName="text-font-black"
            nextClassName="text-font-black"
            previousClassName="text-font-black"
            nextLabel=">"
            previousLabel="<"
            onPageChange={(item) => props.onPageChange!(item.selected)}
            pageRangeDisplayed={5}
            pageCount={props.result.pages}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </DashboardBox>
  );
}

export default ProjectCollaboratorResponseList;
