import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import HTMLReactParser from 'html-react-parser';
import AuthContext from '../../../../context/auth-context';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const levels = [100, 75, 50, 25];

function DashboardExp(props: Props) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');
  const { dashboardData } = useContext(AuthContext);
  const [level, setLevel] = useState(0);

  useEffect(() => {
    if (dashboardData) {
      for (let i = 0; i < levels.length; i++) {
        if (dashboardData.expRank <= levels[i]) {
          setLevel(i + 1);
        } else {
          break;
        }
      }
    }
  }, [dashboardData]);

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(props.className, '!p-4 relative'),
      }}
    >
      <DashboardTitle className="!mb-0">{t('exp.title')}</DashboardTitle>
      <div className="flex items-center mx-3">
        <img
          className="mr-2"
          width={32}
          height={32}
          src="/images/dashboard/exp.svg"
          alt="exp"
        />
        <div className="text-[2.25rem] font-medium">
          {user ? user.exp : '-'}
        </div>
        {level > 0 && (
          <div className="text-[0.625rem] text-font-grey ml-4 leading-[1.1] max-w-[150px]">
            {HTMLReactParser(t('exp.' + level))}
          </div>
        )}
      </div>
    </DashboardBox>
  );
}

export default DashboardExp;
