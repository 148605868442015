import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  executeTransferForwardRequest,
  getTransferForwardRequest,
  getTransferrableUsers,
} from '../../../../api/users';
import AuthContext from '../../../../context/auth-context';
import { UserProfileItem } from '../../../../interfaces/user.interface';
import { loadEDIHContract } from '../../../../utils/contracts/edih';
import { userProfileRoute } from '../../../../utils/routes';
import Button from '../../../shared/button';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import SelectUserInput from '../../../shared/form/select-user';
import UserProfileItemEl from '../../../users/item';
import Modal from '../../../shared/modal';
import Notification from '../../../shared/notification';
import Web3Context from '../../../../context/web3-context';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';

const schema = yup
  .object()
  .shape({
    user: yup.mixed().required(),
    amount: yup.number().integer().min(1),
  })
  .required();

function TransferRewardTokensModal(props: {
  user?: UserProfileItem;
  onClose: () => void;
}) {
  const { t } = useTranslation('transfer-reward-tokens');
  const [error, setError] = useState<string>();
  const [sendState, setSendState] = useState<
    'notSent' | 'sending' | 'sent' | 'confirmed'
  >('notSent');
  const { connect } = useContext(Web3Context);
  const { user, fetchDashboardData } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (props.user) {
      setValue('user', props.user);
    }
  }, [props.user]);

  async function onSubmit(formValues: any) {
    // ensure sufficient balance
    if (formValues.amount > user!.tokens) {
      return setError(t('errors.notEnough'));
    }

    setError(undefined);
    setSendState('sending');

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      try {
        if (getCurrentProviderService() === 'metamask') {
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihContract = loadEDIHContract(signer);
          const tx = await edihContract.transfer(
            formValues.user.walletAddress,
            formValues.amount
          );
          console.log('sent', tx.hash);
          setSendState('sent');

          await tx.wait();
          setSendState('confirmed');
          fetchDashboardData();
        } else {
          const forwardRequest = await getTransferForwardRequest(
            formValues.user.id,
            formValues.amount
          );
          console.log('forwardr', forwardRequest);

          const signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);

          await executeTransferForwardRequest(
            formValues.user.id,
            formValues.amount,
            signature
          );

          setSendState('sent');
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSendState('notSent');
      }

      // const edihContract = await loadEDIHContract();
      // edihContract
      //   .transfer(
      //     user!.walletAddress,
      //     formValues.user.walletAddress,
      //     formValues.amount
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('sent', tx);
      //     setSendState('sent');
      //   })
      //   .on('receipt', async (tx: string) => {
      //     console.log('confirmed', tx);
      //     setSendState('confirmed');
      //     fetchDashboardData();
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSendState('notSent');
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSendState('notSent');
    }
  }

  function onClose() {
    if (sendState !== 'sending') {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      {sendState === 'sent' && (
        <Notification color="neutral">{t('sent')}</Notification>
      )}
      {sendState === 'confirmed' && (
        <Notification color="success">{t('confirmed')}</Notification>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('user')}</FormLabel>
          {!props.user && (
            <Controller
              render={({ field }) => (
                <SelectUserInput
                  searchApi={(search: string) => getTransferrableUsers(search)}
                  onSelect={field.onChange}
                  placeholder={t('userPlaceholder')}
                  noResultsText={t('userNoResults')}
                  errorText={t('errors.user')}
                  changeText={t('userChange')}
                  error={errors.user !== undefined}
                  disabled={sendState !== 'notSent'}
                />
              )}
              control={control}
              name="user"
            />
          )}
          {props.user && (
            <a
              className="w-[190px]"
              href={userProfileRoute(props.user.id)}
              target="_blank"
            >
              <UserProfileItemEl item={props.user} />
            </a>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>{t('amount')}</FormLabel>
          <FormInput
            {...register('amount')}
            type="number"
            error={errors.amount !== undefined}
            placeholder="1"
            min="1"
            disabled={sendState !== 'notSent'}
          />
        </FormGroup>
        <Button className="w-full mt-4" disabled={sendState !== 'notSent'}>
          {t('submit')}
        </Button>
      </form>
    </Modal>
  );
}

export default TransferRewardTokensModal;
