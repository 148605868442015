import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  purchaseEventEntries,
  canPurchaseEventEntries,
  getPurchaseEventEntriesForwardRequest,
} from '../../../api/events-entries';
import AuthContext from '../../../context/auth-context';
import { EventDetail } from '../../../interfaces/event.interface';
import { loadEDIHEventsContract } from '../../../utils/contracts/edih-events';
import Button from '../../shared/button';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function EventDetailPurchase(props: {
  event: EventDetail;
  loading: boolean;
  amount: number;
  isOwner?: boolean;
  pendingPurchase?: boolean;
  onPurchase: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('event-detail');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onPurchase() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanPurchase();

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.purchaseEventEntries(
            props.event.contractId!,
            props.amount
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchPurchaseForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await purchaseEventEntries(
            props.event.id,
            props.amount,
            tx,
            signature
          );
          console.log('result', result);
          props.onPurchase(tx!);
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to purchase', err);
          props.setError(t('purchase.errors.purchase'));
          props.setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('purchase.errors.transaction'));
        props.setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .purchaseEventEntries(
      //     user!.walletAddress,
      //     props.event.contractId!,
      //     props.amount
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('purchased', tx);
      //     try {
      //       const result = await purchaseEventEntries(
      //         props.event.id,
      //         props.amount,
      //         tx
      //       );
      //       console.log('result', result);
      //       props.onPurchase(tx);
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to purchase', err);
      //       props.setError(t('purchase.errors.purchase'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('purchase.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanPurchase() {
    try {
      await canPurchaseEventEntries(props.event.id, props.amount);
    } catch (err) {
      handlePurchaseError(err);

      throw err;
    }
  }

  async function fetchPurchaseForwardRequest() {
    try {
      return getPurchaseEventEntriesForwardRequest(
        props.event.id,
        props.amount
      );
    } catch (err) {
      handlePurchaseError(err);

      throw err;
    }
  }

  function handlePurchaseError(err: any) {
    if (!axios.isAxiosError(err)) {
      props.setError(t('purchase.errors.canPurchase'));
    } else if (err.response!.data.code === 'selfPurchase') {
      props.setError(t('purchase.errors.selfPurchase'));
    } else if (err.response!.data.code === 'notEnoughTokens') {
      props.setError(t('purchase.errors.notEnoughTokens'));
    } else if (err.response!.data.code === 'supplyTooLow') {
      props.setError(t('purchase.errors.supplyTooLow'));
    } else if (err.response!.data.code === 'deadline') {
      props.setError(t('purchase.errors.deadline'));
    } else {
      props.setError(t('purchase.errors.canPurchase'));
    }
  }

  return (
    <>
      {+props.event.supply > 0 && (
        <Button
          className="flex items-center justify-center min-w-[188px]"
          size="medium"
          disabled={
            props.loading ||
            props.pendingPurchase ||
            props.isOwner ||
            props.event.state !== 'active' ||
            new Date(props.event.deadline).getTime() < Date.now()
          }
          onClick={onPurchase}
        >
          <span className="pr-1">{t('join')}</span>
          <span className="pr-1">({+props.event.price * props.amount}</span>
          <img
            src="/images/events/join.svg"
            width="10"
            height="10"
            alt="join"
          />
          <span>)</span>
        </Button>
      )}
      {+props.event.supply < 1 && (
        <Button className="min-w-[188px]" disabled size="medium">
          {t('soldOut')}
        </Button>
      )}
    </>
  );
}
