import React, { useContext, useEffect, useState } from 'react';
import DashboardHeaderMenu from './header-menu';
import DashboardLayoutMenu from './menu';
import DashboardPath, { PathItem } from './path-layout';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import { getDashboardRoute } from '../../../utils/routes';

export interface DashboardItem {
  icon: any;
  label: string;
  path: string;
}

// interface Props extends React.HTMLAttributes<HTMLDivElement> {
//   children: React.ReactNode;
// }

function DashboardLayout(props: {
  children: React.ReactNode;
  items: DashboardItem[];
  selectedPath: string;
  title: string;
  subtitle?: string;
  path?: PathItem[];
}) {
  const { user } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  // const pathname = usePathname();
  const location = useLocation();

  useEffect(() => {
    console.log(`Route changed to: ${location.pathname}`);
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <div className="h-screen flex relative">
      <DashboardLayoutMenu
        items={props.items}
        selectedPath={props.selectedPath}
        show={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <div className="flex-1 flex flex-col bg-grey-3 min-h-screen">
        <div className="flex items-center px-9 py-3 bg-white max-lg:px-4">
          <img
            className="hidden max-lg:block mr-4"
            onClick={() => setShowMenu(true)}
            src="/images/dashboard/mobile-menu.svg"
          />
          <div className="flex-1 max-lg:mr-4">
            <p className="text-2xl max-lg:text-xl max-lg:text-center">
              {props.title}
            </p>
            {props.subtitle && (
              <p className="text-sm text-font-grey max-lg:hidden">
                {props.subtitle}
              </p>
            )}
            {props.path && <DashboardPath items={props.path} />}
          </div>

          <div className="max-lg:hidden">
            <DashboardHeaderMenu />
          </div>
          <Link
            className="hidden max-lg:block"
            to={getDashboardRoute(user?.role)}
          >
            <img src="/images/dashboard/mobile-logo.png" />
          </Link>
        </div>
        <div className="px-9 py-8 flex-1 overflow-auto max-lg:px-4 max-lg:py-4">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
