import clsx from 'clsx';

function MyTableRow(props: { className?: string; children: any }) {
  return (
    <tr
      className={clsx(
        'font-normal text-sm border-t border-grey-2 text-font-black-2 py-3',
        props.className
      )}
    >
      {props.children}
    </tr>
  );
}

export default MyTableRow;
