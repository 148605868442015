import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardOtherEventIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.438 1.031a6.523 6.523 0 0 0-6.49 7.247l-5.893 8.037a1.71 1.71 0 0 0 .172 2.235l1.228 1.228a1.709 1.709 0 0 0 2.234.172l8.037-5.894a6.532 6.532 0 1 0 .712-13.025Zm4.468 6.532c.001.827-.23 1.64-.666 2.343L12.095 3.76a4.469 4.469 0 0 1 6.811 3.803ZM4.703 18.108l-.81-.81 4.828-6.586a6.572 6.572 0 0 0 2.567 2.567l-6.585 4.829ZM9.969 7.562c-.001-.828.23-1.64.666-2.343l6.145 6.145A4.468 4.468 0 0 1 9.97 7.562Z"
    />
  </svg>
);
export default DashboardOtherEventIcon;
