import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import {
  transferEventEntries,
  canTransferEventEntries,
  getTransferrableEventEntryUsers,
  getTransferEventEntriesForwardRequest,
} from '../../../../../../api/events-entries';
import AuthContext from '../../../../../../context/auth-context';
import { EventEntryItem } from '../../../../../../interfaces/event-entry.interface';
import { loadEDIHEventsContract } from '../../../../../../utils/contracts/edih-events';
import Button from '../../../../../shared/button';
import FormGroup from '../../../../../shared/form/group';
import FormLabel from '../../../../../shared/form/label';
import FormSelect from '../../../../../shared/form/select';
import SelectUserInput from '../../../../../shared/form/select-user';
import Web3Context from '../../../../../../context/web3-context';
import Notification from '../../../../../shared/notification';
import Modal from '../../../../../shared/modal';
import { UncheckedJsonRpcSigner } from '../../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../../utils/web3';
import { sleep } from '../../../../../../utils/utils';

const schema = yup
  .object()
  .shape({
    user: yup.mixed().required(),
    amount: yup.number().integer().min(1),
  })
  .required();

function EventEntryTransferModal(props: {
  item: EventEntryItem;
  usableAmount: number;
  onClose: () => void;
  onTransfer: (entries: { id: string }[], tx: string) => void;
}) {
  const { t } = useTranslation('event-entry-list');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { amount: 1 } });

  async function onSubmit(values: any) {
    setLoading(true);
    setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanUse(values.user.id, values.amount);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.safeTransferFrom(
            user!.walletAddress,
            values.user.walletAddress,
            props.item.event.contractId,
            values.amount
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchTransferEventEntriesForwardRequest(
            values.user.id,
            values.amount
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await transferEventEntries(
            props.item.event.id,
            values.user.id,
            values.amount,
            tx,
            signature
          );
          props.onTransfer(result, tx!);
          toast.success(t('transfer.transferred'), { duration: 8000 });
          setLoading(false);
        } catch (err: any) {
          console.error('failed to transfer', err);
          setError(t('transfer.errors.transfer'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('transfer.errors.transaction'));
        setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .safeTransferFrom(
      //     user!.walletAddress,
      //     values.user.walletAddress,
      //     props.item.event.contractId,
      //     values.amount
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('transferred', tx);
      //     try {
      //       const result = await transferEventEntries(
      //         props.item.event.id,
      //         values.user.id,
      //         values.amount,
      //         tx
      //       );
      //       props.onTransfer(result, tx);
      //       toast.success(t('transfer.transferred'), { duration: 8000 });
      //       setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to transfer', err);
      //       setError(t('transfer.errors.transfer'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('transfer.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function checkCanUse(userId: string, amount: number) {
    try {
      await canTransferEventEntries(props.item.event.id, userId, amount);
    } catch (err) {
      throw err;
    }
  }

  async function fetchTransferEventEntriesForwardRequest(
    userId: string,
    amount: number
  ) {
    try {
      return getTransferEventEntriesForwardRequest(
        props.item.event.id,
        userId,
        amount
      );
    } catch (err) {
      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[350px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('transfer.user')}</FormLabel>
          <Controller
            render={({ field }) => (
              <SelectUserInput
                searchApi={(search: string) =>
                  getTransferrableEventEntryUsers(props.item.event.id, search)
                }
                onSelect={field.onChange}
                placeholder={t('transfer.userPlaceholder')}
                noResultsText={t('transfer.userNoResults')}
                errorText={t('transfer.errors.user')}
                changeText={t('transfer.userChange')}
                error={errors.user !== undefined}
              />
            )}
            control={control}
            name="user"
          />
        </FormGroup>
        <FormGroup className="items-start mb-6">
          <FormLabel>{t('transfer.amountSelect')}</FormLabel>
          <FormSelect
            {...register('amount', { valueAsNumber: true })}
            className="min-w-[89px]"
          >
            {Array.from(Array(props.usableAmount).keys()).map((i) => (
              <option key={'amount-' + i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <Button className="w-full" disabled={loading}>
          {t('transfer.transfer')}
        </Button>
      </form>
    </Modal>
  );
}

export default EventEntryTransferModal;
