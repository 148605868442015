import { useTranslation } from 'react-i18next';
import { Tag } from '../../../../../interfaces/tag.interface';
import Tags from '../../../../shared/tags';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tags: Tag[];
}

function DashboardUserProfileTags(props: Props) {
  const { t } = useTranslation('dashboard-profile');

  return (
    <>
      <p className="text-xs text-font-grey pb-1">{t('hashtags')}</p>
      <Tags tags={props.tags} />
    </>
  );
}

export default DashboardUserProfileTags;
