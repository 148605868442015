import { Rating } from '@smastrom/react-rating';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { User } from '../../../../../interfaces/user.interface';
import { formatUrl } from '../../../../../utils/utils';
import { ratingStyle } from '../../../../projects/rate/modal/rating-item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
}

function DashboardUserProfileInfos(props: Props) {
  const { t } = useTranslation('dashboard-profile');
  const [info, setInfo] = useState();

  const els = (
    [
      {
        icon: '/images/dashboard/user/website.svg',
        value: props.user.website,
        url: props.user.website ? formatUrl(props.user.website) : undefined,
      },
      {
        icon: '/images/dashboard/user/email.svg',
        value: props.user.contactEmail,
        url: props.user.contactEmail
          ? `mailto:${props.user.contactEmail}`
          : undefined,
      },
      {
        icon: '/images/dashboard/user/phone.svg',
        value: props.user.phoneNumber,
      },
      {
        icon: '/images/dashboard/user/rating.svg',
        value: props.user.exp,
        // rating: props.user.rating > 0 ? props.user.rating : undefined,
      },
      // {
      //   icon: '/images/dashboard/user/rating.svg',
      //   value: props.user.rating > 0 ? props.user.rating : undefined,
      //   rating: props.user.rating > 0 ? props.user.rating : undefined,
      // },
    ] as {
      icon: string;
      value: any;
      url?: string;
      rating?: number;
    }[]
  ).map((info) => (
    <div key={'info-' + info.icon} className="flex align-center text-sm">
      <img className="mr-2" width={14} height={14} src={info.icon} alt="icon" />
      {info.url && (
        <a className="text-[#4791FF] underline" href={info.url}>
          {info.value || '-'}
        </a>
      )}
      {info.rating && (
        <div className="flex space-x-1">
          <span>{info.value}</span>
          <Rating
            className="max-w-[75px] -translate-y-[1px]"
            value={info.value!}
            itemStyles={{ ...ratingStyle, itemStrokeWidth: 2 }}
            readOnly
          />
        </div>
      )}
      {!info.url && !info.rating && <span>{info.value || '-'}</span>}
    </div>
  ));

  return (
    <>
      <p className="text-xs text-font-grey pb-1">{t('info')}</p>
      <div className="flex flex-col space-y-2">{els}</div>
    </>
  );
}

export default DashboardUserProfileInfos;
