import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../api/auth';
import SplitLayout from '../../components/core/split-layout';
import Button from '../../components/shared/button';
import FormGroup from '../../components/shared/form/group';
import FormInput from '../../components/shared/form/input';
import FormLabel from '../../components/shared/form/label';
import PasswordFormInput from '../../components/shared/form/password-input';
import ProgressLines from '../../components/shared/progress-lines';
import AuthContext from '../../context/auth-context';
import NotAuthGuard from '../../guards/not-auth-guard';
import {
  getDashboardRoute,
  resetPasswordRoute,
  signUpRoute,
} from '../../utils/routes';
import Notification from '../../components/shared/notification';

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const redirectStorageKey = 'redirect';

export default function SignInPage() {
  const { t } = useTranslation('login');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [redirect, setRedirect] = useState<string | null | undefined>();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectData = localStorage.getItem(redirectStorageKey);
    setRedirect(redirectData);

    // delayed remove to make it work in strict mode in development
    setTimeout(() => {
      localStorage.removeItem(redirectStorageKey);
    }, 500);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      const user = await login(values.email.toLowerCase(), values.password);
      authContext.login(user);

      if (redirect) {
        navigate(redirect);
      } else {
        navigate(getDashboardRoute(user.role));
      }
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response!.data.code === 'auth') {
        setError(t('errors.auth'));
      } else if (
        axios.isAxiosError(err) &&
        err.response!.data.code === 'notWhitelisted'
      ) {
        setError(t('errors.notWhitelisted'));
      } else {
        setError(t('errors.unexpected')!);
      }

      console.error('failed to login', err);
      setValue('password', '');
    }
    setLoading(false);
  }

  return (
    <NotAuthGuard>
      <SplitLayout>
        <div className="min-h-[620px] flex flex-col">
          <h1 className="text-[2.5rem] font-medium text-center mb-8">
            {t('title')}
          </h1>
          {error && <Notification color="danger">{error}</Notification>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormLabel>{t('email')}</FormLabel>
              <FormInput
                type="email"
                {...register('email')}
                error={errors.email !== undefined}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t('password')}</FormLabel>
              <PasswordFormInput
                {...register('password')}
                disabled={loading}
                error={errors.password !== undefined}
              />
              <Link
                className="text-xs underline text-right block !mt-2"
                to={resetPasswordRoute}
              >
                {t('forgot')}
              </Link>
            </FormGroup>

            <Button className="w-full mt-2" disabled={loading}>
              {t('login')}
            </Button>

            <div className="text-center mt-2 text-sm">
              <span className="pr-1">{t('signup1')}</span>
              <Link className="underline text-secondary" to={signUpRoute}>
                {t('signup2')}
              </Link>
            </div>
          </form>
          <div className="mt-auto flex justify-center">
            <ProgressLines className="w-[100px]" max={1} current={0} />
          </div>
        </div>
      </SplitLayout>
    </NotAuthGuard>
  );
}
