import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  claimEventReward,
  canClaimEventReward,
  getClaimEventRewardForwardRequest,
} from '../../../api/events';
import AuthContext from '../../../context/auth-context';
import { EventToManage } from '../../../interfaces/event.interface';
import { loadEDIHEventsContract } from '../../../utils/contracts/edih-events';
import Button from '../../shared/button';
import Notification from '../../shared/notification';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function EventDetailClaimReward(props: {
  event: EventToManage;
  loading: boolean;
  onClaim: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('manage-event');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onClaim() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanClaim();

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.claimEventReward(
            props.event.contractId!
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchClaimEventRewardForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await claimEventReward(props.event.id, tx, signature);
          props.onClaim(tx!);
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to claim', err);
          props.setError(t('claimReward.errors.end'));
          props.setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('claimReward.errors.transaction'));
        props.setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .claimEventReward(user!.walletAddress, props.event.contractId!)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('claimed', tx);
      //     try {
      //       await claimEventReward(props.event.id, tx);
      //       props.onClaim(tx);
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to claim', err);
      //       props.setError(t('claimReward.errors.end'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('claimReward.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanClaim() {
    try {
      await canClaimEventReward(props.event.id);
    } catch (err) {
      props.setError(t('claimReward.errors.canClaim'));

      throw err;
    }
  }

  async function fetchClaimEventRewardForwardRequest() {
    try {
      return getClaimEventRewardForwardRequest(props.event.id);
    } catch (err) {
      throw err;
    }
  }

  return (
    <Notification color="neutral">
      <div className="mb-2">{t('claimReward.message')}</div>
      <Button
        color="secondary"
        size="small"
        onClick={onClaim}
        disabled={props.loading}
      >
        {t('claimReward.button')}
      </Button>
    </Notification>
  );
}
