import { ProjectCollaboratorRecommendationFormData } from '../components/projects/collaborators/recommendations/form';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import {
  ProjectCollaboratorRecommendationDetail,
  ProjectCollaboratorRecommendationInviteForm,
  ProjectCollaboratorRecommendationState,
  GetProjectCollaboratorRecommendationsResult,
  GetProjectCollaboratorPlatformRecommendationsResult,
} from '../interfaces/project-collaborator-recommendation.interface';
import { RecommendationFormProject } from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import { UserProfileItem } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const getProjectCollaboratorRecommendationDataHash = async (
  projectId: string,
  formData: ProjectCollaboratorRecommendationFormData
): Promise<string> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/projects/${projectId}/recommendations/data-hash`,
    {
      ...formData,
      user: undefined,
      userId: formData.user.id,
    }
  );

  return data.data;
};

export const getProjectCollaboratorRecommendationForwardRequest = async (
  projectId: string,
  formData: ProjectCollaboratorRecommendationFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/recommendations/forward-request`,
    {
      ...formData,
      user: undefined,
      userId: formData.user.id,
    }
  );

  return data.data;
};

export const createProjectCollaboratorRecommendation = async (
  projectId: string,
  formData: ProjectCollaboratorRecommendationFormData,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/recommendations`,
    {
      ...formData,
      user: undefined,
      userId: formData.user.id,
      tx,
      signature,
    }
  );

  return data.data;
};

export const getProjectCollaboratorRecommendation = async (
  recommendationId: string
): Promise<ProjectCollaboratorRecommendationDetail> => {
  const { data } = await myAxios.get(
    `/project-collaborator-recommendations/${recommendationId}`
  );

  return data.data;
};

export const rejectProjectCollaboratorRecommendation = async (
  responseId: string,
  reason: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/project-collaborator-recommendations/${responseId}/reject`,
    { reason }
  );

  return data.data;
};

export const requestProjectCollaboratorRecommendationFileDownload = async (
  recommendationid: string,
  path: string
): Promise<string> => {
  const { data } = await myAxios.get(
    `/project-collaborator-recommendations/${recommendationid}/request-download`,
    {
      params: { path },
    }
  );

  return data.data.url;
};

export const getProjectCollaboratorRecommendationFormData = async (
  projectId: string
): Promise<RecommendationFormProject> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/recommendation-form`
  );

  return data.data;
};

export const requestUploadForProjectCollaboratorRecommendation = async (
  projectId: string,
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/recommendations/request-upload`,
    {
      fileName,
    }
  );

  return data.data;
};

export const getRecommendableUsersForProject = async (
  projectId: string,
  search: string
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/recommendable-users`,
    {
      params: { search },
    }
  );

  return data.data;
};

export const getProjectCollaboratorRecommendationInviteFormData = async (
  recommendationId: string
): Promise<ProjectCollaboratorRecommendationInviteForm> => {
  const { data } = await myAxios.get(
    `/project-collaborator-recommendations/${recommendationId}/invite-form`
  );

  return data.data;
};

export interface GetProjectCollaboratorRecommendationsFilter {
  search?: string;
  state?: ProjectCollaboratorRecommendationState;
}

export const getProjectCollaboratorRecommendations = async (
  page: number,
  projectId: string,
  filter: GetProjectCollaboratorRecommendationsFilter
): Promise<GetProjectCollaboratorRecommendationsResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/recommendations`, {
    params: { page, ...filter },
  });

  return data.data;
};

export const getProjectCollaboratorPlatformRecommendations = async (
  projectId: string
): Promise<GetProjectCollaboratorPlatformRecommendationsResult> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/heuristics-recommendations`
  );

  return data.data;
};
