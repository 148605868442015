import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import truncateEthAddress from 'truncate-eth-address';
import * as yup from 'yup';
import Web3Context from '../../../context/web3-context';
import { loadEDIHContract } from '../../../utils/contracts/edih';
import Notification from '../notification';
import Modal from '../modal';
import { getChainScannerUrl, getProvider } from '../../../utils/web3';
import Button from '../button';
import FormGroup from '../form/group';
import FormInput from '../form/input';
import FormLabel from '../form/label';
import WalletAddress from '../wallet-address';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import user from '../../core/dashboard-layout/user';

const schema = yup
  .object()
  .shape({
    amount: yup.number().integer().min(1),
  })
  .required();

function MintModal(props: { walletAddress: string; onClose: () => void }) {
  const { t } = useTranslation('mint-modal');
  const [error, setError] = useState<string>();
  const [sendState, setSendState] = useState<
    'notSent' | 'sending' | 'sent' | 'confirmed'
  >('notSent');
  const [tx, setTx] = useState<string>();
  const { connect } = useContext(Web3Context);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(formValues: any) {
    setError(undefined);
    setSendState('sending');

    try {
      await connect({
        hasToBeAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
      });

      try {
        const signer = await new UncheckedJsonRpcSigner(
          getProvider(),
          process.env.REACT_APP_ADMIN_WALLET_ADDRESS!
        );

        const edihContract = loadEDIHContract(signer);

        const tx = await edihContract.mintRewardTokens(
          props.walletAddress,
          formValues.amount
        );

        console.log('sent', tx.hash);
        setTx(tx.hash);
        setSendState('sent');

        await tx.wait();
        setSendState('confirmed');
      } catch (err: any) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSendState('notSent');
      }

      // const edihContract = await loadEDIHContract();
      // edihContract
      //   .mintRewardTokens(address, props.walletAddress, formValues.amount)
      //   .on('transactionHash', async (tx: string) => {
      //     setTx(tx);
      //     console.log('sent', tx);
      //     setSendState('sent');
      //   })
      //   .on('receipt', async (tx: string) => {
      //     console.log('confirmed', tx);
      //     setSendState('confirmed');
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSendState('notSent');
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSendState('notSent');
    }
  }

  function onClose() {
    if (sendState !== 'sending') {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      {sendState === 'sent' && (
        <Notification color="neutral">
          {parse(
            t('sent', {
              tx: truncateEthAddress(tx!),
              url: getChainScannerUrl(tx!),
            })
          )}
        </Notification>
      )}
      {sendState === 'confirmed' && (
        <Notification color="success">
          {parse(
            t('confirmed', {
              tx: truncateEthAddress(tx!),
              url: getChainScannerUrl(tx!),
            })
          )}
        </Notification>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('mintTo')}</FormLabel>
          <WalletAddress walletAddress={props.walletAddress} />
        </FormGroup>

        <FormGroup>
          <FormLabel>{t('amount')}</FormLabel>
          <FormInput
            {...register('amount')}
            type="number"
            error={errors.amount !== undefined}
            placeholder="1"
            min="1"
            disabled={sendState !== 'notSent'}
          />
        </FormGroup>
        <Button className="w-full mt-4" disabled={sendState !== 'notSent'}>
          {t('submit')}
        </Button>
      </form>
    </Modal>
  );
}

export default MintModal;
