import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  createProject,
  getProjectDataHashForCreation,
  getProject,
  getCreateProjectForwardRequest,
} from '../../../api/projects';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import ProjectForm, {
  ProjectFormData,
} from '../../../components/projects/form';
import Button from '../../../components/shared/button';
import Loading from '../../../components/shared/loading';
import AuthContext from '../../../context/auth-context';
import { ProjectState } from '../../../interfaces/project.interface';
import { loadEDIHContract } from '../../../utils/contracts/edih';
import { loadEDIHProjectsContract } from '../../../utils/contracts/edih-projects';
import { myProjectsRoute, projectRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../utils/utils';

export default function CreateProjectPage() {
  const { t } = useTranslation('create-project');
  const commonTranslations = useTranslation('common');
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [project, setProject] = useState<{ id: string; state: ProjectState }>();
  const [price, setPrice] = useState<number>(0);
  const { connect } = useContext(Web3Context);
  const waitForConfirmTimeout = useRef<any>();

  useEffect(() => {
    onConnect();
  }, []);

  async function onConnect() {
    setError(undefined);
    try {
      // get and validate wallet address
      await connect({
        hasToBeAddress: user!.walletAddress,
        noToast: true,
      });

      // fetch reward token balance
      const signer = await getProvider().getSigner();

      const edihContract = await loadEDIHContract(signer);
      const balance = await edihContract.balanceOf(user!.walletAddress);

      // fetch project creation price
      const edihProjectsContract = await loadEDIHProjectsContract(signer);
      const [creationPrice, contractUser] = await Promise.all([
        edihProjectsContract.projectCreationPrice(),
        edihContract.getUser(user!.walletAddress),
      ]);
      setPrice(Number(creationPrice));

      // check if user can affort project creation price
      if (balance < creationPrice) {
        throw t('errors.notEnough', { price: creationPrice.toString() });
      }

      // ensure at least 1 endorsement
      if (contractUser.endorsementCount < 1) {
        throw t('errors.noEndorsement');
      }

      setCanCreate(true);
    } catch (err: any) {
      console.error('failed to connect', err);
      setError(err.toString());

      setCanCreate(false);
    }
  }

  async function onSubmit(formData: ProjectFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchProjectDataHash(formData);

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.createProject(hash);

          tx = result.hash;
        } else {
          const forwardRequest = await fetchCreateProjectForwardRequest(
            formData
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await createProject(formData, tx, signature);
          setSuccess(true);
          setProject(result);
        } catch (err: any) {
          console.error('failed to create project', err);
          setError(t('errors.create'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchProjectDataHash(formData: ProjectFormData) {
    try {
      let hash = await getProjectDataHashForCreation(formData);
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }
  async function fetchCreateProjectForwardRequest(formData: ProjectFormData) {
    try {
      return getCreateProjectForwardRequest(formData);
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  useEffect(() => {
    if (project && !waitForConfirmTimeout.current) {
      startWaitingForTransactionConfirmation();
    }
  }, [project]);

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      try {
        const result = await fetchCreatedProject();

        if (result && result.state === 'waiting') {
          startWaitingForTransactionConfirmation();
        }
      } catch (err) {
        if (err === 'reverted') {
          setSuccess(false);
          setError(t('errors.waitForTransactionFail'));
        }
      }
    }, 5000);
  }

  async function fetchCreatedProject() {
    try {
      const result = await getProject(project!.id);
      setProject(result);

      return result;
    } catch (err: any) {
      console.error('failed to fetch project', err);
      if (err.response && err.response.status === 404) {
        throw 'reverted';
      }
    }
  }

  useEffect(() => {
    return () => {
      if (waitForConfirmTimeout.current) {
        clearTimeout(waitForConfirmTimeout.current);
      }
      console.log('cancel poll', waitForConfirmTimeout.current);
    };
  }, []);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myProjects'),
          path: myProjectsRoute,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.create'),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && (
        <Notification color="danger" className="max-w-[400px]">
          <div>{error}</div>
          {!canCreate && (
            <div className="mt-2 text-right">
              <Button size="small" color="secondary" onClick={onConnect}>
                {t('reconnect')}
              </Button>
            </div>
          )}
        </Notification>
      )}
      {!canCreate && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {success && (!project || project.state === 'waiting') && (
        <Notification color="neutral">{t('success')}</Notification>
      )}
      {project && project.state !== 'waiting' && (
        <Notification color="success">
          {parse(t('created', { url: projectRoute(project.id) }))}
        </Notification>
      )}
      {canCreate && (
        <ProjectForm onSubmit={onSubmit} disabled={submitting} price={price} />
      )}
    </UserDashboard>
  );
}
