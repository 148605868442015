import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RateProjectModal from './modal';
import { ProjectDetail } from '../../../interfaces/project.interface';
import { formatDateTime } from '../../../utils/date';
import Button from '../../shared/button';
import Notification from '../../shared/notification';
import ProjectDetailUploadCollaboratorFilesModal from './modal';

export default function ProjectDetailUploadCollaboratorFiles(props: {
  project: ProjectDetail;
  loading: boolean;
  onUpload: () => void;
}) {
  const { t } = useTranslation('project-detail');
  const [showModal, setShowModal] = useState(false);

  function onUpload() {
    setShowModal(false);
    props.onUpload();
  }

  return (
    <>
      <Button
        className="w-full"
        color="secondary"
        disabled={props.loading}
        onClick={() => setShowModal(true)}
      >
        {t('uploadCollaboratorFiles.button')}
      </Button>

      {showModal && (
        <ProjectDetailUploadCollaboratorFilesModal
          project={props.project}
          onClose={() => setShowModal(false)}
          onUpload={onUpload}
        />
      )}
    </>
  );
}
