import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../../../context/web3-context';
import {
  startMilestoneCompletion,
  canStartMilestoneCompletion,
  getStartProjectMilestoneCompletionForwardRequest,
} from '../../../../../api/project-milestones';
import AuthContext from '../../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
} from '../../../../../interfaces/project-milestones.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import Button from '../../../../shared/button';
import { loadEDIHProjectMilestonesContract } from '../../../../../utils/contracts/edih-project-milestones';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../utils/web3';
import { sleep } from '../../../../../utils/utils';

export default function ProjectMilestoneModalStartCompletion(props: {
  data: GetProjectMilestonesResult;
  milestone: ProjectMilestone;
  loading: boolean;
  onStart: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('project-milestones');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onStart() {
    props.setLoading(true);
    props.setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanStart();

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectMilestonesContract =
            await loadEDIHProjectMilestonesContract(signer);
          const result =
            await edihProjectMilestonesContract.markProjectMilestoneForCompletion(
              props.data.project.contractId,
              props.milestone.contractId!
            );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchStartProjectMilestoneCompletionForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await startMilestoneCompletion(props.milestone.id, tx, signature);
          props.onStart(tx!);
        } catch (err: any) {
          console.error('failed to start completion', err);
          props.setError(t('detail.errors.start.start'));
        }
        props.setLoading(false);
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('detail.errors.start.transaction'));
        props.setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectMilestonesContract();
      // edihProjectsContract
      //   .markProjectMilestoneForCompletion(
      //     user!.walletAddress,
      //     props.data.project.contractId,
      //     props.milestone.contractId!
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('started', tx);
      //     try {
      //       await startMilestoneCompletion(props.milestone.id, tx);
      //       props.onStart(tx);
      //     } catch (err: any) {
      //       console.error('failed to start completion', err);
      //       props.setError(t('detail.errors.start.start'));
      //     }
      //     props.setLoading(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('detail.errors.start.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanStart() {
    try {
      await canStartMilestoneCompletion(props.milestone.id);
    } catch (err) {
      props.setError(t('detail.errors.start.canStart'));

      throw err;
    }
  }

  async function fetchStartProjectMilestoneCompletionForwardRequest() {
    try {
      return getStartProjectMilestoneCompletionForwardRequest(
        props.milestone.id
      );
    } catch (err) {
      props.setError(t('detail.errors.start.canStart'));

      throw err;
    }
  }

  return (
    <Button className="w-full" onClick={onStart} disabled={props.loading}>
      {t('detail.startCompletion')}
    </Button>
  );
}
