import clsx from 'clsx';
import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';
import DashboardSearchFilterModal from './modal';
import { DashboardSearchFilterItem } from './modal/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  filters: DashboardSearchFilterItem[];
  values: any;
  modalClassName?: string;
  onFilterChange: (key: string, value?: string) => void;
}

function DashboardSearchFilter(props: Props) {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);

  // let className = clsx(
  //   'bg-white px-3 py-2.5 rounded-lg cursor-pointer relative',
  //   props.className
  // );

  function onClose() {
    setShow(false);
  }

  const ref = useDetectClickOutside({ onTriggered: onClose });

  return (
    <div {...props} className={clsx('relative', props.className)} ref={ref}>
      <div
        className="bg-white px-3 py-[0.7rem] rounded-lg cursor-pointer"
        onClick={() => setShow(!show)}
      >
        <img
          width={18}
          height={18}
          src="/images/dashboard/filter.svg"
          alt="filter"
        />
      </div>
      <DashboardSearchFilterModal
        items={props.filters}
        values={props.values}
        show={show}
        className={props.modalClassName}
        onChecked={(key, value) => props.onFilterChange(key, value)}
        // onClose={onClose}
      />
    </div>
  );
}

export default DashboardSearchFilter;

// [
//   {
//     title: 'Role',

//     items: [
//       {
//         label: 'Company',
//         value: 'company',
//       },
//       {
//         label: 'Individual',
//         value: 'individual',
//       },
//     ],
//   },
//   {
//     title: 'Status',
//     items: [
//       {
//         label: 'Applied',
//         value: 'applied',
//       },
//       {
//         label: 'Pending acceptance',
//         value: 'pendingAcceptance',
//       },
//       {
//         label: 'Accepted',
//         value: 'accepted',
//       },
//       {
//         label: 'Rejected',
//         value: 'rejected',
//       },
//     ],
//   },
// ]
