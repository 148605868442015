import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RateProjectModal from './modal';
import { ProjectDetail } from '../../../interfaces/project.interface';
import { formatDateTime } from '../../../utils/date';
import Button from '../../shared/button';
import Notification from '../../shared/notification';

export default function ProjectDetailRate(props: {
  project: ProjectDetail;
  loading: boolean;
  onRate: (tx: string) => void;
}) {
  const { t } = useTranslation('project-detail');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!props.project.userRating &&
        new Date(props.project.ratingDeadline!).getTime() > Date.now() && (
          <Notification color="neutral">
            <div className="mb-2">
              {parse(
                t('rate.msg', {
                  date: formatDateTime(props.project.ratingDeadline!),
                })
              )}
            </div>
            <Button
              size="small"
              color="secondary"
              onClick={() => setShowModal(true)}
              disabled={props.loading}
            >
              {t('rate.button')}
            </Button>
          </Notification>
        )}

      {!props.project.userRating &&
        new Date(props.project.ratingDeadline!).getTime() <= Date.now() && (
          <Notification color="neutral">{t('ratingMissedMsg')}</Notification>
        )}

      {props.project.userRating &&
        props.project.userRating.state === 'waiting' && (
          <Notification color="neutral">{t('ratingMsg')}</Notification>
        )}
      {props.project.userRating &&
        props.project.userRating.state === 'rated' && (
          <Notification color="neutral">
            {t('ratedMsg', {
              date: formatDateTime(props.project.ratingDeadline!),
            })}
          </Notification>
        )}

      {showModal && (
        <RateProjectModal
          project={props.project}
          onClose={() => setShowModal(false)}
          onRate={props.onRate}
        />
      )}
    </>
  );
}
