import { Rating, Star } from '@smastrom/react-rating';
import clsx from 'clsx';
import parse from 'html-react-parser';
import FormGroup from '../../../../shared/form/group';

export const ratingStyle = {
  itemShapes: Star,
  activeFillColor: '#FECA16',
  activeStrokeColor: '#FECA16',
  inactiveFillColor: '#ffffff',
  inactiveStrokeColor: '#FECA16',
  itemStrokeWidth: 1,
};

export interface ProjectRatingItem {
  userId: string;
  walletAddress?: string;
  title: string;
  rating?: number;
  readonly?: boolean;
}

export default function ProjectRatingItemEl(props: {
  rating: ProjectRatingItem;
  onRate: (rating: number) => void;
}) {
  return (
    <FormGroup
      className={clsx(props.rating.readonly ? 'opacity-50' : undefined)}
    >
      <div className="text-sm">{parse(props.rating.title)}</div>
      <Rating
        className="max-w-[150px]"
        value={props.rating.rating || 0}
        onChange={props.onRate}
        readOnly={props.rating.readonly}
        itemStyles={ratingStyle}
      />
    </FormGroup>
  );
}
