import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardActivities from '../../components/core/dashboard-layout/activities';
import DashboardDiscoverProjects from '../../components/core/dashboard-layout/discover-projects';
import DashboardExp from '../../components/core/dashboard-layout/exp';
import DashboardOverallInformation from '../../components/core/dashboard-layout/info';
import DashboardUserProfile from '../../components/core/dashboard-layout/profile';
import DashboardRequestEndorsement from '../../components/core/dashboard-layout/request-endorsements';
import DashboardTokens from '../../components/core/dashboard-layout/tokens';
import UserDashboard from '../../components/core/dashboard-layout/user';
import EventEntriesShortList from '../../components/events/entries/short-list';
import Loading from '../../components/shared/loading';
import ParticipatingProjectsOfUser from '../../components/users/participating-projects';
import AuthContext from '../../context/auth-context';
import {
  participatingProjectsRoute,
  getDashboardRoute,
  myEventEntriesRoute,
} from '../../utils/routes';
import Notification from '../../components/shared/notification';

export default function UserDashboardPage() {
  const { t } = useTranslation('dashboard');
  const { dashboardData, fetchDashboardData, user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const pollTimeout = useRef<any>();
  const navigate = useNavigate();

  useEffect(() => {
    loadDashboardData();
  }, []);

  async function loadDashboardData() {
    console.log('loadme!!!');
    try {
      await fetchDashboardData();
    } catch (err) {
      console.error('failed to load dashboard', err);
      if (!dashboardData) {
        setError(t('errors.load'));
      }
    }
  }

  console.log('dashboard?', dashboardData);

  function onUseEntry() {
    loadDashboardData();
  }

  function onTransferEntry() {
    loadDashboardData();
  }

  useEffect(() => {
    if (!dashboardData) return;

    if (
      dashboardData.entries.results.find(
        (e) => e.state === 'waitingForUse' || e.state === 'transferring'
      ) &&
      !pollTimeout.current
    ) {
      startPolling();
    } else if (
      pollTimeout.current &&
      !dashboardData.entries.results.find(
        (e) => e.state === 'waitingForUse' || e.state === 'transferring'
      )
    ) {
      stopPolling();
    }
  }, [dashboardData]);

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      loadDashboardData();
    }, 5000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  function onViewMoreProjects() {
    navigate(participatingProjectsRoute);
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      subtitle={t('dashboard.subtitle')}
      selectedPath={getDashboardRoute()}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {dashboardData && (
        <div className="w-full h-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-[1420px]:block max-[1420px]:max-w-[850px]">
          <div className="mb-8">
            <div className="grid grid-cols-[auto_1fr_1fr] grid-rows-[auto] gap-6 mb-8 max-md:grid-cols-1">
              {!dashboardData.isEndorsed && (
                <DashboardRequestEndorsement className="col-span-full" />
              )}
              <div>
                <DashboardDiscoverProjects />
              </div>
              <div>
                <DashboardOverallInformation />
              </div>
              <div className="max-sm:hidden">
                <DashboardTokens className="mb-4" />
                <DashboardExp />
                {/* <DashboardRating /> */}
              </div>
            </div>
            <ParticipatingProjectsOfUser
              className="mb-8 max-sm:mb-4"
              result={dashboardData.projects}
              title={t('projects.title')}
              notFoundText={t('projects.notFound')}
              viewMoreText={t('projects.viewAll')}
              userId={user ? user.id : ''}
              small
            />
            {/* <ProjectHorizontalList
              result={dashboardData.projects}
              title={t('projects.title')}
              notFoundText={t('projects.notFound')}
              viewMoreText={t('projects.viewAll')}
              onViewMore={onViewMoreProjects}
            /> */}
            <EventEntriesShortList
              result={dashboardData.entries}
              title={t('eventEntries.title')}
              notFoundText={t('eventEntries.notFound')}
              viewAllText={t('eventEntries.viewAll')}
              viewAllUrl={myEventEntriesRoute}
              onUse={onUseEntry}
              onTransferEntry={onTransferEntry}
            />
            {/* <EventShortList
              items={dashboardData.events}
              title={t('events.title')}
              notFoundText={t('events.notFound')}
              viewAllText={t('events.viewAll')}
              viewAllUrl={participatingEventsRoute}
            /> */}
          </div>

          <div className="max-[1420px]:hidden">
            <DashboardUserProfile className="mb-6" user={dashboardData.user} />
            <div className="flex-1">
              <DashboardActivities activities={dashboardData.activities} />
            </div>
          </div>
        </div>
      )}
      {!dashboardData && !error && <Loading fadeIn />}
    </UserDashboard>
  );
}
