import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function DashboardTitle(props: Props) {
  let className = clsx('font-medium mb-3', props.className);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default DashboardTitle;
