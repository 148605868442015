export interface DashboardOverallInformationItem {
  color: string;
  title: string;
  value: number;
  icon: string;
}

function DashboardOverallInformationItemEl(props: {
  item: DashboardOverallInformationItem;
}) {
  return (
    <div
      className="rounded-md p-5 flex flex-col items-center"
      style={{ background: props.item.color }}
    >
      <img className="mb-5" src={props.item.icon} />
      <div className="mb-2">{props.item.value}</div>
      <div className="text-[0.5rem] text-center max-md:text-xs max-[420px]:text-[0.625rem]">
        {props.item.title}
      </div>
    </div>
  );
}

export default DashboardOverallInformationItemEl;
