import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const ButtonTokensSmallIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 1.313a5.687 5.687 0 1 0 0 11.374A5.687 5.687 0 0 0 7 1.313Zm-3.697 2.61.934.933a3.481 3.481 0 0 0-.711 1.707H2.208a4.79 4.79 0 0 1 1.095-2.64ZM2.208 7.436h1.32c.08.623.325 1.213.711 1.707l-.936.934a4.79 4.79 0 0 1-1.095-2.64Zm4.354 4.355a4.791 4.791 0 0 1-2.64-1.094l.934-.933a3.482 3.482 0 0 0 1.707.707v1.32Zm0-8.264a3.481 3.481 0 0 0-1.706.711l-.934-.936a4.79 4.79 0 0 1 2.64-1.094v1.32Zm5.23 3.034h-1.32a3.481 3.481 0 0 0-.709-1.706l.934-.934a4.791 4.791 0 0 1 1.095 2.64ZM7.438 2.208a4.79 4.79 0 0 1 2.64 1.093l-.934.936a3.481 3.481 0 0 0-1.707-.711V2.208Zm0 9.584v-1.32a3.481 3.481 0 0 0 1.706-.709l.934.934a4.791 4.791 0 0 1-2.64 1.095Zm3.259-1.714-.934-.934c.386-.494.63-1.084.709-1.707h1.32a4.791 4.791 0 0 1-1.095 2.64Z"
    />
  </svg>
);
export default ButtonTokensSmallIcon;
