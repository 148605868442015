import { useTrackableWidth } from '../../../../hooks/use-trackable-width';
import { GetEventEntriesResult } from '../../../../interfaces/event-entry.interface';
import DashboardBox from '../../../core/dashboard-layout/box';
import DashboardTitle from '../../../core/dashboard-layout/title';
import EventEntryItemEl from '../item';
import { Link } from 'react-router-dom';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  result: GetEventEntriesResult;
  title: string;
  notFoundText: string;
  viewAllUrl: string;
  viewAllText: string;
  onUse: (entries: { id: string }[], tx: string) => void;
  onTransferEntry: (entries: { id: string }[], tx: string) => void;
}

function EventEntriesShortList(props: Props) {
  const { width } = useTrackableWidth();
  let maxItems = 0;

  if (width > 1490) {
    maxItems = 3;
  } else if (width > 1420) {
    maxItems = 2;
  } else if (width > 1136) {
    maxItems = 3;
  } else {
    maxItems = 2;
  }

  const els = props.result.results.map((item, i) => (
    <>
      {i < maxItems && (
        <EventEntryItemEl
          key={'entries-short-' + item.id}
          className="shrink-0 w-[200px] max-[660px]:w-auto max-[660px]:!min-h-[280px]"
          item={item}
          onUse={props.onUse}
          onTransfer={props.onTransferEntry}
        />
      )}
    </>
  ));

  return (
    <DashboardBox {...props}>
      <DashboardTitle>{props.title}</DashboardTitle>
      <div className="flex space-x-6 overflow-auto max-[660px]:grid max-[660px]:grid-cols-1 max-[660px]:space-x-0 max-[660px]:gap-6">
        {els}
        {props.result.total > maxItems && (
          <div className="px-6 flex-1 flex items-center justify-center">
            <Link
              to={props.viewAllUrl}
              className="text-secondary underline text-sm"
            >
              {props.viewAllText}
            </Link>
          </div>
        )}
      </div>

      {props.result.results.length < 1 && (
        <div className="py-12 text-sm">{props.notFoundText}</div>
      )}
    </DashboardBox>
  );
}

export default EventEntriesShortList;
