import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ProjectDetailStartCompletionModal from './modal';
import { ProjectDetail } from '../../../interfaces/project.interface';
import Button from '../../shared/button';

export default function ProjectDetailStartCompletion(props: {
  project: ProjectDetail;
  loading: boolean;
  onStart: () => void;
}) {
  const { t } = useTranslation('project-detail');
  const [showModal, setShowModal] = useState(false);

  // async function onStart() {
  //   props.setLoading(true);
  //   props.setError(undefined);

  //   try {
  //     await connect({ hasToBeAddress: user!.walletAddress });

  //     await checkCanStart();

  //     const edihProjectsContract = await loadEDIHProjectsContract();
  //     edihProjectsContract
  //       .markProjectForCompletion(user!.walletAddress, props.project.contractId)
  //       .on('transactionHash', async (tx: string) => {
  //         console.log('started', tx);
  //         try {
  //           await startProjectCompletion(props.project.id, tx);
  //           props.onStart();
  //         } catch (err: any) {
  //           console.error('failed to start completion', err);
  //           props.setError(t('startCompletion.errors.start'));
  //         }
  //         props.setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         console.error('failed to send transaction', err);
  //         props.setError(t('startCompletion.errors.transaction'));
  //         props.setLoading(false);
  //       });
  //   } catch (err: any) {
  //     console.log('failed to connect', err);
  //     props.setLoading(false);
  //   }
  // }

  // async function checkCanStart() {
  //   try {
  //     await canStartProjectCompletion(props.project.id);
  //   } catch (err) {
  //     props.setError(t('startCompletion.errors.canStart'));

  //     throw err;
  //   }
  // }

  function onStart() {
    props.onStart();
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setShowModal(true)}
        disabled={props.loading}
      >
        {t('startCompletion.button')}
      </Button>
      {showModal && (
        <ProjectDetailStartCompletionModal
          project={props.project}
          onClose={() => setShowModal(false)}
          onStart={onStart}
        />
      )}
    </>
  );
}
