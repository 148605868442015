import axios from 'axios';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getEvent } from '../../../api/events';
import DashboardBox from '../../../components/core/dashboard-layout/box';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import EventDetailIconItems from '../../../components/events/icon-items';
import EventParticipantList from '../../../components/events/participants';
import EventDetailPurchase from '../../../components/events/purchase';
import EventDetailRate from '../../../components/events/rate';
import Button from '../../../components/shared/button';
import ButtonTokensSmallIcon from '../../../components/shared/button/tokens-small';
import FormGroup from '../../../components/shared/form/group';
import FormLabel from '../../../components/shared/form/label';
import FormSelect from '../../../components/shared/form/select';
import OptimizedImage from '../../../components/shared/image';
import Loading from '../../../components/shared/loading';
import Tags from '../../../components/shared/tags';
import UserName from '../../../components/shared/user-name';
import AuthContext from '../../../context/auth-context';
import { EventDetail } from '../../../interfaces/event.interface';
import { formatDateTime } from '../../../utils/date';
import {
  myEventEntriesRoute,
  myEventsRoute,
  otherEventsRoute,
  manageEventRoute,
  userProfileRoute,
} from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

export default function EventDetailPage() {
  const { t } = useTranslation('event-detail');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<EventDetail>();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [amount, setAmount] = useState(1);
  const pollTimeout = useRef<any>();
  const [success, setSuccess] = useState<string>();

  const isOwner = event && user && event.user.id === user.id;
  const isBuyer = event && event.entries.length > 1;
  const isParticipant =
    event &&
    event.entries.find(
      (entry) => entry.state === 'used' || entry.state === 'verified'
    );

  const hasAttended =
    event && event.entries.find((entry) => entry.state === 'verified');

  const pendingPurchase =
    event &&
    event.entries.find((entry) => entry.state === 'waiting') !== undefined;

  async function fetchEvent() {
    setError(undefined);
    try {
      const result = await getEvent(eventId! as string);
      console.log('event loaded', result);

      if (event) {
        handleStateChange(result);
      }

      setEvent(result);
    } catch (err) {
      console.error('failed to get event', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'eventNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  function handleStateChange(newEvent: EventDetail) {
    if (pendingPurchase) {
      if (newEvent.entries.length < event.entries.length) {
        setError(t('purchase.errors.failedToPurchase'));
        stopPolling();
      } else if (
        newEvent.entries.find((entry) => entry.state === 'waiting') ===
        undefined
      ) {
        setSuccess(t('purchase.success', { url: myEventEntriesRoute }));
        stopPolling();
      }
    } else if (
      event!.userRating &&
      (event!.userRating as any).state === 'waiting'
    ) {
      if (!newEvent.userRating) {
        setError(t('rate.errors.failedToRate'));
        stopPolling();
      } else if (
        newEvent.userRating &&
        (newEvent!.userRating as any).state === 'rated'
      ) {
        setSuccess(t('rate.success'));
        stopPolling();
      }
    }
  }

  useEffect(() => {
    fetchEvent();
  }, [eventId!]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  function onPurchase(tx: string) {
    const entries = Array.from(Array(amount).keys()).map((i) => {
      return {
        id: 'entry',
        createdAt: new Date().toISOString(),
        tx,
        state: 'waiting',
      };
    });

    setEvent({
      ...event!,
      entries: event!.entries.concat(...(entries as any)),
    });

    setSuccess(undefined);

    console.log('new event', {
      ...event!,
      entries: event!.entries.concat(...(entries as any)),
    });
  }

  function onRate(tx: string, rating: number) {
    setEvent({
      ...event!,
      userRating: { id: 'rated', tx, state: 'waiting' as any, rating },
    });
    setSuccess(undefined);
  }

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      console.log('poll');
      fetchEvent();
    }, 10000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  useEffect(() => {
    if (!event || pollTimeout.current) return;

    if (
      (event.userRating && (event.userRating as any).state === 'waiting') ||
      pendingPurchase
    ) {
      startPolling();
      setSuccess(undefined);
    }
  }, [event]);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label:
            user && event && event!.user.id === user.id
              ? commonTranslations.t('dashboard.pathLabels.myEvents')
              : commonTranslations.t('dashboard.pathLabels.otherEvents'),
          path:
            user && event && event!.user.id === user.id
              ? myEventsRoute
              : otherEventsRoute,
        },
        {
          label: event ? event.title : '',
        },
      ]}
      selectedPath={
        user && event && event!.user.id === user.id
          ? myEventsRoute
          : otherEventsRoute
      }
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!event && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {event && (
        <div className="w-full max-w-[1200px] flex flex-col">
          {success && (
            <Notification color="success">{parse(success)}</Notification>
          )}

          {event.state === 'waiting' && (
            <Notification color="neutral">{t('waitingMsg')}</Notification>
          )}

          {pendingPurchase && (
            <Notification color="success">
              {t('pendingPurchaseMsg')}
            </Notification>
          )}

          {!isParticipant && !isOwner && event.state !== 'active' && (
            <Notification color="neutral">{t('endedMsg')}</Notification>
          )}

          {isOwner &&
            event.state === 'rating' &&
            new Date(event.ratingDeadline!).getTime() > Date.now() && (
              <Notification color="neutral">
                {t('ratingOwnerMsg', {
                  ratingDeadline: formatDateTime(event.ratingDeadline!),
                })}
              </Notification>
            )}

          {isOwner &&
            event.state === 'rating' &&
            new Date(event.ratingDeadline!).getTime() <= Date.now() && (
              <Notification color="neutral">
                {parse(
                  t('ratingOwnerDeadlineMsg', {
                    url: manageEventRoute(event.id),
                  })
                )}
              </Notification>
            )}

          {hasAttended && event.state === 'rating' && (
            <EventDetailRate event={event} onRate={onRate} loading={loading} />
          )}

          <DashboardBox className="mb-6 grid grid-cols-[auto_490px] gap-6 max-[1450px]:grid-cols-1">
            <div className="relative">
              <OptimizedImage
                className="w-full rounded-lg overflow-hidden"
                params={{ w: '600' }}
                src={event.image}
              />
              <Button
                className="absolute top-3 right-3 min-w-[60px] !cursor-auto"
                color="gradient"
                size="small"
                icon={() => <ButtonTokensSmallIcon className="mr-2" />}
              >
                {event.price}
              </Button>
            </div>

            <div>
              <div className="flex">
                <Link to={userProfileRoute(event.user.id)}>
                  <div className="flex items-center">
                    {event.user.image && (
                      <OptimizedImage
                        className="w-[28px] h-[28px] rounded-full mr-2"
                        params={{ w: '40', h: '40' }}
                        src={event.user.image}
                      />
                    )}
                    {!event.user.image && (
                      <div className="w-[28px] h-[28px] rounded-full mr-2 bg-grey-2 aspect-[1/1]" />
                    )}
                    <div className="text-xs flex">
                      <p className="mr-1 text-font-grey">{t('by')}</p>
                      <p className="font-medium">
                        <UserName obj={event.user} />
                      </p>
                    </div>
                  </div>
                </Link>
                {isOwner && (
                  <Link className="ml-auto" to={manageEventRoute(event.id)}>
                    <Button size="small">{t('manage')}</Button>
                  </Link>
                )}
              </div>
              <h2 className="text-2xl font-medium mt-3 mb-2">{event.title}</h2>
              <Tags tags={event.tags} small className="!space-x-1.5" />
              <div className="my-3">
                <EventDetailIconItems
                  items={[
                    {
                      image: '/images/events/time.svg',
                      text: formatDateTime(event.startDate),
                    },
                    {
                      image: '/images/events/map.svg',
                      text: event.location,
                    },
                    {
                      image: '/images/events/ticket.svg',
                      text: t('supply', { supply: event.supply }),
                    },
                  ]}
                />
              </div>
              <FormGroup className="items-start mb-3">
                <FormLabel>{t('amountSelect')}</FormLabel>
                <FormSelect
                  className="min-w-[89px]"
                  disabled={
                    loading ||
                    +event.supply < 1 ||
                    event.state !== 'active' ||
                    pendingPurchase
                  }
                  onChange={(event) => setAmount(+event.currentTarget.value)}
                >
                  {Array.from(Array(+event.supply).keys()).map((i) => (
                    <option key={'amount-' + i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
              <div className="flex mb-3 space-x-3">
                <EventDetailPurchase
                  event={event}
                  loading={loading}
                  amount={amount}
                  onPurchase={onPurchase}
                  setLoading={setLoading}
                  setError={setError}
                  isOwner={isOwner}
                  pendingPurchase={pendingPurchase}
                />
                {/* {isOwner && event.state === 'active' && (
                  <EventDetailEnd
                    event={event}
                    loading={loading}
                    onEnd={onEnd}
                    setLoading={setLoading}
                    setError={setError}
                  />
                )} */}
              </div>
              <div className="font-medium text-sm mb-1">{t('description')}</div>
              <div className="text-sm whitespace-pre-line">
                {event.description}
              </div>
            </div>
          </DashboardBox>
          {event.participants.results.length > 0 && (
            <EventParticipantList
              participants={event.participants}
              title={t('participants')}
            />
          )}
        </div>
      )}
    </UserDashboard>
  );
}
