import clsx from 'clsx';
import ProjectItemElMilestoneProgress from './milestone-progress';
import ProjectItemElParticipants from './participants';
import ProjectItemElState from './state';
import { useNavigate } from 'react-router-dom';
import { ProjectItem } from '../../../../interfaces/project.interface';
import { projectRoute } from '../../../../utils/routes';
import Tags from '../../../shared/tags';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: ProjectItem;
}

function ProjectItemEl(props: Props) {
  const navigate = useNavigate();

  function onClickProject() {
    navigate(projectRoute(props.item.id));
  }

  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(
          props.className,
          'flex flex-col min-h-[220px] h-full cursor-pointer'
        ),
      }}
      onClick={onClickProject}
    >
      <div className="flex items-start mb-3 space-x-2">
        <ProjectItemElParticipants item={props.item} />
        <ProjectItemElState state={props.item.state} />
      </div>
      <div className="text-sm font-medium mb-auto pb-3">{props.item.title}</div>
      <Tags tags={props.item.tags} small />
      <div className="h-[1px] bg-[#E0E0E0] my-3"></div>
      <ProjectItemElMilestoneProgress item={props.item} />
    </div>
  );
}

export default ProjectItemEl;
