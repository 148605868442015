import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RateEventModal from './modal';
import { EventDetail } from '../../../interfaces/event.interface';
import { formatDateTime } from '../../../utils/date';
import Button from '../../shared/button';
import Notification from '../../shared/notification';

export default function EventDetailRate(props: {
  event: EventDetail;
  loading: boolean;
  onRate: (tx: string, rating: number) => void;
}) {
  const { t } = useTranslation('event-detail');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!props.event.userRating &&
        new Date(props.event.ratingDeadline!).getTime() > Date.now() && (
          <Notification color="neutral">
            <div className="mb-2">
              {parse(
                t('rate.msg', {
                  date: formatDateTime(props.event.ratingDeadline!),
                })
              )}
            </div>
            <Button
              size="small"
              color="secondary"
              onClick={() => setShowModal(true)}
              disabled={props.loading}
            >
              {t('rate.button')}
            </Button>
          </Notification>
        )}

      {!props.event.userRating &&
        new Date(props.event.ratingDeadline!).getTime() <= Date.now() && (
          <Notification color="neutral">{t('ratingMissedMsg')}</Notification>
        )}

      {props.event.userRating &&
        (props.event.userRating.state as any) === 'waiting' && (
          <Notification color="neutral">{t('ratingMsg')}</Notification>
        )}
      {props.event.userRating &&
        (props.event.userRating.state as any) === 'rated' && (
          <Notification color="neutral">
            {t('ratedMsg', { reward: props.event.userRating.reward })}
          </Notification>
        )}

      {showModal && (
        <RateEventModal
          event={props.event}
          onClose={() => setShowModal(false)}
          onRate={props.onRate}
        />
      )}
    </>
  );
}
