import axios from 'axios';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getEventToManage } from '../../../../api/events';
import DashboardBox from '../../../../components/core/dashboard-layout/box';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import EventDetailCheckRatingDeadline from '../../../../components/events/check-rating-deadline';
import EventDetailClaimReward from '../../../../components/events/claim-reward';
import EventDetailEnd from '../../../../components/events/end';
import EventDetailEntryScanners from '../../../../components/events/entry-scanners';
import EventDetailIconItems from '../../../../components/events/icon-items';
import EventEntryTable from '../../../../components/events/manage/entries';
import Button from '../../../../components/shared/button';
import ButtonTokensSmallIcon from '../../../../components/shared/button/tokens-small';
import EventStateEl from '../../../../components/shared/event-state';
import OptimizedImage from '../../../../components/shared/image';
import Loading from '../../../../components/shared/loading';
import Tags from '../../../../components/shared/tags';
import AuthContext from '../../../../context/auth-context';
import { EventToManage } from '../../../../interfaces/event.interface';
import { formatDateTime } from '../../../../utils/date';
import {
  myEventsRoute,
  eventRoute,
  userProfileRoute,
  editEventRoute,
  scanOwnEventEntriesRoute,
} from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';
import UserName from '../../../../components/shared/user-name';

export default function ManageEventPage() {
  const { t } = useTranslation('manage-event');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<EventToManage>();
  const { fetchDashboardData } = useContext(AuthContext);
  const navigate = useNavigate();
  const { eventId } = useParams();
  const pollTimeout = useRef<any>();
  const [success, setSuccess] = useState<string>();

  async function fetchEvent() {
    setError(undefined);
    try {
      const result = await getEventToManage(eventId! as string);
      console.log('event loaded', result);

      if (event) {
        handleStateChange(result);
      }

      setEvent(result);
    } catch (err) {
      console.error('failed to get event', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'eventNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  function handleStateChange(newEvent: EventToManage) {
    if (event!.state === 'waitingForRating') {
      if (newEvent.state === 'active') {
        setError(t('end.errors.failedToEnd'));
        stopPolling();
      } else if (newEvent.state === 'rating') {
        // setSuccess(
        //   t('end.success', {
        //     ratingDeadline: formatDateTime(newEvent.ratingDeadline!),
        //   })
        // );
        stopPolling();
      }
    } else if (event!.state === 'completed') {
      if (!newEvent.rewardClaimState) {
        setError(t('claimReward.errors.failedToClaim'));
        stopPolling();
      } else if (newEvent.rewardClaimState === 'claimed') {
        stopPolling();
        fetchDashboardData();
      }
    } else if (event!.state === 'waitingForRatingDeadline') {
      if (newEvent.state === 'rating') {
        setError(t('checkForRatingDeadline.errors.failedToCheck'));
        stopPolling();
      } else if (newEvent.state === 'completed') {
        stopPolling();
      }
    }
  }

  useEffect(() => {
    fetchEvent();
  }, [eventId!]);

  function onEnd() {
    setEvent({ ...event!, state: 'waitingForRating' });
  }

  function onCheckRatingDeadline() {
    setEvent({ ...event!, state: 'waitingForRatingDeadline' });
  }

  function onClaimReward(tx: string) {
    setEvent({
      ...event!,
      rewardClaimState: 'waiting',
      rewardClaimTx: tx,
    });
  }

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      console.log('poll');
      fetchEvent();
    }, 10000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (!event || pollTimeout.current) return;

    if (
      event.state === 'waitingForRating' ||
      event.state === 'waitingForRatingDeadline' ||
      event.rewardClaimState === 'waiting'
    ) {
      startPolling();
      setSuccess(undefined);
    }
  }, [event]);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myEvents'),
          path: myEventsRoute,
        },
        {
          label: event ? event.title : '',
          path: event ? eventRoute(event.id) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.manage'),
        },
      ]}
      selectedPath={myEventsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!event && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {event && (
        <div className="w-full max-w-[1200px] flex flex-col">
          {success && (
            <Notification color="success">{parse(success)}</Notification>
          )}

          {event.state === 'waitingForRating' && (
            <Notification color="neutral">
              {t('waitingForRatingMsg')}
            </Notification>
          )}
          {event.state === 'rating' &&
            new Date(event.ratingDeadline!).getTime() > Date.now() && (
              <Notification color="neutral">
                {t('end.success', {
                  ratingDeadline: formatDateTime(event.ratingDeadline!),
                })}
              </Notification>
            )}
          {event.state === 'rating' &&
            new Date(event.ratingDeadline!).getTime() <= Date.now() && (
              <EventDetailCheckRatingDeadline
                event={event}
                loading={loading}
                onCheck={onCheckRatingDeadline}
                setLoading={setLoading}
                setError={setError}
              />
            )}
          {event.state === 'waitingForRatingDeadline' && (
            <Notification color="neutral">
              {t('waitingForRatingDeadlineMsg')}
            </Notification>
          )}

          {event.state === 'completed' && !event.rewardClaimState && (
            <EventDetailClaimReward
              event={event}
              loading={loading}
              onClaim={onClaimReward}
              setLoading={setLoading}
              setError={setError}
            />
          )}

          {event.rewardClaimState === 'waiting' && (
            <Notification color="neutral">
              {t('waitingForClaimMsg')}
            </Notification>
          )}

          {event.rewardClaimState === 'claimed' && (
            <Notification color="success">
              {parse(
                t('rewardClaimedMsg', {
                  reward: event.reward,
                  rating: event.rating,
                })
              )}
            </Notification>
          )}

          <DashboardBox className="mb-6 grid grid-cols-[auto_490px] gap-6 max-[1450px]:grid-cols-1">
            <div className="relative">
              <OptimizedImage
                className="w-full rounded-lg overflow-hidden"
                params={{ w: '600' }}
                src={event.image}
              />
              <Button
                className="absolute top-3 right-3 min-w-[60px] !cursor-auto"
                color="gradient"
                size="small"
                icon={() => <ButtonTokensSmallIcon className="mr-2" />}
              >
                {event.price}
              </Button>
            </div>

            <div>
              <Link to={userProfileRoute(event.user.id)}>
                <div className="flex items-center">
                  {event.user.image && (
                    <OptimizedImage
                      className="w-[28px] h-[28px] rounded-full mr-2"
                      params={{ w: '40', h: '40' }}
                      src={event.user.image}
                    />
                  )}
                  {!event.user.image && (
                    <div className="w-[28px] h-[28px] rounded-full mr-2 bg-grey-2 aspect-[1/1]" />
                  )}
                  <div className="text-xs flex">
                    <p className="mr-1 text-font-grey">{t('by')}</p>
                    <p className="font-medium">
                      <UserName obj={event.user} />
                    </p>
                  </div>
                </div>
              </Link>
              <h2 className="text-2xl font-medium mt-3 mb-2">{event.title}</h2>
              <Tags tags={event.tags} small className="!space-x-1.5" />
              <div className="my-3">
                <EventDetailIconItems
                  items={[
                    {
                      image: '/images/events/time.svg',
                      text: formatDateTime(event.startDate),
                    },
                    {
                      image: '/images/events/map.svg',
                      text: event.location,
                    },
                    {
                      image: '/images/events/ticket.svg',
                      text: t('supply', { supply: event.supply }),
                    },
                  ]}
                />
              </div>
              <div className="space-x-1 mb-1 text-sm">
                <span>{t('state')}</span>
                <span className="font-medium">
                  <EventStateEl state={event.state} />
                </span>
              </div>
              <div className="space-x-1  text-sm">
                <span>{t('sold')}</span>
                <span className="font-medium">{event.sold}</span>
              </div>

              <div className="flex mt-5 space-x-3 max-[500px]:space-x-0 max-[500px]:flex max-[500px]:flex-col max-[500px]:space-y-3">
                {event.state === 'active' && (
                  <>
                    <Link
                      className="min-w-[188px] flex flex-col"
                      to={editEventRoute(event.id)}
                    >
                      <Button size="medium" disabled={loading}>
                        {t('edit')}
                      </Button>
                    </Link>
                    <EventDetailEnd
                      event={event}
                      loading={loading}
                      onEnd={onEnd}
                      setLoading={setLoading}
                      setError={setError}
                    />
                  </>
                )}
              </div>
              <div className="flex mt-3 space-x-3 max-[500px]:space-x-0 max-[500px]:flex max-[500px]:flex-col max-[500px]:space-y-3">
                {event.state === 'active' && (
                  <>
                    <Link
                      className="min-w-[188px] flex flex-col"
                      to={scanOwnEventEntriesRoute(event.id)}
                      target="_blank"
                    >
                      <Button color="success" size="medium" disabled={loading}>
                        {t('scan')}
                      </Button>
                    </Link>
                    <EventDetailEntryScanners event={event} loading={loading} />
                  </>
                )}
              </div>
            </div>
          </DashboardBox>
          <EventEntryTable eventId={event.id} />
        </div>
      )}
    </UserDashboard>
  );
}
