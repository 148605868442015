import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { deleteEventEntryScanner } from '../../../../../api/events-entry-scanners';
import { EventEntryScannerItem } from '../../../../../interfaces/event-entry-scanner.interface';
import { EventToManage } from '../../../../../interfaces/event.interface';
import Button from '../../../../shared/button';
import MyTableRow from '../../../../shared/table/row';

function EventDetailEntryScannersItem(props: {
  event: EventToManage;
  item: EventEntryScannerItem;
  onRemove: () => void;
}) {
  const { t } = useTranslation('manage-event');
  const [removing, setRemoving] = useState(false);

  async function onRemove() {
    setRemoving(true);
    try {
      await deleteEventEntryScanner(props.item.id);
      props.onRemove();
    } catch (err) {
      toast.error(t('manageScanners.errors.remove'), { duration: 5000 });
    }
    setRemoving(false);
  }

  return (
    <MyTableRow
      className={clsx(removing ? 'pointer-events-none opacity-50' : undefined)}
    >
      <td>{props.item.email}</td>
      <td>
        {props.event.state === 'active' && (
          <div className="flex justify-end">
            <Button size="small" color="danger" onClick={onRemove}>
              {t('manageScanners.remove')}
            </Button>
          </div>
        )}
      </td>
    </MyTableRow>
  );
}

export default EventDetailEntryScannersItem;
