import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ProjectMilestoneModalApproveCompletion from './approve-completion';
import ProjectMilestoneModalCancelCompletion from './cancel-completion';
import ProjectMilestoneEditModal from './edit';
import ProjectMilestoneModalStartCompletion from './start-completion';
import AuthContext from '../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
  ProjectMilestoneUpdate,
} from '../../../../interfaces/project-milestones.interface';
import { formatShortDate } from '../../../../utils/date';
import DashboardGroup from '../../../core/dashboard-layout/group';
import DashboardGroupLabel from '../../../core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../core/dashboard-layout/group/value';
import DashboardTitle from '../../../core/dashboard-layout/title';
import Button from '../../../shared/button';
import ProjectMilestoneStateEl from '../../../shared/project-milestone-state';
import Modal from '../../../shared/modal';
import Notification from '../../../shared/notification';

export default function ProjectMilestoneModal(props: {
  data: GetProjectMilestonesResult;
  milestone: ProjectMilestone;
  onClose: () => void;
  onUpdate: (update: any) => void;
  onApprove: (tx: string) => void;
}) {
  const { t } = useTranslation('project-milestones');
  const [error, setError] = useState<string>();
  const [edit, setEdit] = useState(false);

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const isOwner = user && props.data.project.userId === user.id;

  function onUpdate(update: ProjectMilestoneUpdate) {
    props.onUpdate({ updates: [update] });
  }

  function onStartCompletion(tx: string) {
    props.onUpdate({ state: 'waitingForCompletionInitiation', completeTx: tx });
  }

  function onCancelCompletion(tx: string) {
    props.onUpdate({
      state: 'waitingForCancelCompletion',
      cancelCompletionTx: tx,
    });
  }

  const approval = props.data.approvals.find(
    (a) => a.projectMilestoneId === props.milestone.id
  );

  const milestone =
    props.milestone.updates.length > 0
      ? { ...props.milestone, ...props.milestone.updates[0] }
      : props.milestone;

  return (
    <>
      <Modal
        className="w-full max-w-[430px]"
        contentClassName="!p-3 !pt-0"
        closeClassName="!p-0 !pt-3 !pr-3"
        onClose={props.onClose}
      >
        <DashboardTitle>{t('detail.modalTitle')}</DashboardTitle>
        {isOwner && props.milestone.updates.length > 0 && (
          <Notification color="neutral">
            {t('detail.pendingUpdate')}
          </Notification>
        )}
        {isOwner &&
          props.milestone.state === 'waitingForCompletionInitiation' && (
            <Notification color="neutral">
              {t('detail.waitingForCompletionInitiation')}
            </Notification>
          )}
        {isOwner && props.milestone.state === 'completionApproval' && (
          <Notification color="neutral">
            {t('detail.completionApproval')}
          </Notification>
        )}
        {approval && approval.state === 'waiting' && (
          <Notification color="neutral">{t('detail.approving')}</Notification>
        )}

        {approval && approval.state === 'approved' && (
          <Notification color="success">{t('detail.approved')}</Notification>
        )}
        {!isOwner && props.milestone.state === 'waitingForCancelCompletion' && (
          <Notification color="neutral">
            {t('detail.waitingForCancelCompletion')}
          </Notification>
        )}

        {isOwner && props.milestone.state === 'waitingForCancelCompletion' && (
          <Notification color="neutral">
            {t('detail.waitingForCancelCompletionOwner')}
          </Notification>
        )}

        {error && <Notification color="danger">{error}</Notification>}
        <DashboardGroup>
          <DashboardGroupLabel>{t('detail.title')}</DashboardGroupLabel>
          <DashboardGroupValue>{milestone.title}</DashboardGroupValue>
        </DashboardGroup>
        <DashboardGroup>
          <DashboardGroupLabel>{t('detail.state')}</DashboardGroupLabel>
          <DashboardGroupValue>
            <ProjectMilestoneStateEl state={milestone.state} />
          </DashboardGroupValue>
        </DashboardGroup>
        <DashboardGroup>
          <DashboardGroupLabel>{t('detail.description')}</DashboardGroupLabel>
          <DashboardGroupValue>{milestone.description}</DashboardGroupValue>
        </DashboardGroup>
        <DashboardGroup>
          <DashboardGroupLabel>{t('detail.deadline')}</DashboardGroupLabel>
          <DashboardGroupValue>
            {formatShortDate(milestone.deadline)}
          </DashboardGroupValue>
        </DashboardGroup>
        {/* <DashboardGroup>
          <DashboardGroupLabel className="mb-2">
            {t('detail.files')}
          </DashboardGroupLabel>
          <DashboardGroupValue>
            <MilestoneFiles
              title={t('detail.files')}
              addText={t('addFile')}
              maxSize={+process.env.REACT_APP_PROJECT_MILESTONE_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_MILESTONE_MAX_FILE_SIZE!
              }
              defaultFiles={props.milestone.files}
              requestDownloadApi={(path) =>
                requestProjectMilestoneFileDownload(props.milestone.id, path)
              }
            />
          </DashboardGroupValue>
        </DashboardGroup> */}
        {props.data.project.state === 'started' && (
          <div className="space-y-3">
            {isOwner && props.milestone.state === 'active' && (
              <ProjectMilestoneModalStartCompletion
                data={props.data}
                milestone={props.milestone}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                onStart={onStartCompletion}
              />
            )}

            {isOwner && props.milestone.state === 'active' && (
              <Button
                className="w-full"
                color="secondary"
                onClick={() => setEdit(true)}
                disabled={props.milestone.updates.length > 0 || loading}
              >
                {t('detail.edit')}
              </Button>
            )}

            {isOwner && props.milestone.state === 'completionApproval' && (
              <ProjectMilestoneModalCancelCompletion
                data={props.data}
                milestone={props.milestone}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                onCancel={onCancelCompletion}
              />
            )}

            {props.milestone.state === 'completionApproval' &&
              !isOwner &&
              !approval && (
                <ProjectMilestoneModalApproveCompletion
                  data={props.data}
                  milestone={props.milestone}
                  loading={loading}
                  setLoading={setLoading}
                  setError={setError}
                  onApprove={props.onApprove}
                />
              )}
          </div>
        )}
      </Modal>
      {edit && (
        <ProjectMilestoneEditModal
          data={props.data}
          milestone={props.milestone}
          onUpdate={onUpdate}
          onClose={() => setEdit(false)}
        />
      )}
    </>
  );
}
