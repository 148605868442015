import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardMyEventsIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.594 9.316a1.72 1.72 0 0 0 1.375-1.684V5.5a1.719 1.719 0 0 0-1.719-1.719H2.75A1.719 1.719 0 0 0 1.031 5.5v2.132a1.719 1.719 0 0 0 1.375 1.684 1.719 1.719 0 0 1 0 3.368 1.719 1.719 0 0 0-1.375 1.684V16.5a1.719 1.719 0 0 0 1.719 1.719h16.5a1.718 1.718 0 0 0 1.719-1.719v-2.132a1.72 1.72 0 0 0-1.375-1.684 1.719 1.719 0 0 1 0-3.368Zm-16.5 5.323a3.782 3.782 0 0 0 0-7.278V5.844h4.125v10.312H3.094V14.64Zm15.812 0v1.517H9.281V5.844h9.625V7.36a3.781 3.781 0 0 0 0 7.278Z"
    />
  </svg>
);
export default DashboardMyEventsIcon;
