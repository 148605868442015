import { ProjectMilestoneFormData } from '../components/projects/milestones/form';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
  ProjectMilestoneUpdate,
} from '../interfaces/project-milestones.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import myAxios from '../utils/my-axios';

export const getProjectMilestones = async (
  projectId: string
): Promise<GetProjectMilestonesResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/milestones`);

  return data.data;
};

export const getProjectMilestoneDataHashForCreation = async (
  projectId: string,
  formData: ProjectMilestoneFormData
): Promise<string> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/projects/${projectId}/milestones/data-hash`,
    formData
  );

  return data.data;
};

export const getCreateProjectMilestoneForwardRequest = async (
  projectId: string,
  formData: ProjectMilestoneFormData
): Promise<ForwardRequestTypedData> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/projects/${projectId}/milestones/forward-request`,
    formData
  );

  return data.data;
};

export const createProjectMilestone = async (
  projectId: string,
  formData: ProjectMilestoneFormData,
  tx?: string,
  signature?: string
): Promise<ProjectMilestone> => {
  const { data } = await myAxios.post(`/projects/${projectId}/milestones`, {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export const requestUploadForProjectMilestone = async (
  projectId: string,
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/milestones/request-upload`,
    {
      fileName,
    }
  );

  return data.data;
};

export const requestProjectMilestoneFileDownload = async (
  milestoneId: string,
  path: string
): Promise<string> => {
  const { data } = await myAxios.get(
    `/project-milestones/${milestoneId}/request-download`,
    {
      params: { path },
    }
  );

  return data.data.url;
};

export const getProjectMilestoneDataHashForUpdate = async (
  projectMilestoneId: string,
  formData: ProjectMilestoneFormData
): Promise<string> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/project-milestones/${projectMilestoneId}/data-hash`,
    formData
  );

  return data.data;
};

export const getUpdateProjectMilestoneForwardRequest = async (
  projectMilestoneId: string,
  formData: ProjectMilestoneFormData
): Promise<ForwardRequestTypedData> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/project-milestones/${projectMilestoneId}/update-forward-request`,
    formData
  );

  return data.data;
};

export const updateProjectMilestone = async (
  projectMilestoneId: string,
  formData: ProjectMilestoneFormData,
  tx?: string,
  signature?: string
): Promise<ProjectMilestoneUpdate> => {
  console.log('myform', formData);
  const { data } = await myAxios.patch(
    `/project-milestones/${projectMilestoneId}`,
    {
      ...formData,
      tx,
      signature,
    }
  );

  return data.data;
};

export const canStartMilestoneCompletion = async (
  projectMilestoneId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/can-start-completion`
  );

  return data.data;
};

export const getStartProjectMilestoneCompletionForwardRequest = async (
  projectMilestoneId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/start-completion-forward-request`
  );

  return data.data;
};

export const startMilestoneCompletion = async (
  projectMilestoneId: string,
  tx?: string,
  signature?: string
): Promise<ProjectMilestone> => {
  const { data } = await myAxios.post(
    `/project-milestones/${projectMilestoneId}/start-completion`,
    { tx, signature }
  );

  return data.data;
};

export const canCancelMilestoneCompletion = async (
  projectMilestoneId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/can-cancel-completion`
  );

  return data.data;
};

export const getCancelProjectMilestoneCompletionForwardRequest = async (
  projectMilestoneId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/cancel-completion-forward-request`
  );

  return data.data;
};

export const cancelMilestoneCompletion = async (
  projectMilestoneId: string,
  tx?: string,
  signature?: string
): Promise<ProjectMilestone> => {
  const { data } = await myAxios.post(
    `/project-milestones/${projectMilestoneId}/cancel-completion`,
    { tx, signature }
  );

  return data.data;
};

export const canApproveMilestoneCompletion = async (
  projectMilestoneId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/can-approve`
  );

  return data.data;
};

export const getApproveProjectMilestoneCompletionForwardRequest = async (
  projectMilestoneId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/project-milestones/${projectMilestoneId}/approve-forward-request`
  );

  return data.data;
};

export const approveMilestoneCompletion = async (
  projectMilestoneId: string,
  tx?: string,
  signature?: string
): Promise<ProjectMilestone> => {
  const { data } = await myAxios.post(
    `/project-milestones/${projectMilestoneId}/approve`,
    { tx, signature }
  );

  return data.data;
};
