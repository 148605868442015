import clsx from 'clsx';
import styles from './index.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'default' | 'underline' | 'outline';
  color?:
    | 'default'
    | 'danger'
    | 'secondary'
    | 'light'
    | 'white'
    | 'gradient'
    | 'magic'
    | 'success'
    | 'blue';
  size?: 'default' | 'small' | 'medium';
  icon?: () => any;
}

function Button(props: Props) {
  const variant = props.variant || 'default';
  const color = props.color || 'default';
  const size = props.size || 'default';

  let className = clsx(
    styles.button,
    styles[variant],
    color !== 'default' ? styles[props.color!] : undefined,
    size !== 'default' ? styles[props.size!] : undefined,
    props.className
  );

  return (
    <button {...props} className={className}>
      {props.icon && (
        <div className="flex items-center">
          {props.icon()}
          <span>{props.children}</span>
        </div>
      )}
      {!props.icon && props.children}
    </button>
  );
}

export default Button;
