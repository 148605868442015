import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { rejectProjectCollaboratorRecommendation } from '../../../../../../api/projects-collaborator-recommendations';
import { ProjectCollaboratorRecommendationDetail } from '../../../../../../interfaces/project-collaborator-recommendation.interface';
import Button from '../../../../../shared/button';
import FormGroup from '../../../../../shared/form/group';
import FormLabel from '../../../../../shared/form/label';
import FormTextarea from '../../../../../shared/form/textarea';
import Modal from '../../../../../shared/modal';
import Notification from '../../../../../shared/notification';

const schema = yup
  .object()
  .shape({
    reason: yup.string().required(),
  })
  .required();

function RejectProjectCollaboratorRecommendationModal(props: {
  recommendation: ProjectCollaboratorRecommendationDetail;
  onClose: () => void;
  onReject: (reason: string) => void;
}) {
  const { t } = useTranslation('project-collaborator-recommendation');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await rejectProjectCollaboratorRecommendation(
        props.recommendation.id,
        values.reason
      );
      props.onReject(values.reason);
    } catch (err: any) {
      if (
        axios.isAxiosError(err) &&
        err.response!.data.code === 'recommendationState'
      ) {
        setError(t('reject.errors.recommendationState'));
      } else {
        setError(t('reject.errors.reject'));
      }
    }
    setLoading(false);
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('reject.reason')}</FormLabel>
          <FormTextarea
            {...register('reason')}
            error={errors.reason !== undefined}
            rows={6}
            disabled={loading}
          />
        </FormGroup>
        <Button className="mt-3 w-full" color="secondary" disabled={loading}>
          {t('reject.button')}
        </Button>
      </form>
    </Modal>
  );
}

export default RejectProjectCollaboratorRecommendationModal;
