import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  GetProjectCollaboratorsFilter,
  getProjectCollaborators,
} from '../../../../api/projects-collaborators';
import DashboardSearch from '../../../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../../components/core/dashboard-layout/search-filter/modal/item';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import ProjectCollaboratorList from '../../../../components/projects/collaborators/list';
import Button from '../../../../components/shared/button';
import Loading from '../../../../components/shared/loading';
import AuthContext from '../../../../context/auth-context';
import { GetProjectCollaboratorsResult } from '../../../../interfaces/project-collaborator.interface';
import {
  myProjectsRoute,
  otherProjectsRoute,
  projectRoute,
  projectCollaboratorInvitesRoute,
  projectCollaboratorApplicationsRoute,
  projectCollaboratorRecommendationsRoute,
} from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';

export default function ProjectCollaboratorsPage() {
  const { t } = useTranslation('project-collaborators');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [data, setData] = useState<GetProjectCollaboratorsResult>();
  const [filter, setFilter] = useState<GetProjectCollaboratorsFilter>({});

  const { user } = useContext(AuthContext);
  const { projectId } = useParams();

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('types.title'),
      key: 'role',
      items: [{ label: t('types.all') }].concat(
        ...['company', 'individual'].map((role) => {
          return { label: t('types.' + role), value: role };
        })
      ),
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    try {
      console.log('search with filter', filter);
      const result = await getProjectCollaborators(
        page,
        projectId! as string,
        filter
      );
      setData(result);
    } catch (err) {
      console.error('failed to get project collaborators', err);
      setError(t('errors.load'));
    }
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({ ...filter, [key]: value === '' ? undefined : value });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label:
            data && user && data.project.userId === user.id
              ? commonTranslations.t('dashboard.pathLabels.myProjects')
              : commonTranslations.t('dashboard.pathLabels.otherProjects'),
          path:
            data && user && data.project.userId === user.id
              ? myProjectsRoute
              : otherProjectsRoute,
        },
        {
          label: data ? data.project.title : '',
          path: data ? projectRoute(projectId! as string) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.collaborators'),
        },
      ]}
      selectedPath={
        data && user && data.project.userId === user.id
          ? myProjectsRoute
          : otherProjectsRoute
      }
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1113px] flex flex-col min-h-full">
          {user && data.project.userId === user.id && (
            <div className="flex space-x-3 justify-end flex-wrap max-md:justify-center">
              <Link to={projectCollaboratorInvitesRoute(projectId! as string)}>
                <Button size="small" className="min-w-[150px] mb-3">
                  {t('invites')}
                </Button>
              </Link>
              <Link
                to={projectCollaboratorApplicationsRoute(projectId! as string)}
              >
                <Button size="small" className="min-w-[150px] mb-3">
                  {t('applications')}
                </Button>
              </Link>
              <Link
                to={projectCollaboratorRecommendationsRoute(
                  projectId! as string
                )}
              >
                <Button size="small" className="min-w-[150px] mb-3">
                  {t('recommendations')}
                </Button>
              </Link>
            </div>
          )}
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <ProjectCollaboratorList
            projectId={
              user && user.id === data.project.userId
                ? (projectId! as string)
                : undefined
            }
            result={data}
            createText={
              user && data.project.userId === user.id
                ? t('createText')
                : undefined
            }
            notFoundText={t('notFoundText')}
            onPageChange={(page: number) => fetchItems(page)}
          />
        </div>
      )}
    </UserDashboard>
  );
}
