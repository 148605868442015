import { useTranslation } from 'react-i18next';
import UserDashboard from '../../components/core/dashboard-layout/user';
import Accordion from '../../components/shared/accordion';
import { AccordionItem } from '../../components/shared/accordion/item';
import { faqRoute } from '../../utils/routes';

export default function FAQPage() {
  const commonTranslations = useTranslation('common');
  const { t } = useTranslation('faq');

  const items = t('faq', { returnObjects: true }) as AccordionItem[];

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.faq'),
        },
      ]}
      selectedPath={faqRoute}
    >
      <div className="max-w-[1200px]">
        <Accordion items={items} />
      </div>
    </UserDashboard>
  );
}
