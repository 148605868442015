import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getMagic } from '../../../../utils/web3';
import Button from '../../../shared/button';

function DashboardWalletButton() {
  const { t } = useTranslation('common');
  const [showingMagicWallet, setShowingMagicWallet] = useState(false);

  async function onShowMagicWallet() {
    setShowingMagicWallet(true);
    try {
      if (!(await getMagic().user.isLoggedIn())) {
        await getMagic().wallet.connectWithUI();
      }

      await getMagic().wallet.showUI();
    } catch (err) {
      console.error('failed to option magic wallet', err);
    }
    setShowingMagicWallet(false);
  }

  return (
    <Button
      className=" min-w-[60px] mr-4"
      variant="outline"
      color="magic"
      size="small"
      onClick={onShowMagicWallet}
      disabled={showingMagicWallet}
      icon={() => (
        <img
          src="/images/dashboard/magic-small.png"
          width="14px"
          height="14px"
          className="mr-2"
        />
      )}
    >
      {t('dashboard.wallet')}
    </Button>
  );
}

export default DashboardWalletButton;
