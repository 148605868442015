import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import EventEntryUseModal from './use-modal';
import Web3Context from '../../../../../context/web3-context';
import {
  getUsableEventEntries,
  sendUseEventEntries,
  canUseEventEntries,
  getUseEventEntriesForwardRequest,
} from '../../../../../api/events-entries';
import AuthContext from '../../../../../context/auth-context';
import { EventEntryItem } from '../../../../../interfaces/event-entry.interface';
import { loadEDIHEventsContract } from '../../../../../utils/contracts/edih-events';
import Button from '../../../../shared/button';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../utils/web3';
import { sleep } from '../../../../../utils/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: EventEntryItem;
  setError: (error?: string) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  onUse: (entries: { id: string }[], tx: string) => void;
}

function EventEntryItemElRedeem(props: Props) {
  const { t } = useTranslation('event-entry-list');
  const [usableAmount, setUsableAmount] = useState<number | undefined>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  // function onClickEvent() {
  //   router.push(eventRoute(props.item.event.id));
  // }

  async function onRedeem() {
    props.setError(undefined);
    props.setLoading(true);
    try {
      const result = await getUsableEventEntries(props.item.event.id);
      console.log('usable entries loaded', result);

      if (result.length === 1) {
        await onSubmitAmount(1);
      } else {
        setUsableAmount(result.length);
      }
    } catch (err) {
      console.error('failed to load entries', err);
    }
    props.setLoading(false);
  }

  async function onSubmitAmount(amount: number) {
    setUsableAmount(undefined);
    console.log('submit amount', amount);

    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanUse(amount);

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.useEventEntries(
            props.item.event.contractId,
            amount
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchUseEventEntriesForwardRequest(
            amount
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await sendUseEventEntries(
            props.item.event.id,
            amount,
            tx,
            signature
          );
          props.onUse(result, tx!);
          toast.success(t('used'), { duration: 8000 });
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to use', err);
          props.setError(t('errors.redeem'));
          props.setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('errors.redeem'));
        props.setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .useEventEntries(
      //     user!.walletAddress,
      //     props.item.event.contractId,
      //     amount
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('used', tx);
      //     try {
      //       const result = await sendUseEventEntries(
      //         props.item.event.id,
      //         amount,
      //         tx
      //       );
      //       props.onUse(result, tx);
      //       toast.success(t('used'), { duration: 8000 });
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to purchase', err);
      //       props.setError(t('purchase.errors.purchase'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('purchase.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanUse(amount: number) {
    try {
      await canUseEventEntries(props.item.event.id, amount);
    } catch (err) {
      throw err;
    }
  }

  async function fetchUseEventEntriesForwardRequest(amount: number) {
    try {
      return getUseEventEntriesForwardRequest(props.item.event.id, amount);
    } catch (err) {
      throw err;
    }
  }

  return (
    <>
      <Button
        className="w-full"
        size="medium"
        onClick={onRedeem}
        disabled={props.item.event.state !== 'active' || props.loading}
      >
        {t('redeem')}
      </Button>
      {usableAmount && (
        <EventEntryUseModal
          usableAmount={usableAmount}
          onSubmit={onSubmitAmount}
          onClose={() => setUsableAmount(undefined)}
        />
      )}
    </>
  );
}

export default EventEntryItemElRedeem;
