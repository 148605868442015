import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardOtherProjectsIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.392 8.938 18.298 5.5a1.031 1.031 0 0 0-.767-.344h-5.5V2.75a1.031 1.031 0 1 0-2.062 0v2.406H3.438a1.719 1.719 0 0 0-1.72 1.719v5.5a1.719 1.719 0 0 0 1.72 1.719h6.53v5.156a1.031 1.031 0 0 0 2.063 0v-5.156h5.5a1.031 1.031 0 0 0 .767-.344l3.094-3.438a1.031 1.031 0 0 0 0-1.374Zm-4.32 3.093H3.782V7.22h13.29l2.166 2.406-2.166 2.406Z"
    />
  </svg>
);
export default DashboardOtherProjectsIcon;
