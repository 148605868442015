import clsx from 'clsx';
import styles from './index.module.css';
import { Tag } from '../../../interfaces/tag.interface';
import { useTranslation } from 'react-i18next';
import {
  SupportedLang,
  getPropByLang,
} from '../../../translations/i18n-settings';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tags: Tag[];
  small?: boolean;
}

function Tags(props: Props) {
  const { i18n } = useTranslation();

  console.log('my tags', props.tags);

  const tags = props.tags.map((tag) => (
    <div className="relative" key={tag.name}>
      <div
        className={clsx(
          props.small
            ? 'text-[0.625rem] text-font-grey bg-[#E0E0E0] px-2 py-0.5 mr-2 mb-2 rounded-full font-medium'
            : 'text-xs border border-grey-2 bg-[#FAFAFA] rounded-2xl px-2 py-1.5 mr-2 mb-2'
        )}
      >
        {getPropByLang(i18n.language as SupportedLang, tag)}
        {/* {tag.name} */}
      </div>
      {tag.endorsements && +tag.endorsements > 0 && (
        <div className="absolute text-[0.55rem] top-[-4px] right-[4px] min-w-[15px] min-h-[15px] bg-primary flex justify-center items-center rounded-full px-1">
          {tag.endorsements}
        </div>
      )}
    </div>
  ));

  return (
    <div className={clsx(styles.tags, props.className, 'flex flex-wrap')}>
      {tags}
    </div>
  );
}

export default Tags;
