import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import StartProjectModal from './modal';
import { ProjectDetail } from '../../../interfaces/project.interface';
import Button from '../../shared/button';

export default function ProjectDetailStart(props: {
  project: ProjectDetail;
  onStart: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation('project-detail');
  const [showStartModal, setShowStartModal] = useState<boolean>(false);

  function onStart() {
    setShowStartModal(false);
    props.onStart();
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setShowStartModal(true)}
        disabled={props.project.state === 'waitingForStart' || props.loading}
      >
        {t('start.button')}
      </Button>

      {showStartModal && (
        <StartProjectModal
          project={props.project}
          onStart={onStart}
          onClose={() => setShowStartModal(false)}
        />
      )}
    </>
  );
}
