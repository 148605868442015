import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import {
  checkEventRatingDeadline,
  canCheckEventRatingDeadline,
  getCheckEventRatingDeadlineForwardRequest,
} from '../../../api/events';
import AuthContext from '../../../context/auth-context';
import { EventToManage } from '../../../interfaces/event.interface';
import { loadEDIHEventsContract } from '../../../utils/contracts/edih-events';
import Button from '../../shared/button';
import Web3Context from '../../../context/web3-context';
import Notification from '../../shared/notification';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function EventDetailCheckRatingDeadline(props: {
  event: EventToManage;
  loading: boolean;
  onCheck: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('manage-event');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onCheck() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await canCheck();

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.checkForEventRatingDeadline(
            props.event.contractId!
          );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchCheckEventRatingDeadlineForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await checkEventRatingDeadline(props.event.id, tx, signature);
          props.onCheck(tx!);
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to check rating deadline', err);
          props.setError(t('checkForRatingDeadline.errors.check'));
          props.setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('checkForRatingDeadline.errors.transaction'));
        props.setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .checkForEventRatingDeadline(
      //     user!.walletAddress,
      //     props.event.contractId!
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('checked rating deadline', tx);
      //     try {
      //       await checkEventRatingDeadline(props.event.id, tx);
      //       props.onCheck(tx);
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to check rating deadline', err);
      //       props.setError(t('checkForRatingDeadline.errors.check'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('checkForRatingDeadline.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function canCheck() {
    try {
      await canCheckEventRatingDeadline(props.event.id);
    } catch (err) {
      props.setError(t('checkForRatingDeadline.errors.canCheck'));

      throw err;
    }
  }

  async function fetchCheckEventRatingDeadlineForwardRequest() {
    try {
      return getCheckEventRatingDeadlineForwardRequest(props.event.id);
    } catch (err) {
      props.setError(t('checkForRatingDeadline.errors.canCheck'));

      throw err;
    }
  }

  return (
    <Notification color="neutral">
      <div className="mb-2">{t('checkForRatingDeadline.message')}</div>
      <Button
        size="small"
        color="secondary"
        onClick={onCheck}
        disabled={props.loading}
      >
        {t('checkForRatingDeadline.button')}
      </Button>
    </Notification>
  );
}
