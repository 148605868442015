import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const ButtonEditSmallIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.983 5.159 12.84 2.017a1.125 1.125 0 0 0-1.591 0l-8.67 8.67a1.115 1.115 0 0 0-.33.796v3.142a1.125 1.125 0 0 0 1.125 1.125h3.142a1.115 1.115 0 0 0 .796-.33l8.67-8.67a1.126 1.126 0 0 0 0-1.591Zm-9.466 9.466H3.375v-3.142l6.188-6.188 3.142 3.143-6.188 6.187ZM13.5 7.642 10.358 4.5l1.687-1.687 3.143 3.141L13.5 7.642Z"
    />
  </svg>
);
export default ButtonEditSmallIcon;
