import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  password: string;
}

function PasswordInfo(props: Props) {
  const { t } = useTranslation('password-info');
  let className = clsx(`text-xs text-font-grey`, props.className);

  let password = props.password || '';

  const hasMin = password.length >= 8;
  const hasLower = /(?=.*[a-z])/.test(password);
  const hasUpper = /(?=.*[A-Z])/.test(password);
  const hasDigit = /(?=.*\d)/.test(password);

  return (
    <div {...props} className={className}>
      <p
        className={
          hasMin && hasLower && hasUpper && hasDigit
            ? 'text-[#68e073]'
            : undefined
        }
      >
        {t('title')}
      </p>
      <ul className="list-disc list-inside">
        <li className={hasMin ? 'text-[#68e073]' : undefined}>{t('min')}</li>
        <li className={hasLower ? 'text-[#68e073]' : undefined}>
          {t('lower')}
        </li>
        <li className={hasUpper ? 'text-[#68e073]' : undefined}>
          {t('upper')}
        </li>
        <li className={hasDigit ? 'text-[#68e073]' : undefined}>
          {t('digit')}
        </li>
      </ul>
    </div>
  );
}

export default PasswordInfo;
