import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getProjectCollaboratorInvite } from '../../../../../../api/projects-collaborator-invites';
import DashboardBox from '../../../../../../components/core/dashboard-layout/box';
import DashboardGroup from '../../../../../../components/core/dashboard-layout/group';
import DashboardGroupLabel from '../../../../../../components/core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../../../../components/core/dashboard-layout/group/value';
import DashboardUserProfile from '../../../../../../components/core/dashboard-layout/profile';
import DashboardTitle from '../../../../../../components/core/dashboard-layout/title';
import UserDashboard from '../../../../../../components/core/dashboard-layout/user';
import AcceptProjectCollaboratorInvite from '../../../../../../components/projects/collaborators/accept';
import RejectProjectCollaboratorInvite from '../../../../../../components/projects/collaborators/reject';
import Loading from '../../../../../../components/shared/loading';
import ProjectCollaboratorInviteStateEl from '../../../../../../components/shared/project-collaborator-invite-state';
import UserProfileItemEl from '../../../../../../components/users/item';
import AuthContext from '../../../../../../context/auth-context';
import { ProjectCollaboratorInviteDetail } from '../../../../../../interfaces/project-collaborator-invite.interface';
import { formatDate } from '../../../../../../utils/date';
import {
  myProjectsRoute,
  projectRoute,
  projectCollaboratorsRoute,
  projectCollaboratorInvitesRoute,
  getDashboardRoute,
} from '../../../../../../utils/routes';
import Notification from '../../../../../../components/shared/notification';

export default function ProjectCollaboratorInvitePage() {
  const { t } = useTranslation('project-collaborator-invite');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [invite, setInvite] = useState<ProjectCollaboratorInviteDetail>();
  const { user } = useContext(AuthContext);
  const { projectId, inviteId } = useParams();

  async function fetchInvite() {
    setError(undefined);
    try {
      const result = await getProjectCollaboratorInvite(inviteId! as string);
      console.log('invite loaded', result);
      setInvite(result);
    } catch (err) {
      console.error('failed to get invite', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (
        err.response!.data.code === 'projectCollaboratorInviteNotFound'
      ) {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  useEffect(() => {
    fetchInvite();
  }, [inviteId!]);

  function onAccept() {
    setInvite({ ...invite!, state: 'accepting' });
  }

  function onReject() {
    setInvite({ ...invite!, state: 'rejecting' });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={
        user && invite && invite?.user.id === user.id
          ? [
              {
                label: commonTranslations.t('dashboard.pathLabels.invite'),
              },
            ]
          : [
              {
                label: commonTranslations.t('dashboard.pathLabels.myProjects'),
                path: myProjectsRoute,
              },
              {
                label: invite ? invite.project.title : '',
                path: projectRoute(projectId! as string),
              },
              {
                label: commonTranslations.t(
                  'dashboard.pathLabels.collaborators'
                ),
                path: projectCollaboratorsRoute(projectId! as string),
              },
              {
                label: commonTranslations.t('dashboard.pathLabels.invites'),
                path: projectCollaboratorInvitesRoute(projectId! as string),
              },
            ]
      }
      selectedPath={getDashboardRoute(user ? user.role : undefined)}
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!invite && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {invite && (
        <div className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1">
          <div className="flex flex-col">
            {invite.state === 'waiting' && (
              <Notification color="neutral">{t('waiting')}</Notification>
            )}

            {invite.state === 'accepting' &&
              user &&
              invite.user.id === user!.id && (
                <Notification color="success">{t('accepting')}</Notification>
              )}
            {invite.state === 'accepted' && (
              <Notification color="success">{t('accepted')}</Notification>
            )}
            {invite.state === 'rejecting' &&
              user &&
              invite.user.id === user!.id && (
                <Notification color="neutral">{t('rejecting')}</Notification>
              )}
            {invite.state === 'rejected' && (
              <Notification color="neutral">
                <div>
                  {invite.rejectReason
                    ? t('rejectedWithReason')
                    : t('rejectedWithNotReason')}
                </div>
                {invite.rejectReason && (
                  <div className="whitespace-pre-line mt-3">
                    {invite.rejectReason}
                  </div>
                )}
              </Notification>
            )}

            <DashboardBox className="mb-6">
              <DashboardTitle>{t('details')}</DashboardTitle>
              {user && invite.project.user.id === user!.id && (
                <DashboardGroup>
                  <DashboardGroupLabel>{t('invited')}</DashboardGroupLabel>
                  <UserProfileItemEl item={invite.user} className="w-[190px]" />
                </DashboardGroup>
              )}
              <DashboardGroup>
                <DashboardGroupLabel>{t('project')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <Link
                    to={projectRoute(invite.project.id)}
                    className="text-secondary underline"
                    target="_blank"
                  >
                    {invite.project.title}
                  </Link>
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('state')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <ProjectCollaboratorInviteStateEl state={invite.state} />
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('deadline')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {formatDate(invite.deadline)}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('message')}</DashboardGroupLabel>
                <DashboardGroupValue>{invite.message}</DashboardGroupValue>
              </DashboardGroup>
            </DashboardBox>
            {/* <DashboardFiles
              title={t('files')}
              addText={t('addFile')}
              maxSize={+process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILE_SIZE!
              }
              defaultFiles={invite.files}
              requestDownloadApi={(path) =>
                requestProjectCollaboratorInviteFileDownload(invite.id, path)
              }
            /> */}
          </div>
          <div className="flex flex-col space-y-4">
            <DashboardUserProfile
              className="max-xl:hidden"
              user={invite.project.user}
            />

            {user && invite.user.id === user!.id && invite.state == 'sent' && (
              <>
                <AcceptProjectCollaboratorInvite
                  invite={invite}
                  onAccept={onAccept}
                  onError={setError}
                />
                <RejectProjectCollaboratorInvite
                  invite={invite}
                  onReject={onReject}
                />
              </>
            )}
          </div>
        </div>
      )}
    </UserDashboard>
  );
}
