import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { getMe } from '../../../api/users';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import Loading from '../../../components/shared/loading';
import DashboardUserProfileEdit from '../../../components/users/edit';
import AuthContext from '../../../context/auth-context';
import { User } from '../../../interfaces/user.interface';
import {
  membersRoute,
  userProfileRoute,
  getDashboardRoute,
} from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

export default function UserProfileEditPage() {
  const { t } = useTranslation('dashboard-profile-edit');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [profile, setProfile] = useState<User>();
  const { setUser } = useContext(AuthContext);

  function onUpdateUser(user: User) {
    setUser(user);
  }

  useEffect(() => {
    loadProfile();
  }, []);

  async function loadProfile() {
    try {
      const result = await getMe();
      setUser(result);
      setProfile(result);
    } catch (err) {
      console.error('failed to load profile', err);
      setError(t('errors.load'));
    }
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.members'),
          path: membersRoute,
        },
        {
          label: profile
            ? profile.name || `${profile.firstName} ${profile.lastName}`
            : '',
          path: userProfileRoute(profile ? profile.id : ''),
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.edit'),
        },
      ]}
      selectedPath={getDashboardRoute()}
    >
      {!profile && !error && <Loading fadeIn />}
      <div className="max-w-[1200px]">
        {error && <Notification color="danger">{error}</Notification>}

        {profile && (
          <DashboardUserProfileEdit
            className="mb-6"
            user={profile}
            onUpdateUser={onUpdateUser}
          />
        )}
      </div>
    </UserDashboard>
  );
}
