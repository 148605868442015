import clsx from 'clsx';
import OptimizedImage from '../../../image';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: string;
  loading?: boolean;
  imageWidth: number;
  error?: boolean;
  text: string;
}

function FormImageUploadBox(props: Props) {
  return (
    <div
      {...{
        ...props,
        className: clsx(
          props.className,
          props.loading ? 'opacity-50' : undefined
        ),
      }}
    >
      {props.value && (
        <OptimizedImage
          className="cursor-pointer"
          params={{ w: props.imageWidth.toString() }}
          src={props.value}
        />
      )}
      {!props.value && (
        <div
          className={clsx(
            props.error ? 'border-red-500 bg-red-100' : 'border-grey',
            'border p-3 rounded flex flex-col items-center justify-center w-full h-full cursor-pointer'
          )}
        >
          <img
            className="mb-3"
            width={64}
            height={64}
            src="/images/create.svg"
            alt="add"
          />

          <div className="text-sm font-medium text-font-grey">{props.text}</div>
        </div>
      )}
    </div>
  );
}

export default FormImageUploadBox;
