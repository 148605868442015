import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export default function NotFoundPage() {
  const { t } = useTranslation('common');

  return (
    <div className="bg-grey-3 w-screen h-screen flex justify-center items-center">
      <div className="text-3xl font-medium border-r border-r-grey pr-4 mr-4">
        404
      </div>
      <div className="text-xl">{t('notFound')}</div>
    </div>
  );
}
