import { UserRole } from '../interfaces/user.interface';

export const signInRoute = '/signin';
export const resetPasswordRoute = '/reset-password';
export const signUpRoute = '/signup';

export const resetPasswordConfirmRoute = '/reset-password/confirm';

export const getDashboardRoute = (role?: UserRole) => {
  if (role === 'superAdmin' || role === 'admin') {
    return '/admin';
  } else {
    return '/dashboard';
  }
};

export const adminUsersRoute = `${getDashboardRoute('admin')}/users`;
export const adminRegistrationRoute = (id: string) =>
  `${getDashboardRoute('admin')}/registrations/${id}`;

export const userProfileRoute = (id: string) => `/members/${id}`;
export const editProfileRoute = `/members/edit`;
export const myProjectsRoute = `/projects/my`;
export const participatingProjectsRoute = `/projects/participating`;
export const createProjectRoute = `/projects/create`;
export const otherProjectsRoute = `/projects`;
export const myEventsRoute = `/events/my`;
export const otherEventsRoute = `/events`;
export const participatingEventsRoute = `/events/participating`;
export const membersRoute = `/members`;
export const endorsementsRoute = `/endorsements`;
export const faqRoute = `/faq`;

export const projectRoute = (projectId: string) => `/projects/${projectId}`;
export const editProjectRoute = (projectId: string) =>
  `/projects/${projectId}/edit`;
export const projectMilestonesRoute = (projectId: string) =>
  `/projects/${projectId}/milestones`;
export const projectMilestoneRoute = (
  projectId: string,
  projectMilestoneId: string
) => `/projects/${projectId}/milestones?id=${projectMilestoneId}`;
export const projectCollaboratorsRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators`;
export const projectCollaboratorInvitesRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators/invites`;
export const projectCollaboratorInviteRoute = (
  projectId: string,
  inviteId: string
) => `/projects/${projectId}/collaborators/invites/${inviteId}`;
export const projectCollaboratorApplicationsRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators/applications`;
export const projectCollaboratorApplicationRoute = (
  projectId: string,
  applicationId: string
) => `/projects/${projectId}/collaborators/applications/${applicationId}`;
export const projectCollaboratorRecommendationsRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators/recommendations`;
export const projectCollaboratorPlatformRecommendationsRoute = (
  projectId: string
) => `/projects/${projectId}/collaborators/recommendations/top`;
export const projectCollaboratorRecommendationRoute = (
  projectId: string,
  recommendationId: string
) => `/projects/${projectId}/collaborators/recommendations/${recommendationId}`;
export const inviteCollaboratorRoute = (projectId: string, userId?: string) => {
  const route = `/projects/${projectId}/collaborators/invite`;

  return userId ? `${route}?userId=${userId}` : route;
};

export const collaboratorResponseFormRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators/apply`;

export const collaboratorRecommendationFormRoute = (projectId: string) =>
  `/projects/${projectId}/collaborators/recommend`;

export const inviteCollaboratorUsingRecommendationRoute = (
  projectId: string,
  recommendationId: string
) =>
  `/projects/${projectId}/collaborators/recommendations/${recommendationId}/invite`;

export const eventRoute = (eventId: string) => `/events/${eventId}`;
export const editEventRoute = (eventId: string) => `/events/${eventId}/edit`;
export const manageEventRoute = (eventId: string) =>
  `/events/${eventId}/manage`;
export const createEventRoute = `/events/create`;
export const myEventEntriesRoute = `/events/my-tickets`;
export const scanOwnEventEntriesRoute = (eventId: string) =>
  `/events/${eventId}/manage/scan-tickets`;

export const endorsersOfUserRoute = (userId: string) =>
  `/members/${userId}/endorsers`;
export const participatingProjectsOfUserRoute = (userId: string) =>
  `/members/${userId}/participating-projects`;

export const eventEntryScannerRoute = (token: string) =>
  `/events/scan-tickets/${token}`;
