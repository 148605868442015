import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { Link, useSearchParams } from 'react-router-dom';
import { verifyResetPassword, confirmResetPassword } from '../../../api/auth';
import SplitLayout from '../../../components/core/split-layout';
import Button from '../../../components/shared/button';
import FormError from '../../../components/shared/form/error';
import FormGroup from '../../../components/shared/form/group';
import FormLabel from '../../../components/shared/form/label';
import PasswordInfo from '../../../components/shared/form/password-info';
import PasswordFormInput from '../../../components/shared/form/password-input';
import Loading from '../../../components/shared/loading';
import NotAuthGuard from '../../../guards/not-auth-guard';
import { signInRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

YupPassword(yup);

const schema = yup
  .object()
  .shape({
    password: yup.string().password().minSymbols(0).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password')]),
  })
  .required();

export default function ConfirmResetPasswordPage() {
  const { t } = useTranslation('confirm-reset-password');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<'verifying' | 'verified' | 'success'>(
    'verifying'
  );
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      verify();
    }
  }, [token]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function verify() {
    try {
      await verifyResetPassword(token! as string);
      setState('verified');
    } catch (err: any) {
      handleError(err);
    }
  }

  async function handleError(err: any) {
    if (axios.isAxiosError(err) && err.response!.data.code === 'invalidToken') {
      setError(t('errors.invalidToken'));
    } else if (
      axios.isAxiosError(err) &&
      err.response!.data.code === 'expired'
    ) {
      setError(t('errors.expired'));
    } else {
      setError(t('errors.unexpected')!);
    }
  }

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await confirmResetPassword(token! as string, values.password);
      setState('success');
    } catch (err: any) {
      handleError(err);
    }
    setLoading(false);
  }

  const password = watch('password');

  return (
    <NotAuthGuard>
      <SplitLayout>
        <div className="min-h-[620px] flex flex-col">
          {state !== 'verifying' && (
            <h1 className="text-[2.5rem] font-medium text-center mb-8">
              {t('title')}
            </h1>
          )}
          {error && <Notification color="danger">{error}</Notification>}
          {state === 'verifying' && !error && (
            <div className="w-full h-full">
              <Loading fadeIn />
            </div>
          )}
          {state === 'verified' && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <FormLabel>{t('password')}</FormLabel>
                <PasswordFormInput
                  {...register('password')}
                  disabled={loading}
                  error={errors.password !== undefined}
                />
                <PasswordInfo password={password} />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('confirmPassword')}</FormLabel>
                <PasswordFormInput
                  {...register('confirmPassword')}
                  disabled={loading}
                  error={errors.confirmPassword !== undefined}
                />
                {errors.confirmPassword && (
                  <FormError>{t('errors.passwordMismatch')}</FormError>
                )}
              </FormGroup>

              <Button className="w-full mt-4" disabled={loading}>
                {t('submit')}
              </Button>
            </form>
          )}
          {state === 'success' && (
            <div className="flex flex-col items-center">
              <div className="text-sm text-center mb-6">{t('success')}</div>
              <Link to={signInRoute}>
                <Button className="w-[100px]">{t('login')}</Button>
              </Link>
            </div>
          )}
        </div>
      </SplitLayout>
    </NotAuthGuard>
  );
}
