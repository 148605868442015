import clsx from 'clsx';
import { ProjectCollaboratorResponseState } from '../../../../../../interfaces/project-collaborator-response.interface';
import ProjectCollaboratorResponseStateEl from '../../../../../shared/project-collaborator-response-state';

interface Props {
  state: ProjectCollaboratorResponseState;
}

const stateColors: { [key in ProjectCollaboratorResponseState]: string } = {
  waiting: 'text-black bg-grey-2',
  sent: 'text-black bg-grey-2',
  accepting: 'text-black bg-grey-2',
  accepted: 'text-white bg-success',
  rejected: 'text-white bg-danger',
};

function ProjectCollaboratorResponseItemElState(props: Props) {
  return (
    <p
      className={clsx(
        'absolute top-2 right-2 text-[0.5rem] px-2 py-1 rounded-full',
        stateColors[props.state]
      )}
    >
      <ProjectCollaboratorResponseStateEl state={props.state} />
    </p>
  );
}

export default ProjectCollaboratorResponseItemElState;
