import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

function SignupFinished() {
  const { t } = useTranslation('signup');

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center mb-8">
        {t('finished.title')}
      </h1>
      <p className="text-center">{parse(t('finished.text'))}</p>
    </div>
  );
}

export default SignupFinished;
