import { useTranslation } from 'react-i18next';
import { ProjectMilestoneState } from '../../../interfaces/project-milestones.interface';

function ProjectMilestoneStateEl(props: { state: ProjectMilestoneState }) {
  const { t } = useTranslation('project-milestone-states');

  return <>{t(props.state)}</>;
}

export default ProjectMilestoneStateEl;
