import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import {
  getEndorsementRequestTargets,
  requestEndorsements,
} from '../../../../../api/endorsements';
import { UserProfileItem } from '../../../../../interfaces/user.interface';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import SelectUserList from '../../../../shared/form/select-user-list';
import Notification from '../../../../shared/notification';
import Modal from '../../../../shared/modal';

function ConfirmSmeModal(props: {
  onConfirm: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation('signup');

  return (
    <Modal className="w-full max-w-[400px]" onClose={props.onClose}>
      {t('introForm.smeModal.text')}
      <div className="flex space-x-3 mt-4">
        <Button className="flex-1" color="light" onClick={props.onClose}>
          {t('introForm.smeModal.cancel')}
        </Button>
        <Button className="flex-1" onClick={props.onConfirm}>
          {t('introForm.smeModal.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmSmeModal;
