import clsx from 'clsx';
import Autocomplete from 'react-google-autocomplete';
import { FormInputProps } from '../input';

interface Props extends FormInputProps {
  onPlaceSelected: (place: any) => void;
}

function LocationInput(props: Props) {
  let className = clsx(
    'border outline-none p-[0.813rem] px-3 text-font-black rounded text-sm placeholder:text-font-grey',
    props.error ? 'border-red-500 bg-red-100' : 'border-grey',
    props.className
  );

  return (
    <Autocomplete
      className={className}
      apiKey={process.env.REACT_APP_MAPS_API_KEY}
      options={{ types: 'locality' }}
      {...props}
    />
  );
}

export default LocationInput;
