import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignupData } from '../../../../pages/signup';
import Button from '../../../shared/button';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import TagsInput from '../../../shared/form/tags-input';
import FormTextarea from '../../../shared/form/textarea';
import {
  requestUploadForRegistration,
  sendCompanyRegistration,
  sendIndividualRegistration,
} from '../../../../api/registrations';
import { uploadToS3 } from '../../../../utils/utils';
import { generatePersonalSignature, getMagic } from '../../../../utils/web3';
import { Tag } from '../../../../interfaces/tag.interface';
import Notification from '../../../shared/notification';
import FormCheckbox from '../../../shared/form/checkbox';
import HTMLReactParser from 'html-react-parser';

const schema = yup
  .object()
  .shape({
    description: yup.string().required(),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required(),
        })
      )
      .min(1)
      .max(5)
      .required(),
    accept: yup.bool().oneOf([true], 'Field must be checked').required(),
  })
  .required();

function SignupIntroFormDescription(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
  onBack: () => void;
}) {
  const { t, i18n } = useTranslation('signup');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    const data = {
      ...props.signupData,
      description: values.description,
      tags: values.tags,
    };

    setLoading(true);
    setError(undefined);

    try {
      const message = {
        email: data.email,
        contactEmail: data.contactEmail,
        name: data.name,
        firstName: data.firstName,
        lastName: data.lastName,
        organization: data.organization,
        phoneNumber: data.phoneNumber,
        website: data.website,
        description: data.description,
        walletAddress: data.walletAddress,
      };

      console.log('message', message, props.signupData.walletAddress!);

      // request metamask signature
      const signature = await generatePersonalSignature(
        JSON.stringify(message),
        props.signupData.walletAddress!
      );

      console.log('signature received', signature);

      if (props.signupData.type === 'company') {
        await sendCompanyRegistration({
          email: data.email!,
          password: data.password!,
          contactEmail: data.contactEmail!,
          name: data.name!,
          phoneNumber: data.phoneNumber,
          website: data.website,
          description: data.description!,
          walletAddress: data.walletAddress!,
          signature,
          tags: data.tags.map((t: Tag) => t.id),
          sme: data.sme || false,
          lang: i18n.language,
          magicToken:
            data.walletType === 'magic'
              ? await getMagic().user.generateIdToken()
              : undefined,
        });
      } else {
        await sendIndividualRegistration({
          email: data.email!,
          password: data.password!,
          contactEmail: data.contactEmail!,
          firstName: data.firstName!,
          lastName: data.lastName!,
          organization: data.organization!,
          phoneNumber: data.phoneNumber,
          website: data.website,
          description: data.description!,
          walletAddress: data.walletAddress!,
          lang: i18n.language,
          signature,
          tags: data.tags.map((t: Tag) => t.id),
          magicToken:
            data.walletType === 'magic'
              ? await getMagic().user.generateIdToken()
              : undefined,
        });
      }

      props.onSubmit(data);
    } catch (err) {
      console.error('failed to send registration', err);
      setError(t('introFormDescription.errors.register'));
    }

    setLoading(false);
  }

  // prefill form with already entered data if loaded by going back
  // useEffect(() => {
  //   setValue('description', props.signupData.description!);
  //   setValue('tags', props.signupData.tags!);
  // }, []);

  console.log('errors', errors);
  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center">
        {t('introFormDescription.title')}
      </h1>
      <p className="text-sm text-center text-font-grey mb-8">
        {t('introFormDescription.subtitle')}
      </p>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel required showRequiredInfo>
            {t('introFormDescription.description')}
          </FormLabel>
          <FormTextarea
            {...register('description')}
            error={errors.description !== undefined}
            rows={6}
            maxLength={1000}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel required>
            <span className="pr-1">{t('introFormDescription.tags')}</span>
            <span className="ext-[0.625rem] text-font-grey">
              {t('introFormDescription.tagsHelp')}
            </span>
          </FormLabel>
          <Controller
            render={({ field }) => (
              <TagsInput
                tags={field.value}
                onChange={field.onChange}
                placeholder={t('introFormDescription.tagsPlaceholder')}
                error={errors.tags !== undefined}
                disabled={loading}
              />
            )}
            control={control}
            name="tags"
          />
          {errors.tags && errors.tags.type === 'min' && (
            <div className="text-sm text-danger">
              {t('introFormDescription.errors.tagsMin')}
            </div>
          )}
          {errors.tags && errors.tags.type === 'max' && (
            <div className="text-sm text-danger">
              {t('introFormDescription.errors.tagsMax')}
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <FormCheckbox
                className="text-sm"
                checkboxProps={{
                  onChange: (ev) => field.onChange(ev.target.checked),
                  checked: field.value === true,
                  name: 'accept',
                  disabled: loading,
                }}
              >
                {HTMLReactParser(t('introFormDescription.privacyPolicy'))}
              </FormCheckbox>
            )}
            control={control}
            name="accept"
          />
          {errors.accept && (
            <div className="text-sm text-danger">
              {t('introFormDescription.errors.accept')}
            </div>
          )}
        </FormGroup>

        <div className="flex flex-col">
          <Button className="mt-4" disabled={loading}>
            {t('introFormDescription.proceed')}
          </Button>
          <Button
            variant="underline"
            type="button"
            onClick={props.onBack}
            disabled={loading}
          >
            {t('back')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SignupIntroFormDescription;
