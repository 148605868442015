import {
  EventEntryState,
  GetEventEntriesResult,
  GetEntriesOfEventResult,
  EventEntryItem,
} from '../interfaces/event-entry.interface';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import { UserProfileItem } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const canPurchaseEventEntries = async (
  eventId: string,
  amount: number
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/can-purchase`, {
    amount,
  });

  return data.data;
};

export const getPurchaseEventEntriesForwardRequest = async (
  eventId: string,
  amount: number
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/purchase-forward-request`,
    {
      amount,
    }
  );
  return data.data;
};

export const purchaseEventEntries = async (
  eventId: string,
  amount: number,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/purchase`, {
    amount,
    tx,
    signature,
  });

  return data.data;
};

export const canUseEventEntries = async (
  eventId: string,
  amount: number
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/can-use-entries`, {
    amount,
  });

  return data.data;
};

export const getUseEventEntriesForwardRequest = async (
  eventId: string,
  amount: number
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/use-entries-forward-request`,
    {
      amount,
    }
  );
  return data.data;
};

export const sendUseEventEntries = async (
  eventId: string,
  amount: number,
  tx?: string,
  signature?: string
): Promise<{ id: string }[]> => {
  const { data } = await myAxios.post(`/events/${eventId}/use-entries`, {
    amount,
    tx,
    signature,
  });

  return data.data;
};

export const getTransferEventEntriesForwardRequest = async (
  eventId: string,
  recipientId: string,
  amount: number
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/transfer-entries-forward-request`,
    {
      recipientId,
      amount,
    }
  );
  return data.data;
};

export const canTransferEventEntries = async (
  eventId: string,
  recipientId: string,
  amount: number
): Promise<void> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/can-transfer-entries`,
    {
      recipientId,
      amount,
    }
  );

  return data.data;
};

export const transferEventEntries = async (
  eventId: string,
  recipientId: string,
  amount: number,
  tx?: string,
  signature?: string
): Promise<{ id: string }[]> => {
  console.log('transfer', eventId, recipientId, amount, tx);
  const { data } = await myAxios.post(`/events/${eventId}/transfer-entries`, {
    recipientId,
    amount,
    tx,
    signature,
  });

  return data.data;
};

export interface GetMyEventEntriesFilter {
  search?: string;
  states?: EventEntryState[];
}

export const getMyEventEntries = async (
  page: number,
  filter: GetMyEventEntriesFilter
): Promise<GetEventEntriesResult> => {
  const { data } = await myAxios.post('/events/my-entries', {
    page,
    ...filter,
  });

  return data.data;
};

export const getUsableEventEntries = async (
  eventId: string
): Promise<{ id: string }[]> => {
  const { data } = await myAxios.get(`/events/${eventId}/usable-entries`);

  return data.data;
};

export interface GetEntriesOfEventFilter {
  search?: string;
  states?: EventEntryState[];
}

export const getEntriesOfEvent = async (
  eventId: string,
  page: number,
  filter: GetEntriesOfEventFilter
): Promise<GetEntriesOfEventResult> => {
  const { data } = await myAxios.post(`/events/${eventId}/entries`, {
    page,
    ...filter,
  });

  return data.data;
};

export const getSpecificEventEntries = async (
  eventEntryIds: string[]
): Promise<EventEntryItem[]> => {
  const { data } = await myAxios.post(`/event-entries/specific`, {
    ids: eventEntryIds,
  });

  return data.data;
};

export const getTransferrableEventEntryUsers = async (
  eventId: string,
  search: string
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.get(
    `/events/${eventId}/entries/transferrable`,
    {
      params: { search },
    }
  );

  return data.data;
};
