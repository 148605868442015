import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Web3Context from '../../../../../../../context/web3-context';
import {
  inviteUserToProject,
  getProjectCollaboratorInviteDataHash,
  getProjectCollaboratorInviteForwardRequest,
} from '../../../../../../../api/projects-collaborator-invites';
import { getProjectCollaboratorRecommendationInviteFormData } from '../../../../../../../api/projects-collaborator-recommendations';
import UserDashboard from '../../../../../../../components/core/dashboard-layout/user';
import ProjectCollaboratorInviteForm, {
  ProjectCollaboratorInviteFormData,
} from '../../../../../../../components/projects/collaborators/invites/form';
import Button from '../../../../../../../components/shared/button';
import Loading from '../../../../../../../components/shared/loading';
import AuthContext from '../../../../../../../context/auth-context';
import { ProjectCollaboratorRecommendationInviteForm } from '../../../../../../../interfaces/project-collaborator-recommendation.interface';
import { loadEDIHProjectsContract } from '../../../../../../../utils/contracts/edih-projects';
import {
  myProjectsRoute,
  projectCollaboratorsRoute,
  projectRoute,
} from '../../../../../../../utils/routes';
import Notification from '../../../../../../../components/shared/notification';
import { UncheckedJsonRpcSigner } from '../../../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../../../utils/web3';
import { sleep } from '../../../../../../../utils/utils';

export default function InviteCollaboratorViaRecommendationPage() {
  const { t } = useTranslation('invite-collaborator');
  const [recommendation, setRecommendation] =
    useState<ProjectCollaboratorRecommendationInviteForm>();
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { recommendationId } = useParams();
  const commonTranslations = useTranslation('common');
  const { connect } = useContext(Web3Context);

  useEffect(() => {
    onConnect();
  }, []);

  async function onConnect() {
    setError(undefined);
    try {
      setCanRetry(true);

      // get and validate wallet address
      await connect({
        hasToBeAddress: user!.walletAddress,
        noToast: true,
      });

      setCanRetry(false);

      // check if user has permission to invite
      try {
        const result = await getProjectCollaboratorRecommendationInviteFormData(
          recommendationId! as string
        );
        setRecommendation(result);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (
            err.response!.data.code === 'projectNotFound' ||
            err.response!.data.code === 'projectState' ||
            err.response!.data.code === 'accessDenied'
          ) {
            throw t('errors.' + err.response!.data.code);
          }

          throw t('errors.load');
        }
      }
    } catch (err: any) {
      console.error('failed to connect', err);
      setError(err.toString());
    }
  }

  async function onSubmit(formData: ProjectCollaboratorInviteFormData) {
    console.log('submit!', formData);

    setSubmitting(true);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchInviteDataHash(formData);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.addProjectCollaborator(
            recommendation!.project.contractId,
            formData.user.walletAddress,
            new Date(formData.deadline),
            hash,
            recommendation!.recommender.walletAddress
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchInviteForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await inviteUserToProject(
            recommendation!.project.id,
            formData,
            tx,
            signature,
            recommendation!.id
          );
          setSuccess(true);
        } catch (err: any) {
          console.error('failed to invite', err);
          setError(t('errors.invite'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .addProjectCollaborator(
      //     user!.walletAddress,
      //     recommendation!.project.contractId,
      //     formData.user.walletAddress,
      //     new Date(formData.deadline),
      //     hash,
      //     recommendation!.recommender.walletAddress
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('created', tx);
      //     try {
      //       await inviteUserToProject(
      //         recommendation!.project.id,
      //         formData,
      //         tx,
      //         recommendation!.id
      //       );
      //       setSuccess(true);
      //     } catch (err: any) {
      //       console.error('failed to invite', err);
      //       setError(t('errors.invite'));
      //       setSubmitting(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchInviteDataHash(
    formData: ProjectCollaboratorInviteFormData
  ) {
    try {
      const hash = await getProjectCollaboratorInviteDataHash(
        recommendation!.project.id,
        formData,
        recommendation!.id
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchInviteForwardRequest(
    formData: ProjectCollaboratorInviteFormData
  ) {
    try {
      let hash = await getProjectCollaboratorInviteForwardRequest(
        recommendation!.project.id,
        formData,
        recommendation!.id
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={myProjectsRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myProjects'),
          path: myProjectsRoute,
        },
        {
          label: recommendation ? recommendation.project.title : '',
          path: recommendation
            ? projectRoute(recommendation.project.id! as string)
            : '',
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.collaborators'),
          path: recommendation
            ? projectCollaboratorsRoute(recommendation.project.id! as string)
            : '',
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.invite'),
        },
      ]}
    >
      {error && (
        <Notification color="danger" className="max-w-[400px]">
          <div>{error}</div>
          {canRetry && (
            <div className="mt-2 text-right">
              <Button size="small" color="secondary" onClick={onConnect}>
                {t('reconnect')}
              </Button>
            </div>
          )}
        </Notification>
      )}
      {!recommendation && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {success && <Notification color="success">{t('success')}</Notification>}
      {recommendation && (
        <ProjectCollaboratorInviteForm
          project={recommendation.project}
          selectedUser={recommendation.recommended}
          onSubmit={onSubmit}
          disabled={submitting}
        />
      )}
    </UserDashboard>
  );
}
