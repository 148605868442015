import { AxiosError } from 'axios';
import { createContext, useEffect, useState } from 'react';
import { User } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';
import { useNavigate } from 'react-router-dom';
import { getMe, getDashboardData } from '../api/users';
import Loading from '../components/shared/loading';
import { DashboardData } from '../interfaces/dashboard-data.interface';
import { signInRoute } from '../utils/routes';
import { publicRpcProvider, switchWeb3Provider } from '../utils/web3';
import { EDIHContract, loadEDIHContract } from '../utils/contracts/edih';

interface AuthContextType {
  user?: User;
  setUser: (user: User) => void;
  dashboardData?: DashboardData;
  sessionInvalid: boolean;
  fetchDashboardData: () => Promise<DashboardData>;
  setDashboardData: (data: DashboardData | undefined) => void;
  login: (user: User) => void;
  logout: () => void;
  isAdmin: () => boolean;
}

const AuthContext = createContext<AuthContextType>({
  sessionInvalid: false,
  setUser: (user: User) => {},
  login: (user: User) => {},
  fetchDashboardData: {} as any,
  setDashboardData: {} as any,
  logout: () => {},
  isAdmin: () => false,
});

export function AuthContextProvider(props: any) {
  const [user, setUser] = useState<User | undefined>();
  const [dashboardData, setDashboardData] = useState<
    DashboardData | undefined
  >();
  const [initialized, setInitialized] = useState(false);
  const [sessionInvalid, setSessionInvalid] = useState(false);
  const [edihContract, setEdihContract] = useState<EDIHContract>();
  const navigate = useNavigate();

  // init by fetching profile
  useEffect(() => {
    fetchProfile();
  }, []);

  // prompt for login form when getting 401 error
  useEffect(() => {
    if (initialized) {
      myAxios.interceptors.response.clear();
      myAxios.interceptors.response.use(
        undefined,
        async (error: AxiosError) => {
          if (
            error.response &&
            [
              'sessionTokenMissing',
              'invalidSessionToken',
              'sessionExpired',
              'blacklisted',
            ].includes((error.response as any).data.code)
          ) {
            updateUser(undefined);
            setSessionInvalid(true);
          }

          return Promise.reject(error);
        }
      );
    }
  }, [setUser]);

  // refresh user profile on window focus
  useEffect(() => {
    const handler = async () => {
      if (user) {
        fetchProfile();
      }
    };

    window.addEventListener('focus', handler);

    return () => {
      window.removeEventListener('focus', handler);
    };
  }, [user]);

  async function fetchProfile() {
    try {
      console.log('fetch profile!!');
      const newUser = await getMe();
      updateUser({ ...newUser, tokens: user ? user.tokens : '0' });
    } catch (err) {
      console.error('failed to fetch profile', err);
    }

    setInitialized(true);
  }

  function updateUser(newUser?: User) {
    switchWeb3Provider(newUser && newUser.magicIssuer ? 'magic' : 'metamask');
    setUser(newUser);

    if (newUser) {
      fetchTokens(newUser);
    }
  }

  function login(user: User) {
    updateUser(user);
  }

  function logout() {
    updateUser(undefined);
    navigate(signInRoute);
  }

  async function fetchTokens(user: User) {
    try {
      let contract: EDIHContract;

      if (!edihContract) {
        contract = await loadEDIHContract(publicRpcProvider);
        setEdihContract(contract);
      } else {
        contract = edihContract;
      }
      const tokens = await contract.balanceOf(user!.walletAddress);
      setUser({ ...user!, tokens: tokens.toString() });
    } catch (err) {
      console.error('failed to fetch tokens', err);
    }
  }

  function isAdmin() {
    return (
      user !== undefined &&
      (user.role === 'admin' || user.role === 'superAdmin')
    );
  }

  async function fetchDashboardData() {
    const dashboardData = await getDashboardData();
    setDashboardData(dashboardData);
    updateUser({ ...user, ...dashboardData.user });

    return dashboardData;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        sessionInvalid,
        login,
        logout,
        isAdmin,
        fetchDashboardData,
        dashboardData,
        setDashboardData,
        setUser,
      }}
    >
      {initialized && props.children}
      {!initialized && <Loading fadeIn className="w-screen h-screen" />}
    </AuthContext.Provider>
  );
}

export default AuthContext;
