import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import { ContractUser } from '../../interfaces/user.interface';

const { abi } = require('./abi/EDIHEvents.json');

export class EDIHEventsContract {
  instance: ethers.Contract;

  constructor(instance: any) {
    this.instance = instance;
  }

  eventCreationPrice(): Promise<bigint> {
    return this.instance.eventCreationPrice();
  }

  createEvent(price: number, deadline: Date, supply: number, data: string) {
    console.log('createme', price, deadline, supply, data);
    return this.instance.createEvent(
      price,
      Math.floor(deadline.getTime() / 1000),
      supply,
      data
    );
  }
  updateEvent(
    eventId: string,
    price: number,
    deadline: Date,
    supply: number,
    data: string
  ) {
    console.log('updateme', price, deadline, supply, data);
    return this.instance.updateEvent(
      eventId,
      price,
      Math.floor(deadline.getTime() / 1000),
      supply,
      data
    );
  }

  purchaseEventEntries(eventId: string, amount: number) {
    return this.instance.purchaseEventEntries(eventId, amount);
  }
  useEventEntries(eventId: string, amount: number) {
    return this.instance.useEventEntries(eventId, amount);
  }
  safeTransferFrom(from: string, to: string, eventId: string, amount: number) {
    console.log('entry transfer', from, to, eventId, amount);
    return this.instance.safeTransferFrom(from, to, eventId, amount, '0x');
  }
  endEvent(eventId: string) {
    return this.instance.endEvent(eventId);
  }
  rateEvent(eventId: string, rating: number) {
    return this.instance.rateEvent(eventId, rating);
  }
  checkForEventRatingDeadline(eventId: string) {
    return this.instance.checkForEventRatingDeadline(eventId);
  }
  claimEventReward(eventId: string) {
    return this.instance.claimEventReward(eventId);
  }
}

export const loadEDIHEventsContract = (
  signer: JsonRpcSigner | JsonRpcProvider,
  address?: string
) => {
  const instance = new ethers.Contract(
    address || process.env.REACT_APP_EDIH_EVENTS_CONTRACT_ADDRESS!,
    abi,
    signer
  );

  return new EDIHEventsContract(instance);
};

// import { getWeb3 } from '../web3';

// const contractArtifact = require('./abi/EDIHEvents.json');
// const contract = require('@truffle/contract');
// const EDIHEvents = contract(contractArtifact);

// export class EDIHEventsContract {
//   instance: any;

//   constructor(instance: any) {
//     this.instance = instance;
//   }

//   eventCreationPrice(): Promise<bigint> {
//     return this.instance.eventCreationPrice();
//   }

//   createEvent(
//     from: string,
//     price: number,
//     deadline: Date,
//     supply: number,
//     data: string
//   ) {
//     console.log('createme', from, price, deadline, supply, data);
//     return this.instance.createEvent.sendTransaction(
//       price,
//       Math.floor(deadline.getTime() / 1000),
//       supply,
//       data,
//       {
//         from,
//       }
//     );
//   }
//   updateEvent(
//     from: string,
//     eventId: string,
//     price: number,
//     deadline: Date,
//     supply: number,
//     data: string
//   ) {
//     console.log('updateme', from, price, deadline, supply, data);
//     return this.instance.updateEvent.sendTransaction(
//       eventId,
//       price,
//       Math.floor(deadline.getTime() / 1000),
//       supply,
//       data,
//       {
//         from,
//       }
//     );
//   }

//   purchaseEventEntries(from: string, eventId: string, amount: number) {
//     return this.instance.purchaseEventEntries.sendTransaction(eventId, amount, {
//       from,
//     });
//   }
//   useEventEntries(from: string, eventId: string, amount: number) {
//     return this.instance.useEventEntries.sendTransaction(eventId, amount, {
//       from,
//     });
//   }
//   safeTransferFrom(from: string, to: string, eventId: string, amount: number) {
//     console.log('entry transfer', from, to, eventId, amount);
//     return this.instance.safeTransferFrom.sendTransaction(
//       from,
//       to,
//       eventId,
//       amount,
//       getWeb3().utils.asciiToHex(''),
//       {
//         from,
//       }
//     );
//   }
//   endEvent(from: string, eventId: string) {
//     return this.instance.endEvent.sendTransaction(eventId, {
//       from,
//     });
//   }
//   rateEvent(from: string, eventId: string, rating: number) {
//     return this.instance.rateEvent.sendTransaction(eventId, rating, {
//       from,
//     });
//   }
//   checkForEventRatingDeadline(from: string, eventId: string) {
//     return this.instance.checkForEventRatingDeadline.sendTransaction(eventId, {
//       from,
//     });
//   }
//   claimEventReward(from: string, eventId: string) {
//     return this.instance.claimEventReward.sendTransaction(eventId, {
//       from,
//     });
//   }
// }

// export const loadEDIHEventsContract = async () => {
//   const web3 = getWeb3();
//   if (web3.currentProvider) {
//     EDIHEvents.setProvider(web3.currentProvider);
//   }

//   const instance = await EDIHEvents.at(
//     process.env.REACT_APP_EDIH_EVENTS_CONTRACT_ADDRESS
//   );
//   return new EDIHEventsContract(instance);
// };
