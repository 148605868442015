import { UserProfileItem } from '../../../../../interfaces/user.interface';
import OptimizedImage from '../../../image';
import UserName from '../../../user-name';
import UserOrganization from '../../../user-organization';

interface Props {
  item: UserProfileItem;
  onSelect: (item: UserProfileItem) => void;
}

function SelectUserInputItem(props: Props) {
  function onSelect() {
    props.onSelect(props.item);
  }

  return (
    <div
      className="flex border-b border-[#F5F5F5] last:border-0 p-3 hover:bg-[#F5F5F5] cursor-pointer"
      onClick={onSelect}
    >
      {props.item.image && (
        <OptimizedImage
          className="w-[30px] h-[30px] rounded-full mr-2"
          params={{ w: '40', h: '40' }}
          src={props.item.image}
        />
      )}
      {!props.item.image && (
        <div className="w-[30px] h-[30px] rounded-full mr-2 aspect-square bg-grey-2" />
      )}
      <div className="flex flex-col justify-center">
        <p className="text-sm mb-[-.2rem]">
          <UserName obj={props.item} />
        </p>
        <p className="text-[0.625rem] text-font-grey">
          <UserOrganization obj={props.item} />
          {/* <UserRole role={props.item.role} /> */}
        </p>
      </div>
    </div>
  );
}

export default SelectUserInputItem;
