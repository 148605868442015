import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  getEventDataHashForCreation,
  getEvent,
  createEvent,
  getCreateEventForwardRequest,
} from '../../../api/events';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import EventForm, { EventFormData } from '../../../components/events/form';
import Button from '../../../components/shared/button';
import Loading from '../../../components/shared/loading';
import AuthContext from '../../../context/auth-context';
import { EventState } from '../../../interfaces/event.interface';
import { loadEDIHContract } from '../../../utils/contracts/edih';
import { loadEDIHEventsContract } from '../../../utils/contracts/edih-events';
import { myEventsRoute, eventRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';
import HTMLReactParser from 'html-react-parser';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../utils/utils';

export default function CreateEventPage() {
  const { t } = useTranslation('create-event');
  const commonTranslations = useTranslation('common');
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [event, setEvent] = useState<{ id: string; state: EventState }>();
  const [price, setPrice] = useState<number>(0);
  const { connect } = useContext(Web3Context);
  const waitForConfirmTimeout = useRef<any>();

  useEffect(() => {
    onConnect();
  }, []);

  async function onConnect() {
    setError(undefined);
    try {
      // get and validate wallet address
      await connect({
        hasToBeAddress: user!.walletAddress,
        noToast: true,
      });

      const signer = await new UncheckedJsonRpcSigner(
        getProvider(),
        user!.walletAddress
      );

      // fetch reward token balance
      const edihContract = await loadEDIHContract(signer);
      const balance = await edihContract.balanceOf(user!.walletAddress);

      // fetch project creation price
      const edihEventsContract = await loadEDIHEventsContract(signer);
      const [creationPrice, contractUser] = await Promise.all([
        edihEventsContract.eventCreationPrice(),
        edihContract.getUser(user!.walletAddress),
      ]);

      setPrice(Number(creationPrice));

      // check if user can affort project creation price
      if (balance < creationPrice) {
        throw t('errors.notEnough', { price: creationPrice.toString() });
      }

      // ensure at least 1 endorsement
      if (contractUser.endorsementCount < 1) {
        throw t('errors.noEndorsement');
      }

      setCanCreate(true);
    } catch (err: any) {
      console.error('failed to connect', err);
      setError(err.toString());

      setCanCreate(false);
    }
  }

  async function onSubmit(formData: EventFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchEventDataHash(formData);

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.createEvent(
            formData.price,
            formData.deadline,
            formData.supply,
            hash
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchCreateEventForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const event = await createEvent(formData, tx, signature);
          setSuccess(true);
          setEvent(event);
        } catch (err: any) {
          console.error('failed to create event', err);
          setError(t('errors.create'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .createEvent(
      //     user!.walletAddress,
      //     formData.price,
      //     formData.deadline,
      //     formData.supply,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('created', tx);
      //     try {
      //       const event = await createEvent(formData, tx);
      //       setSuccess(true);
      //       setEvent(event);
      //     } catch (err: any) {
      //       console.error('failed to create event', err);
      //       setError(t('errors.create'));
      //       setSubmitting(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchEventDataHash(formData: EventFormData) {
    try {
      let hash = await getEventDataHashForCreation(formData);
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchCreateEventForwardRequest(formData: EventFormData) {
    try {
      return getCreateEventForwardRequest(formData);
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  useEffect(() => {
    if (event && !waitForConfirmTimeout.current) {
      startWaitingForTransactionConfirmation();
    }
  }, [event]);

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      try {
        const result = await fetchCreatedEvent();

        if (result && result.state === 'waiting') {
          startWaitingForTransactionConfirmation();
        }
      } catch (err) {
        if (err === 'reverted') {
          setSuccess(false);
          setError(t('errors.waitForTransactionFail'));
        }
      }
    }, 5000);
  }

  async function fetchCreatedEvent() {
    try {
      const result = await getEvent(event!.id);
      setEvent(result);

      return result;
    } catch (err: any) {
      console.error('failed to fetch event', err);
      if (err.response && err.response.status === 404) {
        throw 'reverted';
      }
    }
  }

  useEffect(() => {
    return () => {
      if (waitForConfirmTimeout.current) {
        clearTimeout(waitForConfirmTimeout.current);
      }
      console.log('cancel poll', waitForConfirmTimeout.current);
    };
  }, []);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myEvents'),
          path: myEventsRoute,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.create'),
        },
      ]}
      selectedPath={myEventsRoute}
    >
      {error && (
        <Notification color="danger" className="max-w-[400px]">
          <div>{error}</div>
          {!canCreate && (
            <div className="mt-2 text-right">
              <Button size="small" color="secondary" onClick={onConnect}>
                {t('reconnect')}
              </Button>
            </div>
          )}
        </Notification>
      )}
      {!canCreate && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {success && (!event || event.state === 'waiting') && (
        <Notification color="success">{t('success')}</Notification>
      )}
      {event && event.state !== 'waiting' && (
        <Notification color="success">
          {HTMLReactParser(t('created', { url: eventRoute(event.id) }))}
        </Notification>
      )}
      {canCreate && (
        <EventForm onSubmit={onSubmit} disabled={submitting} price={price} />
      )}
    </UserDashboard>
  );
}
