import { PaginationResult } from './pagination-result.interface';
import { ProjectState } from './project.interface';
import { UserProfile, UserProfileItem } from './user.interface';

export type ProjectCollaboratorRecommendationState =
  | 'waiting'
  | 'sent'
  | 'accepted'
  | 'rejected';

export const projectCollaboratorRecommendationStates: ProjectCollaboratorRecommendationState[] =
  ['waiting', 'sent', 'accepted', 'rejected'];

export interface ProjectCollaboratorRecommendationDetail {
  id: string;
  recommended: UserProfile;
  recommender: {
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
  };
  project: {
    id: string;
    title: string;
    name: string;
    state: ProjectState;
    contractId: string;
    user: { id: string };
  };
  state: ProjectCollaboratorRecommendationState;
  message: string;
  files: { path: string; size: number }[];
  tx?: string;
  rejectReason?: string;
  createdAt: Date;
}

export interface ProjectCollaboratorRecommendationInviteForm {
  id: string;
  recommended: UserProfile;
  recommender: UserProfile;
  project: {
    id: string;
    title: string;
    state: ProjectState;
    contractId: string;
    user: { id: string };
  };
  state: ProjectCollaboratorRecommendationState;
}

export interface GetProjectCollaboratorRecommendationsResult
  extends PaginationResult<ProjectCollaboratorRecommendationItem> {
  project: {
    userId: string;
    title: string;
  };
}

export interface ProjectCollaboratorRecommendationItem {
  id: string;
  state: ProjectCollaboratorRecommendationState;
  createdAt: Date;
  recommended: UserProfileItem;
}

export interface GetProjectCollaboratorPlatformRecommendationsResult
  extends PaginationResult<ProjectCollaboratorPlatformRecommendationItem> {
  project: {
    userId: string;
    title: string;
  };
}
export interface ProjectCollaboratorPlatformRecommendationItem {
  user: UserProfileItem;
  tags: number;
  endorsements: number;
  score: number;
}
