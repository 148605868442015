import clsx from 'clsx';
import ProjectCollaboratorResponseItemElState from './state';
import { Link } from 'react-router-dom';
import { ProjectCollaboratorResponseItem } from '../../../../../interfaces/project-collaborator-response.interface';
import { projectCollaboratorApplicationRoute } from '../../../../../utils/routes';
import UserProfileItemEl from '../../../../users/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projectId: string;
  item: ProjectCollaboratorResponseItem;
}

function ProjectCollaboratorResponseItemEl(props: Props) {
  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(props.className, 'relative'),
      }}
    >
      <Link
        to={projectCollaboratorApplicationRoute(props.projectId, props.item.id)}
      >
        <UserProfileItemEl item={props.item.user} />
      </Link>
      <ProjectCollaboratorResponseItemElState state={props.item.state} />
    </div>
  );
}

export default ProjectCollaboratorResponseItemEl;
