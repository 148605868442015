import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import {
  getEndorsementRequestTargets,
  requestEndorsements,
} from '../../../../../api/endorsements';
import { UserProfileItem } from '../../../../../interfaces/user.interface';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import SelectUserList from '../../../../shared/form/select-user-list';
import Notification from '../../../../shared/notification';
import Modal from '../../../../shared/modal';

const schema = yup
  .object()
  .shape({
    users: yup.array().min(1).max(10).required(),
  })
  .required();

function RequestEndorsementsModal(props: { onClose: () => void }) {
  const { t } = useTranslation('dashboard');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    setError(undefined);
    setLoading(true);

    try {
      await requestEndorsements(values.users.map((u: UserProfileItem) => u.id));
      toast.success(t('requestEndorsement.modal.success', { duration: 3000 }));
      props.onClose();
    } catch (err: any) {
      console.error('failed to request', err);
      if (!axios.isAxiosError(err)) {
        setError(t('requestEndorsement.modal.errors.unexpected'));
      } else if (err.response!.data.code === 'alreadyEndorsed') {
        setError(t('requestEndorsement.modal.errors.alreadyEndorsed'));
      } else if (err.response!.data.code === 'alreadyRequested') {
        setError(t('requestEndorsement.modal.errors.alreadyRequested'));
      } else {
        setError(t('requestEndorsement.modal.errors.unexpected'));
      }
    }
    setLoading(false);
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <SelectUserList
                searchApi={(ignore: string[]) => (search: string) =>
                  getEndorsementRequestTargets(search, ignore)}
                onChange={field.onChange}
                placeholder={t('requestEndorsement.modal.users')}
                noResultsText={t('requestEndorsement.modal.noResults')}
                errorText={t('requestEndorsement.modal.errors.users')}
                error={errors.users !== undefined}
                disabled={loading}
                deleteText={t('requestEndorsement.modal.delete')}
                userList={field.value || []}
                max={10}
              />
            )}
            control={control}
            name="users"
          />
        </FormGroup>

        <Button className="w-full mt-4" disabled={loading}>
          {t('requestEndorsement.modal.submit')}
        </Button>
      </form>
    </Modal>
  );
}

export default RequestEndorsementsModal;
