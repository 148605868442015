import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import EventDetailAddEntryScanner from './add';
import EventDetailEntryScannersItem from './item';
import { getEventEntryScanners } from '../../../../api/events-entry-scanners';
import { EventEntryScannerItem } from '../../../../interfaces/event-entry-scanner.interface';
import { EventToManage } from '../../../../interfaces/event.interface';
import Loading from '../../../shared/loading';
import MyTable from '../../../shared/table';
import Modal from '../../../shared/modal';
import Notification from '../../../shared/notification';

function EventDetailEntryScannersModal(props: {
  event: EventToManage;
  onClose: () => void;
}) {
  const { t } = useTranslation('manage-event');
  const [scanners, setScanners] = useState<EventEntryScannerItem[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      setScanners(await getEventEntryScanners(props.event.id));
    } catch (err) {
      console.error('failed to load event entry scanners', err);
      setError(t('manageScanners.errors.load'));
    }
  }

  function onAdd(item: EventEntryScannerItem) {
    setScanners(scanners!.concat(item));
  }
  function onRemove(item: EventEntryScannerItem) {
    setScanners(scanners!.filter((obj) => obj.id !== item.id));
  }

  return (
    <Modal className="w-full max-w-[600px]" onClose={props.onClose}>
      {error && <Notification color="danger">{error}</Notification>}

      {!scanners && !error && (
        <div className="h-[100px]">
          <Loading fadeIn centerY />
        </div>
      )}

      {scanners && (
        <>
          {props.event.state === 'active' && (
            <EventDetailAddEntryScanner
              eventId={props.event.id}
              onAdd={onAdd}
            />
          )}

          {scanners.length > 0 && (
            <MyTable
              headers={
                t('manageScanners.tableHeaders', { returnObjects: true }) as any
              }
            >
              {scanners.map((item) => (
                <EventDetailEntryScannersItem
                  key={item.id}
                  event={props.event}
                  item={item}
                  onRemove={() => onRemove(item)}
                />
              ))}
            </MyTable>
          )}
          {scanners.length < 1 && (
            <div className="text-center mb-5">
              {HTMLReactParser(t('manageScanners.noResults'))}
            </div>
          )}
        </>
      )}

      {/* {showAddModal && (
        <EventDetailAddEntryScannerModal
          eventId={props.eventId}
          onClose={() => setShowAddModal(false)}
          onAdd={onAdd}
        />
      )} */}
    </Modal>
  );
}

export default EventDetailEntryScannersModal;
