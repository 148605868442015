import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function DashboardBox(props: Props) {
  let className = clsx('bg-white p-5 rounded-[10px]', props.className);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default DashboardBox;
