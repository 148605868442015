import { useNavigate } from 'react-router-dom';
import { EndorsementItem } from '../../../interfaces/endorsement.interface';
import { userProfileRoute } from '../../../utils/routes';
import OptimizedImage from '../../shared/image';
import UserName from '../../shared/user-name';
import UserRole from '../../shared/user-role';

function Endorser(props: { item: EndorsementItem }) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(userProfileRoute(props.item.sender.id));
  };

  const tags = props.item.tags.map((tag) => tag.name).join(' / ');

  return (
    <div
      className="flex  border-b border-[#F5F5F5] last:border-0 py-3 cursor-pointer"
      onClick={onClick}
    >
      {props.item.sender.image && (
        <OptimizedImage
          className="w-[41px] h-[41px] rounded-full mr-2"
          params={{ w: '41', h: '41' }}
          src={props.item.sender.image}
        />
      )}
      {!props.item.sender.image && (
        <div className="w-[41px] h-[41px] rounded-full mr-2 aspect-square bg-grey-2" />
      )}
      <div className="flex flex-col justify-center">
        <p className="text-sm mb-[-.2rem]">
          <UserName obj={props.item.sender} />
        </p>
        <div className="text-[0.625rem] text-font-grey mb-[-.1rem]">
          <UserRole role={props.item.sender.role} />
        </div>
        <div className="text-[0.625rem] text-[#b3b3b3] leading-none">
          {tags}
        </div>
      </div>
    </div>
  );
}

export default Endorser;
