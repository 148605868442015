import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function DashboardGroupValue(props: Props) {
  let className = clsx('text-sm whitespace-pre-line', props.className);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default DashboardGroupValue;
