import clsx from 'clsx';
import { UserProfileItem } from '../../../interfaces/user.interface';
import OptimizedImage from '../../shared/image';
import UserName from '../../shared/user-name';
import UserOrganization from '../../shared/user-organization';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: UserProfileItem;
}

function UserProfileItemEl(props: Props) {
  return (
    <div
      {...{
        ...props,
        className: clsx(
          props.className,
          'rounded-[10px] border border-grey-2 overflow-hidden'
        ),
      }}
    >
      {props.item.backgroundImage && (
        <OptimizedImage
          params={{ ar: '190:100', fit: 'crop', w: '400' }}
          className="w-full aspect-[190/100]"
          src={props.item.backgroundImage}
        />
      )}
      {!props.item.backgroundImage && (
        <div className="w-full aspect-[190/100] bg-grey-2" />
      )}

      <div className="p-2 mt-[-50px] flex flex-col items-center min-h-[200px]">
        {props.item.image && (
          <OptimizedImage
            params={{ w: '90', h: '90' }}
            className="border-2 border-white rounded-full w-[90px] h-[90px]"
            src={props.item.image}
          />
        )}
        {!props.item.image && (
          <div className="border-2 border-white rounded-full w-[90px] h-[90px] bg-grey-2" />
        )}

        <p className="font-medium mt-3 mb-auto">
          <UserName obj={props.item} />
        </p>

        <p className="text-sm text-font-grey">
          <UserOrganization obj={props.item} />
          {/* {props.item.organization && <span>{props.item.organization}</span>}
          {!props.item.organization && <UserRole role={props.item.role} />} */}
        </p>
      </div>
    </div>
  );
}

export default UserProfileItemEl;
