import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  VerifyEventEntryScannerResult,
  verifyEventEntryScanner,
} from '../../../../api/events-entry-scanners';
import EventTicketScanner from '../../../../components/events/qr-scanner';
import Loading from '../../../../components/shared/loading';
import Notification from '../../../../components/shared/notification';

export default function ScanEventEntriesPage() {
  const { t } = useTranslation('scan-event-entries');
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<VerifyEventEntryScannerResult>();
  const { scannerToken } = useParams();

  useEffect(() => {
    verifyScanner();
  }, [scannerToken!]);

  async function verifyScanner() {
    setError(undefined);
    try {
      setResult(await verifyEventEntryScanner(scannerToken! as string));
    } catch (err) {
      console.error('failed to verify', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.loadVerify'));
      } else if (err.response!.data.code === 'invalidScanner') {
        setError(t('errors.invalidScannerVerify'));
      } else if (err.response!.data.code === 'eventState') {
        setError(t('errors.eventState'));
      } else {
        setError(t('errors.loadVerify'));
      }
    }
  }

  return (
    <div>
      {!result && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {error && (
        <Notification className="m-5" color="danger">
          {error}
        </Notification>
      )}
      {result && (
        <EventTicketScanner
          title={result.event.title}
          scannerToken={scannerToken as string}
        />
      )}
    </div>
  );
}
