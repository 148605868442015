import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/button';
import Modal from '../../../shared/modal';

function ProjectFormConfirmServiceModal(props: {
  onConfirm: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation('project-form');

  return (
    <Modal className="w-full max-w-[400px]" onClose={props.onClose}>
      {t('serviceModal.text')}
      <div className="flex space-x-3 mt-4">
        <Button className="flex-1" color="light" onClick={props.onClose}>
          {t('serviceModal.cancel')}
        </Button>
        <Button className="flex-1" onClick={props.onConfirm}>
          {t('serviceModal.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default ProjectFormConfirmServiceModal;
