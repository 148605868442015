import clsx from 'clsx';
import DashboardSearchFilterModalItem, {
  DashboardSearchFilterItem,
} from './item';

function DashboardSearchFilterModal(props: {
  items: DashboardSearchFilterItem[];
  values: any;
  show: boolean;
  className?: string;
  onChecked: (key: string, value?: string) => void;
}) {
  return (
    <div
      className={clsx(
        'absolute top-[50px] right-0 rounded-[10px] bg-white shadow-2xl max-h-[400px] z-20 overflow-auto',
        !props.show ? 'pointer-events-none invisible' : undefined,
        props.className
      )}
    >
      {props.items.map((item) => (
        <DashboardSearchFilterModalItem
          item={item}
          values={props.values}
          key={item.title}
          onChecked={(value) => props.onChecked(item.key, value)}
        />
      ))}
    </div>
  );
}

export default DashboardSearchFilterModal;
