import { UserProfileItem } from '../../../../interfaces/user.interface';
import SelectUserInput from '../select-user';
import SelectUserListItem from './item';

interface Props {
  placeholder?: string;
  noResultsText: string;
  errorText: string;
  deleteText: string;
  error?: boolean;
  disabled?: boolean;
  searchApi: (
    ignore: string[]
  ) => (search: string) => Promise<UserProfileItem[]>;
  userList: UserProfileItem[];
  max: number;
  onChange: (items: UserProfileItem[]) => void;
}

function SelectUserList(props: Props) {
  function onSelect(item: UserProfileItem) {
    props.onChange(props.userList.concat(item));
  }

  function onDelete(item: UserProfileItem) {
    props.onChange(props.userList.filter((obj) => obj.id !== item.id));
  }

  const maxReached = props.userList.length >= props.max;

  const items = props.userList.map((user) => (
    <SelectUserListItem
      key={user.id}
      item={user}
      onDelete={onDelete}
      deleteText={props.deleteText}
    />
  ));

  return (
    <>
      <SelectUserInput
        searchApi={props.searchApi(props.userList.map((u) => u.id))}
        onSelect={onSelect}
        placeholder={props.placeholder}
        noResultsText={props.noResultsText}
        error={props.error}
        errorText={props.errorText}
        changeText=""
        disabled={props.disabled || maxReached}
        listMode
      />
      <div className="text-right text-xs">
        {props.userList.length} / {props.max}
      </div>
      <div className="flex flex-col">{items}</div>
    </>
  );
}

export default SelectUserList;
