import clsx from 'clsx';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import FilesForm, { FilesFormProps } from '../../../shared/form/files';

interface Props extends FilesFormProps {
  title: string;
  children?: any;
}

function DashboardFiles(props: Props) {
  return (
    <DashboardBox {...props}>
      <DashboardTitle>{props.title}</DashboardTitle>
      <div
        className={clsx(
          'grid grid-cols-3 gap-2 max-[800px]:grid-cols-2 max-[600px]:grid-cols-1',
          props.disabled ? 'opacity-50 pointer-events-none' : undefined
        )}
      >
        <FilesForm {...props} />
      </div>

      {props.children}
    </DashboardBox>
  );
}

export default DashboardFiles;
