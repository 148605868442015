import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { Tag } from '../../interfaces/tag.interface';
import SignupFinished from '../../components/auth/signup/finished';
import SignupIntroForm from '../../components/auth/signup/intro-form';
import SignupIntroFormDescription from '../../components/auth/signup/intro-form-description';
import SignupIntroFormDocument from '../../components/auth/signup/intro-form-document';
import SignupIntroFormIndividual from '../../components/auth/signup/intro-form-individual';
import SignupLoginForm from '../../components/auth/signup/login-form';
import SignupUserType from '../../components/auth/signup/type';
import SignupWallet from '../../components/auth/signup/wallet';
import SplitLayout from '../../components/core/split-layout';
import ProgressLines from '../../components/shared/progress-lines';
import NotAuthGuard from '../../guards/not-auth-guard';

export type SignupDataType = 'company' | 'individual';
export type SignupWalletType = 'metamask' | 'magic';

export interface SignupData {
  email?: string;
  password?: string;
  type: SignupDataType;
  name?: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  contactEmail?: string;
  phoneNumber?: string;
  website?: string;
  description?: string;
  sme?: boolean;
  file?: File;
  walletAddress?: string;
  walletType?: SignupWalletType;
  tags: Tag[];
}

export default function SignUpPage() {
  const { t } = useTranslation('signup');
  const [signupData, setSignupData] = useState<SignupData>({
    type: 'company',
    tags: [],
  });
  const [page, setPage] = useState(0);

  function onNextPage(data: SignupData) {
    console.log('submitted', data);
    setSignupData(data);
    setPage(page + 1);
  }

  function onBack() {
    setPage(page - 1);
  }

  return (
    <NotAuthGuard>
      <SplitLayout>
        <div className="min-h-[620px] flex flex-col justify-between">
          {page === 0 && (
            <SignupLoginForm signupData={signupData} onSubmit={onNextPage} />
          )}
          {page === 1 && (
            <SignupWallet
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )}
          {page === 2 && (
            <SignupUserType
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )}
          {page === 3 && signupData.type === 'company' && (
            <SignupIntroForm
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )}
          {page === 3 && signupData.type === 'individual' && (
            <SignupIntroFormIndividual
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )}
          {page === 4 && (
            <SignupIntroFormDescription
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )}
          {/* {page === 5 && (
            <SignupIntroFormDocument
              signupData={signupData}
              onSubmit={onNextPage}
              onBack={onBack}
            />
          )} */}

          {page === 5 && <SignupFinished />}
          {page < 5 && (
            <ProgressLines className="mt-auto" max={5} current={page} />
          )}
        </div>
      </SplitLayout>
    </NotAuthGuard>
  );
}
