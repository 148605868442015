import clsx from 'clsx';
import CalendarIcon from './calendar.svg';
import ProjectMilestoneItemElState from './state';
import { ProjectMilestone } from '../../../../../interfaces/project-milestones.interface';
import { formatShortDate } from '../../../../../utils/date';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: ProjectMilestone;
}

function ProjectMilestoneItemEl(props: Props) {
  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(
          props.className,
          'flex flex-col min-h-[164px] cursor-pointer border border-grey-2 rounded-lg p-2'
        ),
      }}
    >
      <div className="flex justify-end mb-3">
        <ProjectMilestoneItemElState state={props.item.state} />
      </div>
      <div className="text-sm font-medium mb-auto">{props.item.title}</div>
      <div className="h-[1px] bg-grey-2 my-2"></div>
      <div className="flex items-center text-[0.625rem] text-font-grey">
        <img src={CalendarIcon} alt="calendar" className="mr-2" />
        <span>{formatShortDate(props.item.deadline)}</span>
      </div>
    </div>
  );
}

export default ProjectMilestoneItemEl;
