import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import { ContractUser } from '../../interfaces/user.interface';

const { abi } = require('./abi/EDIH.json');

export class EDIHContract {
  instance: ethers.Contract;

  constructor(instance: any) {
    this.instance = instance;
  }

  getUser(address: string): Promise<ContractUser> {
    return this.instance.users(address);
  }

  addUser(address: string, data: string, tokens: number, endorse: boolean) {
    return this.instance.addUser(address, data, tokens, endorse);
  }

  mintRewardTokens(to: string, amount: number) {
    return this.instance.mintRewardTokens(to, amount);
  }

  isEndorserOfUser(user: string, endorser: string): Promise<boolean> {
    return this.instance.isEndorserOfUser(user, endorser);
  }

  balanceOf(address: string): Promise<bigint> {
    return this.instance.balanceOf(address);
  }

  endorse(user: string) {
    return this.instance.endorse(user);
  }

  claimEndorsementReward() {
    return this.instance.claimEndorsementReward();
  }

  transfer(to: string, amount: number) {
    return this.instance.transfer(to, amount);
  }
}

export const loadEDIHContract = (
  signer: JsonRpcSigner | JsonRpcProvider,
  address?: string
) => {
  const instance = new ethers.Contract(
    address || process.env.REACT_APP_EDIH_CONTRACT_ADDRESS!,
    abi,
    signer
  );

  return new EDIHContract(instance);
};
