import { ProjectCollaboratorInviteFormData } from '../components/projects/collaborators/invites/form';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import {
  ProjectCollaboratorInviteDetail,
  ProjectCollaboratorInviteState,
  GetProjectCollaboratorInvitesResult,
} from '../interfaces/project-collaborator-invite.interface';
import { InviteFormProject } from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import { UserProfileItem } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const getProjectCollaboratorInviteDataHash = async (
  projectId: string,
  formData: ProjectCollaboratorInviteFormData,
  recommendationId?: string
): Promise<string> => {
  console.log('myform', formData);
  const { data } = await myAxios.post(
    `/projects/${projectId}/invites/data-hash`,
    { ...formData, recommendationId, user: undefined, userId: formData.user.id }
  );

  return data.data;
};

export const getProjectCollaboratorInviteForwardRequest = async (
  projectId: string,
  formData: ProjectCollaboratorInviteFormData,
  recommendationId?: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/invites/forward-request`,
    { ...formData, recommendationId, user: undefined, userId: formData.user.id }
  );

  return data.data;
};

export const getProjectCollaboratorInvite = async (
  inviteId: string
): Promise<ProjectCollaboratorInviteDetail> => {
  const { data } = await myAxios.get(
    `/project-collaborator-invites/${inviteId}`
  );

  return data.data;
};

export const getRejectProjectCollaboratorInviteDataHash = async (
  inviteId: string,
  reason: string
): Promise<string> => {
  const { data } = await myAxios.post(
    `/project-collaborator-invites/${inviteId}/reject-data-hash`,
    { reason }
  );

  return data.data;
};

export const getRejectProjectCollaboratorInviteForwardRequest = async (
  inviteId: string,
  reason: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/project-collaborator-invites/${inviteId}/reject-forward-request`,
    { reason }
  );

  return data.data;
};

export const rejectProjectCollaboratorInvite = async (
  inviteId: string,
  tx?: string,
  signature?: string,
  reason?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/project-collaborator-invites/${inviteId}/reject`,
    { reason, tx, signature }
  );

  return data.data;
};

export const getAcceptProjectCollaboratorInviteForwardRequest = async (
  inviteId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/project-collaborator-invites/${inviteId}/accept-forward-request`
  );

  return data.data;
};

export const acceptProjectCollaboratorInvite = async (
  inviteId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/project-collaborator-invites/${inviteId}/accept`,
    { tx, signature }
  );

  return data.data;
};

export const requestProjectCollaboratorInviteFileDownload = async (
  inviteId: string,
  path: string
): Promise<string> => {
  const { data } = await myAxios.get(
    `/project-collaborator-invites/${inviteId}/request-download`,
    {
      params: { path },
    }
  );

  return data.data.url;
};

export const getInviteFormData = async (
  projectId: string
): Promise<InviteFormProject> => {
  const { data } = await myAxios.get(`/projects/${projectId}/invite-form`);

  return data.data;
};

export const getInvitableUsersForProject = async (
  projectId: string,
  search: string
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.get(`/projects/${projectId}/invitable-users`, {
    params: { search },
  });

  return data.data;
};

export const requestUploadForProjectCollaboratorInvite = async (
  projectId: string,
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/invites/request-upload`,
    {
      fileName,
    }
  );

  return data.data;
};

export const inviteUserToProject = async (
  projectId: string,
  formData: ProjectCollaboratorInviteFormData,
  tx?: string,
  signature?: string,
  recommendationId?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/projects/${projectId}/invites`, {
    ...formData,
    user: undefined,
    userId: formData.user.id,
    tx,
    signature,
    recommendationId,
  });

  return data.data;
};

export interface GetProjectCollaboratorInvitesFilter {
  search?: string;
  state?: ProjectCollaboratorInviteState;
}

export const getProjectCollaboratorInvites = async (
  page: number,
  projectId: string,
  filter: GetProjectCollaboratorInvitesFilter
): Promise<GetProjectCollaboratorInvitesResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/invites`, {
    params: { page, ...filter },
  });

  return data.data;
};
