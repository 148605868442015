import { ProjectFormData } from '../components/projects/form';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import { ProjectParticipantRating } from '../interfaces/project-rating.interface';
import {
  ProjectItem,
  ProjectUpdate,
  ProjectState,
  GetProjectsResult,
  ProjectDetail,
  EditProjectResult,
  RatableUser,
} from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import myAxios from '../utils/my-axios';

export const requestUploadForProject = async (
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post('/projects/request-upload', {
    fileName,
  });

  return data.data;
};

export const getProjectDataHashForCreation = async (
  formData: ProjectFormData
): Promise<string> => {
  const { data } = await myAxios.post('/projects/data-hash', formData);

  return data.data;
};

export const getCreateProjectForwardRequest = async (
  formData: ProjectFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    '/projects/create-forward-request',
    formData
  );

  return data.data;
};

export const getProjectDataHashForUpdate = async (
  projectId: string,
  formData: ProjectFormData
): Promise<string> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/data-hash`,
    formData
  );

  return data.data;
};

export const createProject = async (
  formData: ProjectFormData,
  tx?: string,
  signature?: string
): Promise<ProjectItem> => {
  const { data } = await myAxios.post('/projects', {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export const getUpdateProjectForwardRequest = async (
  projectId: string,
  formData: ProjectFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/update-forward-request`,
    formData
  );

  return data.data;
};

export const updateProject = async (
  projectId: string,
  formData: ProjectFormData,
  tx?: string,
  signature?: string
): Promise<ProjectUpdate> => {
  const { data } = await myAxios.patch(`/projects/${projectId}`, {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export const canStartProject = async (projectId: string): Promise<void> => {
  const { data } = await myAxios.get(`/projects/${projectId}/can-start`);

  return data.data;
};

export const getStartProjectForwardRequest = async (
  projectId: string,
  deadline: Date
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/start-forward-request`,
    { deadline }
  );

  return data.data;
};

export const startProject = async (
  projectId: string,
  deadline: Date,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/projects/${projectId}/start`, {
    tx,
    signature,
    deadline,
  });

  return data.data;
};

export interface GetMyProjectsFilter {
  search?: string;
  states?: ProjectState[];
}

export const getMyProjects = async (
  page: number,
  filter: GetMyProjectsFilter
): Promise<GetProjectsResult> => {
  const { data } = await myAxios.post('/projects/my', {
    page,
    ...filter,
  });

  return data.data;
};

export interface GetOtherProjectsFilter {
  search?: string;
  state?: ProjectState;
  tags?: string[];
}

export const getOtherProjects = async (
  page: number,
  filter: GetOtherProjectsFilter
): Promise<GetProjectsResult> => {
  const { data } = await myAxios.post('/projects/other', {
    page,
    ...filter,
  });

  return data.data;
};

export const getProject = async (projectId: string): Promise<ProjectDetail> => {
  const { data } = await myAxios.get(`/projects/${projectId}`);

  return data.data;
};

export const getProjectUpdate = async (
  projectUpdateId: string
): Promise<ProjectUpdate> => {
  const { data } = await myAxios.get(`/projects/updates/${projectUpdateId}`);

  return data.data;
};

export const getProjectToEdit = async (
  projectId: string
): Promise<EditProjectResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/edit`);

  return data.data;
};

export const requestProjectFileDownload = async (
  projectId: string,
  path: string,
  type: 'files' | 'closingFiles'
): Promise<string> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/request-download`,
    {
      params: { path, type },
    }
  );

  return data.data.url;
};

export const canStartProjectCompletion = async (
  projectId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/can-start-completion`
  );

  return data.data;
};

export const getProjectDataHashForCompletion = async (
  files: string[]
): Promise<string> => {
  const { data } = await myAxios.post('/projects/start-completion-data-hash', {
    files,
  });

  return data.data;
};

export const getStartProjectCompletionForwardRequest = async (
  projectId: string,
  files: string[]
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/start-completion-forward-request`,
    { files }
  );

  return data.data;
};

export const startProjectCompletion = async (
  projectId: string,
  files: string[],
  filesPrivate: boolean,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/start-completion`,
    { tx, signature, files, filesPrivate }
  );

  return data.data;
};

export const canCancelProjectCompletion = async (
  projectId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/can-cancel-completion`
  );

  return data.data;
};

export const getCancelProjectCompletionForwardRequest = async (
  projectId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/cancel-completion-forward-request`
  );

  return data.data;
};

export const cancelProjectCompletion = async (
  projectId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/cancel-completion`,
    { tx, signature }
  );

  return data.data;
};

export const canRateProject = async (projectId: string): Promise<void> => {
  const { data } = await myAxios.get(`/projects/${projectId}/can-rate`);

  return data.data;
};

export const getRateProjectForwardRequest = async (
  projectId: string,
  projectRating: number,
  participantRatings: ProjectParticipantRating[]
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/rate-forward-request`,
    { projectRating, participantRatings }
  );

  return data.data;
};

export const rateProject = async (
  projectId: string,
  projectRating: number,
  participantRatings: ProjectParticipantRating[],
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/projects/${projectId}/rate`, {
    tx,
    signature,
    projectRating,
    participantRatings,
  });

  return data.data;
};

export const getRatableUsers = async (
  projectId: string
): Promise<RatableUser[]> => {
  const { data } = await myAxios.get(`/projects/${projectId}/ratable-users`);

  return data.data;
};

export const canApproveProjectCompletion = async (
  projectId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/can-approve-completion`
  );

  return data.data;
};

export const getApproveProjectCompletionForwardRequest = async (
  projectId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/approve-completion-forward-request`
  );

  return data.data;
};

export const approveProjectCompletion = async (
  projectId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/approve-completion`,
    { tx, signature }
  );

  return data.data;
};

export const canClaimProjectReward = async (
  projectId: string
): Promise<void> => {
  const { data } = await myAxios.get(`/projects/${projectId}/can-claim-reward`);

  return data.data;
};

export const getClaimProjectRewardForwardRequest = async (
  projectId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/projects/${projectId}/claim-forward-request`
  );

  return data.data;
};

export const claimProjectReward = async (
  projectId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/projects/${projectId}/claim-reward`, {
    tx,
    signature,
  });

  return data.data;
};
