import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import { redirectStorageKey } from '../../pages/signin';
import { signInRoute, getDashboardRoute } from '../../utils/routes';

function AuthGuard(props: { children: any; adminOnly?: boolean }) {
  const { user, isAdmin } = useContext(AuthContext);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      localStorage.setItem(redirectStorageKey, location.pathname);
      navigate(signInRoute);
    } else if (props.adminOnly && !isAdmin()) {
      navigate(getDashboardRoute(user.role));
    }
  }, []);

  return <>{user && props.children}</>;
}

export default AuthGuard;
