import { useTranslation } from 'react-i18next';

function UserName(props: {
  obj: { name?: string; firstName?: string; lastName?: string };
}) {
  const { t } = useTranslation('event-states');

  return (
    <>{props.obj.name || `${props.obj.firstName} ${props.obj.lastName}`}</>
  );
}

export default UserName;
