import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import Web3Context from '../../../../context/web3-context';
import {
  acceptProjectCollaboratorInvite,
  getAcceptProjectCollaboratorInviteForwardRequest,
} from '../../../../api/projects-collaborator-invites';
import AuthContext from '../../../../context/auth-context';
import { ProjectCollaboratorInviteDetail } from '../../../../interfaces/project-collaborator-invite.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import Button from '../../../shared/button';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../utils/web3';
import { sleep } from '../../../../utils/utils';

export default function AcceptProjectCollaboratorInvite(props: {
  invite: ProjectCollaboratorInviteDetail;
  onError: (error?: string) => void;
  onAccept: () => void;
}) {
  const { t } = useTranslation('project-collaborator-invite');
  const [loading, setLoading] = useState(false);
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onSubmit() {
    props.onError(undefined);
    setLoading(true);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result =
            await edihProjectsContract.acceptProjectCollaboratorInvite(
              props.invite.project.contractId
            );

          tx = result.hash;
          console.log('accepted', tx);
        } else {
          const forwardRequest = await fetchAcceptForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await acceptProjectCollaboratorInvite(props.invite.id, tx, signature);
          props.onAccept();
        } catch (err: any) {
          console.error('failed to accept invite', err);
          props.onError(t('accept.errors.accept'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.onError(t('accept.errors.transaction'));
        setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .acceptProjectCollaboratorInvite(
      //     user!.walletAddress,
      //     props.invite.project.contractId
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('accepted', tx);
      //     try {
      //       await acceptProjectCollaboratorInvite(props.invite.id, tx);
      //       props.onAccept();
      //     } catch (err: any) {
      //       console.error('failed to accept invite', err);
      //       props.onError(t('accept.errors.accept'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.onError(t('accept.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function fetchAcceptForwardRequest() {
    try {
      let hash = await getAcceptProjectCollaboratorInviteForwardRequest(
        props.invite.id
      );
      return hash;
    } catch (err) {
      props.onError(t('accept.errors.transaction'));

      throw err;
    }
  }

  return (
    <>
      <Button onClick={onSubmit} disabled={loading}>
        {t('accept.button')}
      </Button>
    </>
  );
}
