import clsx from 'clsx';
import React from 'react';

export interface FormTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const FormTextarea = React.forwardRef((props: FormTextareaProps, ref?: any) => {
  let className = clsx(
    'border outline-none p-[0.813rem] px-3 text-font-black rounded text-sm',
    props.error ? 'border-red-500 bg-red-100' : 'border-grey',
    props.className
  );

  return (
    <textarea
      {...{ ...props, error: undefined }}
      className={className}
      ref={ref}
    >
      {props.children}
    </textarea>
  );
});

FormTextarea.displayName = 'FormTextarea';

export default FormTextarea;
