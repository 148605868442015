import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetEndorsementsForUserResult,
  GetEndorsementsFilter,
  getEndorsementsForUser,
} from '../../../../api/endorsements';
import { getUserProfile } from '../../../../api/users';
import DashboardSearch from '../../../../components/core/dashboard-layout/search';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import Loading from '../../../../components/shared/loading';
import MemberList from '../../../../components/users/members';
import { GetUserProfileResult } from '../../../../interfaces/user.interface';
import { membersRoute, userProfileRoute } from '../../../../utils/routes';
import { getDisplayName } from '../../../../utils/utils';
import Notification from '../../../../components/shared/notification';

export default function EndorsersOfMemberPage() {
  const { t } = useTranslation('endorsers');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<GetUserProfileResult>();
  const [data, setData] = useState<GetEndorsementsForUserResult>();
  const [filter, setFilter] = useState<GetEndorsementsFilter>({});

  const commonTranslations = useTranslation('common');
  const { userId } = useParams();

  useEffect(() => {
    loadUserProfile(userId! as string);
  }, [userId]);

  useEffect(() => {
    if (userProfile) {
      fetchItems(0);
    }
  }, [filter]);

  async function loadUserProfile(userId: string) {
    try {
      const result = await getUserProfile(userId);
      setUserProfile(result);
      fetchItems(0);
      console.log('profile loaded', result);
      return result;
    } catch (err: any) {
      setError(t('errors.loadProfile'));
    }
  }

  async function fetchItems(page: number) {
    console.log('fetch doggo1');
    setError(undefined);
    setLoading(true);
    try {
      const result = await getEndorsementsForUser(
        userId! as string,
        page,
        filter
      );
      console.log('found', result);
      setData(result);
    } catch (err) {
      console.error('failed to get endorsers', err);
      setError(t('errors.loadEndorsers'));
    }
    setLoading(false);
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={membersRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.members'),
          path: membersRoute,
        },
        {
          label: userProfile ? getDisplayName(userProfile.user) : '',
          path: userProfileRoute(userProfile ? userProfile?.user.id : ''),
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.endorsers'),
        },
      ]}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1113px] flex flex-col min-h-full">
          <div className="flex space-x-4">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
          </div>

          <MemberList
            className="mt-5"
            result={{
              ...data,
              results: data.results.map((item) => item.sender),
            }}
            onPageChange={(page: number) => fetchItems(page)}
            loading={loading}
            notFoundText={t('notFound')}
          />
        </div>
      )}
    </UserDashboard>
  );
}
