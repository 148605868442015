import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { resetPassword } from '../../api/auth';
import SplitLayout from '../../components/core/split-layout';
import Button from '../../components/shared/button';
import FormGroup from '../../components/shared/form/group';
import FormInput from '../../components/shared/form/input';
import FormLabel from '../../components/shared/form/label';
import NotAuthGuard from '../../guards/not-auth-guard';
import Notification from '../../components/shared/notification';

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required();

export default function ResetPasswordPage() {
  const { t } = useTranslation('reset-password');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [sent, setSent] = useState<boolean>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await resetPassword(values.email.toLowerCase());
      setSent(true);
    } catch (err: any) {
      console.error('failed to reset', err);
      setError(t('errors.unexpected')!);
    }
    setLoading(false);
  }

  return (
    <NotAuthGuard>
      <SplitLayout>
        <div className="min-h-[620px] flex flex-col">
          <h1 className="text-[2.5rem] font-medium text-center mb-8">
            {t('title')}
          </h1>
          {error && <Notification color="danger">{error}</Notification>}
          {!sent && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <FormLabel>{t('email')}</FormLabel>
                <FormInput
                  type="email"
                  {...register('email')}
                  error={errors.email !== undefined}
                  disabled={loading}
                />
              </FormGroup>

              <Button className="w-full mt-2" disabled={loading}>
                {t('submit')}
              </Button>
            </form>
          )}

          {sent && <div className="text-sm text-center">{t('sent')}</div>}
        </div>
      </SplitLayout>
    </NotAuthGuard>
  );
}
