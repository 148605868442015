import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { Tag } from '../../interfaces/tag.interface';
import { GetOtherEventsFilter, getOtherEvents } from '../../api/events';
import { getOtherEventTags } from '../../api/tags';
import DashboardSearch from '../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../components/core/dashboard-layout/search-filter/modal/item';
import UserDashboard from '../../components/core/dashboard-layout/user';
import EventList from '../../components/events/list';
import Loading from '../../components/shared/loading';
import AuthContext from '../../context/auth-context';
import { GetEventsResult } from '../../interfaces/event.interface';
import { otherEventsRoute } from '../../utils/routes';
import Notification from '../../components/shared/notification';

const filterStates = {
  active: ['active'],
  completed: [
    'waitingForRating',
    'rating',
    'waitingForRatingDeadline',
    'completed',
  ],
};

export default function OtherEventsPage() {
  const { t } = useTranslation('other-events');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEventsResult>();
  const [filter, setFilter] = useState<GetOtherEventsFilter>({});
  const [tags, setTags] = useState<Tag[]>();
  const { user } = useContext(AuthContext);

  const filterTags = tags
    ? tags.map((tag) => {
        return { label: tag.name, value: tag.id };
      })
    : [];

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('states.title'),
      key: 'states',
      items: [
        { label: t('states.all') },
        { label: t('states.active'), value: 'active' },
        { label: t('states.completed'), value: 'completed' },
      ],
    },
    {
      title: t('tags.title'),
      key: 'tags',
      items: [
        { label: t('tags.all') },
        { label: t('tags.mine'), value: '[mine]' },
      ].concat(...filterTags),
    },
  ];

  useEffect(() => {
    loadTags();
  }, []);

  async function loadTags() {
    try {
      const result = await getOtherEventTags();
      setTags(result);
    } catch (err) {
      console.error('failed to  load tags', err);
    }
  }

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    setLoading(true);
    try {
      console.log('search with filter', filter);
      const result = await getOtherEvents(page, filter);
      setData(result);
    } catch (err) {
      console.error('failed to get events', err);
      setError(t('errors.load'));
    }
    setLoading(false);
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    if (key === 'tags' && value !== '') {
      if (value === '[mine]') {
        value = user!.tags!.map((tag) => tag.id) as any;
      } else {
        value = [value] as any;
      }
    } else if (key === 'states' && value !== '') {
      value = (filterStates as any)[value as any];
    }

    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({ ...filter, [key]: value === '' ? undefined : value });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={otherEventsRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.otherEvents'),
        },
      ]}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1200px] flex flex-col min-h-full">
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <EventList
            result={data}
            onPageChange={(page: number) => fetchItems(page)}
            loading={loading}
            notFoundText={t('notFound')}
          />
        </div>
      )}
    </UserDashboard>
  );
}
