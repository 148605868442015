import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RejectProjectCollaboratorRecommendationModal from './modal';
import { ProjectCollaboratorRecommendationDetail } from '../../../../../interfaces/project-collaborator-recommendation.interface';
import Button from '../../../../shared/button';

export default function RejectProjectCollaboratorRecommendation(props: {
  recommendation: ProjectCollaboratorRecommendationDetail;
  onReject: (reason: string) => void;
}) {
  const { t } = useTranslation('project-collaborator-recommendation');
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button color="secondary" onClick={() => setShowModal(true)}>
        {t('reject.button')}
      </Button>

      {showModal && (
        <RejectProjectCollaboratorRecommendationModal
          recommendation={props.recommendation}
          onClose={() => setShowModal(false)}
          onReject={props.onReject}
        />
      )}
    </>
  );
}
