import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RejectProjectCollaboratorInviteModal from './modal';
import { ProjectCollaboratorInviteDetail } from '../../../../interfaces/project-collaborator-invite.interface';
import Button from '../../../shared/button';

export default function RejectProjectCollaboratorInvite(props: {
  invite: ProjectCollaboratorInviteDetail;
  onReject: () => void;
}) {
  const { t } = useTranslation('project-collaborator-invite');
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button color="secondary" onClick={() => setShowModal(true)}>
        {t('reject.button')}
      </Button>

      {showModal && (
        <RejectProjectCollaboratorInviteModal
          invite={props.invite}
          onClose={() => setShowModal(false)}
          onReject={props.onReject}
        />
      )}
    </>
  );
}
