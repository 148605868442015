import { GetProjectCollaboratorsResult } from '../interfaces/project-collaborator.interface';
import myAxios from '../utils/my-axios';

export interface GetProjectCollaboratorsFilter {
  search?: string;
  role?: 'company' | 'individual';
}

export const getProjectCollaborators = async (
  page: number,
  projectId: string,
  filter: GetProjectCollaboratorsFilter
): Promise<GetProjectCollaboratorsResult> => {
  const { data } = await myAxios.get(`/projects/${projectId}/collaborators`, {
    params: { page, ...filter },
  });

  return data.data;
};

export const uploadProjectCollaboratorFiles = async (
  projectId: string,
  files: string[],
  filesPrivate: boolean
): Promise<void> => {
  const { data } = await myAxios.post(
    `/projects/${projectId}/collaborators/files`,
    {
      files,
      private: filesPrivate,
    }
  );

  return data.data;
};

export const requestProjectCollaboratorFileDownload = async (
  projectCollaboratorId: string,
  path: string
): Promise<string> => {
  const { data } = await myAxios.get(
    `/project-collaborators/${projectCollaboratorId}/request-download`,
    {
      params: {
        path,
      },
    }
  );

  return data.data.url;
};
