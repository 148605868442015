import { GetActivitiesResult } from '../interfaces/activity.interface';
import myAxios from '../utils/my-axios';

export const getActivities = async (
  page: number
): Promise<GetActivitiesResult> => {
  const { data } = await myAxios.get('/activities', {
    params: { page },
  });

  return data.data;
};

export const claimEndorsementReward = async (tx: string): Promise<void> => {
  const { data } = await myAxios.post(`/endorsements/claim-reward`, {
    tx,
  });

  return data.data;
};
