import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getInvitableUsersForProject } from '../../../../../api/projects-collaborator-invites';
import AuthContext from '../../../../../context/auth-context';
import { InviteFormProject } from '../../../../../interfaces/project.interface';
import { UserProfileItem } from '../../../../../interfaces/user.interface';
import { userProfileRoute } from '../../../../../utils/routes';
import DashboardBox from '../../../../core/dashboard-layout/box';
import DashboardUserProfile from '../../../../core/dashboard-layout/profile';
import DashboardTitle from '../../../../core/dashboard-layout/title';
import Button from '../../../../shared/button';
import DateFormInput from '../../../../shared/form/date-input';
import FormGroup from '../../../../shared/form/group';
import FormLabel from '../../../../shared/form/label';
import SelectUserInput from '../../../../shared/form/select-user';
import FormTextarea from '../../../../shared/form/textarea';
import UserProfileItemEl from '../../../../users/item';

export interface ProjectCollaboratorInviteFormData {
  user: UserProfileItem;
  deadline: string;
  message: string;
  files: string[];
}

const schema = yup
  .object()
  .shape({
    user: yup.mixed().required(),
    message: yup.string().required(),
    deadline: yup.date().required(),
    files: yup.array().of(yup.string().required()).required(),
  })
  .required();

function ProjectCollaboratorInviteForm(props: {
  project: InviteFormProject;
  selectedUser?: UserProfileItem;
  disabled?: boolean;
  onSubmit: (formData: ProjectCollaboratorInviteFormData) => void;
}) {
  const { t } = useTranslation('invite-collaborator');
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { files: [] } });

  function onSubmit(values: any) {
    props.onSubmit(values);
  }

  useEffect(() => {
    if (props.selectedUser) {
      setValue('user', props.selectedUser);
    }
  }, [props.selectedUser]);

  console.log('errors?', errors);
  const minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1"
    >
      <div className="flex flex-col space-y-6">
        <DashboardBox>
          <DashboardTitle>{t('title')}</DashboardTitle>

          <FormGroup>
            <FormLabel required showRequiredInfo>
              {t('name')}
            </FormLabel>
            {!props.selectedUser && (
              <Controller
                render={({ field }) => (
                  <SelectUserInput
                    searchApi={(search: string) =>
                      getInvitableUsersForProject(props.project.id, search)
                    }
                    onSelect={field.onChange}
                    placeholder={t('namePlaceholder')}
                    noResultsText={t('nameNoResults')}
                    errorText={t('errors.name')}
                    changeText={t('nameChange')}
                    disabled={props.disabled}
                    error={errors.user !== undefined}
                  />
                )}
                control={control}
                name="user"
              />
            )}
            {props.selectedUser && (
              <a
                className="w-[190px]"
                href={userProfileRoute(props.selectedUser.id)}
                target="_blank"
              >
                <UserProfileItemEl item={props.selectedUser} />
              </a>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel required>{t('deadline')}</FormLabel>

            <Controller
              render={({ field }) => (
                <DateFormInput
                  value={field.value}
                  onChangeDate={field.onChange}
                  error={errors.deadline !== undefined}
                  disabled={props.disabled}
                  minDate={minDate}
                />
              )}
              control={control}
              name="deadline"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel required>{t('message')}</FormLabel>
            <FormTextarea
              {...register('message')}
              error={errors.message !== undefined}
              rows={6}
              maxLength={2000}
              disabled={props.disabled}
            />
          </FormGroup>
        </DashboardBox>
        {/* <Controller
          render={({ field }) => (
            <DashboardFiles
              title={t('files')}
              addText={t('addFile')}
              editable
              onChangeFormFiles={field.onChange}
              maxSize={+process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILE_SIZE!
              }
              getUploadUrlApi={(fileName: string) =>
                requestUploadForProjectCollaboratorInvite(
                  props.project.id,
                  fileName
                )
              }
              disabled={props.disabled}
            />
          )}
          control={control}
          name="files"
        /> */}
      </div>
      <div className="flex flex-col space-y-4">
        <DashboardUserProfile className="max-xl:hidden" user={user!} />
        <Button disabled={errors.files !== undefined || props.disabled}>
          {t('send')}
        </Button>
      </div>
    </form>
  );
}

export default ProjectCollaboratorInviteForm;
