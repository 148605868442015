import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MintModal from '../../../shared/mint-modal';
import { UserRowItem } from '../../../../interfaces/user.interface';
import Button from '../../../shared/button';
import MyTableRow from '../../../shared/table/row';
import UserName from '../../../shared/user-name';
import UserRole from '../../../shared/user-role';
import WalletAddress from '../../../shared/wallet-address';

function UserItemRow(props: { item: UserRowItem }) {
  const { t } = useTranslation('users');
  const [showMintModal, setShowMintModal] = useState(false);

  return (
    <MyTableRow>
      <td>
        <UserName obj={props.item} />
      </td>
      <td>{props.item.email}</td>
      <td>
        <UserRole role={props.item.role} />
      </td>
      <td>
        {props.item.walletAddress ? (
          <WalletAddress walletAddress={props.item.walletAddress} />
        ) : (
          '-'
        )}
      </td>
      {props.item.walletAddress && (
        <td>
          <Button size="small" onClick={() => setShowMintModal(true)}>
            {t('table.mint.button')}
          </Button>
          {showMintModal && (
            <MintModal
              walletAddress={props.item.walletAddress}
              onClose={() => setShowMintModal(false)}
            />
          )}
        </td>
      )}
    </MyTableRow>
  );
}

export default UserItemRow;
