import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import DashboardLayout, { DashboardItem } from '..';
import { PathItem } from '../path-layout';
import DashboardIcon from '../svgs/dashboard';
import DashboardEndorsementsIcon from '../svgs/dashboard-endorsements';
import DashboardFAQIcon from '../svgs/faq';
import DashboardMembersIcon from '../svgs/members';
import DashboardMyEventsIcon from '../svgs/my-events';
import DashboardMyProjectsIcon from '../svgs/my-projects';
import DashboardOtherEventsIcon from '../svgs/other-events';
import DashboardOtherProjectsIcon from '../svgs/other-projects';
import AuthContext from '../../../../context/auth-context';
import AuthGuard from '../../../../guards/auth-guard';
import {
  getDashboardRoute,
  myProjectsRoute,
  otherProjectsRoute,
  myEventsRoute,
  otherEventsRoute,
  membersRoute,
  endorsementsRoute,
  faqRoute,
} from '../../../../utils/routes';

interface Props {
  title: string;
  selectedPath: string;
  subtitle?: string;
  path?: PathItem[];
  children: React.ReactNode;
}

function UserDashboard(props: Props) {
  const { t } = useTranslation('common');
  const { user } = useContext(AuthContext);

  const items: DashboardItem[] = [
    {
      icon: (className: string) => <DashboardIcon className={className} />,
      label: t('dashboard.items.dashboard'),
      path: getDashboardRoute(),
    },
    {
      icon: (className: string) => (
        <DashboardMyProjectsIcon className={className} />
      ),
      label: t('dashboard.items.myProjects'),
      path: myProjectsRoute,
    },
    {
      icon: (className: string) => (
        <DashboardOtherProjectsIcon className={className} />
      ),
      label: t('dashboard.items.otherProjects'),
      path: otherProjectsRoute,
    },
    {
      icon: (className: string) => (
        <DashboardMyEventsIcon className={className} />
      ),
      label: t('dashboard.items.myEvents'),
      path: myEventsRoute,
    },
    {
      icon: (className: string) => (
        <DashboardOtherEventsIcon className={className} />
      ),
      label: t('dashboard.items.otherEvents'),
      path: otherEventsRoute,
    },
    {
      icon: (className: string) => (
        <DashboardMembersIcon className={className} />
      ),
      label: t('dashboard.items.members'),
      path: membersRoute,
    },
    {
      icon: (className: string) => (
        <DashboardEndorsementsIcon className={className} />
      ),
      label: t('dashboard.items.endorsements'),
      path: endorsementsRoute,
    },
    {
      icon: (className: string) => <DashboardFAQIcon className={className} />,
      label: t('dashboard.items.faq'),
      path: faqRoute,
    },
  ];

  const path = props.path
    ? (
        [
          {
            label: t('dashboard.pathLabels.dashboard'),
            path: getDashboardRoute(user ? user.role : 'individual'),
          },
        ] as PathItem[]
      ).concat(...props.path!)
    : undefined;

  return (
    <AuthGuard>
      <DashboardLayout
        title={props.title}
        subtitle={props.subtitle}
        items={items}
        selectedPath={props.selectedPath}
        path={path}
      >
        {props.children}
      </DashboardLayout>
    </AuthGuard>
  );
}

export default UserDashboard;
