import { useState } from 'react';
import FormRadio from '../../../../../shared/form/radio';

export interface DashboardSearchFilterItem {
  title: string;
  key: string;
  items: {
    label: string;
    value?: string;
  }[];
}

function DashboardSearchFilterModalItem(props: {
  item: DashboardSearchFilterItem;
  values: any;
  onChecked: (value?: string) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="border-b border-grey-2 text-sm text-font-black-2 last:border-b-0">
      <div
        className="flex align-items-center px-4 py-3 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <span className="mr-auto">{props.item.title}</span>
        <img
          src={'/images/dashboard/filter-' + (open ? 'open.svg' : 'closed.svg')}
          width={14}
          height={14}
          alt="expand"
        />
      </div>

      {open && (
        <div className="px-6 pb-3 space-y-2">
          {props.item.items.map((item) => (
            <FormRadio
              className="last:mb-0"
              key={'filter-' + item.label + '-' + item.value}
              radioProps={{
                name: props.item.key,
                value: item.value,
                defaultChecked: !item.value,
                onChange: (ev) => props.onChecked(ev.currentTarget.value),
              }}
            >
              {item.label}
            </FormRadio>
          ))}
        </div>
      )}
    </div>
  );
}

export default DashboardSearchFilterModalItem;
