import { useTranslation } from 'react-i18next';
import { ProjectState } from '../../../interfaces/project.interface';

function ProjectStateEl(props: { state: ProjectState }) {
  const { t } = useTranslation('project-states');

  return <>{t(props.state)}</>;
}

export default ProjectStateEl;
