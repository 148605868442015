import { UserProfileItem } from '../../../../../interfaces/user.interface';
import Button from '../../../button';
import OptimizedImage from '../../../image';
import UserName from '../../../user-name';
import UserOrganization from '../../../user-organization';

interface Props {
  item: UserProfileItem;
  deleteText: string;
  onDelete: (item: UserProfileItem) => void;
}

function SelectUserListItem(props: Props) {
  return (
    <div className="flex items-center border-b border-[#F5F5F5] py-2">
      <div className="flex mr-auto">
        {props.item.image && (
          <OptimizedImage
            className="w-[30px] h-[30px] rounded-full mr-2"
            params={{ w: '40', h: '40' }}
            src={props.item.image}
          />
        )}
        {!props.item.image && (
          <div className="w-[30px] h-[30px] rounded-full mr-2 aspect-square bg-grey-2" />
        )}
        <div className="flex flex-col justify-center">
          <p className="text-sm mb-[-.2rem]">
            <UserName obj={props.item} />
          </p>
          <p className="text-[0.625rem] text-font-grey">
            <UserOrganization obj={props.item} />
            {/* <UserRole role={props.item.role} /> */}
          </p>
        </div>
      </div>
      <Button
        className="ml-2"
        size="small"
        color="secondary"
        onClick={() => props.onDelete(props.item)}
      >
        {props.deleteText}
      </Button>
    </div>
  );
}

export default SelectUserListItem;
