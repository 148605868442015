import clsx from 'clsx';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import styles from './index.module.css';
import { getActivities } from '../../../../api/activities';
import { GetActivitiesResult } from '../../../../interfaces/activity.interface';
import { formatDateTime } from '../../../../utils/date';
import {
  userProfileRoute,
  projectRoute,
  projectCollaboratorInviteRoute,
  projectCollaboratorApplicationRoute,
  projectCollaboratorRecommendationRoute,
  eventRoute,
  projectMilestoneRoute,
} from '../../../../utils/routes';
import { getDisplayName } from '../../../../utils/utils';
import Button from '../../../shared/button';
import Notification from '../../../shared/notification';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  activities: GetActivitiesResult;
}

function DashboardActivities(props: Props) {
  const { t } = useTranslation('dashboard-activities');

  const [page, setPage] = useState<number>(0);
  const [activities, setActivities] = useState(props.activities);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setActivities(props.activities);
  }, [props.activities]);

  async function nextPage(page: number) {
    setLoading(true);
    setError(undefined);
    try {
      let result = await getActivities(page);

      setActivities({
        ...activities,
        results: activities.results.concat(...result.results),
      });

      console.log('activities loaded', result);
    } catch (err) {
      console.error('failed to load activities', err);
      setError(t('errors.loadMore'));
    }
    setLoading(false);
  }

  useEffect(() => {
    if (page > 0) {
      nextPage(page);
    }
  }, [page]);

  // load next page if no error, otherwise reload same page
  function onLoadMore() {
    if (!error) {
      setPage(page + 1);
    } else {
      nextPage(page);
    }
  }

  const els = activities.results.map((activity) => (
    <div
      key={'activitiy-' + activity.id}
      className={clsx(
        'border border-grey-2 text-sm rounded-xl p-3 pb-2',
        styles.activities
      )}
    >
      {parse(
        t('types.' + activity.type, {
          user: {
            ...activity.user,
            name: activity.user ? getDisplayName(activity.user!) : '',
          },
          targetUser: {
            ...activity.targetUser,
            name: activity.targetUser
              ? getDisplayName(activity.targetUser!)
              : '',
          },
          targetUserUrl: activity.targetUser
            ? userProfileRoute(activity.targetUser.id)
            : undefined,
          project: activity.project,
          projectUrl: activity.project
            ? projectRoute(activity.project.id)
            : undefined,
          projectCollaboratorInviteUrl: activity.projectCollaboratorInviteId
            ? projectCollaboratorInviteRoute(
                activity.project!.id,
                activity.projectCollaboratorInviteId
              )
            : undefined,
          projectCollaboratorResponseUrl: activity.projectCollaboratorResponseId
            ? projectCollaboratorApplicationRoute(
                activity.project!.id,
                activity.projectCollaboratorResponseId
              )
            : undefined,
          projectCollaboratorRecommendationUrl:
            activity.projectCollaboratorRecommendationId
              ? projectCollaboratorRecommendationRoute(
                  activity.project!.id,
                  activity.projectCollaboratorRecommendationId
                )
              : undefined,
          event: activity.event,
          eventUrl: activity.event ? eventRoute(activity.event.id) : undefined,
          projectMilestone: activity.projectMilestone,
          projectMilestoneUrl: activity.projectMilestone
            ? projectMilestoneRoute(
                activity.project!.id,
                activity.projectMilestone.id
              )
            : undefined,
          data: activity.data,
        })
      )}
      <p className="text-[0.625rem] text-font-grey text-right pt-1">
        {formatDateTime(activity.createdAt)}
      </p>
    </div>
  ));

  return (
    <DashboardBox {...props}>
      <DashboardTitle>{t('title')}</DashboardTitle>
      {error && <Notification color="danger">{error}</Notification>}
      <div className="flex flex-col space-y-3">{els}</div>
      {activities.results.length < activities.total && (
        <Button
          className="mt-3"
          size="small"
          onClick={onLoadMore}
          disabled={loading}
        >
          {t('loadMore')}
        </Button>
      )}
    </DashboardBox>
  );
}

export default DashboardActivities;
