import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import { ContractUser } from '../../interfaces/user.interface';

const { abi } = require('./abi/EDIHProjectMilestones.json');

export class EDIHProjectMilestonesContract {
  instance: ethers.Contract;

  constructor(instance: any) {
    this.instance = instance;
  }

  createProjectMilestone(projectId: string, deadline: Date, data: string) {
    return this.instance.createProjectMilestones(
      projectId,
      [Math.floor(deadline.getTime() / 1000)],
      [data]
    );
  }

  updateProjectMilestone(
    projectId: string,
    projectMilestoneId: string,
    data: string
  ) {
    return this.instance.updateProjectMilestone(
      projectId,
      projectMilestoneId,
      data
    );
  }

  markProjectMilestoneForCompletion(
    projectId: string,
    projectMilestoneId: string
  ) {
    return this.instance.markProjectMilestoneForCompletion(
      projectId,
      projectMilestoneId
    );
  }

  approveProjectMilestoneCompletion(
    projectId: string,
    projectMilestoneId: string
  ) {
    return this.instance.approveProjectMilestoneCompletion(
      projectId,
      projectMilestoneId
    );
  }

  cancelProjectMilestoneCompletion(
    projectId: string,
    projectMilestoneId: string
  ) {
    return this.instance.cancelProjectMilestoneCompletion(
      projectId,
      projectMilestoneId
    );
  }
}

export const loadEDIHProjectMilestonesContract = (
  signer: JsonRpcSigner | JsonRpcProvider,
  address?: string
) => {
  const instance = new ethers.Contract(
    address || process.env.REACT_APP_EDIH_PROJECT_MILESTONES_CONTRACT_ADDRESS!,
    abi,
    signer
  );

  return new EDIHProjectMilestonesContract(instance);
};
