import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Title from '../../../title';
import Web3Context from '../../../../../../context/web3-context';
import {
  canEndorse,
  endorse,
  getEndorseForwardRequest,
} from '../../../../../../api/users';
import AuthContext from '../../../../../../context/auth-context';
import { loadEDIHContract } from '../../../../../../utils/contracts/edih';
import Button from '../../../../../shared/button';
import FormGroup from '../../../../../shared/form/group';
import TagsCheckList from '../../../../../shared/form/tags-checklist';
import { Tag } from '../../../../../../interfaces/tag.interface';
import Notification from '../../../../../shared/notification';
import Modal from '../../../../../shared/modal';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../../../utils/utils';

const schema = yup
  .object()
  .shape({
    endorsedTags: yup.array().min(1).of(yup.string().required()).required(),
  })
  .required();

function EndorseModal(props: {
  userId: string;
  walletAddress: string;
  userTags: Tag[];
  onEndorse: (tx: string, endorsedTags: string[]) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation('dashboard-profile');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onEndorse(values: any) {
    setLoading(true);
    setError(undefined);

    try {
      // get and validate wallet address
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          // check if endorsement is valid
          await checkCanEndorse(values);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihContract = await loadEDIHContract(signer);
          const result = await edihContract.endorse(props.walletAddress);

          tx = result.hash;
        } else {
          const forwardRequest = await fetchEndorseForwardRequest(values);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        await endorse(props.userId, values.endorsedTags, tx, signature);
        props.onEndorse(tx!, values.endorsedTags);
        setLoading(false);
      } catch (err: any) {
        console.error('failed to endorse', err);
        setError(t('endorsement.modal.errors.transaction'));
        setLoading(false);
      }

      // call setUser function in EDIH contract
      // const edihContract = await loadEDIHContract();
      // edihContract
      //   .endorse(address, props.walletAddress)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('endorsed', tx);
      //     try {
      //       await endorse(props.userId, tx, values.endorsedTags);
      //     } catch (err: any) {
      //       console.error('failed to log endorse', err);
      //     }
      //     props.onEndorse(tx, values.endorsedTags);
      //     setLoading(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('endorsement.modal.errors.transaction'));
      //     setLoading(false);
      //   });
      console.log('submitted');
    } catch (err) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function checkCanEndorse(values: any) {
    try {
      await canEndorse(props.userId, values.endorsedTags);
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response!.data.code === 'noEndorsement'
      ) {
        setError(t('endorsement.modal.errors.noEndorsement'));
      } else {
        setError(t('endorsement.modal.errors.unexpected'));
      }

      throw err;
    }
  }

  async function fetchEndorseForwardRequest(values: any) {
    try {
      let hash = await getEndorseForwardRequest(
        props.userId,
        values.endorsedTags
      );
      return hash;
    } catch (err) {
      setError(t('endorsement.modal.errors.unexpected'));

      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onEndorse)}>
        <FormGroup>
          <Title>{t('endorsement.modal.tags')}</Title>
          <Controller
            render={({ field }) => (
              <TagsCheckList
                tags={props.userTags}
                selectedTagIds={field.value || []}
                disabled={loading}
                onChange={(tagIds: string[]) => field.onChange(tagIds)}
              />
            )}
            control={control}
            name="endorsedTags"
          />
          {errors.endorsedTags && (
            <div className="text-danger">
              {t('endorsement.modal.errors.tagsMin')}
            </div>
          )}
        </FormGroup>
        <Button className="mt-3 w-full" disabled={loading}>
          {t('endorsement.modal.endorse')}
        </Button>
      </form>
    </Modal>
  );
}

export default EndorseModal;
