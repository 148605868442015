import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/inter';
import { WebPSupportContextProvider } from './context/webp-support-context';
import { Web3ContextProvider } from './context/web3-context';
import { Toaster } from 'react-hot-toast';
import { AuthContextProvider } from './context/auth-context';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import i18nBuilder from './translations/i18n-settings';
import '@smastrom/react-rating/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

i18nBuilder();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <main className="font-sans">
    <WebPSupportContextProvider>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <AuthContextProvider>
            <Web3ContextProvider>
              <App />
            </Web3ContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </I18nextProvider>
    </WebPSupportContextProvider>
    <Toaster toastOptions={{ style: { whiteSpace: 'pre-line' } }} />
  </main>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
