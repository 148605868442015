import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import EventEntryItemRow from './item';
import {
  GetEntriesOfEventFilter,
  getEntriesOfEvent,
} from '../../../../api/events-entries';
import { GetEntriesOfEventResult } from '../../../../interfaces/event-entry.interface';
import DashboardBox from '../../../core/dashboard-layout/box';
import DashboardSearch from '../../../core/dashboard-layout/search';
import DashboardSearchFilter from '../../../core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../core/dashboard-layout/search-filter/modal/item';
import MyTable from '../../../shared/table';
import Notification from '../../../shared/notification';

interface Props {
  eventId: string;
}

const eventEntriesStateLabels = {
  idle: ['idle', 'waitingForUse'],
  used: ['used'],
  verified: ['verified'],
};

export default function EventEntryTable(props: Props) {
  const { t } = useTranslation('event-entries');
  const [error, setError] = useState<string>();
  const [data, setData] = useState<GetEntriesOfEventResult>();
  const [filter, setFilter] = useState<GetEntriesOfEventFilter>({});

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('states.title'),
      key: 'states',
      items: [
        { label: t('states.all') },
        { label: t('states.idle'), value: 'idle' },
        { label: t('states.used'), value: 'used' },
        { label: t('states.verified'), value: 'verified' },
      ],
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    try {
      console.log('search with filter', filter);
      const result = await getEntriesOfEvent(props.eventId, page, filter);
      setData(result);
    } catch (err) {
      console.error('failed to get entries', err);
      setError(t('errors.load'));
    }
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({
      ...filter,
      states:
        value === ''
          ? undefined
          : (eventEntriesStateLabels as any)[value as any],
    });
  }

  return (
    <>
      {data && (
        <>
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <DashboardBox className="flex-1 flex flex-col">
            {error && <Notification color="danger">{error}</Notification>}

            <div
              className="overflow-auto"
              style={{
                maxWidth: 'calc(100vw - 72px)',
              }}
            >
              <MyTable
                className="mb-5"
                headers={t('tableHeaders', { returnObjects: true }) as any}
              >
                {data.results.map((item) => (
                  <EventEntryItemRow key={item.id} item={item} />
                ))}
              </MyTable>
            </div>

            {data.pages > 1 && (
              <div className="flex justify-center">
                <ReactPaginate
                  breakLabel="..."
                  containerClassName="flex space-x-4 text-xs text-font-grey"
                  activeClassName="text-font-black"
                  nextClassName="text-font-black"
                  previousClassName="text-font-black"
                  nextLabel=">"
                  previousLabel="<"
                  onPageChange={(item) => fetchItems(item.selected)}
                  pageRangeDisplayed={5}
                  pageCount={data.pages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </DashboardBox>
        </>
      )}
    </>
  );
}
