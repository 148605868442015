import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectCollaboratorPlatformRecommendations } from '../../../../../../api/projects-collaborator-recommendations';
import UserDashboard from '../../../../../../components/core/dashboard-layout/user';
import Loading from '../../../../../../components/shared/loading';
import MemberList from '../../../../../../components/users/members';
import { GetProjectCollaboratorPlatformRecommendationsResult } from '../../../../../../interfaces/project-collaborator-recommendation.interface';
import {
  myProjectsRoute,
  projectRoute,
  projectCollaboratorsRoute,
  inviteCollaboratorRoute,
} from '../../../../../../utils/routes';
import Notification from '../../../../../../components/shared/notification';

export default function ProjectCollaboratorPlatformRecommendationsPage() {
  const { t } = useTranslation('project-collaborator-platform-recommendations');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [data, setData] =
    useState<GetProjectCollaboratorPlatformRecommendationsResult>();

  const { projectId } = useParams();

  useEffect(() => {
    fetchItems();
  }, []);

  async function fetchItems() {
    setError(undefined);
    try {
      const result = await getProjectCollaboratorPlatformRecommendations(
        projectId! as string
      );
      console.log('loaded', result);
      console.log('!!recommendations result!!', result.results);
      setData(result);
    } catch (err) {
      console.error('failed to get project platform recommendations', err);
      setError(t('errors.load'));
    }
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myProjects'),
          path: myProjectsRoute,
        },
        {
          label: data ? data.project.title : '',
          path: data ? projectRoute(projectId! as string) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.collaborators'),
          path: projectCollaboratorsRoute(projectId! as string),
        },
        {
          label: commonTranslations.t(
            'dashboard.pathLabels.platformRecommendations'
          ),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1113px] flex flex-col min-h-full">
          <MemberList
            result={{ ...data, results: data.results.map((r) => r.user) }}
            notFoundText={t('notFoundText')}
            onPageChange={() => {}}
            url={(userId: string) =>
              inviteCollaboratorRoute(projectId! as string, userId)
            }
          />
        </div>
      )}
    </UserDashboard>
  );
}
