import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getProjectMilestones } from '../../../../api/project-milestones';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import ProjectMilestoneCreateForm from '../../../../components/projects/milestones/create';
import ProjectMilestoneList from '../../../../components/projects/milestones/list';
import ProjectMilestoneModal from '../../../../components/projects/milestones/modal';
import Loading from '../../../../components/shared/loading';
import AuthContext from '../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
} from '../../../../interfaces/project-milestones.interface';
import {
  myProjectsRoute,
  otherProjectsRoute,
  projectRoute,
} from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';

export default function ProjectMilestonesPage() {
  const { t } = useTranslation('project-milestones');
  const [error, setError] = useState<string>();
  const [data, setData] = useState<GetProjectMilestonesResult>();
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<string>();
  const commonTranslations = useTranslation('common');
  const pollTimeout = useRef<any>();

  const id = searchParams.get('id');

  const isOwner = data && user && data.project.userId === user.id;
  const selectedMilestone =
    data && selectedMilestoneId
      ? data.milestones.find((m) => m.id === selectedMilestoneId)
      : undefined;

  async function fetchData(poll?: boolean) {
    setError(undefined);
    try {
      const result = await getProjectMilestones(projectId! as string);

      // preselect milestone if specified in query
      if (id && !poll) {
        const milestone = result.milestones.find((m) => m.id === id);
        setSelectedMilestoneId(milestone ? milestone.id : undefined);
      }

      console.log('milestones loaded', result);
      setData(result);
    } catch (err) {
      console.error('failed to get milestones', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'projectNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  useEffect(() => {
    fetchData();
    startPolling();
  }, [projectId!]);

  useEffect(() => {}, []);

  function onCreate(milestone: ProjectMilestone) {
    setData({
      ...data,
      milestones: [milestone].concat(...data!.milestones),
    } as any);
  }

  function onClickMilestone(milestone: ProjectMilestone) {
    setSelectedMilestoneId(milestone.id);
  }

  function onUpdateMilestone(update: any) {
    setData({
      ...data,
      milestones: data?.milestones.map((m) => {
        if (m.id === selectedMilestoneId) {
          m = { ...m, ...update };
        }

        return m;
      }),
    } as any);
  }

  function onApprove(tx: string) {
    setData({
      ...data,
      approvals: data!.approvals.concat({
        id: '',
        state: 'waiting',
        projectMilestoneId: selectedMilestoneId!,
        tx,
      }),
    } as any);
  }

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      console.log('poll');
      fetchData(true);
    }, 10000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label:
            data && user && data.project.userId === user.id
              ? commonTranslations.t('dashboard.pathLabels.myProjects')
              : commonTranslations.t('dashboard.pathLabels.otherProjects'),
          path:
            data && user && data.project.userId === user.id
              ? myProjectsRoute
              : otherProjectsRoute,
        },
        {
          label: data ? data.project.title : '',
          path: data ? projectRoute(projectId! as string) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.milestones'),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <>
          <div className="w-full max-w-[1400px] flex space-x-8 max-[1300px]:flex-col max-[1300px]:space-x-0">
            <div className="flex-1">
              <ProjectMilestoneList
                milestones={data.milestones}
                title={t('upcoming')}
                noResultsText={t('noMilestones')}
                onClickItem={onClickMilestone}
                full={!isOwner || data.project.state !== 'started'}
              />
            </div>
            {isOwner && data.project.state === 'started' && (
              <ProjectMilestoneCreateForm data={data} onCreate={onCreate} />
            )}
          </div>
          {selectedMilestone && (
            <ProjectMilestoneModal
              data={data}
              milestone={selectedMilestone}
              onClose={() => setSelectedMilestoneId(undefined)}
              onUpdate={onUpdateMilestone}
              onApprove={onApprove}
            />
          )}
        </>
      )}
    </UserDashboard>
  );
}
