import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import TransferRewardTokensModal from '../transfer-reward-tokens-modal';
import AuthContext from '../../../../context/auth-context';
import Button from '../../../shared/button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function DashboardTokens(props: Props) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');
  const [showTransferModal, setShowTransferModel] = useState(false);

  return (
    <>
      <DashboardBox
        {...{
          ...props,
          className: clsx(
            props.className,
            'bg-gradient-to-r from-primary to-secondary text-white !p-4'
          ),
        }}
      >
        <DashboardTitle className="!mb-0">{t('tokens.title')}</DashboardTitle>
        <div className="flex items-center mx-3">
          <img
            className="mr-2"
            width={32}
            height={32}
            src="/images/dashboard/tokens.svg"
            alt="tokens"
          />
          <p className="text-[2.25rem] font-medium mr-auto">
            {user ? user.tokens : '-'}
          </p>
          <Button
            size="small"
            variant="outline"
            color="white"
            onClick={() => setShowTransferModel(true)}
          >
            {t('tokens.transfer')}
          </Button>
        </div>
      </DashboardBox>
      {showTransferModal && (
        <TransferRewardTokensModal
          onClose={() => setShowTransferModel(false)}
        />
      )}
    </>
  );
}

export default DashboardTokens;
