import { PaginationResult } from './pagination-result.interface';
import { ProjectState } from './project.interface';
import { UserProfile, UserProfileItem } from './user.interface';

export type ProjectCollaboratorResponseState =
  | 'waiting'
  | 'sent'
  | 'accepting'
  | 'accepted'
  | 'rejected';

export const projectCollaboratorResponseStates: ProjectCollaboratorResponseState[] =
  ['waiting', 'sent', 'accepting', 'accepted', 'rejected'];

export interface ProjectCollaboratorResponseDetail {
  id: string;
  user: UserProfile;
  project: {
    id: string;
    title: string;
    name: string;
    state: ProjectState;
    contractId: string;
    user: { id: string };
  };
  state: ProjectCollaboratorResponseState;
  message: string;
  files: { path: string; size: number }[];
  tx?: string;
  rejectReason?: string;
  createdAt: Date;
}

export interface GetProjectCollaboratorResponsesResult
  extends PaginationResult<ProjectCollaboratorResponseItem> {
  project: {
    userId: string;
    title: string;
  };
}

export interface ProjectCollaboratorResponseItem {
  id: string;
  state: ProjectCollaboratorResponseState;
  createdAt: Date;
  user: UserProfileItem;
}
