import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const ButtonExpSmallIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.813 5.25a4.812 4.812 0 1 0-7.876 3.71v4.165a.438.438 0 0 0 .634.392L7 12.305l2.43 1.214a.442.442 0 0 0 .633-.394V8.959a4.803 4.803 0 0 0 1.75-3.709Zm-8.75 0a3.938 3.938 0 1 1 7.875 0 3.938 3.938 0 0 1-7.876 0Zm.874 0a3.062 3.062 0 1 1 6.125 0 3.062 3.062 0 0 1-6.124 0Z"
    />
  </svg>
);
export default ButtonExpSmallIcon;
