import clsx from 'clsx';
import React from 'react';
import styles from './index.module.css';

export interface FormCheckboxElProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export interface FormCheckboxProps {
  children: any;
  checkboxProps?: FormCheckboxElProps;
  className?: string;
  small?: boolean;
  error?: boolean;
}

const FormCheckbox = React.forwardRef((props: FormCheckboxProps, ref?: any) => {
  return (
    <label
      className={clsx(
        styles.label,
        props.small ? styles.small : undefined,
        props.className
      )}
    >
      <input
        type="checkbox"
        className={styles.checkbox}
        {...{ ...(props.checkboxProps || {}) }}
        ref={ref}
      />
      <span></span>
      <span>{props.children}</span>
    </label>
  );
});

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox;
