import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import AcceptRegistrationModal from '../accept-modal';
import Web3Context from '../../../../context/web3-context';
import { Registration } from '../../../../interfaces/registration.interface';
import { formatDate } from '../../../../utils/date';
import { getChainScannerUrl } from '../../../../utils/web3';
import DashboardBox from '../../../core/dashboard-layout/box';
import Button from '../../../shared/button';
import RejectRegistrationModal from '../reject-modal';

export default function RegistrationButtons(props: {
  registration: Registration;
  onReject: (reason: string) => void;
  onAccept: (tx: string) => void;
  error?: string;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('registration');
  const [loading, setLoading] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>();
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>();
  const { connect } = useContext(Web3Context);

  function onReject(reason: string) {
    setShowRejectModal(false);
    props.onReject(reason);
  }

  // async function onAccept() {
  //   setLoading(true);
  //   props.setError(undefined);

  //   try {
  //     // get and validate wallet address
  //     const address = await connect({
  //       hasToBeAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
  //     });

  //     // call setUser function in EDIH contract
  //     const edihContract = await loadEDIHContract();
  //     edihContract
  //       .setUser(address, props.registration.walletAddress, '', true)
  //       .on('transactionHash', async (hash: string) => {
  //         console.log('onaccept', hash);
  //         try {
  //           await acceptRegistration(props.registration.id, hash);
  //           props.onAccept(hash);
  //           setLoading(false);
  //         } catch (err: any) {
  //           showAcceptError(err, t('errors.accept', { reason: err.message }));
  //         }
  //       })
  //       .catch((err: any) => {
  //         showAcceptError(
  //           err,
  //           t('errors.transactionFailed', { reason: err.message })
  //         );
  //       });
  //   } catch (err) {
  //     showAcceptError(err, t('errors.accept', { reason: err }));
  //   }
  // }

  // function showAcceptError(err: any, message: string) {
  //   console.error('accept error', err);
  //   props.setError(message);
  //   setLoading(false);
  // }

  function onAccept(tx: string) {
    setShowAcceptModal(false);
    props.onAccept(tx);
  }

  return (
    <>
      <div className="flex flex-col space-y-3">
        {props.registration.state === 'waitingForAccept' && (
          <DashboardBox className="mb-3">
            {parse(
              t('waitingForAccept', {
                url: getChainScannerUrl(props.registration.tx!),
                tx: props.registration.tx,
              })
            )}
          </DashboardBox>
        )}

        {props.registration.state === 'rejected' && (
          <DashboardBox className="mb-3 !bg-danger text-white">
            {parse(
              t('rejected', {
                reason: props.registration.rejectReason,
                date: formatDate(props.registration.rejectedOn!),
              })
            )}
          </DashboardBox>
        )}

        {props.registration.state === 'accepted' && (
          <DashboardBox className="mb-3 !bg-success text-white">
            {parse(
              t('accepted', {
                url: getChainScannerUrl(props.registration.tx!),
                tx: props.registration.tx,
              })
            )}
          </DashboardBox>
        )}

        <Button
          onClick={() => setShowAcceptModal(true)}
          disabled={loading || props.registration.state !== 'applied'}
        >
          {t('accept')}
        </Button>
        <Button
          color="secondary"
          onClick={() => setShowRejectModal(true)}
          disabled={loading || props.registration.state !== 'applied'}
        >
          {t('reject')}
        </Button>
      </div>

      {showAcceptModal && (
        <AcceptRegistrationModal
          registration={props.registration}
          onAccept={onAccept}
          onClose={() => setShowAcceptModal(false)}
        />
      )}
      {showRejectModal && (
        <RejectRegistrationModal
          id={props.registration.id}
          onReject={onReject}
          onClose={() => setShowRejectModal(false)}
        />
      )}
    </>
  );
}
