import { ProjectItem } from '../../../../../interfaces/project.interface';
import OptimizedImage from '../../../../shared/image';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: ProjectItem;
}

function ProjectItemElParticipants(props: Props) {
  let participants: {
    id: string;
    image: string;
  }[] = [];

  participants.push({
    id: props.item.user.id,
    image: props.item.user.image,
  });

  for (const collaborator of props.item.collaborators) {
    participants.push(collaborator);
  }

  // limit to 3
  if (participants.length > 3) {
    participants = participants.slice(0, 3);
  }

  const els = participants.map((participant, i) => (
    <div key={`part-${props.item.id}-${participant.id}`}>
      {participant.image && (
        <OptimizedImage
          className="w-[24px] h-[24px] rounded-full mr-2 border border-grey-2"
          params={{ w: '40', h: '40' }}
          src={participant.image}
        />
      )}
      {!participant.image && (
        <div className="w-[24px] h-[24px] rounded-full mr-2 bg-grey-2 border border-grey-2" />
      )}
    </div>
    // <Link
    //   key={`part-${props.item.id}-${participant.id}`}
    //   href={userProfileRoute(participant.id)}
    //   onClick={(ev) => ev.stopPropagation()}
    // >
    //   {participant.image && (
    //     <OptimizedImage
    //       className="w-[24px] h-[24px] rounded-full mr-2 border border-grey-2"
    //       params={{ w: '40', h: '40' }}
    //       src={participant.image}
    //     />
    //   )}
    //   {!participant.image && (
    //     <div className="w-[24px] h-[24px] rounded-full mr-2 bg-grey-2 border border-grey-2" />
    //   )}
    // </Link>
  ));

  return <div className="mr-auto flex space-x-[0.5]">{els}</div>;
}

export default ProjectItemElParticipants;
