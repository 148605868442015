import {
  GetEndorsementsResult,
  EndorsementItem,
} from '../interfaces/endorsement.interface';
import { PaginationResult } from '../interfaces/pagination-result.interface';
import { UserProfileItem } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const getEndorsements = async (
  page: number
): Promise<GetEndorsementsResult> => {
  const { data } = await myAxios.get('/endorsements', {
    params: { page },
  });

  return data.data;
};

export interface GetEndorsementsFilter {
  search?: string;
}

export interface GetEndorsementsForUserResult
  extends PaginationResult<EndorsementItem> {}

export const getEndorsementsForUser = async (
  userId: string,
  page: number,
  filter: GetEndorsementsFilter
): Promise<GetEndorsementsForUserResult> => {
  const { data } = await myAxios.get(`/users/${userId}/endorsements`, {
    params: { page, ...filter },
  });

  return data.data;
};

export const claimEndorsementReward = async (tx: string): Promise<void> => {
  const { data } = await myAxios.post(`/endorsements/claim-reward`, {
    tx,
  });

  return data.data;
};

export const getEndorsementRequestTargets = async (
  search: string,
  ignore: string[]
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.post(`/endorsements/request-targets`, {
    search,
    ignore,
  });

  return data.data;
};

export const requestEndorsements = async (
  userIds: string[]
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.post(`/endorsements/request`, {
    userIds,
  });

  return data.data;
};
