import { Link } from 'react-router-dom';

export interface PathItem {
  label: string;
  path?: string;
}

// interface Props extends React.HTMLAttributes<HTMLDivElement> {
//   children: React.ReactNode;
// }

function DashboardPath(props: { items: PathItem[] }) {
  const itemEls = props.items.map((item, i) => {
    return (
      <>
        {item.path && (
          <Link
            key={'dashboard-path-' + item.path}
            to={item.path}
            className="text-font-grey underline"
          >
            {item.label}
          </Link>
        )}

        {!item.path && (
          <span key={'dashboard-path-' + item.path}>{item.label}</span>
        )}
        {i < props.items.length - 1 && (
          <span className="text-font-grey">/</span>
        )}
      </>
    );
  });

  return (
    <div className="flex text-sm flex-wrap space-x-2 max-lg:hidden">
      {itemEls}
    </div>
  );
}

export default DashboardPath;
