import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { Link } from 'react-router-dom';
import { emailTaken } from '../../../../api/registrations';
import { SignupData } from '../../../../pages/signup';
import { signInRoute } from '../../../../utils/routes';
import Button from '../../../shared/button';
import FormError from '../../../shared/form/error';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import PasswordInfo from '../../../shared/form/password-info';
import PasswordFormInput from '../../../shared/form/password-input';
import Notification from '../../../shared/notification';

YupPassword(yup);

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().password().minSymbols(0).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password')]),
  })
  .required();

function SignupLoginForm(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
}) {
  const { t } = useTranslation('signup');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      const exists = await emailTaken(values.email.toLowerCase());

      if (!exists) {
        props.onSubmit({
          ...props.signupData,
          email: values.email.toLowerCase(),
          password: values.password,
        });
      } else {
        setError(t('loginForm.errors.emailExists'));
      }
    } catch (err) {
      console.error('failed to verify email uniqueness', err);
      setError(t('loginForm.errors.unexpected'));
    }
    setLoading(false);
  }

  const password = watch('password');

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    setValue('email', props.signupData.email!);
    setValue('password', props.signupData.password!);
    setValue('confirmPassword', props.signupData.password!);
  }, []);

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center mb-8">
        {t('loginForm.title')}
      </h1>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('loginForm.email')}</FormLabel>
          <FormInput
            type="email"
            {...register('email')}
            error={errors.email !== undefined}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('loginForm.password')}</FormLabel>
          <PasswordFormInput
            {...register('password')}
            disabled={loading}
            error={errors.password !== undefined}
          />
          <PasswordInfo password={password} />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('loginForm.confirmPassword')}</FormLabel>
          <PasswordFormInput
            {...register('confirmPassword')}
            disabled={loading}
            error={errors.confirmPassword !== undefined}
          />
          {errors.confirmPassword && (
            <FormError>{t('loginForm.errors.passwordMismatch')}</FormError>
          )}
        </FormGroup>

        <Button className="w-full mt-4" disabled={loading}>
          {t('loginForm.proceed')}
        </Button>

        <div className="text-center mt-2 text-sm">
          <span className="pr-1">{t('loginForm.login1')}</span>
          <Link className="underline text-secondary" to={signInRoute}>
            {t('loginForm.login2')}
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignupLoginForm;
