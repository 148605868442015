import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ModalFiles from '../../milestones/form/files';
import {
  startProjectCompletion,
  canStartProjectCompletion,
  getProjectDataHashForCompletion,
  requestUploadForProject,
} from '../../../../api/projects';
import AuthContext from '../../../../context/auth-context';
import {
  ProjectDetail,
  ProjectDetailCollaboratorFiles,
} from '../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import Button from '../../../shared/button';
import FormCheckbox from '../../../shared/form/checkbox';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import Loading from '../../../shared/loading';
import Web3Context from '../../../../context/web3-context';
import Notification from '../../../shared/notification';
import Modal from '../../../shared/modal';
import { uploadProjectCollaboratorFiles } from '../../../../api/projects-collaborators';
import toast from 'react-hot-toast';

const schema = yup
  .object()
  .shape({
    files: yup.array().of(yup.string().required()).required(),
    filesPrivate: yup.boolean().default(false).required(),
  })
  .required();

function ProjectDetailUploadCollaboratorFilesModal(props: {
  project: ProjectDetail;
  onClose: () => void;
  onUpload: () => void;
}) {
  const { t } = useTranslation('project-detail');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { user } = useContext(AuthContext);
  const [collaboratorFiles, setCollaboratorFiles] = useState<
    ProjectDetailCollaboratorFiles | undefined
  >(props.project.collaboratorFiles.find((f) => f.user.id === user?.id));
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);

    try {
      await uploadProjectCollaboratorFiles(
        props.project.id,
        values.files,
        values.filesPrivate
      );
      toast.success(t('uploadCollaboratorFiles.submitted'), { duration: 3000 });
      props.onUpload();
    } catch (err: any) {
      if (!axios.isAxiosError(err)) {
        setError(t('uploadCollaboratorFiles.errors.upload'));
      } else if (err.response!.data.code === 'notCollaborator') {
        setError(t('uploadCollaboratorFiles.errors.notCollaborator'));
      } else if (
        err.response!.data.code === 'notService' ||
        err.response!.data.code === 'projectState'
      ) {
        setError(t('uploadCollaboratorFiles.errors.projectState'));
      } else {
        setError(t('uploadCollaboratorFiles.errors.upload'));
      }

      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  useEffect(() => {
    if (collaboratorFiles) {
      setValue('filesPrivate', collaboratorFiles.filesPrivate || false);
    }
  }, [collaboratorFiles]);

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('uploadCollaboratorFiles.files')}</FormLabel>
          <Controller
            render={({ field }) => (
              <ModalFiles
                addText={t('uploadCollaboratorFiles.addFile')}
                editable
                onChangeFormFiles={field.onChange}
                maxSize={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                getUploadUrlApi={(fileName: string) =>
                  requestUploadForProject(fileName)
                }
                defaultFiles={
                  collaboratorFiles ? collaboratorFiles.files : undefined
                }
                disabled={loading}
              />
            )}
            control={control}
            name="files"
          />
          <Controller
            render={({ field }) => (
              <FormCheckbox
                className="mt-3"
                small
                checkboxProps={{
                  onChange: (ev) => field.onChange(ev.target.checked),
                  checked: field.value === true,
                  name: 'filesPrivate',
                  disabled: loading,
                }}
              >
                {t('uploadCollaboratorFiles.filesPrivate')}
              </FormCheckbox>
            )}
            control={control}
            name="filesPrivate"
          />
        </FormGroup>
        <FormGroup>
          <Button className="mt-4" disabled={loading}>
            {t('uploadCollaboratorFiles.submit')}
          </Button>
        </FormGroup>
      </form>
    </Modal>
  );
}

export default ProjectDetailUploadCollaboratorFilesModal;
