import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Tag } from '../../../interfaces/tag.interface';
import {
  requestUploadForProject,
  requestProjectFileDownload,
} from '../../../api/projects';
import AuthContext from '../../../context/auth-context';
import { EditProjectResult } from '../../../interfaces/project.interface';
import DashboardBox from '../../core/dashboard-layout/box';
import DashboardFiles from '../../core/dashboard-layout/files';
import DashboardUserProfile from '../../core/dashboard-layout/profile';
import DashboardTitle from '../../core/dashboard-layout/title';
import Button from '../../shared/button';
import FormCheckbox from '../../shared/form/checkbox';
import FormGroup from '../../shared/form/group';
import FormInput from '../../shared/form/input';
import FormLabel from '../../shared/form/label';
import TagsInput from '../../shared/form/tags-input';
import FormTextarea from '../../shared/form/textarea';
import ProjectFormConfirmServiceModal from './service-modal';

export interface ProjectFormData {
  title: string;
  description: string;
  files: string[];
  tags: Tag[];
  filesPrivate: boolean;
  service: boolean;
}

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    files: yup.array().of(yup.string().required()).required(),
    filesPrivate: yup.boolean().default(false).required(),
    service: yup.boolean(),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required(),
        })
      )
      .min(1)
      .max(5)
      .required(),
  })
  .required();

function ProjectForm(props: {
  project?: EditProjectResult;
  price?: number;
  disabled?: boolean;
  onSubmit: (formData: ProjectFormData) => void;
}) {
  const { t } = useTranslation('project-form');
  const { user } = useContext(AuthContext);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(values: any) {
    props.onSubmit({
      ...values,
      service: values.service || false,
      tags: values.tags.map((tag: Tag) => tag.id),
    });
  }

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    if (props.project) {
      setValue('title', props.project.title);
      setValue('description', props.project.description);
      setValue('filesPrivate', props.project.filesPrivate);
      setValue('tags', props.project.tags);
      setValue('service', props.project.service);
    }
  }, [props.project]);

  function onCloseServiceModal() {
    setShowServiceModal(false);
    setValue('service', false);
  }

  console.log('errors?', errors);
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1"
      >
        <div className="flex flex-col space-y-6">
          <DashboardBox>
            <DashboardTitle>
              {props.project ? t('editTitle') : t('createTitle')}
            </DashboardTitle>

            <FormGroup>
              <FormLabel required showRequiredInfo>
                {t('title')}
              </FormLabel>
              <FormInput
                {...register('title')}
                error={errors.title !== undefined}
                maxLength={200}
                disabled={props.disabled}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel required showRequiredInfo>
                {t('description')}
              </FormLabel>
              <FormTextarea
                {...register('description')}
                error={errors.description !== undefined}
                rows={6}
                maxLength={2000}
                disabled={props.disabled}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel required>{t('tags')}</FormLabel>
              <Controller
                render={({ field }) => (
                  <TagsInput
                    tags={field.value}
                    onChange={field.onChange}
                    placeholder={t('tagsPlaceholder')}
                    error={errors.tags !== undefined}
                    disabled={props.disabled}
                  />
                )}
                control={control}
                name="tags"
              />
              {errors.tags && errors.tags.type === 'min' && (
                <div className="text-sm text-danger">{t('errors.tagsMin')}</div>
              )}
              {errors.tags && errors.tags.type === 'max' && (
                <div className="text-sm text-danger">{t('errors.tagsMax')}</div>
              )}
            </FormGroup>
            <FormGroup className="items-start">
              <Controller
                render={({ field }) => (
                  <FormCheckbox
                    small
                    checkboxProps={{
                      onChange: (ev) => {
                        if (ev.target.checked) {
                          setShowServiceModal(true);
                        }

                        field.onChange(ev.target.checked);
                      },
                      checked: field.value === true,
                      name: 'service',
                      disabled: props.disabled,
                    }}
                  >
                    {t('service')}
                  </FormCheckbox>
                )}
                control={control}
                name="service"
              />
            </FormGroup>
          </DashboardBox>
          <Controller
            render={({ field }) => (
              <DashboardFiles
                title={t('files')}
                addText={t('addFile')}
                editable
                onChangeFormFiles={field.onChange}
                maxSize={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                getUploadUrlApi={(fileName: string) =>
                  requestUploadForProject(fileName)
                }
                requestDownloadApi={(path) =>
                  requestProjectFileDownload(props.project!.id, path, 'files')
                }
                defaultFiles={props.project ? props.project.files : undefined}
                disabled={props.disabled}
              >
                <Controller
                  render={({ field }) => (
                    <FormCheckbox
                      className="mt-3"
                      small
                      checkboxProps={{
                        onChange: (ev) => field.onChange(ev.target.checked),
                        checked: field.value === true,
                        name: 'filesPrivate',
                        disabled: props.disabled,
                      }}
                    >
                      {t('filesPrivate')}
                    </FormCheckbox>
                  )}
                  control={control}
                  name="filesPrivate"
                />
              </DashboardFiles>
            )}
            control={control}
            name="files"
          />
        </div>
        <div className="flex flex-col space-y-4">
          <DashboardUserProfile className="max-xl:hidden" user={user!} />
          <Button
            disabled={errors.files !== undefined || props.disabled}
            className="flex items-center justify-center"
          >
            {props.project ? (
              t('save')
            ) : (
              <>
                <span className="pr-1">{t('create')}</span>
                <span className="pr-1">({props.price!}</span>
                <img
                  src="/images/price.svg"
                  width="12"
                  height="12"
                  alt="create"
                />
                <span>)</span>
              </>
            )}
          </Button>
        </div>
      </form>
      {showServiceModal && (
        <ProjectFormConfirmServiceModal
          onConfirm={() => setShowServiceModal(false)}
          onClose={onCloseServiceModal}
        />
      )}
    </>
  );
}

export default ProjectForm;
