import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetParticipatingProjectsOfUserFilter,
  getUserProfile,
  getParticipatingProjectsOfUser,
} from '../../../../api/users';
import DashboardSearch from '../../../../components/core/dashboard-layout/search';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import ProjectList from '../../../../components/projects/list';
import Loading from '../../../../components/shared/loading';
import { GetProjectsResult } from '../../../../interfaces/project.interface';
import { GetUserProfileResult } from '../../../../interfaces/user.interface';
import {
  otherProjectsRoute,
  membersRoute,
  userProfileRoute,
} from '../../../../utils/routes';
import { getDisplayName } from '../../../../utils/utils';
import Notification from '../../../../components/shared/notification';

export default function ParticipatingProjectsPage() {
  const { t } = useTranslation('participating-projects');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetProjectsResult>();
  const [filter, setFilter] = useState<GetParticipatingProjectsOfUserFilter>(
    {}
  );
  const [userProfile, setUserProfile] = useState<GetUserProfileResult>();
  const { userId } = useParams();

  useEffect(() => {
    loadUserProfile(userId! as string);
  }, [userId]);

  async function loadUserProfile(userId: string) {
    try {
      const result = await getUserProfile(userId);
      setUserProfile(result);
      fetchItems(0);
      console.log('profile loaded', result);
      return result;
    } catch (err: any) {
      setError(t('errors.loadProfile'));
    }
  }

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    setLoading(true);
    try {
      console.log('search with filter', filter);
      const result = await getParticipatingProjectsOfUser(
        userId! as string,
        page,
        filter
      );
      setData(result);
    } catch (err) {
      console.error('failed to get projects', err);
      setError(t('errors.load'));
    }
    setLoading(false);
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={otherProjectsRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.members'),
          path: membersRoute,
        },
        {
          label: userProfile ? getDisplayName(userProfile.user) : '',
          path: userProfileRoute(userProfile ? userProfile?.user.id : ''),
        },
        {
          label: commonTranslations.t(
            'dashboard.pathLabels.participatingProjects'
          ),
        },
      ]}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1113px] flex flex-col min-h-full">
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
          </div>
          <ProjectList
            result={data}
            onPageChange={(page: number) => fetchItems(page)}
            loading={loading}
            notFoundText={t('notFound')}
          />
        </div>
      )}
    </UserDashboard>
  );
}
