import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getProjectCollaboratorRecommendationFormData,
  createProjectCollaboratorRecommendation,
  getProjectCollaboratorRecommendationDataHash,
  getProjectCollaboratorRecommendationForwardRequest,
} from '../../../../../api/projects-collaborator-recommendations';
import UserDashboard from '../../../../../components/core/dashboard-layout/user';
import ProjectCollaboratorRecommendationForm, {
  ProjectCollaboratorRecommendationFormData,
} from '../../../../../components/projects/collaborators/recommendations/form';
import Button from '../../../../../components/shared/button';
import Loading from '../../../../../components/shared/loading';
import AuthContext from '../../../../../context/auth-context';
import { RecommendationFormProject } from '../../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import {
  otherProjectsRoute,
  projectRoute,
  myProjectsRoute,
} from '../../../../../utils/routes';
import Web3Context from '../../../../../context/web3-context';
import Notification from '../../../../../components/shared/notification';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../utils/web3';
import { sleep } from '../../../../../utils/utils';

export default function RecommendCollaboratorPage() {
  const { t } = useTranslation('recommend-collaborator');
  const commonTranslations = useTranslation('common');
  const [project, setProject] = useState<RecommendationFormProject>();
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { projectId } = useParams();
  const { connect } = useContext(Web3Context);

  useEffect(() => {
    onConnect();
  }, []);

  async function onConnect() {
    setError(undefined);
    try {
      setCanRetry(true);

      // get and validate wallet address
      await connect({
        hasToBeAddress: user!.walletAddress,
        noToast: true,
      });

      setCanRetry(false);

      // check if user has permission to recommend
      try {
        const result = await getProjectCollaboratorRecommendationFormData(
          projectId! as string
        );
        setProject(result);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (
            err.response!.data.code === 'projectNotFound' ||
            err.response!.data.code === 'projectState' ||
            err.response!.data.code === 'accessDenied' ||
            err.response!.data.code === 'selfRecommend'
          ) {
            throw t('errors.' + err.response!.data.code);
          }

          throw t('errors.load');
        }
      }
    } catch (err: any) {
      console.error('failed to connect', err);
      setError(err.toString());
    }
  }

  async function onSubmit(formData: ProjectCollaboratorRecommendationFormData) {
    console.log('submit!', formData);

    setSubmitting(true);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchRecommendationDataHash(formData);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result =
            await edihProjectsContract.sendProjectCollaboratorRecommendation(
              project!.contractId,
              formData.user.walletAddress,
              hash
            );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchRecommendForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await createProjectCollaboratorRecommendation(
            project!.id,
            formData,
            tx,
            signature
          );
          setSuccess(true);
        } catch (err: any) {
          console.error('failed to recommend', err);
          setError(t('errors.recommend'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to recommend', err);
        setError(t('errors.recommend'));
        setSubmitting(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .sendProjectCollaboratorRecommendation(
      //     user!.walletAddress,
      //     project!.contractId,
      //     formData.user.walletAddress,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('created', tx);
      //     try {
      //       await createProjectCollaboratorRecommendation(
      //         project!.id,
      //         formData,
      //         tx
      //       );
      //       setSuccess(true);
      //     } catch (err: any) {
      //       console.error('failed to recommend', err);
      //       setError(t('errors.recommend'));
      //       setSubmitting(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchRecommendationDataHash(
    formData: ProjectCollaboratorRecommendationFormData
  ) {
    try {
      const hash = await getProjectCollaboratorRecommendationDataHash(
        project!.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchRecommendForwardRequest(
    formData: ProjectCollaboratorRecommendationFormData
  ) {
    try {
      let hash = await getProjectCollaboratorRecommendationForwardRequest(
        project!.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.otherProjects'),
          path: otherProjectsRoute,
        },
        {
          label: project ? project.title : '',
          path: projectRoute(projectId! as string),
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.recommend'),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && (
        <Notification color="danger" className="max-w-[400px]">
          <div>{error}</div>
          {canRetry && (
            <div className="mt-2 text-right">
              <Button size="small" color="secondary" onClick={onConnect}>
                {t('reconnect')}
              </Button>
            </div>
          )}
        </Notification>
      )}
      {!project && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {success && <Notification color="success">{t('success')}</Notification>}
      {project && (
        <ProjectCollaboratorRecommendationForm
          project={project}
          onSubmit={onSubmit}
          disabled={submitting}
        />
      )}
    </UserDashboard>
  );
}
