import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SignupData } from '../../../../pages/signup';
import { phoneNumberRegex, urlRegex } from '../../../../utils/utils';
import Button from '../../../shared/button';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    organization: yup.string(),
    contactEmail: yup.string().email().required(),
    phoneNumber: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(phoneNumberRegex),
    website: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(urlRegex),
  })
  .required();

function SignupIntroFormIndividual(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
  onBack: () => void;
}) {
  const { t } = useTranslation('signup');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(values: any) {
    props.onSubmit({
      ...props.signupData,
      firstName: values.firstName,
      lastName: values.lastName,
      organization: values.organization,
      contactEmail: values.contactEmail.toLowerCase(),
      phoneNumber: values.phoneNumber,
      website: values.website,
    });
  }

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    setValue('firstName', props.signupData.firstName!);
    setValue('lastName', props.signupData.lastName!);
    setValue('organization', props.signupData.organization!);
    setValue('contactEmail', props.signupData.contactEmail!);
    setValue('phoneNumber', props.signupData.phoneNumber!);
    setValue('website', props.signupData.website!);
  }, []);

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center">
        {t('introFormIndividual.title')}
      </h1>
      <p className="text-sm text-center text-font-grey mb-8">
        {t('introFormIndividual.subtitle')}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel required showRequiredInfo>
            {t('introFormIndividual.firstName')}
          </FormLabel>
          <FormInput
            {...register('firstName')}
            error={errors.firstName !== undefined}
            maxLength={200}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel required showRequiredInfo>
            {t('introFormIndividual.lastName')}
          </FormLabel>
          <FormInput
            {...register('lastName')}
            error={errors.lastName !== undefined}
            maxLength={200}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel required>{t('introFormIndividual.email')}</FormLabel>
          <FormInput
            type="email"
            {...register('contactEmail')}
            error={errors.contactEmail !== undefined}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('introFormIndividual.organization')}</FormLabel>
          <FormInput
            {...register('organization')}
            error={errors.organization !== undefined}
            maxLength={200}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('introFormIndividual.phoneNumber')}</FormLabel>
          <FormInput
            {...register('phoneNumber')}
            error={errors.phoneNumber !== undefined}
            maxLength={200}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('introFormIndividual.website')}</FormLabel>
          <FormInput
            {...register('website')}
            error={errors.website !== undefined}
            maxLength={500}
          />
        </FormGroup>

        <div className="flex flex-col">
          <Button className="mt-4">{t('introFormIndividual.proceed')}</Button>
          <Button variant="underline" type="button" onClick={props.onBack}>
            {t('back')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SignupIntroFormIndividual;
