import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { requestUploadForProjectCollaboratorResponse } from '../../../../../api/projects-collaborator-responses';
import AuthContext from '../../../../../context/auth-context';
import { ResponseFormProject } from '../../../../../interfaces/project.interface';
import DashboardBox from '../../../../core/dashboard-layout/box';
import DashboardFiles from '../../../../core/dashboard-layout/files';
import DashboardUserProfile from '../../../../core/dashboard-layout/profile';
import DashboardTitle from '../../../../core/dashboard-layout/title';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import FormLabel from '../../../../shared/form/label';
import FormTextarea from '../../../../shared/form/textarea';

export interface ProjectCollaboratorResponseFormData {
  message: string;
  files: string[];
}

const schema = yup
  .object()
  .shape({
    message: yup.string().required(),
    files: yup.array().of(yup.string().required()).required(),
  })
  .required();

function ProjectCollaboratorResponseForm(props: {
  project: ResponseFormProject;
  disabled?: boolean;
  onSubmit: (formData: ProjectCollaboratorResponseFormData) => void;
}) {
  const { t } = useTranslation('project-collaborator-response-form');
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(values: any) {
    props.onSubmit(values);
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1"
    >
      <div className="flex flex-col space-y-6">
        <DashboardBox>
          <DashboardTitle>{t('title')}</DashboardTitle>

          <FormGroup>
            <FormLabel required>{t('message')}</FormLabel>
            <FormTextarea
              {...register('message')}
              error={errors.message !== undefined}
              rows={6}
              maxLength={2000}
              disabled={props.disabled}
            />
          </FormGroup>
        </DashboardBox>
        <Controller
          render={({ field }) => (
            <DashboardFiles
              title={t('files')}
              addText={t('addFile')}
              editable
              onChangeFormFiles={field.onChange}
              maxSize={+process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILE_SIZE!
              }
              getUploadUrlApi={(fileName: string) =>
                requestUploadForProjectCollaboratorResponse(
                  props.project.id,
                  fileName
                )
              }
              disabled={props.disabled}
            />
          )}
          control={control}
          name="files"
        />
      </div>
      <div className="flex flex-col space-y-4">
        <DashboardUserProfile className="max-xl:hidden" user={user!} />
        <Button disabled={errors.files !== undefined || props.disabled}>
          {t('send')}
        </Button>
      </div>
    </form>
  );
}

export default ProjectCollaboratorResponseForm;
