import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserProfile } from '../../../api/users';
import DashboardUserProfileLarge from '../../../components/core/dashboard-layout/profile-large';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import Loading from '../../../components/shared/loading';
import EndorsementsForMember from '../../../components/users/members/horizontal-list/endorsers';
import ParticipatingProjectsOfUser from '../../../components/users/participating-projects';
import { EndorsementState } from '../../../interfaces/endorsement.interface';
import { GetUserProfileResult } from '../../../interfaces/user.interface';
import { membersRoute } from '../../../utils/routes';
import { getDisplayName } from '../../../utils/utils';
import Notification from '../../../components/shared/notification';

export default function UserProfilePage() {
  const { t } = useTranslation('user-profile');
  const commonTranslations = useTranslation('common');
  const [result, setResult] = useState<GetUserProfileResult>();
  const [error, setError] = useState<string>();
  const { userId } = useParams();
  const waitForEndorseConfirmTimeout = useRef<any>();

  useEffect(() => {
    loadUserProfile(userId! as string);
    document.getElementById('user-profile-large')?.scrollIntoView();
  }, [userId]);

  useEffect(() => {
    if (
      result &&
      result.user.endorsementState === 'waiting' &&
      !waitForEndorseConfirmTimeout.current
    ) {
      startWaitingForEndorseConfirmation();
    }
  }, [result]);

  useEffect(() => {
    return () => {
      if (waitForEndorseConfirmTimeout.current) {
        clearTimeout(waitForEndorseConfirmTimeout.current);
      }
      console.log('cancel poll', waitForEndorseConfirmTimeout.current);
    };
  }, []);

  async function loadUserProfile(userId: string) {
    try {
      const result = await getUserProfile(userId);
      setResult(result);
      console.log('profile loaded', result);
      return result;
    } catch (err: any) {
      console.error('failed to load profile', err);
      if (err.response!.status === 404) {
        setError(t('errors.notFound'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  function startWaitingForEndorseConfirmation() {
    waitForEndorseConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      const result = await loadUserProfile(userId! as string);

      if (result && !result.user.endorsementState) {
        setError(t('errors.waitForEndorseTransactionFail'));
      } else if (!result || result.user.endorsementState === 'waiting') {
        startWaitingForEndorseConfirmation();
      }
    }, 5000);

    console.log('started', waitForEndorseConfirmTimeout.current);
  }

  function onEndorsementStateChanged(state: EndorsementState) {
    setResult({
      ...result,
      user: { ...result!.user, endorsementState: state },
    } as any);
  }

  return (
    <UserDashboard
      title={result ? getDisplayName(result.user) : ''}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.members'),
          path: membersRoute,
        },
        {
          label: result ? getDisplayName(result.user) : '',
        },
      ]}
      selectedPath={membersRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {result && (
        <div className="max-w-[1200px] space-y-6">
          <DashboardUserProfileLarge
            id="user-profile-large"
            user={result.user}
            onEndorsementStateChanged={onEndorsementStateChanged}
          />
          <ParticipatingProjectsOfUser
            result={result.projects}
            title={t('projects')}
            viewMoreText={commonTranslations.t('viewAll')}
            userId={result.user.id}
          />
          <EndorsementsForMember
            result={result.endorsements}
            title={t('endorsements', { total: result.endorsements.total })}
            viewMoreText={commonTranslations.t('viewAll')}
            userId={result.user.id}
          />
        </div>
      )}
      {!result && !error && <Loading fadeIn />}
    </UserDashboard>
  );
}
