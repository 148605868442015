import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import DashboardBox from '../core/dashboard-layout/box';
import DashboardTitle from '../core/dashboard-layout/title';
import Button from '../shared/button';
import Endorser from './endorser';
import { claimEndorsementReward } from '../../api/activities';
import AuthContext from '../../context/auth-context';
import { GetEndorsementsResult } from '../../interfaces/endorsement.interface';
import { loadEDIHContract } from '../../utils/contracts/edih';
import Web3Context from '../../context/web3-context';

function Endorsements(props: {
  endorsements: GetEndorsementsResult;
  onClaimed: () => void;
  onLoadMore: () => void;
  loadingMore: boolean;
}) {
  const { t } = useTranslation('endorsements');
  const { user } = useContext(AuthContext);
  const { connect } = useContext(Web3Context);
  const [loading, setLoading] = useState(false);

  const endorsers = props.endorsements.endorsements.results.map((item) => {
    return (
      <>
        <Endorser key={item.id} item={item} />
      </>
    );
  });

  // const canClaim =
  //   !props.endorsements.status.claimed &&
  //   props.endorsements.transaction.state !== 'claiming' &&
  //   props.endorsements.status.collected >= props.endorsements.status.needed;

  // async function onClaim() {
  //   setLoading(true);

  //   try {
  //     // get and validate wallet address
  //     const address = await connect({ hasToBeAddress: user!.walletAddress });

  //     // call setUser function in EDIH contract
  //     const edihContract = await loadEDIHContract();

  //     // throw if already claimed
  //     const contractUser = await edihContract.getUser(user!.walletAddress);
  //     if (contractUser.endorsementRewardClaimed) {
  //       toast.error(t('errors.alreadyClaimed', { duration: 3000 }));
  //       throw 'alreadyClaimed';
  //     }

  //     edihContract
  //       .claimEndorsementReward(address)
  //       .on('transactionHash', async (tx: string) => {
  //         console.log('claimed', tx);
  //         try {
  //           await claimEndorsementReward(tx);
  //         } catch (err: any) {
  //           console.error('failed to log endorsement reward claim', err);
  //         }
  //         props.onClaimed();
  //         setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         console.error('failed to send transaction', err.message);
  //         toast.error(t('errors.transaction'), { duration: 3000 });
  //         setLoading(false);
  //       });
  //   } catch (err) {
  //     console.log('failed to connect', err);
  //     setLoading(false);
  //   }
  // }

  console.log(
    'status',
    props.endorsements.status.claimed,
    props.endorsements.transaction.state
  );

  return (
    <DashboardBox className="max-w-[400px]">
      <DashboardTitle>{t('title')}</DashboardTitle>
      {/* <p className="text-sm">
        {props.endorsements.status.collected <
          props.endorsements.status.needed &&
          parse(
            t('notEnough', {
              needs:
                props.endorsements.status.needed -
                props.endorsements.status.collected,
              reward: props.endorsements.status.reward,
            })
          )}

        {canClaim &&
          parse(t('enough', { reward: props.endorsements.status.reward }))}
        {props.endorsements.status.claimed && parse(t('claimed'))}
        {!props.endorsements.status.claimed &&
          props.endorsements.transaction.state === 'claiming' &&
          parse(t('claiming'))}
      </p>
      {canClaim && (
        <Button
          className="w-[90px] mt-3"
          size="small"
          disabled={loading}
          onClick={onClaim}
        >
          {t('claim')}
        </Button>
      )} */}
      {endorsers.length < 1 && <p className="text-sm">{t('noEndorsers')}</p>}
      {endorsers.length > 0 && (
        <>
          {/* <p className="text-xs text-font-grey mt-3">{t('endorsements')}</p> */}
          <div>{endorsers}</div>
          {props.endorsements.endorsements.results.length <
            props.endorsements.endorsements.total && (
            <Button
              size="small"
              disabled={props.loadingMore}
              onClick={props.onLoadMore}
            >
              {t('loadMore')}
            </Button>
          )}
        </>
      )}
    </DashboardBox>
  );
}

export default Endorsements;
