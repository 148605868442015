import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../../../context/web3-context';
import {
  cancelMilestoneCompletion,
  canCancelMilestoneCompletion,
  getCancelProjectMilestoneCompletionForwardRequest,
} from '../../../../../api/project-milestones';
import AuthContext from '../../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
} from '../../../../../interfaces/project-milestones.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import Button from '../../../../shared/button';
import { loadEDIHProjectMilestonesContract } from '../../../../../utils/contracts/edih-project-milestones';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../../utils/web3';
import { sleep } from '../../../../../utils/utils';

export default function ProjectMilestoneModalCancelCompletion(props: {
  data: GetProjectMilestonesResult;
  milestone: ProjectMilestone;
  loading: boolean;
  onCancel: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('project-milestones');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onCancel() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanCancel();

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectMilestonesContract =
            await loadEDIHProjectMilestonesContract(signer);
          const result =
            await edihProjectMilestonesContract.cancelProjectMilestoneCompletion(
              props.data.project.contractId,
              props.milestone.contractId!
            );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchCancelProjectMilestoneCompletionForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await cancelMilestoneCompletion(props.milestone.id, tx, signature);
          props.onCancel(tx!);
        } catch (err: any) {
          console.error('failed to start completion', err);
          props.setError(t('detail.errors.cancelCompletion.cancel'));
        }
        props.setLoading(false);
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('detail.errors.cancelCompletion.transaction'));
        props.setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectMilestonesContract();
      // edihProjectsContract
      //   .cancelProjectMilestoneCompletion(
      //     user!.walletAddress,
      //     props.data.project.contractId,
      //     props.milestone.contractId!
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('cancelled', tx);
      //     try {
      //       await cancelMilestoneCompletion(props.milestone.id, tx);
      //       props.onCancel(tx);
      //     } catch (err: any) {
      //       console.error('failed to start completion', err);
      //       props.setError(t('detail.errors.cancelCompletion.cancel'));
      //     }
      //     props.setLoading(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('detail.errors.cancelCompletion.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanCancel() {
    try {
      await canCancelMilestoneCompletion(props.milestone.id);
    } catch (err) {
      props.setError(t('detail.errors.cancelCompletion.canCancel'));

      throw err;
    }
  }

  async function fetchCancelProjectMilestoneCompletionForwardRequest() {
    try {
      return getCancelProjectMilestoneCompletionForwardRequest(
        props.milestone.id
      );
    } catch (err) {
      props.setError(t('detail.errors.cancelCompletion.canCancel'));

      throw err;
    }
  }

  return (
    <Button
      className="w-full"
      color="secondary"
      onClick={onCancel}
      disabled={props.loading}
    >
      {t('detail.cancelCompletion')}
    </Button>
  );
}
