import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SignupData } from '../../../../pages/signup';
import { phoneNumberRegex, urlRegex } from '../../../../utils/utils';
import Button from '../../../shared/button';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import FormCheckbox from '../../../shared/form/checkbox';
import ConfirmSmeModal from './sme-modal';

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    contactEmail: yup.string().email().required(),
    sme: yup.boolean().required(),
    phoneNumber: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(phoneNumberRegex),
    website: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(urlRegex),
  })
  .required();

function SignupIntroForm(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
  onBack: () => void;
}) {
  const { t } = useTranslation('signup');
  const [showSmeModal, setShowSmeModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(values: any) {
    props.onSubmit({
      ...props.signupData,
      name: values.name,
      contactEmail: values.contactEmail.toLowerCase(),
      phoneNumber: values.phoneNumber,
      website: values.website,
      sme: values.sme || false,
    });
  }

  function onCloseSmeModal() {
    setShowSmeModal(false);
    setValue('sme', false);
  }

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    setValue('name', props.signupData.name!);
    setValue('contactEmail', props.signupData.contactEmail!);
    setValue('phoneNumber', props.signupData.phoneNumber!);
    setValue('website', props.signupData.website!);
    setValue('sme', props.signupData.sme || false);
  }, []);

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center">
        {t('introForm.title')}
      </h1>
      <p className="text-sm text-center text-font-grey mb-8">
        {t('introForm.subtitle')}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel required showRequiredInfo>
            {t('introForm.name')}
          </FormLabel>
          <FormInput
            {...register('name')}
            error={errors.name !== undefined}
            maxLength={200}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel required>{t('introForm.email')}</FormLabel>
          <FormInput
            type="email"
            {...register('contactEmail')}
            error={errors.contactEmail !== undefined}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('introForm.phoneNumber')}</FormLabel>
          <FormInput
            {...register('phoneNumber')}
            error={errors.phoneNumber !== undefined}
            maxLength={200}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('introForm.website')}</FormLabel>
          <FormInput
            {...register('website')}
            error={errors.website !== undefined}
            maxLength={500}
          />
        </FormGroup>

        <FormGroup className="items-start">
          <Controller
            render={({ field }) => (
              <FormCheckbox
                small
                checkboxProps={{
                  onChange: (ev) => {
                    if (ev.target.checked) {
                      setShowSmeModal(true);
                    }

                    field.onChange(ev.target.checked);
                    console.log('onchange', ev.target.checked);
                  },
                  checked: field.value === true,
                  name: 'sme',
                }}
              >
                {t('introForm.sme')}
              </FormCheckbox>
            )}
            control={control}
            name="sme"
          />
        </FormGroup>

        <div className="flex flex-col">
          <Button className="mt-4">{t('introForm.proceed')}</Button>
          <Button variant="underline" type="button" onClick={props.onBack}>
            {t('back')}
          </Button>
        </div>
      </form>
      {showSmeModal && (
        <ConfirmSmeModal
          onConfirm={() => setShowSmeModal(false)}
          onClose={onCloseSmeModal}
        />
      )}
    </div>
  );
}

export default SignupIntroForm;
