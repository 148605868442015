import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  approveProjectCompletion,
  canApproveProjectCompletion,
  getApproveProjectCompletionForwardRequest,
} from '../../../api/projects';
import AuthContext from '../../../context/auth-context';
import { ProjectDetail } from '../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../utils/contracts/edih-projects';
import Button from '../../shared/button';
import Notification from '../../shared/notification';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function ProjectDetailApproveCompletion(props: {
  project: ProjectDetail;
  loading: boolean;
  onApprove: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('project-detail');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onApprove() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanApprove();

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.approveProjectCompletion(
            props.project.contractId
          );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchApproveProjectCompletionForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await approveProjectCompletion(props.project.id, tx, signature);
          props.onApprove(tx!);
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to approved', err);
          props.setError(t('approve.errors.approve'));
          props.setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('approve.errors.transaction'));
        props.setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .approveProjectCompletion(user!.walletAddress, props.project.contractId)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('approved', tx);
      //     try {
      //       await approveProjectCompletion(props.project.id, tx);
      //       props.onApprove(tx);
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to approved', err);
      //       props.setError(t('approve.errors.approve'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('approve.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanApprove() {
    try {
      await canApproveProjectCompletion(props.project.id);
    } catch (err) {
      props.setError(t('approve.errors.canApprove'));

      throw err;
    }
  }

  async function fetchApproveProjectCompletionForwardRequest() {
    try {
      return getApproveProjectCompletionForwardRequest(props.project.id);
    } catch (err) {
      props.setError(t('approve.errors.canApprove'));

      throw err;
    }
  }

  return (
    <Notification color="neutral">
      <div className="mb-2">{t('approve.msg')}</div>
      <Button
        size="small"
        color="secondary"
        onClick={onApprove}
        disabled={props.loading}
      >
        {t('approve.button')}
      </Button>
    </Notification>
  );
}
