import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { EventToManage } from '../../../interfaces/event.interface';
import Web3Context from '../../../context/web3-context';
import {
  endEvent,
  canEndEvent,
  getEndEventForwardRequest,
} from '../../../api/events';
import AuthContext from '../../../context/auth-context';
import { loadEDIHEventsContract } from '../../../utils/contracts/edih-events';
import Button from '../../shared/button';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function EventDetailEnd(props: {
  event: EventToManage;
  loading: boolean;
  onEnd: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('manage-event');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onEnd() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanEnd();
          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.endEvent(
            props.event.contractId!
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchEndEventForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await endEvent(props.event.id, tx, signature);
          props.onEnd(tx!);
          props.setLoading(false);
        } catch (err: any) {
          console.error('failed to end', err);
          props.setError(t('end.errors.end'));
          props.setLoading(false);
        }
      } catch (err) {
        if (err !== 'handled') {
          console.error('failed to send transaction', err);

          if (
            axios.isAxiosError(err) &&
            err.response!.data.code === 'noUsedTickets'
          ) {
            props.setError(t('end.errors.noUsedTickets'));
          } else {
            props.setError(t('end.errors.transaction'));
          }
          props.setLoading(false);
        }
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .endEvent(user!.walletAddress, props.event.contractId!)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('ended', tx);
      //     try {
      //       await endEvent(props.event.id, tx);
      //       props.onEnd(tx);
      //       props.setLoading(false);
      //     } catch (err: any) {
      //       console.error('failed to end', err);
      //       props.setError(t('end.errors.end'));
      //       props.setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('end.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanEnd() {
    try {
      await canEndEvent(props.event.id);
    } catch (err) {
      handleCanEndError(err);

      throw 'handled';
    }
  }

  async function fetchEndEventForwardRequest() {
    try {
      return getEndEventForwardRequest(props.event.id);
    } catch (err) {
      handleCanEndError(err);

      throw 'handled';
    }
  }

  function handleCanEndError(err: any) {
    if (
      axios.isAxiosError(err) &&
      err.response!.data.code === 'noUsedTickets'
    ) {
      props.setError(t('end.errors.noUsedTickets'));
    } else {
      props.setError(t('end.errors.canEnd'));
    }
  }

  return (
    <Button
      className="min-w-[188px]"
      color="secondary"
      size="medium"
      onClick={onEnd}
      disabled={props.loading}
    >
      {t('end.button')}
    </Button>
  );
}
