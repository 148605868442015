import clsx from 'clsx';
import { ProjectCollaboratorRecommendationState } from '../../../../../../interfaces/project-collaborator-recommendation.interface';
import ProjectCollaboratorRecommendationStateEl from '../../../../../shared/project-collaborator-recommendation-state';

interface Props {
  state: ProjectCollaboratorRecommendationState;
}

const stateColors: { [key in ProjectCollaboratorRecommendationState]: string } =
  {
    waiting: 'text-black bg-grey-2',
    sent: 'text-black bg-grey-2',
    accepted: 'text-white bg-success',
    rejected: 'text-white bg-danger',
  };

function ProjectCollaboratorRecommendationItemElState(props: Props) {
  return (
    <p
      className={clsx(
        'absolute top-2 right-2 text-[0.5rem] px-2 py-1 rounded-full',
        stateColors[props.state]
      )}
    >
      <ProjectCollaboratorRecommendationStateEl state={props.state} />
    </p>
  );
}

export default ProjectCollaboratorRecommendationItemElState;
