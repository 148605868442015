import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import { ContractUser } from '../../interfaces/user.interface';

const { abi } = require('./abi/EDIHProjects.json');

export class EDIHProjectsContract {
  instance: ethers.Contract;

  constructor(instance: any) {
    this.instance = instance;
  }

  projectCreationPrice(): Promise<bigint> {
    return this.instance.projectCreationPrice();
  }

  createProject(data: string) {
    return this.instance.createProject(data);
  }
  updateProject(projectId: string, data: string) {
    return this.instance.updateProject(projectId, data);
  }
  getProject(id: string) {
    return this.instance.getProject(id);
  }
  startProject(projectId: string, deadline: number) {
    return this.instance.startProject(projectId, deadline, [], []);
  }
  addProjectCollaborator(
    projectId: string,
    collaborator: string,
    deadline: Date,
    message: string,
    recommender?: string
  ) {
    console.log('my params', {
      projectId,
      collaborator,
      deadline,
      message,
      recommender,
    });
    return this.instance.addProjectCollaborator(
      projectId,
      collaborator,
      Math.floor(deadline.getTime() / 1000),
      message,
      recommender || ethers.ZeroAddress
    );
  }

  acceptProjectCollaboratorInvite(projectId: string) {
    return this.instance.acceptProjectCollaboratorInvite(projectId);
  }

  rejectProjectCollaboratorInvite(projectId: string, message: string) {
    return this.instance.rejectProjectCollaboratorInvite(projectId, message);
  }
  sendProjectResponse(projectId: string, message: string) {
    return this.instance.sendProjectResponse(projectId, message);
  }
  sendProjectCollaboratorRecommendation(
    projectId: string,
    collaborator: string,
    message: string
  ) {
    return this.instance.sendProjectCollaboratorRecommendation(
      projectId,
      collaborator,
      message
    );
  }

  markProjectForCompletion(projectId: string, hash: string) {
    return this.instance.markProjectForCompletion(projectId, hash);
  }

  cancelProjectCompletion(projectId: string) {
    return this.instance.cancelProjectCompletion(projectId);
  }

  approveProjectCompletion(projectId: string) {
    return this.instance.approveProjectCompletion(projectId);
  }

  submitProjectRatings(
    projectId: string,
    targets: string[],
    ratings: number[]
  ) {
    return this.instance.submitProjectRatings(projectId, targets, ratings);
  }
  claimProjectReward(projectId: string) {
    return this.instance.claimProjectReward(projectId);
  }
}

export const loadEDIHProjectsContract = (
  signer: JsonRpcSigner | JsonRpcProvider,
  address?: string
) => {
  const instance = new ethers.Contract(
    address || process.env.REACT_APP_EDIH_PROJECTS_CONTRACT_ADDRESS!,
    abi,
    signer
  );

  return new EDIHProjectsContract(instance);
};

// import { ZERO_ADDRESS, getWeb3 } from '../web3';

// const contractArtifact = require('./abi/EDIHProjects.json');
// const contract = require('@truffle/contract');
// const EDIHProjects = contract(contractArtifact);

// export class EDIHProjectsContract {
//   instance: any;

//   constructor(instance: any) {
//     this.instance = instance;
//   }

//   projectCreationPrice(): Promise<bigint> {
//     return this.instance.projectCreationPrice();
//   }

//   createProject(from: string, data: string) {
//     return this.instance.createProject.sendTransaction(data, {
//       from,
//     });
//   }
//   updateProject(from: string, projectId: string, data: string) {
//     return this.instance.updateProject.sendTransaction(projectId, data, {
//       from,
//     });
//   }
//   getProject(id: string) {
//     return this.instance.getProject(id);
//   }
//   startProject(from: string, projectId: string, deadline: number) {
//     return this.instance.startProject.sendTransaction(
//       projectId,
//       deadline,
//       [],
//       [],
//       {
//         from,
//       }
//     );
//   }
//   addProjectCollaborator(
//     from: string,
//     projectId: string,
//     collaborator: string,
//     deadline: Date,
//     message: string,
//     recommender?: string
//   ) {
//     console.log('my params', {
//       from,
//       projectId,
//       collaborator,
//       deadline,
//       message,
//       recommender,
//     });
//     return this.instance.addProjectCollaborator.sendTransaction(
//       projectId,
//       collaborator,
//       Math.floor(deadline.getTime() / 1000),
//       message,
//       recommender || ZERO_ADDRESS,
//       {
//         from,
//       }
//     );
//   }

//   acceptProjectCollaboratorInvite(from: string, projectId: string) {
//     return this.instance.acceptProjectCollaboratorInvite.sendTransaction(
//       projectId,

//       {
//         from,
//       }
//     );
//   }

//   rejectProjectCollaboratorInvite(
//     from: string,
//     projectId: string,
//     message: string
//   ) {
//     return this.instance.rejectProjectCollaboratorInvite.sendTransaction(
//       projectId,
//       message,
//       {
//         from,
//       }
//     );
//   }
//   sendProjectResponse(from: string, projectId: string, message: string) {
//     return this.instance.sendProjectResponse.sendTransaction(
//       projectId,
//       message,
//       {
//         from,
//       }
//     );
//   }
//   sendProjectCollaboratorRecommendation(
//     from: string,
//     projectId: string,
//     collaborator: string,
//     message: string
//   ) {
//     return this.instance.sendProjectCollaboratorRecommendation.sendTransaction(
//       projectId,
//       collaborator,
//       message,
//       {
//         from,
//       }
//     );
//   }

//   markProjectForCompletion(from: string, projectId: string, hash: string) {
//     console.log(from, projectId);
//     return this.instance.markProjectForCompletion.sendTransaction(
//       projectId,
//       hash,
//       {
//         from,
//       }
//     );
//   }

//   cancelProjectCompletion(from: string, projectId: string) {
//     return this.instance.cancelProjectCompletion.sendTransaction(projectId, {
//       from,
//     });
//   }

//   approveProjectCompletion(from: string, projectId: string) {
//     return this.instance.approveProjectCompletion.sendTransaction(projectId, {
//       from,
//     });
//   }

//   submitProjectRatings(
//     from: string,
//     projectId: string,
//     targets: string[],
//     ratings: number[]
//   ) {
//     return this.instance.submitProjectRatings.sendTransaction(
//       projectId,
//       targets,
//       ratings,
//       {
//         from,
//       }
//     );
//   }
//   claimProjectReward(from: string, projectId: string) {
//     return this.instance.claimProjectReward.sendTransaction(projectId, {
//       from,
//     });
//   }
// }

// export const loadEDIHProjectsContract = async () => {
//   const web3 = getWeb3();
//   if (web3.currentProvider) {
//     EDIHProjects.setProvider(web3.currentProvider);
//   }

//   const instance = await EDIHProjects.at(
//     process.env.REACT_APP_EDIH_PROJECTS_CONTRACT_ADDRESS
//   );
//   return new EDIHProjectsContract(instance);
// };
