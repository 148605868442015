import axios from 'axios';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  scanEventEntry,
  scanEventEntryFromOwner,
} from '../../../api/events-entry-scanners';
import { getDisplayName } from '../../../utils/utils';
import Button from '../../shared/button';
import QrCodeScanner from '../../shared/qr-scanner';
import ScanEventEntryResultModal, {
  ScanEventEntryResultType,
} from './result-modal';

const constraints: MediaStreamConstraints = {
  video: true,
  audio: false,
};

interface Props {
  title: string;
  scannerToken?: string;
}

function EventTicketScanner(props: Props) {
  const { t } = useTranslation('scan-event-entries');
  const [scanStarted, setScanStarted] = useState(false);
  const [scanResult, setScanResult] = useState<{
    type: ScanEventEntryResultType;
    message: string;
  }>();
  const [scannerEnabled, setScannerEnabled] = useState(true);

  function onScan(result: string) {
    // if (!loading && !scanResult) {
    //   console.log('Scanned!!!', result);
    // }
    console.log('scanned', result);
    scan(result);
  }

  async function scan(content: string) {
    setScannerEnabled(false);
    try {
      let result;
      if (props.scannerToken) {
        result = await scanEventEntry(content, props.scannerToken);
      } else {
        result = await scanEventEntryFromOwner(content);
      }

      setScanResult({ type: 'valid', message: getDisplayName(result) });
    } catch (err) {
      console.error('failed to get event', err);
      // toast.dismiss();
      const knownErrors = [
        'accessDenied',
        'eventState',
        'invalidToken',
        'alreadyVerified',
        'invalidScanner',
      ];

      let error;

      if (
        !axios.isAxiosError(err) ||
        !knownErrors.includes(err.response!.data.code)
      ) {
        error = t('errors.scan');
      } else {
        error = t('errors.' + err.response!.data.code);
      }

      setScanResult({ type: 'invalid', message: error });
    }
  }

  function onCloseScanResult() {
    setScanResult(undefined);
    setTimeout(() => {
      setScannerEnabled(true);
    }, +process.env.REACT_APP_EVENT_SCAN_DELAY_AFTER_RESULT!);
  }

  // function changeScannerState(state: QrCodeScannerState, duration: number) {
  //   clearTimeout(stateChangeTimeout.current);
  //   setScannerState(state);
  //   console.log('swtch to ' + state);

  //   stateChangeTimeout.current = setTimeout(() => {
  //     console.log('reset!');
  //     setScannerState('idle');
  //   }, duration);
  // }

  return (
    <div
      className={clsx('min-h-screen', scanStarted ? 'bg-black' : 'bg-grey-3')}
    >
      {scanStarted && (
        <div className="relative h-screen overflow-hidden">
          <QrCodeScanner
            className="w-full h-full"
            enabled={scannerEnabled}
            onDecode={onScan}
            onDecodeError={() => {}}
            timeBetweenDecodingAttempts={
              +process.env.REACT_APP_EVENT_SCAN_DELAY!
            }
          />
          {scanResult && (
            <ScanEventEntryResultModal
              type={scanResult.type}
              message={scanResult.message}
              onClose={onCloseScanResult}
            />
          )}
        </div>
      )}
      <div
        className={clsx(
          'absolute top-0 left-0 right-0 px-9 py-3',
          scanStarted ? 'bg-black bg-opacity-70 text-white' : 'bg-white'
        )}
      >
        <div className="text-2xl">{props.title}</div>
      </div>
      {!scanStarted && (
        <div className="px-4 pt-36 flex justify-center">
          <Button color="secondary" onClick={() => setScanStarted(true)}>
            {t('start')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default EventTicketScanner;
