import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardEndorsementsIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.658 8.598c-.302-.315-.614-.641-.716-.888-.092-.22-.099-.673-.105-1.071-.012-.86-.029-1.929-.788-2.688-.76-.758-1.828-.773-2.688-.788-.398-.007-.851-.013-1.071-.104-.247-.103-.573-.415-.888-.717-.608-.584-1.366-1.31-2.402-1.31s-1.794.726-2.402 1.31c-.315.302-.641.614-.888.717-.22.09-.673.097-1.071.103-.86.018-1.929.03-2.686.791-.757.762-.773 1.826-.79 2.686-.007.398-.013.851-.104 1.071-.103.247-.415.573-.717.888-.584.608-1.31 1.37-1.31 2.402 0 1.031.726 1.793 1.31 2.406.302.316.614.641.717.888.09.22.097.673.103 1.072.013.859.03 1.928.79 2.687.76.759 1.827.773 2.687.789.398.006.851.013 1.071.104.247.102.573.414.888.716.608.58 1.37 1.307 2.402 1.307 1.031 0 1.793-.727 2.402-1.31.315-.303.641-.615.888-.717.22-.092.673-.099 1.071-.105.86-.012 1.929-.029 2.688-.788.758-.76.773-1.828.788-2.688.006-.398.013-.851.105-1.071.102-.247.414-.573.716-.888.584-.608 1.31-1.366 1.31-2.402s-.726-1.794-1.31-2.402Zm-1.488 3.377c-.415.43-.884.92-1.134 1.526-.242.584-.252 1.217-.258 1.83-.006.46-.016 1.092-.184 1.26-.169.167-.8.177-1.26.184-.613.01-1.245.019-1.83.258-.601.25-1.094.72-1.526 1.133-.31.297-.772.74-.978.74-.206 0-.669-.443-.975-.736-.43-.415-.92-.884-1.526-1.134-.584-.242-1.217-.252-1.83-.258-.46-.006-1.092-.016-1.26-.184-.167-.169-.177-.8-.184-1.26-.01-.613-.019-1.245-.258-1.83-.25-.601-.72-1.094-1.133-1.526-.297-.31-.74-.772-.74-.978 0-.206.443-.669.736-.975.414-.43.884-.92 1.134-1.526.242-.584.252-1.217.258-1.83.01-.46.02-1.092.192-1.255.172-.163.8-.178 1.26-.185.613-.01 1.245-.019 1.83-.258.601-.25 1.094-.719 1.526-1.133.301-.3.764-.744.97-.744.206 0 .669.443.975.736.43.414.92.884 1.526 1.134.584.242 1.217.252 1.83.258.46.006 1.092.016 1.26.184.167.169.177.8.184 1.26.01.613.019 1.245.258 1.83.25.601.72 1.094 1.133 1.526.293.306.737.768.737.975 0 .206-.44.672-.733.978Zm-3.003-3.767a1.033 1.033 0 0 1 0 1.46l-4.812 4.813a1.03 1.03 0 0 1-1.461 0L6.83 12.42a1.033 1.033 0 1 1 1.461-1.461l1.333 1.331 4.083-4.084a1.03 1.03 0 0 1 1.46.003Z"
    />
  </svg>
);
export default DashboardEndorsementsIcon;
