import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardUsersIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.875 1.719H5.5a1.719 1.719 0 0 0-1.719 1.718v1.72H2.75a1.031 1.031 0 1 0 0 2.062h1.031v2.75H2.75a1.031 1.031 0 1 0 0 2.062h1.031v2.75H2.75a1.031 1.031 0 0 0 0 2.063h1.031v1.718a1.719 1.719 0 0 0 1.719 1.72h12.375a1.718 1.718 0 0 0 1.719-1.72V3.438a1.719 1.719 0 0 0-1.719-1.718Zm-.344 16.5H5.844V3.78H17.53V18.22Zm-8.868-3.492a4.124 4.124 0 0 1 6.05 0 1.031 1.031 0 1 0 1.512-1.402 6.187 6.187 0 0 0-1.65-1.262 3.78 3.78 0 1 0-5.775 0 6.2 6.2 0 0 0-1.65 1.262 1.031 1.031 0 0 0 1.512 1.402Zm1.306-5.102a1.72 1.72 0 1 1 3.438 0 1.72 1.72 0 0 1-3.438 0Z"
    />
  </svg>
);
export default DashboardUsersIcon;
