import { User } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const login = async (email: string, password: string): Promise<User> => {
  const { data } = await myAxios.post('/login', { email, password });
  return data.data;
};

export const logout = async (): Promise<void> => {
  const { data } = await myAxios.post('/logout');

  return data.data;
};

export const resetPassword = async (email: string): Promise<void> => {
  const { data } = await myAxios.post('/reset-password', { email });
  return data.data;
};

export const verifyResetPassword = async (token: string): Promise<void> => {
  const { data } = await myAxios.post('/verify-reset-password', { token });
  return data.data;
};

export const confirmResetPassword = async (
  token: string,
  password: string
): Promise<void> => {
  const { data } = await myAxios.post('/confirm-reset-password', {
    token,
    password,
  });
  return data.data;
};
