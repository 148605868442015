import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Notification from '../../../shared/notification';
import { rejectRegistration } from '../../../../api/registrations';
import Button from '../../../shared/button';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import FormTextarea from '../../../shared/form/textarea';
import Modal from '../../../shared/modal';

const schema = yup
  .object()
  .shape({
    reason: yup.string().required(),
  })
  .required();

function RejectRegistrationModal(props: {
  id: string;
  onClose: () => void;
  onReject: (reason: string) => void;
}) {
  const { t } = useTranslation('registration');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await rejectRegistration(props.id, values.reason);
      props.onReject(values.reason);
    } catch (err: any) {
      setError(t('rejectModal.failed')!);
    }
    setLoading(false);
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('rejectModal.label')}</FormLabel>
          <FormTextarea
            {...register('reason')}
            error={errors.reason !== undefined}
            rows={6}
            disabled={loading}
          />
        </FormGroup>
        <Button className="mt-3 w-full" color="secondary" disabled={loading}>
          {t('rejectModal.reject')}
        </Button>
      </form>
    </Modal>
  );
}

export default RejectRegistrationModal;
