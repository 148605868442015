import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { signInRoute } from '../../../utils/routes';
import LangSwitcher from '../../shared/lang-switcher';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export interface SplitCarouselItem {
  image: string;
  text: string;
  name: string;
  position: string;
}

function SplitLayout(props: Props) {
  const { t } = useTranslation('split-carousel-items');
  let className = clsx('flex', props.className);
  const [carouselItems, setCarouselItems] = useState<any[]>();
  const translationItems = t('items', { returnObjects: true });

  useEffect(() => {
    if (Array.isArray(translationItems)) {
      setCarouselItems(
        (translationItems as SplitCarouselItem[]).map((item) => {
          return (
            <div className="relative" key={item.name}>
              <img className="w-full h-screen object-cover" src={item.image} />
              <div className="absolute bottom-0 left-0 right-0 text-left px-10 pt-20 pb-10 text-white bg-gradient-to-b from-transparent to-black/70">
                <p className="text-[1.625rem] text-white mb-3">{item.text}</p>
                <p className="text-white">{item.name}</p>
                <p className="text-[0.625rem]">{item.position}</p>
              </div>
            </div>
          );
        })
      );
    }
  }, [Array.isArray(translationItems)]);
  // console.log('tttt', t('items', { returnObjects: true }));

  // const carouselItems = (
  //   t('items', { returnObjects: true }) as SplitCarouselItem[]
  // ).map((item) => {
  //   return (
  //     <div className="relative" key={item.name}>
  //       <img className="w-full h-screen object-cover" src={item.image} />
  //       <div className="absolute bottom-0 left-0 right-0 text-left px-10 pt-20 pb-10 text-white bg-gradient-to-b from-transparent to-black/70">
  //         <p className="text-[1.625rem] text-white mb-3">{item.text}</p>
  //         <p className="text-white">{item.name}</p>
  //         <p className="text-[0.625rem]">{item.position}</p>
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <div {...props} className={className}>
      <div className="w-full max-w-[800px] max-[1400px]:max-w-full">
        <div className="flex items-center mt-10 mx-8">
          <Link to={signInRoute} className="mr-auto">
            <img className="w-[230px]" src="/images/logo.png" />
          </Link>
          <LangSwitcher className="text-sm max-[500px]:hidden" />
        </div>
        <div className="max-[1400px]:flex max-[1400px]:justify-center">
          <div className="pt-[10rem] px-44 max-[1400px]:px-4 max-[1400px]:w-full max-[1400px]:max-w-[450px] max-[600px]:pt-[8rem]">
            {props.children}
          </div>
        </div>
        <div className="pt-6 pb-4 hidden max-[500px]:flex max-[500px]:justify-center">
          <LangSwitcher className="text-sm" />
        </div>
      </div>
      <div className="flex-1 h-screen rounded-tl-[50px] overflow-hidden max-[1400px]:hidden">
        {carouselItems && (
          <Carousel
            swipeable={false}
            animationHandler="fade"
            autoPlay
            interval={10000}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop={true}
            dynamicHeight={true}
          >
            {carouselItems}
          </Carousel>
        )}
        {/* <img className="w-full h-full object-cover" src="/images/split/1.png" /> */}
      </div>
    </div>
  );
}

export default SplitLayout;
