import clsx from 'clsx';
import UserProfileItemEl from '../../item';
import { Link } from 'react-router-dom';
import { useTrackableWidth } from '../../../../hooks/use-trackable-width';
import { GetMembersResult } from '../../../../interfaces/user.interface';
import { userProfileRoute } from '../../../../utils/routes';
import DashboardBox from '../../../core/dashboard-layout/box';
import DashboardTitle from '../../../core/dashboard-layout/title';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  result: GetMembersResult;
  title: string;
  notFoundText: string;
  viewMoreText: string;
  onViewMore: () => void;
  loading?: boolean;
}

function HorizontalMemberList(props: Props) {
  const { width } = useTrackableWidth();

  let maxItems = 0;

  if (width > 1375) {
    maxItems = 4;
  } else if (width > 1150) {
    maxItems = 3;
  } else if (width > 1022) {
    maxItems = 2;
  } else if (width > 885) {
    maxItems = 3;
  } else {
    maxItems = 2;
  }

  const els = props.result.results.map((item, i) => (
    <>
      {i < maxItems && (
        <Link
          key={'member-' + item.id}
          className="w-[200px] shrink-0 max-[660px]:w-auto"
          to={userProfileRoute(item.id)}
        >
          <UserProfileItemEl item={item} />
        </Link>
      )}
    </>
  ));

  return (
    <DashboardBox {...props}>
      <DashboardTitle>{props.title}</DashboardTitle>
      <div className="flex space-x-6 overflow-auto max-[660px]:grid max-[660px]:grid-cols-1 max-[660px]:space-x-0 max-[660px]:gap-8">
        {els}
        {props.result.total > maxItems && (
          <div
            className={clsx(
              'px-6 flex-1 flex items-center justify-center shrink-0',
              props.loading ? 'opacity-50 pointer-events-none' : undefined
            )}
          >
            <div
              className="text-secondary underline text-sm cursor-pointer"
              onClick={props.onViewMore}
            >
              {props.viewMoreText}
            </div>
          </div>
        )}
      </div>

      {props.result.results.length < 1 && (
        <div className="py-12 text-sm">{props.notFoundText}</div>
      )}
    </DashboardBox>
  );
}

export default HorizontalMemberList;
