import './App.css';
import {
  adminRegistrationRoute,
  adminUsersRoute,
  collaboratorRecommendationFormRoute,
  collaboratorResponseFormRoute,
  createEventRoute,
  createProjectRoute,
  editEventRoute,
  editProfileRoute,
  editProjectRoute,
  endorsementsRoute,
  endorsersOfUserRoute,
  eventEntryScannerRoute,
  eventRoute,
  faqRoute,
  getDashboardRoute,
  inviteCollaboratorRoute,
  inviteCollaboratorUsingRecommendationRoute,
  manageEventRoute,
  membersRoute,
  myEventEntriesRoute,
  myEventsRoute,
  myProjectsRoute,
  otherEventsRoute,
  otherProjectsRoute,
  participatingProjectsOfUserRoute,
  projectCollaboratorApplicationRoute,
  projectCollaboratorApplicationsRoute,
  projectCollaboratorInviteRoute,
  projectCollaboratorInvitesRoute,
  projectCollaboratorPlatformRecommendationsRoute,
  projectCollaboratorRecommendationRoute,
  projectCollaboratorRecommendationsRoute,
  projectCollaboratorsRoute,
  projectMilestonesRoute,
  projectRoute,
  resetPasswordConfirmRoute,
  resetPasswordRoute,
  scanOwnEventEntriesRoute,
  signInRoute,
  signUpRoute,
  userProfileRoute,
} from './utils/routes';
import SignInPage from './pages/signin';
import SignUpPage from './pages/signup';
import { Navigate, Route, Routes } from 'react-router-dom';
import ResetPasswordPage from './pages/reset-password';
import RegistrationsPage from './pages/admin';
import UsersPage from './pages/admin/users';
import RegistrationPage from './pages/admin/registrations/[registrationId]';
import ConfirmResetPasswordPage from './pages/reset-password/confirm';
import UserDashboardPage from './pages/dashboard';
import EndorsementsPage from './pages/endorsements';
import MyProjectsPage from './pages/projects/my';
import OtherProjectsPage from './pages/projects';
import MyEventsPage from './pages/events/my';
import OtherEventsPage from './pages/events';
import MembersPage from './pages/members';
import FAQPage from './pages/faq';
import CreateProjectPage from './pages/projects/create';
import ProjectDetailPage from './pages/projects/[projectId]';
import EditProjectPage from './pages/projects/[projectId]/edit';
import ProjectMilestonesPage from './pages/projects/[projectId]/milestones';
import UserProfilePage from './pages/members/[userId]';
import UserProfileEditPage from './pages/members/edit';
import ProjectCollaboratorsPage from './pages/projects/[projectId]/collaborators';
import ProjectCollaboratorInvitesPage from './pages/projects/[projectId]/collaborators/invites';
import ProjectCollaboratorInvitePage from './pages/projects/[projectId]/collaborators/invites/[inviteId]';
import ProjectCollaboratorResponsesPage from './pages/projects/[projectId]/collaborators/applications';
import ProjectCollaboratorResponsePage from './pages/projects/[projectId]/collaborators/applications/[responseId]';
import ProjectCollaboratorRecommendationsPage from './pages/projects/[projectId]/collaborators/recommendations';
import ProjectCollaboratorPlatformRecommendationsPage from './pages/projects/[projectId]/collaborators/recommendations/top';
import ProjectCollaboratorRecommendationPage from './pages/projects/[projectId]/collaborators/recommendations/[recommendationId]';
import InviteCollaboratorPage from './pages/projects/[projectId]/collaborators/invite';
import ApplyAsCollaboratorPage from './pages/projects/[projectId]/collaborators/apply';
import RecommendCollaboratorPage from './pages/projects/[projectId]/collaborators/recommend';
import InviteCollaboratorViaRecommendationPage from './pages/projects/[projectId]/collaborators/recommendations/[recommendationId]/invite';
import EventDetailPage from './pages/events/[eventId]';
import EditEventPage from './pages/events/[eventId]/edit';
import ManageEventPage from './pages/events/[eventId]/manage';
import CreateEventPage from './pages/events/create';
import MyEventEntriesPage from './pages/events/my-tickets';
import ManageEventScanPage from './pages/events/[eventId]/manage/scan-tickets';
import EndorsersOfMemberPage from './pages/members/[userId]/endorsers';
import ParticipatingProjectsPage from './pages/members/[userId]/participating-projects';
import ScanEventEntriesPage from './pages/events/scan-tickets/[scannerToken]';
import NotFoundPage from './pages/404';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={signInRoute} />} />
      <Route path={signInRoute} element={<SignInPage />} />
      <Route path={signUpRoute} element={<SignUpPage />} />
      <Route path={resetPasswordRoute} element={<ResetPasswordPage />} />
      <Route
        path={getDashboardRoute('admin')}
        element={<RegistrationsPage />}
      />
      <Route path={adminUsersRoute} element={<UsersPage />} />
      <Route
        path={adminRegistrationRoute(':registrationId')}
        element={<RegistrationPage />}
      />
      <Route
        path={resetPasswordConfirmRoute}
        element={<ConfirmResetPasswordPage />}
      />
      <Route path={getDashboardRoute()} element={<UserDashboardPage />} />

      <Route path={myProjectsRoute} element={<MyProjectsPage />} />
      <Route path={otherProjectsRoute} element={<OtherProjectsPage />} />
      <Route path={createProjectRoute} element={<CreateProjectPage />} />
      <Route
        path={projectRoute(':projectId')}
        element={<ProjectDetailPage />}
      />
      <Route
        path={editProjectRoute(':projectId')}
        element={<EditProjectPage />}
      />
      <Route
        path={projectMilestonesRoute(':projectId')}
        element={<ProjectMilestonesPage />}
      />
      <Route
        path={projectCollaboratorsRoute(':projectId')}
        element={<ProjectCollaboratorsPage />}
      />
      <Route
        path={projectCollaboratorInvitesRoute(':projectId')}
        element={<ProjectCollaboratorInvitesPage />}
      />
      <Route
        path={projectCollaboratorInviteRoute(':projectId', ':inviteId')}
        element={<ProjectCollaboratorInvitePage />}
      />
      <Route
        path={projectCollaboratorApplicationsRoute(':projectId')}
        element={<ProjectCollaboratorResponsesPage />}
      />
      <Route
        path={projectCollaboratorApplicationRoute(':projectId', ':responseId')}
        element={<ProjectCollaboratorResponsePage />}
      />
      <Route
        path={projectCollaboratorRecommendationsRoute(':projectId')}
        element={<ProjectCollaboratorRecommendationsPage />}
      />
      <Route
        path={projectCollaboratorPlatformRecommendationsRoute(':projectId')}
        element={<ProjectCollaboratorPlatformRecommendationsPage />}
      />
      <Route
        path={projectCollaboratorRecommendationRoute(
          ':projectId',
          ':recommendationId'
        )}
        element={<ProjectCollaboratorRecommendationPage />}
      />
      <Route
        path={inviteCollaboratorRoute(':projectId')}
        element={<InviteCollaboratorPage />}
      />
      <Route
        path={collaboratorResponseFormRoute(':projectId')}
        element={<ApplyAsCollaboratorPage />}
      />
      <Route
        path={collaboratorRecommendationFormRoute(':projectId')}
        element={<RecommendCollaboratorPage />}
      />
      <Route
        path={inviteCollaboratorUsingRecommendationRoute(
          ':projectId',
          ':recommendationId'
        )}
        element={<InviteCollaboratorViaRecommendationPage />}
      />
      <Route
        path={participatingProjectsOfUserRoute(':userId')}
        element={<ParticipatingProjectsPage />}
      />

      <Route path={myEventsRoute} element={<MyEventsPage />} />
      <Route path={otherEventsRoute} element={<OtherEventsPage />} />
      <Route path={createEventRoute} element={<CreateEventPage />} />
      <Route path={myEventEntriesRoute} element={<MyEventEntriesPage />} />
      <Route path={eventRoute(':eventId')} element={<EventDetailPage />} />
      <Route path={editEventRoute(':eventId')} element={<EditEventPage />} />
      <Route
        path={manageEventRoute(':eventId')}
        element={<ManageEventPage />}
      />
      <Route
        path={scanOwnEventEntriesRoute(':eventId')}
        element={<ManageEventScanPage />}
      />
      <Route
        path={eventEntryScannerRoute(':scannerToken')}
        element={<ScanEventEntriesPage />}
      />

      <Route path={membersRoute} element={<MembersPage />} />
      <Route path={editProfileRoute} element={<UserProfileEditPage />} />
      <Route path={userProfileRoute(':userId')} element={<UserProfilePage />} />

      <Route path={endorsementsRoute} element={<EndorsementsPage />} />
      <Route
        path={endorsersOfUserRoute(':userId')}
        element={<EndorsersOfMemberPage />}
      />

      <Route path={faqRoute} element={<FAQPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
