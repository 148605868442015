import clsx from 'clsx';
import { useContext } from 'react';
import DashboardExpButton from '../rating-button';
import DashboardTokensButton from '../tokens-button';
import DashboardWalletButton from '../wallet-button';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../context/auth-context';
import { userProfileRoute } from '../../../../utils/routes';
import OptimizedImage from '../../../shared/image';
import UserName from '../../../shared/user-name';
import UserRole from '../../../shared/user-role';

function DashboardHeaderMenu() {
  const authContext = useContext(AuthContext);

  return (
    <div className="flex items-center">
      {!authContext.isAdmin() && (
        <>
          <DashboardTokensButton />
          {/* <DashboardRatingButton /> */}
          <DashboardExpButton />

          {authContext.user && authContext.user.magicIssuer && (
            <DashboardWalletButton />
          )}
          <Link
            className="flex border-l border-grey-[#EEF0F6] pl-4 max-lg:border-none max-lg:pl-0 max-lg:-order-1 max-lg:pr-2"
            to={userProfileRoute(authContext.user!.id)}
          >
            {authContext.user!.image && (
              <OptimizedImage
                className={styles['profile-image']}
                params={{ w: '40', h: '40' }}
                src={authContext.user!.image}
              />
            )}
            {!authContext.user!.image && (
              <div
                className={clsx(
                  styles['profile-image'],
                  styles['default-profile-image']
                )}
              />
            )}
            <div className="flex flex-col justify-center max-lg:hidden">
              <p className="text-sm">
                <UserName obj={authContext.user!} />
              </p>
              <p className="text-[0.625rem] text-[#98999D]">
                <UserRole role={authContext.user!.role} />
              </p>
            </div>
          </Link>
        </>
      )}
    </div>
  );
}

export default DashboardHeaderMenu;
