import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import FormError from '../error';
import styles from './index.module.css';
import { Tag } from '../../../../interfaces/tag.interface';
import { getTags } from '../../../../api/tags';
import {
  SupportedLang,
  getPropByLang,
} from '../../../../translations/i18n-settings';

function TagsInput(props: {
  onChange: (tags: Tag[]) => void;
  tags: Tag[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
}) {
  const { t, i18n } = useTranslation('tags-input');

  const [suggestions, setSuggestions] = useState<Tag[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    loadTags();
  }, []);

  // set a default value of empty array if receiving null
  useEffect(() => {
    if (!props.tags) {
      props.onChange([]);
    }
  }, [props.tags]);

  async function loadTags() {
    try {
      const tags = await getTags();
      setSuggestions(tags);
    } catch (err) {
      console.error('failed to load tags', err);
      setError(t('error'));
    }
  }

  function onDelete(index: number) {
    props.onChange(props.tags.filter((t, i) => i !== index));
  }

  function onAdd(tag: Tag) {
    if (suggestions.find((t) => t.id === tag.id)) {
      props.onChange(props.tags ? props.tags.concat(tag) : [tag]);
    }
  }

  return (
    <div className="relative">
      <ReactTags
        classNames={{
          tags: clsx(
            'border outline-none pt-[0.813rem] px-3 text-font-black rounded text-sm',
            props.error ? 'border-red-500 bg-red-100' : 'border-grey'
          ),
          tag: 'mr-2 text-xs border border-grey-2 bg-[#FAFAFA] rounded-xl p-2 mb-[0.813rem]',
          tagInputField:
            'outline-none text-font-black text-sm w-full mb-[0.813rem]',
          suggestions: styles.suggestions,
          activeSuggestion: 'font-medium',
          selected: 'flex flex-wrap',
          remove: 'ml-1',
        }}
        tags={props.tags as any}
        suggestions={
          suggestions
            .map((t) => {
              return {
                id: t.id,
                name: getPropByLang(i18n.language as SupportedLang, t),
              };
            })
            .slice()
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            ) as any
        }
        delimiters={[188, 13]}
        handleDelete={onDelete as any}
        handleAddition={onAdd as any}
        allowDragDrop={false}
        labelField="name"
        inputFieldPosition="bottom"
        placeholder={props.placeholder}
        autocomplete
        minQueryLength={0}
        autofocus={false}
        readOnly={props.disabled}
      />
      {error && <FormError>{error}</FormError>}
    </div>
  );
}

export default TagsInput;
