import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../interfaces/user.interface';

function UserRoleEl(props: { role: UserRole }) {
  const { t } = useTranslation('common');

  return <>{t('userRoles.' + props.role)}</>;
}

export default UserRoleEl;
