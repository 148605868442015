import clsx from 'clsx';
import { ProjectMilestoneState } from '../../../../../../interfaces/project-milestones.interface';
import ProjectMilestoneStateEl from '../../../../../shared/project-milestone-state';

interface Props {
  state: ProjectMilestoneState;
}

const stateColors: { [key in ProjectMilestoneState]: string } = {
  waiting: 'text-black bg-grey-2',
  active: 'text-white bg-warning',
  waitingForCompletionInitiation: 'text-white bg-warning',
  completionApproval: 'text-white bg-blue',
  waitingForCancelCompletion: 'text-white bg-blue',
  waitingForCompletion: 'text-white bg-blue',
  completed: 'text-white bg-success',
};

function ProjectMilestoneItemElState(props: Props) {
  return (
    <p
      className={clsx(
        'text-[0.5rem] px-2 py-1 rounded-full',
        stateColors[props.state]
      )}
    >
      <ProjectMilestoneStateEl state={props.state} />
    </p>
  );
}

export default ProjectMilestoneItemElState;
