import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { DashboardItem } from '..';
import DashboardHeaderMenu from '../header-menu';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../context/auth-context';
import { logout } from '../../../../api/auth';
import { getMagic } from '../../../../utils/web3';
import LangSwitcher from '../../../shared/lang-switcher';

interface Props {
  items: DashboardItem[];
  selectedPath: string;
  show: boolean;
  onClose: () => void;
}

function DashboardLayoutMenu(props: Props) {
  const { t } = useTranslation('common');

  const authContext = useContext(AuthContext);
  const [loggingOut, setLoggingOut] = useState(false);

  const itemEls = props.items.map((item) => {
    const active = item.path === props.selectedPath;

    return (
      <Link
        key={'dashboard-' + item.path}
        to={item.path}
        className={clsx(
          'text-sm text-font-grey px-5 py-2.5 mb-2 flex items-center rounded hover:bg-primary/50 hover:text-secondary max-lg:rounded-none max-lg:py-4',
          active ? 'bg-primary/50 text-secondary' : undefined
        )}
      >
        {item.icon('mr-2 max-lg:mr-4')}

        <span>{item.label}</span>
      </Link>
    );
  });

  async function onLogout() {
    setLoggingOut(true);
    try {
      await logout();
      authContext.logout();
      toast.success(t('dashboard.loggedOut'), { duration: 3000 });
      await getMagic().user.logout();
    } catch (err) {
      console.error('failed to logout', err);
      toast.error(t('dashboard.errors.logout'), { duration: 3000 });
    }
    setLoggingOut(false);
  }

  return (
    <div
      className={clsx(
        'py-4 flex flex-col border-r border-grey-3 bg-white max-lg:absolute max-lg:top-0 max-lg:left-0 max-lg:right-0 max-lg:bottom-0 max-lg:hidden max-lg:z-10 max-lg:border-0',
        props.show ? '!flex' : undefined
      )}
    >
      <div className="px-8 pt-4 pb-14 max-lg:hidden">
        <img className="mr-auto w-[180px]" src="/images/logo.png" />
      </div>

      <div className="hidden px-4 mb-8 max-lg:flex max-lg:items-center">
        <DashboardHeaderMenu />
        <img
          className="ml-auto"
          src="/images/dashboard/mobile-menu-close.svg"
          onClick={props.onClose}
        />
      </div>

      <p className="text-sm text-font-grey px-11 mb-2 max-lg:text-xl max-lg:text-font-black max-lg:px-5">
        {t('dashboard.menu')}
      </p>

      <div className="px-5 flex-1 max-lg:px-0">{itemEls}</div>
      <div className="px-11 flex flex-col max-lg:px-8">
        <div
          className={clsx(
            'text-sm text-[#ff5f5f] mb-4 cursor-pointer hover:underline max-lg:mb-6',
            loggingOut ? 'pointer-events-none opacity-50' : undefined
          )}
          onClick={onLogout}
        >
          {t('dashboard.logout')}
        </div>

        <a
          className={clsx(
            'text-xs text-[#b6b6b6] mb-1 underline cursor-pointer',
            loggingOut ? 'pointer-events-none opacity-50' : undefined
          )}
          href={process.env.REACT_APP_PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          {t('dashboard.privacyPolicy')}
        </a>

        <LangSwitcher className="text-xs mb-1" />

        <div className="text-xs text-[#b6b6b6] max-lg:mb-4" onClick={onLogout}>
          {t('dashboard.copyright')}
        </div>
      </div>
    </div>
  );
}

export default DashboardLayoutMenu;
