import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import SignupWalletTypeItemEl, { SignupWalletTypeItem } from './item';
import { walletAddressTaken } from '../../../../api/registrations';
import { SignupData, SignupWalletType } from '../../../../pages/signup';
import { switchWeb3Provider } from '../../../../utils/web3';
import Button from '../../../shared/button';
import Notification from '../../../shared/notification';
import Web3Context from '../../../../context/web3-context';

function SignupWallet(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
  onBack: () => void;
}) {
  const { t } = useTranslation('signup');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const [selected, setSelected] = useState<SignupWalletType>(
    props.signupData.walletType || 'magic'
  );

  useEffect(() => {
    switchWeb3Provider(selected);
  }, [selected]);

  const items = (
    t('wallet.items', { returnObjects: true }) as SignupWalletTypeItem[]
  ).map((item) => {
    return (
      <SignupWalletTypeItemEl
        item={item}
        key={item.type}
        active={selected === item.type}
        onClick={() => onChangeWallet(item.type)}
      />
    );
  });

  async function onChangeWallet(type: SignupWalletType) {
    setSelected(type);
  }

  async function onSubmit() {
    setLoading(true);
    setError(undefined);
    try {
      const walletAddress = await connect();
      try {
        const exists = await walletAddressTaken(walletAddress!.toLowerCase());

        if (!exists) {
          props.onSubmit({
            ...props.signupData,
            walletAddress: walletAddress!.toLowerCase(),
            walletType: selected,
          });
        } else {
          setError(t('wallet.errors.walletExists'));
        }
      } catch (err) {
        console.error('failed to verify wallet uniqueness', err);
        setError(t('wallet.errors.unexpected'));
      }
    } catch (err) {}
    setLoading(false);
  }

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center">
        {t('wallet.title')}
      </h1>
      <p className="text-sm text-center text-font-grey mb-8">
        {t('wallet.subtitle')}
      </p>
      {error && <Notification color="danger">{error}</Notification>}
      {/* <div className="flex justify-center">
        <img
          className="w-[206px]"
          src="/images/split/metamask.png"
          alt="Metamask"
        />
      </div> */}
      <div className="flex space-x-4 mb-16">{items}</div>
      <div className="flex flex-col">
        <Button className="mt-4" disabled={loading} onClick={onSubmit}>
          {t('wallet.proceed')}
        </Button>
        <Button variant="underline" disabled={loading} onClick={props.onBack}>
          {t('back')}
        </Button>
      </div>
    </div>
  );
}

export default SignupWallet;
