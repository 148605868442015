import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import truncateEthAddress from 'truncate-eth-address';

function WalletAddress(props: { walletAddress: string; full?: boolean }) {
  const { t } = useTranslation('common');

  function onCopy() {
    toast.success(t('copied'), { duration: 2000 });
  }

  return (
    <CopyToClipboard text={props.walletAddress} onCopy={onCopy}>
      <span className="underline cursor-pointer">
        {props.full
          ? props.walletAddress
          : truncateEthAddress(props.walletAddress)}
      </span>
    </CopyToClipboard>
  );
}

export default WalletAddress;
