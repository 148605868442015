import ProgressBar from '@ramonak/react-progress-bar';
import { ProjectItem } from '../../../../../interfaces/project.interface';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: ProjectItem;
}

function ProjectItemElMilestoneProgress(props: Props) {
  const completed = props.item.milestones.filter(
    (milestone) => milestone.state === 'completed'
  );

  console.log('milestones', props.item.milestones);

  return (
    <div className="flex items-center text-[0.625rem] text-font-grey space-x-1">
      <img
        src="/images/projects/milestones.svg"
        width="16"
        height="16"
        alt="milestones"
      />
      <span>{completed.length}</span>
      <span>/</span>
      <span>
        {props.item.milestones.length > 0 ? props.item.milestones.length : '-'}
      </span>
      <ProgressBar
        className="px-1 flex-1"
        completed={completed.length}
        maxCompleted={props.item.milestones.length}
        bgColor="linear-gradient(to right, #FECA16 0%, #C34B01 100%)"
        baseBgColor="#E0E0E0"
        // completedClassName="bg-gradient-to-r from-primary to-secondary"
        height="2px"
        isLabelVisible={false}
      />
      <span>
        {props.item.milestones.length > 0
          ? Math.floor((completed.length / props.item.milestones.length) * 100)
          : 0}
        %
      </span>
      {/* <div className="space-x-1">
        <span>{completed.length}</span>
        <span>/</span>
        <span>
          {props.item.milestones.length > 0
            ? props.item.milestones.length
            : '-'}
        </span>
      </div> */}
    </div>
  );
}

export default ProjectItemElMilestoneProgress;
