import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getProjectCollaboratorRecommendation,
  requestProjectCollaboratorRecommendationFileDownload,
} from '../../../../../../api/projects-collaborator-recommendations';
import DashboardBox from '../../../../../../components/core/dashboard-layout/box';
import DashboardFiles from '../../../../../../components/core/dashboard-layout/files';
import DashboardGroup from '../../../../../../components/core/dashboard-layout/group';
import DashboardGroupLabel from '../../../../../../components/core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../../../../components/core/dashboard-layout/group/value';
import DashboardUserProfile from '../../../../../../components/core/dashboard-layout/profile';
import DashboardTitle from '../../../../../../components/core/dashboard-layout/title';
import UserDashboard from '../../../../../../components/core/dashboard-layout/user';
import RejectProjectCollaboratorRecommendation from '../../../../../../components/projects/collaborators/recommendations/reject';
import Button from '../../../../../../components/shared/button';
import Loading from '../../../../../../components/shared/loading';
import ProjectCollaboratorRecommendationStateEl from '../../../../../../components/shared/project-collaborator-recommendation-state';
import UserName from '../../../../../../components/shared/user-name';
import AuthContext from '../../../../../../context/auth-context';
import { ProjectCollaboratorRecommendationDetail } from '../../../../../../interfaces/project-collaborator-recommendation.interface';
import {
  myProjectsRoute,
  projectRoute,
  projectCollaboratorsRoute,
  projectCollaboratorRecommendationsRoute,
  userProfileRoute,
  inviteCollaboratorUsingRecommendationRoute,
} from '../../../../../../utils/routes';
import Notification from '../../../../../../components/shared/notification';

export default function ProjectCollaboratorRecommendationPage() {
  const { t } = useTranslation('project-collaborator-recommendation');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [recommendation, setRecommendation] =
    useState<ProjectCollaboratorRecommendationDetail>();
  const { user } = useContext(AuthContext);
  const { projectId, recommendationId } = useParams();

  async function fetchRecommendation() {
    setError(undefined);
    try {
      const result = await getProjectCollaboratorRecommendation(
        recommendationId! as string
      );
      console.log('recommendation loaded', result);
      setRecommendation(result);
    } catch (err) {
      console.error('failed to get recommendation', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'recommendationNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  useEffect(() => {
    fetchRecommendation();
  }, [recommendationId!]);

  function onReject(reason: string) {
    setRecommendation({
      ...recommendation!,
      rejectReason: reason,
      state: 'rejected',
    });
  }

  const isOwner =
    recommendation && user && user.id === recommendation.project.user.id;

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={
        user && recommendation && recommendation.recommended.id === user.id
          ? [
              {
                label: commonTranslations.t('dashboard.pathLabels.invite'),
              },
            ]
          : [
              {
                label: commonTranslations.t('dashboard.pathLabels.myProjects'),
                path: myProjectsRoute,
              },
              {
                label: recommendation ? recommendation.project.title : '',
                path: projectRoute(projectId! as string),
              },
              {
                label: commonTranslations.t(
                  'dashboard.pathLabels.collaborators'
                ),
                path: projectCollaboratorsRoute(projectId! as string),
              },
              {
                label: commonTranslations.t(
                  'dashboard.pathLabels.recommendations'
                ),
                path: projectCollaboratorRecommendationsRoute(
                  projectId! as string
                ),
              },
            ]
      }
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!recommendation && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {recommendation && (
        <div className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6  max-xl:grid-cols-1">
          <div className="flex flex-col">
            {recommendation.state === 'waiting' && (
              <Notification color="neutral">{t('waiting')}</Notification>
            )}
            {recommendation.state === 'accepted' && (
              <Notification color="success">{t('accepted')}</Notification>
            )}
            {recommendation.state === 'rejected' && (
              <Notification color="neutral">
                <div>{t('rejected')}</div>
                <div className="whitespace-pre-line mt-3">
                  {recommendation.rejectReason}
                </div>
              </Notification>
            )}

            <DashboardBox className="mb-6">
              <DashboardTitle>{t('details')}</DashboardTitle>
              <DashboardGroup>
                <DashboardGroupLabel>{t('recommender')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <Link
                    to={userProfileRoute(recommendation.recommender.id)}
                    target="_blank"
                    className="text-secondary underline"
                  >
                    <UserName obj={recommendation.recommender} />
                  </Link>
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('state')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <ProjectCollaboratorRecommendationStateEl
                    state={recommendation.state}
                  />
                </DashboardGroupValue>
              </DashboardGroup>

              <DashboardGroup>
                <DashboardGroupLabel>{t('message')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {recommendation.message}
                </DashboardGroupValue>
              </DashboardGroup>
            </DashboardBox>
            <DashboardFiles
              title={t('files')}
              addText={t('addFile')}
              maxSize={+process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILE_SIZE!
              }
              defaultFiles={recommendation.files}
              requestDownloadApi={(path) =>
                requestProjectCollaboratorRecommendationFileDownload(
                  recommendation.id,
                  path
                )
              }
            />
          </div>
          <div className="flex flex-col space-y-4">
            <DashboardUserProfile
              className="max-xl:hidden"
              user={recommendation.recommended}
            />

            {recommendation.state == 'sent' && isOwner && (
              <>
                <Link
                  to={inviteCollaboratorUsingRecommendationRoute(
                    recommendation.project.id,
                    recommendation.id
                  )}
                >
                  <Button className="w-full">{t('invite')}</Button>
                </Link>
                <RejectProjectCollaboratorRecommendation
                  recommendation={recommendation}
                  onReject={onReject}
                />
              </>
            )}
          </div>
        </div>
      )}
    </UserDashboard>
  );
}
