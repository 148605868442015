import { useTranslation } from 'react-i18next';
import UserRoleEl from '../user-role';
import { UserRole } from '../../../interfaces/user.interface';

function UserOrganization(props: {
  obj: { role: UserRole; organization?: string; sme?: boolean };
}) {
  return <>{props.obj.organization || <UserRoleEl role={props.obj.role} />}</>;
}

export default UserOrganization;
