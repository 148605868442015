import clsx from 'clsx';
import { useEffect } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  color: 'danger' | 'success' | 'neutral' | 'blue';
  scrollContainerId?: string;
}

const colors = {
  danger: { bg: 'bg-danger', color: 'text-white' },
  success: { bg: 'bg-success', color: 'text-white' },
  neutral: { bg: 'bg-grey-2', color: '' },
  blue: { bg: 'bg-blue', color: 'text-white' },
};

function Notification(props: Props) {
  let className = clsx(
    `p-4 text-sm rounded mb-5`,
    colors[props.color].bg,
    colors[props.color].color,
    props.className
  );

  useEffect(() => {
    if (props.scrollContainerId) {
      document
        .getElementById(props.scrollContainerId)!
        .scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default Notification;
