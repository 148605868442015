import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventToManage } from '../../../../../api/events';
import EventTicketScanner from '../../../../../components/events/qr-scanner';
import Loading from '../../../../../components/shared/loading';
import AuthGuard from '../../../../../guards/auth-guard';
import { EventToManage } from '../../../../../interfaces/event.interface';
import Notification from '../../../../../components/shared/notification';

export default function ManageEventScanPage() {
  const { t } = useTranslation('scan-event-entries');
  const [error, setError] = useState<string>();
  const [event, setEvent] = useState<EventToManage>();
  const { eventId } = useParams();

  useEffect(() => {
    fetchEvent();
  }, [eventId!]);

  async function fetchEvent() {
    setError(undefined);
    try {
      const result = await getEventToManage(eventId! as string);

      setEvent(result);
    } catch (err) {
      console.error('failed to get event', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'eventNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  return (
    <AuthGuard>
      <div>
        {!event && !error && (
          <div className="w-full h-full">
            <Loading fadeIn />
          </div>
        )}
        {error && (
          <Notification className="m-5" color="danger">
            {error}
          </Notification>
        )}
        {event && <EventTicketScanner title={event.title} />}
      </div>
    </AuthGuard>
  );
}
