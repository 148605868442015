import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { EventParticipants } from '../../../interfaces/event.interface';
import { userProfileRoute } from '../../../utils/routes';
import DashboardBox from '../../core/dashboard-layout/box';
import DashboardTitle from '../../core/dashboard-layout/title';
import UserProfileItemEl from '../../users/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  participants: EventParticipants;
  title: string;
  onPageChange?: (page: number) => void;
  loading?: boolean;
}

function EventParticipantList(props: Props) {
  const els = props.participants.results.map((item) => (
    <Link key={'participant-' + item.id} to={userProfileRoute(item.id)}>
      <UserProfileItemEl item={item} />
    </Link>
  ));

  return (
    <DashboardBox
      className={clsx(
        props.loading ? 'opacity-50 pointer-events-none' : undefined
      )}
    >
      <DashboardTitle>{props.title}</DashboardTitle>
      <div className="grid grid-cols-5 gap-6 max-[1370px]:grid-cols-4 max-[1150px]:grid-cols-3 max-lg:grid-cols-4 max-[870px]:grid-cols-3 max-[680px]:grid-cols-2 max-[540px]:grid-cols-1">
        {els}
      </div>

      {props.participants.pages > 1 && props.onPageChange && (
        <div className="flex justify-center mt-8">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex space-x-4 text-xs text-font-grey"
            activeClassName="text-font-black"
            nextClassName="text-font-black"
            previousClassName="text-font-black"
            nextLabel=">"
            previousLabel="<"
            onPageChange={(item) => props.onPageChange!(item.selected)}
            pageRangeDisplayed={5}
            pageCount={props.participants.pages}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </DashboardBox>
  );
}

export default EventParticipantList;
