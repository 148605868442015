import { AxiosProgressEvent } from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import FileFormAdd from './add';
import FileFormItem, { UploadedFile } from './file';
import { UploadRequestResponse } from '../../../../interfaces/upload-request-response';
import { uploadToS3 } from '../../../../utils/utils';

export interface FilesFormProps extends React.HTMLAttributes<HTMLDivElement> {
  // title: string;
  addText?: string;
  defaultFiles?: UploadedFile[];
  onChangeFormFiles?: (files: (string | undefined)[]) => void;
  editable?: boolean;
  getUploadUrlApi?: (fileName: string) => Promise<UploadRequestResponse>;
  requestDownloadApi?: (path: string) => Promise<string>;
  maxSize?: number;
  maxFiles?: number;
  disabled?: boolean;
}

function FilesForm(props: FilesFormProps) {
  const { t } = useTranslation('files-form');
  const [files, setFiles] = useState<UploadedFile[]>(props.defaultFiles || []);

  useEffect(() => {
    if (props.onChangeFormFiles) {
      onFilesUpdated(props.defaultFiles || []);
    }
  }, [props.defaultFiles]);

  const els = files.map((f, i) => (
    <FileFormItem
      key={f.path}
      file={f}
      onRemove={() => onRemove(i)}
      requestDownloadApi={props.requestDownloadApi}
      editable={props.editable}
    />
  ));

  function onAddFiles(uploadFiles: File[]) {
    console.log('on upload file', files);
    const newFiles: UploadedFile[] = [];

    for (const file of uploadFiles) {
      newFiles.push({
        path: file.name,
        size: file.size,
        file: file,
      });
    }

    const updatedFiles = files.concat(...newFiles);

    const maxFiles =
      props.maxFiles || +process.env.REACT_APP_DEFAULT_MAX_FILES!;

    // limit max files
    if (updatedFiles.length > maxFiles) {
      return toast.error(t('errors.maxFiles', { maxFiles }), {
        duration: 4000,
      });
    }

    onFilesUpdated(updatedFiles);
    startDownload(updatedFiles);
  }

  async function startDownload(filesToUpload: UploadedFile[]) {
    const updatedFiles = [...filesToUpload];

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];

      if (file.file) {
        try {
          const path = await uploadFile(file.file, (progress) => {
            console.log('ppp', progress.loaded, progress.total);
            updatedFiles[i].progress = {
              loaded: progress.loaded,
              total: progress.total!,
            };
            onFilesUpdated([...updatedFiles]);
          });
          updatedFiles[i].path = path;
          updatedFiles[i].file = undefined;
          updatedFiles[i].progress = undefined;
          onFilesUpdated([...updatedFiles]);
        } catch (err) {
          console.log('failed to upload', err);
        }
      }
    }
  }

  async function uploadFile(
    file: File,
    onUploadProgress: (progress: AxiosProgressEvent) => void
  ) {
    const data = await props.getUploadUrlApi!(file.name);
    const url = await uploadToS3(file, data, { onUploadProgress });
    return url;
  }

  function onRemove(index: number) {
    const updatedFiles = files.filter((f, i) => i !== index);

    setFiles(updatedFiles);
    toFormData(updatedFiles);
  }

  function onFilesUpdated(updatedFiles: UploadedFile[]) {
    setFiles(updatedFiles);
    toFormData(updatedFiles);
  }

  function toFormData(items: UploadedFile[]) {
    const result: (string | undefined)[] = [];

    for (const file of items) {
      result.push(file.file ? undefined : file.path);
    }

    props.onChangeFormFiles!(result);
  }

  return (
    <>
      {props.editable && (
        <FileFormAdd
          addText={props.addText || 'Add'}
          onAdd={onAddFiles}
          maxSize={
            props.maxSize || +process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE!
          }
        />
      )}
      {!props.editable && files.length < 1 && (
        <div className="py-3 text-sm">{t('noFiles')}</div>
      )}
      {els}
    </>
  );
}

export default FilesForm;
