import { DashboardData } from '../interfaces/dashboard-data.interface';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import {
  ProjectState,
  GetProjectsResult,
} from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import {
  User,
  GetUserProfileResult,
  GetMembersResult,
  UserRole,
  GetUsersResult,
  UserProfileItem,
} from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const getMe = async (): Promise<User> => {
  const { data } = await myAxios.get('/users/me');

  return data.data;
};

export const getDashboardData = async (): Promise<DashboardData> => {
  const { data } = await myAxios.get('/users/dashboard');

  return data.data;
};

export const getUserProfile = async (
  userId: string
): Promise<GetUserProfileResult> => {
  const { data } = await myAxios.get(`/users/${userId}`);

  return data.data;
};

export const requestUploadForUserImage = async (
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post('/users/request-image-upload', {
    fileName,
  });

  return data.data;
};

export interface EditUserData {
  contactEmail?: string;
  image?: string;
  backgroundImage?: string;
  phoneNumber?: string;
  website?: string;
  description?: string;
  tags: string[];
}

export const editCompanyProfile = async (
  params: EditUserData & { name: string }
): Promise<User> => {
  console.log('submit com', params);
  const { data } = await myAxios.patch('/users/edit/company', params);

  return data.data;
};

export const editIndividualProfile = async (
  params: EditUserData & {
    firstName: string;
    lastName: string;
    organization: string;
  }
): Promise<User> => {
  console.log('submit individual', params);
  const { data } = await myAxios.patch('/users/edit/individual', params);

  return data.data;
};

export const canEndorse = async (
  id: string,
  endorsedTags: string[]
): Promise<User> => {
  const { data } = await myAxios.post(`/users/${id}/can-endorse`, {
    endorsedTags,
  });

  return data.data;
};

export const getEndorseForwardRequest = async (
  id: string,
  endorsedTags: string[]
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(`/users/${id}/endorse-forward-request`, {
    endorsedTags,
  });

  return data.data;
};

export const endorse = async (
  id: string,
  endorsedTags: string[],
  tx?: string,
  signature?: string
): Promise<User> => {
  const { data } = await myAxios.post(`/users/${id}/endorse`, {
    tx,
    signature,
    endorsedTags,
  });

  return data.data;
};

export const requestCacheDownload = async (path: string): Promise<string> => {
  const { data } = await myAxios.get(`/users/request-cache-download`, {
    params: { path },
  });

  return data.data.url;
};

export interface GetMembersFilter {
  search?: string;
  tags?: string[];
}

export const getMembers = async (
  page: number,
  filter: GetMembersFilter
): Promise<GetMembersResult> => {
  const { data } = await myAxios.post('/users/members', { page, ...filter });

  return data.data;
};

export interface GetUsersFilter {
  search?: string;
  whitelisted?: string;
  roles?: UserRole;
}

export const getUsers = async (
  page: number,
  filter: GetUsersFilter
): Promise<GetUsersResult> => {
  const { data } = await myAxios.post('/users', {
    page,
    ...filter,
    roles: filter.roles ? [filter.roles] : undefined,
    whitelisted: filter.whitelisted ? filter.whitelisted === 'true' : undefined,
  });

  return data.data;
};

export const getTransferrableUsers = async (
  search: string
): Promise<UserProfileItem[]> => {
  const { data } = await myAxios.get(`/users/transferrable`, {
    params: { search },
  });

  return data.data;
};

export interface GetParticipatingProjectsOfUserFilter {
  search?: string;
  states?: ProjectState[];
}

export const getParticipatingProjectsOfUser = async (
  userId: string,
  page: number,
  filter: GetParticipatingProjectsOfUserFilter
): Promise<GetProjectsResult> => {
  const { data } = await myAxios.post(
    `/users/${userId}/participating-projects`,
    {
      page,
      ...filter,
    }
  );

  return data.data;
};

export const getTransferForwardRequest = async (
  id: string,
  amount: number
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/users/${id}/get-transfer-forward-request`,
    {
      amount,
    }
  );

  return data.data;
};

export const executeTransferForwardRequest = async (
  id: string,
  amount: number,
  signature: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/users/${id}/execute-transfer-forward-request`,
    {
      amount,
      signature,
    }
  );

  return data.data;
};
