import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import styles from './index.module.css';
import { Tag } from 'react-tag-input';
import {
  EditUserData,
  editCompanyProfile,
  editIndividualProfile,
  requestUploadForUserImage,
} from '../../../api/users';
import { User } from '../../../interfaces/user.interface';
import { phoneNumberRegex, urlRegex } from '../../../utils/utils';
import DashboardBox from '../../core/dashboard-layout/box';
import Button from '../../shared/button';
import FormGroup from '../../shared/form/group';
import FormImageUpload from '../../shared/form/image-upload';
import FormInput from '../../shared/form/input';
import FormLabel from '../../shared/form/label';
import TagsInput from '../../shared/form/tags-input';
import FormTextarea from '../../shared/form/textarea';
import OptimizedImage from '../../shared/image';
import Notification from '../../shared/notification';

// const schema = yup
//   .object()
//   .shape({
//     image: yup.string().nullable(),
//     backgroundImage: yup.string().nullable(),
//     name: yup.string().required(),
//     description: yup.string().nullable(),
//     contactEmail: yup.string().email(),
//     phoneNumber: yup.string().nullable(),
//     website: yup.string().url().nullable(),
//     tags: yup.array().of(
//       yup.object().shape({
//         id: yup.string().required(),
//         name: yup.string().required(),
//       })
//     ),
//   })
//   .required();

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
  onUpdateUser: (user: User) => void;
}

function DashboardUserProfileEdit(props: Props) {
  const { t } = useTranslation('dashboard-profile-edit');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup
        .object()
        .shape({
          image: yup.string().nullable(),
          backgroundImage: yup.string().nullable(),
          name:
            props.user.role === 'company'
              ? yup.string().required()
              : yup.mixed(),
          firstName:
            props.user.role === 'individual'
              ? yup.string().required()
              : yup.mixed(),
          lastName:
            props.user.role === 'individual'
              ? yup.string().required()
              : yup.mixed(),
          organization:
            props.user.role === 'individual'
              ? yup.string().nullable()
              : yup.mixed(),
          description: yup.string().nullable(),
          contactEmail: yup.string().email(),
          phoneNumber: yup
            .string()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .matches(phoneNumberRegex),
          website: yup
            .string()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .matches(urlRegex),
          tags: yup
            .array()
            .of(
              yup.object().shape({
                id: yup.string().required(),
                name: yup.string().required(),
              })
            )
            .min(1)
            .max(5),
        })
        .required()
    ),
  });

  useEffect(() => {
    if (props.user.role === 'company') {
      setValue('name', props.user.name);
    } else {
      setValue('firstName', props.user.firstName);
      setValue('lastName', props.user.lastName);
      setValue('organization', props.user.organization);
    }

    setValue('image', props.user.image);
    setValue('backgroundImage', props.user.backgroundImage);
    setValue('description', props.user.description);
    setValue('contactEmail', props.user.contactEmail || '');
    setValue('website', props.user.website);
    setValue('phoneNumber', props.user.phoneNumber);
    setValue('tags', props.user.tags);
  }, [props.user]);

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      const data: EditUserData = {
        contactEmail:
          values.contactEmail !== '' ? values.contactEmail : undefined,
        image: values.image,
        backgroundImage: values.backgroundImage,
        phoneNumber: values.phoneNumber !== '' ? values.phoneNumber : undefined,
        website: values.website !== '' ? values.website : undefined,
        description: values.description !== '' ? values.description : undefined,
        tags: values.tags.map((t: Tag) => t.id),
      };

      let updatedUser: User;

      if (props.user.role === 'company') {
        updatedUser = await editCompanyProfile({
          ...data,
          name: values.name,
        });
      } else {
        updatedUser = await editIndividualProfile({
          ...data,
          firstName: values.firstName,
          lastName: values.lastName,
          organization: values.organization,
        });
      }

      props.onUpdateUser(updatedUser);

      toast.success(t('saved'), { duration: 3000 });
    } catch (err) {
      console.error('failed to edit profile', err);
      setError(t('errors.save'));
    }
    setLoading(false);
  }

  console.log('err?', errors);

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(props.className, '!p-0'),
      }}
    >
      <Controller
        control={control}
        name="backgroundImage"
        render={({ field: { onChange, value } }) => (
          <FormImageUpload
            aspectRatio={1113 / 216}
            value={value || undefined}
            onChange={onChange}
            onError={(error: any) => {}}
            el={(value?: string, loading?: boolean) => {
              return (
                <div
                  className={clsx(
                    'relative overflow-hidden rounded-t-[10px]',
                    loading ? 'opacity-25' : undefined
                  )}
                >
                  {value && (
                    <OptimizedImage
                      className={styles['background-image']}
                      params={{ w: '1200' }}
                      src={value}
                    />
                  )}
                  {!value && (
                    <div
                      className={clsx(
                        styles['background-image'],
                        styles['default-background']
                      )}
                    />
                  )}
                  <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center bg-black/25 cursor-pointer">
                    <p className="text-white font-semibold text-center">
                      {t('backgroundImage')}
                    </p>
                  </div>
                </div>
              );
            }}
            getUploadUrlApi={() => requestUploadForUserImage('image.png')}
          />
        )}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-8 mt-[-80px] max-[660px]:p-5 max-[660px]:mt-[-65px]"
      >
        <div className="flex items-start">
          <Controller
            control={control}
            name="image"
            render={({ field: { onChange, value } }) => (
              <FormImageUpload
                aspectRatio={1}
                value={value || undefined}
                onChange={onChange}
                onError={(error: any) => {}}
                el={(value?: string, loading?: boolean) => {
                  return (
                    <div
                      className={clsx(
                        'relative',
                        loading ? 'opacity-25' : undefined
                      )}
                    >
                      {value && (
                        <OptimizedImage
                          className={styles['image']}
                          params={{ w: '150', h: '150' }}
                          src={value}
                        />
                      )}
                      {!value && (
                        <div
                          className={clsx(
                            styles['image'],
                            styles['default-image']
                          )}
                        />
                      )}
                      <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center rounded-full bg-black/25 border-2 border-white cursor-pointer">
                        <p className="text-white font-semibold text-center">
                          {t('image')}
                        </p>
                      </div>
                    </div>
                  );
                }}
                getUploadUrlApi={() => requestUploadForUserImage('image.png')}
              />
            )}
          />

          <Button className="ml-auto mt-16 w-[90px]" size="small">
            {t('save')}
          </Button>
        </div>

        <div className="mt-8">
          {error && <Notification color="danger">{error}</Notification>}
          <div className="grid grid-cols-2 gap-28 max-[900px]:grid-cols-1 max-[900px]:gap-0">
            <div className="flex flex-col">
              {props.user.role === 'company' && (
                <FormGroup>
                  <FormLabel>{t('name')}</FormLabel>
                  <FormInput
                    {...register('name')}
                    error={errors.name !== undefined}
                    disabled={loading}
                    maxLength={200}
                  />
                </FormGroup>
              )}
              {props.user.role === 'individual' && (
                <>
                  <FormGroup>
                    <FormLabel>{t('firstName')}</FormLabel>
                    <FormInput
                      {...register('firstName')}
                      error={errors.firstName !== undefined}
                      disabled={loading}
                      maxLength={200}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{t('lastName')}</FormLabel>
                    <FormInput
                      {...register('lastName')}
                      error={errors.lastName !== undefined}
                      disabled={loading}
                      maxLength={200}
                    />
                  </FormGroup>
                </>
              )}
              <FormGroup className="flex flex-col max-[900px]:h-[150px]">
                <FormLabel>{t('description')}</FormLabel>
                <FormTextarea
                  className="flex-1"
                  {...register('description')}
                  error={errors.name !== undefined}
                  disabled={loading}
                  maxLength={500}
                />
              </FormGroup>
            </div>
            <div className="flex-1">
              {props.user.role === 'individual' && (
                <FormGroup>
                  <FormLabel>{t('organization')}</FormLabel>
                  <FormInput
                    {...register('organization')}
                    error={errors.organization !== undefined}
                    disabled={loading}
                    maxLength={200}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <FormLabel>{t('contactEmail')}</FormLabel>
                <FormInput
                  {...register('contactEmail')}
                  type="email"
                  error={errors.contactEmail !== undefined}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('phoneNumber')}</FormLabel>
                <FormInput
                  {...register('phoneNumber')}
                  error={errors.phoneNumber !== undefined}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('website')}</FormLabel>
                <FormInput
                  {...register('website')}
                  error={errors.website !== undefined}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('tags')}</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TagsInput
                      tags={field.value || []}
                      onChange={field.onChange}
                      placeholder=""
                      disabled={loading}
                      error={errors.tags !== undefined}
                    />
                  )}
                  control={control}
                  name="tags"
                />
                {errors.tags && errors.tags.type === 'min' && (
                  <div className="text-sm text-danger">
                    {t('errors.tagsMin')}
                  </div>
                )}
                {errors.tags && errors.tags.type === 'max' && (
                  <div className="text-sm text-danger">
                    {t('errors.tagsMax')}
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
        </div>
      </form>
    </DashboardBox>
  );
}

export default DashboardUserProfileEdit;
