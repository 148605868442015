import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getRegistration,
  requestRegistrationFileDownload,
} from '../../../../api/registrations';
import RegistrationButtons from '../../../../components/admin/registrations/buttons';
import AdminDashboard from '../../../../components/core/dashboard-layout/admin';
import DashboardBox from '../../../../components/core/dashboard-layout/box';
import DashboardFiles from '../../../../components/core/dashboard-layout/files';
import DashboardGroup from '../../../../components/core/dashboard-layout/group';
import DashboardGroupLabel from '../../../../components/core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../../components/core/dashboard-layout/group/value';
import DashboardTitle from '../../../../components/core/dashboard-layout/title';
import Tags from '../../../../components/shared/tags';
import UserName from '../../../../components/shared/user-name';
import { Registration } from '../../../../interfaces/registration.interface';
import { getDashboardRoute } from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';

export default function RegistrationPage() {
  const { t } = useTranslation('registration');
  const [registration, setRegistration] = useState<Registration>();
  const [error, setError] = useState<string>();
  const waitForConfirmTimeout = useRef<any>();
  // const navigate = useNavigate();
  const { registrationId } = useParams();

  useEffect(() => {
    fetchRegistration();
  }, []);

  // poll for transaction confirm
  useEffect(() => {
    if (
      registration &&
      registration.state === 'waitingForAccept' &&
      !waitForConfirmTimeout.current
    ) {
      startWaitingForTransactionConfirmation();
    }
  }, [registration]);

  async function fetchRegistration() {
    setError(undefined);
    try {
      const result = await getRegistration(registrationId as string);
      console.log('reg laoded', result);
      setRegistration(result);

      return result;
    } catch (err) {
      console.error('failed to get registration', err);
      setError(t('errors.load'));
    }
  }

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      const result = await fetchRegistration();

      if (result && result.state === 'applied') {
        setError(t('errors.waitForTransactionFail'));
      } else if (!result || result.state === 'waitingForAccept') {
        startWaitingForTransactionConfirmation();
      }
    }, 5000);
  }

  useEffect(() => {
    return () => {
      console.log('clear timeout');
      clearTimeout(waitForConfirmTimeout.current);
    };
  }, []);

  function onReject(reason: string) {
    setRegistration({
      ...registration,
      state: 'rejected',
      rejectReason: reason,
      rejectedOn: new Date().toISOString(),
    } as any);
  }

  function onAccept(tx: string) {
    setRegistration({
      ...registration,
      state: 'waitingForAccept',
      tx,
    } as any);
  }

  return (
    <AdminDashboard
      title={t('dashboard.title')}
      subtitle={t('dashboard.subtitle')}
      selectedPath={getDashboardRoute('admin')}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {registration && (
        <div className="w-full max-w-[1200px] grid grid-cols-[auto_338px] gap-6 max-xl:grid-cols-1">
          <div>
            <DashboardBox className="mb-6">
              <DashboardTitle>
                <UserName obj={registration} />
              </DashboardTitle>
              <DashboardGroup>
                <DashboardGroupLabel>{t('email')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {registration.contactEmail}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('type')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <span>{t('types.' + registration.type)}</span>
                  {registration.sme && <span className="pl-1">{t('sme')}</span>}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('walletAddress')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {registration.walletAddress}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('phoneNumber')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {registration.phoneNumber || '-'}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('website')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {registration.website || '-'}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('description')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  {registration.description || '-'}
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('tags')}</DashboardGroupLabel>
                {registration.tags.length > 0 && (
                  <Tags className="mt-1" tags={registration.tags} />
                )}
                {registration.tags.length < 1 && (
                  <DashboardGroupValue>-</DashboardGroupValue>
                )}
              </DashboardGroup>
            </DashboardBox>
            {/* <DashboardFiles
              title={t('files')}
              requestDownloadApi={() =>
                requestRegistrationFileDownload(registration.id)
              }
              defaultFiles={[
                {
                  path: registration.file.path,
                  size: registration.file.size,
                },
              ]}
            /> */}
          </div>

          <RegistrationButtons
            registration={registration}
            onAccept={onAccept}
            onReject={onReject}
            error={error}
            setError={setError}
          />
        </div>
      )}
    </AdminDashboard>
  );
}
