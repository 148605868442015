import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import Modal from '../../../../shared/modal';

function EventEntryQrCodeModal(props: {
  entryToken: string;
  onClose: () => void;
}) {
  const { t } = useTranslation('event-entry-list');

  return (
    <Modal className="w-full max-w-[350px]" onClose={props.onClose}>
      {/* <ModalTitle>{t('amountSelectTitle')}</ModalTitle> */}
      <div className="mb-6">{t('qrcodeTitle')}</div>
      <div className="flex justify-center">
        <QRCode value={props.entryToken} />
      </div>
    </Modal>
  );
}

export default EventEntryQrCodeModal;
