import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ProjectMilestoneForm, { ProjectMilestoneFormData } from '../form';
import {
  createProjectMilestone,
  getCreateProjectMilestoneForwardRequest,
  getProjectMilestoneDataHashForCreation,
} from '../../../../api/project-milestones';
import AuthContext from '../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
} from '../../../../interfaces/project-milestones.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import DashboardBox from '../../../core/dashboard-layout/box';
import DashboardTitle from '../../../core/dashboard-layout/title';
import Button from '../../../shared/button';
import Web3Context from '../../../../context/web3-context';
import Notification from '../../../shared/notification';
import { loadEDIHProjectMilestonesContract } from '../../../../utils/contracts/edih-project-milestones';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../utils/utils';

function ProjectMilestoneCreateForm(props: {
  data: GetProjectMilestonesResult;
  onCreate: (milestone: ProjectMilestone) => void;
}) {
  const { t } = useTranslation('project-milestone-form');
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [createdOn, setCreatedOn] = useState<number>();
  const { connect } = useContext(Web3Context);
  const [show, setShow] = useState(false);

  async function onSubmit(formData: ProjectMilestoneFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setSuccess(false);
    setError(undefined);
    try {
      // props.onCreate({
      //   id: '1',
      //   title: formData.title,
      //   description: formData.description,
      //   deadline: formData.deadline,
      //   state: 'waiting',
      // } as any);
      // return;
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchProjectMilestoneDataHash(formData);

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectMilestonesContract =
            await loadEDIHProjectMilestonesContract(signer);
          const result =
            await edihProjectMilestonesContract.createProjectMilestone(
              props.data.project.contractId,
              new Date(formData.deadline),
              hash
            );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchCreateProjectMilestoneForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          const result = await createProjectMilestone(
            props.data.project.id,
            formData,
            tx,
            signature
          );
          props.onCreate({ ...result, updates: [] });
          setCreatedOn(Date.now());
          setSuccess(true);
          setSubmitting(false);
        } catch (err) {
          console.error('failed to create milestone', err);
          setError(t('errors.create.create'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to create milestone', err);
        setError(t('errors.create.create'));
        setSubmitting(false);
      }

      // let hash = await fetchProjectMilestoneDataHash(formData);

      // const signer = await new UncheckedJsonRpcSigner(
      //   getProvider(),
      //   user!.walletAddress
      // );
      // const contract = await loadEDIHProjectMilestonesContract(signer!);

      // const tx = await contract.createProjectMilestone(
      //   props.data.project.contractId,
      //   new Date(formData.deadline),
      //   hash
      // );

      // try {
      //   const result = await createProjectMilestone(
      //     props.data.project.id,
      //     formData,
      //     tx
      //   );
      //   props.onCreate({ ...result, updates: [] });
      //   setCreatedOn(Date.now());
      //   setSuccess(true);
      //   setSubmitting(false);
      // } catch (err) {
      //   console.error('failed to create milestone', err);
      //   setError(t('errors.create.create'));
      //   setSubmitting(false);
      // }
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchProjectMilestoneDataHash(
    formData: ProjectMilestoneFormData
  ) {
    try {
      let hash = await getProjectMilestoneDataHashForCreation(
        props.data.project.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.create.fetchHash'));

      throw err;
    }
  }

  async function fetchCreateProjectMilestoneForwardRequest(
    formData: ProjectMilestoneFormData
  ) {
    try {
      let hash = await getCreateProjectMilestoneForwardRequest(
        props.data.project.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.create.fetchHash'));

      throw err;
    }
  }

  return (
    <div className="max-[1300px]:-order-1 max-[1300px]:flex max-[1300px]:flex-col max-[1300px]:items-end max-[1300px]:mb-4">
      <div className="hidden max-[1300px]:flex">
        <Button size="small" onClick={() => setShow(!show)}>
          {t('create')}
        </Button>
      </div>
      <DashboardBox
        className={clsx(
          'w-[413px] max-[1300px]:hidden max-[1300px]:mt-3 max-[413px]:w-full',
          show ? 'max-[1300px]:!block' : undefined
        )}
      >
        <DashboardTitle>{t('createTitle')}</DashboardTitle>
        {error && <Notification color="danger">{error}</Notification>}
        {success && <Notification color="success">{t('created')}</Notification>}
        <ProjectMilestoneForm
          key={createdOn}
          data={props.data}
          onSubmit={onSubmit}
          disabled={submitting}
        />
      </DashboardBox>
    </div>
  );
}

export default ProjectMilestoneCreateForm;
