import { useTranslation } from 'react-i18next';
import { EventState } from '../../../interfaces/event.interface';

function EventStateEl(props: { state: EventState }) {
  const { t } = useTranslation('event-states');

  return <>{t(props.state)}</>;
}

export default EventStateEl;
