import { useContext } from 'react';
import AuthContext from '../../../../context/auth-context';
import Button from '../../../shared/button';
import ButtonTokensSmallIcon from '../../../shared/button/tokens-small';

function DashboardTokensButton() {
  const { user } = useContext(AuthContext);

  return (
    <Button
      className="mr-2 min-w-[60px] cursor-default max-lg:mr-4"
      color="gradient"
      size="small"
      icon={() => <ButtonTokensSmallIcon className="mr-2" />}
    >
      {user ? user.tokens : ''}
    </Button>
  );
}

export default DashboardTokensButton;
