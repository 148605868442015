import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getProjectCollaboratorResponseFormData,
  createProjectCollaboratorResponse,
  getProjectCollaboratorResponseDataHash,
  getProjectCollaboratorResponseForwardRequest,
} from '../../../../../api/projects-collaborator-responses';
import UserDashboard from '../../../../../components/core/dashboard-layout/user';
import ProjectCollaboratorResponseForm, {
  ProjectCollaboratorResponseFormData,
} from '../../../../../components/projects/collaborators/responses/form';
import Button from '../../../../../components/shared/button';
import Loading from '../../../../../components/shared/loading';
import AuthContext from '../../../../../context/auth-context';
import { ResponseFormProject } from '../../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import {
  myProjectsRoute,
  otherProjectsRoute,
  projectRoute,
} from '../../../../../utils/routes';
import Web3Context from '../../../../../context/web3-context';
import Notification from '../../../../../components/shared/notification';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../../utils/utils';

const expectedErrors = [
  'projectNotFound',
  'projectState',
  'accessDenied',
  'alreadyCollaborator',
  'alreadySentResponse',
];

export default function ApplyAsCollaboratorPage() {
  const { t } = useTranslation('project-collaborator-response-form');
  const [project, setProject] = useState<ResponseFormProject>();
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { projectId } = useParams();
  const { connect } = useContext(Web3Context);
  const commonTranslations = useTranslation('common');

  useEffect(() => {
    onConnect();
  }, []);

  async function onConnect() {
    setError(undefined);
    try {
      setCanRetry(true);

      // get and validate wallet address
      await connect({
        hasToBeAddress: user!.walletAddress,
        noToast: true,
      });

      setCanRetry(false);

      // check if user has permission to invite
      try {
        const result = await getProjectCollaboratorResponseFormData(
          projectId! as string
        );
        setProject(result);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (expectedErrors.includes(err.response!.data.code)) {
            throw t('errors.' + err.response!.data.code);
          }

          throw t('errors.load');
        }
      }
    } catch (err: any) {
      console.error('failed to connect', err);
      setError(err.toString());
    }
  }

  async function onSubmit(formData: ProjectCollaboratorResponseFormData) {
    console.log('submit!', formData);

    setSubmitting(true);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchResponseDataHash(formData);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.sendProjectResponse(
            project!.contractId,
            hash
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchResponseForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await createProjectCollaboratorResponse(
            project!.id,
            formData,
            tx,
            signature
          );
          setSuccess(true);
        } catch (err: any) {
          console.error('failed to send', err);
          setError(t('errors.send'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .sendProjectResponse(user!.walletAddress, project!.contractId, hash)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('sent', tx);
      //     try {
      //       await createProjectCollaboratorResponse(project!.id, formData, tx);
      //       setSuccess(true);
      //     } catch (err: any) {
      //       console.error('failed to send', err);
      //       setError(t('errors.send'));
      //       setSubmitting(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchResponseDataHash(
    formData: ProjectCollaboratorResponseFormData
  ) {
    try {
      const hash = await getProjectCollaboratorResponseDataHash(
        project!.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchResponseForwardRequest(
    formData: ProjectCollaboratorResponseFormData
  ) {
    try {
      let hash = await getProjectCollaboratorResponseForwardRequest(
        project!.id,
        formData
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={otherProjectsRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.otherProjects'),
          path: otherProjectsRoute,
        },
        {
          label: project ? project.title : '',
          path: projectRoute(projectId! as string),
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.apply'),
        },
      ]}
    >
      {error && (
        <Notification color="danger" className="max-w-[400px]">
          <div>{error}</div>
          {canRetry && (
            <div className="mt-2 text-right">
              <Button size="small" color="secondary" onClick={onConnect}>
                {t('reconnect')}
              </Button>
            </div>
          )}
        </Notification>
      )}
      {!project && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {success && <Notification color="success">{t('success')}</Notification>}
      {project && (
        <ProjectCollaboratorResponseForm
          project={project}
          onSubmit={onSubmit}
          disabled={submitting}
        />
      )}
    </UserDashboard>
  );
}
