import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  required?: boolean;
  showRequiredInfo?: boolean;
}

function FormLabel(props: Props) {
  const { t } = useTranslation('common');

  let className = clsx(
    'text-sm',
    props.showRequiredInfo ? 'flex items-end' : undefined,
    props.className
  );

  return (
    <p {...{ ...props, showRequiredInfo: undefined }} className={className}>
      {props.children}
      {props.required && <span className="text-primary">*</span>}
      {props.showRequiredInfo && (
        <span className="ml-auto text-xs text-primary">
          {t('requiredInfo')}
        </span>
      )}
    </p>
  );
}

export default FormLabel;
