import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Range } from 'react-range';
import getCroppedImg from '../../../../../utils/image-croppper';
import Button from '../../../button';
import Modal from '../../../modal';

function ImageCropModal(props: {
  file: any;
  aspectRatio: number;
  onSubmit: (blob: Blob) => void;
  onError: (error: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation('form-image-upload');
  const [image, setImage] = useState(props.file);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setImage(URL.createObjectURL(props.file));
  }, []);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  async function onSubmit() {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      props.onSubmit(croppedImage!);
    } catch (e) {
      console.error('failed to crop image', e);
      props.onError(t('form.imageUpload.crop'));
    }
  }

  return (
    <Modal onClose={props.onClose} className="w-full max-w-[450px]">
      <div className="relative mb-8 h-96">
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={props.aspectRatio}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <Range
        step={0.01}
        min={1}
        max={4}
        values={[zoom]}
        onChange={(values) => setZoom(values[0])}
        renderTrack={({ props, children }) => (
          <div {...props} className="h-1 w-full bg-grey">
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className="w-6 h-6 bg-primary rounded" />
        )}
      />

      <div className="flex justify-between mt-8">
        <Button
          className="font-medium min-w-[160px]"
          size="small"
          color="secondary"
          type="button"
          onClick={props.onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          className="font-medium min-w-[160px]"
          size="small"
          type="button"
          onClick={onSubmit}
        >
          {t('continue')}
        </Button>
      </div>
    </Modal>
  );
}

export default ImageCropModal;
