import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import QrScanner from 'qr-scanner';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onDecode: (result: string) => void;
  onDecodeError: () => void;
  timeBetweenDecodingAttempts?: number;
  enabled: boolean;
}

function QrCodeScanner(props: Props) {
  const { t } = useTranslation('common');
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const [qrOn, setQrOn] = useState(true);
  const lastDecode = useRef(0);
  const enabled = useRef(true);

  useEffect(() => {
    enabled.current = props.enabled;
  }, [props.enabled]);

  function onDecode(result: QrScanner.ScanResult) {
    if (
      enabled.current &&
      Date.now() - lastDecode.current >=
        (props.timeBetweenDecodingAttempts || 300)
    ) {
      props.onDecode(result.data);
      lastDecode.current = Date.now();
    } else {
      console.log('ignore decode');
    }
  }

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onDecode, {
        onDecodeError: props.onDecodeError,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        // overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) alert(t('qrCodeScanner.errors.video'));
  }, [qrOn]);

  return (
    <div {...props}>
      <video className="min-w-full min-h-full object-cover" ref={videoEl} />
    </div>
  );
}

export default QrCodeScanner;
