import { useContext } from 'react';
import AuthContext from '../../../../context/auth-context';
import Button from '../../../shared/button';
import ButtonExpSmallIcon from '../../../shared/button/exp-small';

function DashboardExpButton() {
  const { user } = useContext(AuthContext);

  return (
    <Button
      className="min-w-[60px] mr-2 cursor-default max-lg:mr-4"
      variant="outline"
      color="secondary"
      size="small"
      icon={() => <ButtonExpSmallIcon className="mr-2" />}
    >
      {user ? user.exp : '-'}
    </Button>
  );
}

export default DashboardExpButton;
