import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Web3Context from '../../../../context/web3-context';
import {
  getProjectToEdit,
  updateProject,
  getProjectDataHashForUpdate,
  getProjectUpdate,
  getUpdateProjectForwardRequest,
} from '../../../../api/projects';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import ProjectForm, {
  ProjectFormData,
} from '../../../../components/projects/form';
import Loading from '../../../../components/shared/loading';
import AuthContext from '../../../../context/auth-context';
import { EditProjectResult } from '../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import { myProjectsRoute, projectRoute } from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../utils/utils';

export default function EditProjectPage() {
  const { t } = useTranslation('edit-project');
  const [error, setError] = useState<string>();
  const [project, setProject] = useState<EditProjectResult>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [updateConfirmed, setUpdateConfirmed] = useState<boolean>(false);
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);
  const { connect } = useContext(Web3Context);
  const commonTranslations = useTranslation('common');
  const waitForConfirmTimeout = useRef<any>();

  // poll for transaction confirm
  useEffect(() => {
    if (
      project &&
      project.updates.length > 0 &&
      !waitForConfirmTimeout.current
    ) {
      startWaitingForTransactionConfirmation();
    }
  }, [project]);

  async function fetchProject() {
    setError(undefined);
    try {
      let result = await getProjectToEdit(projectId! as string);

      if (result.updates.length > 0) {
        result = {
          ...result,
          title: result.updates[0].title,
          description: result.updates[0].description,
          files: result.updates[0].files,
          tags: result.updates[0].tags,
          filesPrivate: result.updates[0].filesPrivate,
          service: result.updates[0].service,
        };
      }

      console.log('project loaded', result);
      setProject(result);
      return result;
    } catch (err) {
      console.error('failed to get project', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'projectNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  async function onSubmit(formData: ProjectFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setError(undefined);
    setUpdateConfirmed(false);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchProjectDataHash(formData);

          console.log('proj hash', hash);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.updateProject(
            project!.contractId,
            hash
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchUpdateProjectForwardRequest(
            formData
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await updateProject(project!.id, formData, tx, signature);
          await fetchProject();
        } catch (err: any) {
          console.error('failed to update project', err);
          setError(t('errors.update'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchProjectDataHash(formData: ProjectFormData) {
    try {
      let hash = await getProjectDataHashForUpdate(project!.id, formData);
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchUpdateProjectForwardRequest(formData: ProjectFormData) {
    try {
      let hash = await getUpdateProjectForwardRequest(project!.id, formData);
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  useEffect(() => {
    fetchProject();
  }, [projectId!]);

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      try {
        const result = await getProjectUpdate(project!.updates[0].id);

        if (result.state === 'updated') {
          await fetchProject();
          setUpdateConfirmed(true);
          setSubmitting(false);
          waitForConfirmTimeout.current = undefined;
        } else {
          startWaitingForTransactionConfirmation();
        }
      } catch (err: any) {
        if (err.response && err.response.status === 404) {
          await fetchProject();
          setError(t('errors.waitForTransactionFail'));
          setSubmitting(false);
          waitForConfirmTimeout.current = undefined;
        } else {
          startWaitingForTransactionConfirmation();
        }
      }
    }, 5000);
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myProjects'),
          path: myProjectsRoute,
        },
        {
          label: project ? project.title : '',
          path: project ? projectRoute(project.id) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.edit'),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!project && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {project && (
        <>
          {project.updates.length > 0 && (
            <Notification color="neutral">{t('pendingUpdate')}</Notification>
          )}
          {updateConfirmed && (
            <Notification color="success">{t('updateConfirmed')}</Notification>
          )}
          <ProjectForm
            project={project}
            onSubmit={onSubmit}
            disabled={submitting || project.updates.length > 0}
          />
        </>
      )}
    </UserDashboard>
  );
}
