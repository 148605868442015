import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SignupUserTypeItemEl, { SignupUserTypeItem } from './item';
import { SignupData, SignupDataType } from '../../../../pages/signup';
import Button from '../../../shared/button';

function SignupUserType(props: {
  signupData: SignupData;
  onSubmit: (data: SignupData) => void;
  onBack: () => void;
}) {
  const { t } = useTranslation('signup');
  const [selected, setSelected] = useState<SignupDataType>(
    props.signupData.type!
  );

  const items = (
    t('userType.items', { returnObjects: true }) as SignupUserTypeItem[]
  ).map((item) => {
    return (
      <SignupUserTypeItemEl
        item={item}
        key={item.type}
        active={selected === item.type}
        onClick={() => setSelected(item.type)}
      />
    );
  });

  function onSubmit() {
    props.onSubmit({
      ...props.signupData,
      type: selected,
    });
  }

  return (
    <div>
      <h1 className="text-[2.5rem] font-medium text-center">
        {t('userType.title')}
      </h1>
      <p className="text-sm text-center text-font-grey mb-8">
        {t('userType.subtitle')}
      </p>

      <div className="flex space-x-4 mb-16">{items}</div>

      <div className="flex flex-col">
        <Button className="mt-4" onClick={onSubmit}>
          {t('userType.proceed')}
        </Button>
        <Button variant="underline" onClick={props.onBack}>
          {t('back')}
        </Button>
      </div>
    </div>
  );
}

export default SignupUserType;
