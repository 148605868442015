import clsx from 'clsx';
import React from 'react';
import styles from './index.module.css';

export interface FormRadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const FormRadio = React.forwardRef(
  (
    props: {
      children: any;
      radioProps?: FormRadioProps;
      className?: string;
      error?: boolean;
    },
    ref?: any
  ) => {
    return (
      <label className={clsx(styles.label, props.className)}>
        <input
          type="radio"
          className={styles.radio}
          {...{ ...(props.radioProps || {}) }}
          ref={ref}
        />
        <span></span>
        <span>{props.children}</span>
      </label>
    );
  }
);

FormRadio.displayName = 'FormRadio';

export default FormRadio;
