import axios, { AxiosProgressEvent } from 'axios';
import { UploadRequestResponse } from '../interfaces/upload-request-response';

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const uploadToS3 = async (
  blob: any,
  signedPostData: UploadRequestResponse,
  options: { onUploadProgress?: (progress: AxiosProgressEvent) => void } = {}
): Promise<string> => {
  const formData = new FormData();

  for (const field in signedPostData.fields) {
    formData.append(field, signedPostData.fields[field]);
  }

  formData.append('file', blob);

  await axios.post(signedPostData.url, formData, {
    onUploadProgress: options.onUploadProgress,
  });

  return signedPostData.fields.key;
};

export const getDisplayName = (obj: {
  name?: string;
  firstName?: string;
  lastName?: string;
}) => {
  return obj.name || `${obj.firstName} ${obj.lastName}`;
};

export const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const phoneNumberRegex =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

export const formatUrl = (url: string) =>
  !url.startsWith('http://') && !url.startsWith('https://')
    ? `http://${url}`
    : url;
