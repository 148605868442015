import clsx from 'clsx';
import FilesForm, { FilesFormProps } from '../../../../shared/form/files';

function ModalFiles(props: FilesFormProps) {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 gap-2',
        props.disabled ? 'opacity-50 pointer-events-none' : undefined
      )}
    >
      <FilesForm {...props} />
    </div>
  );
}

export default ModalFiles;
