import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../context/auth-context';
import { otherProjectsRoute } from '../../../../utils/routes';
import Button from '../../../shared/button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function DashboardDiscoverProjects(props: Props) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');

  return (
    <div className="flex h-full flex-col space-y-3">
      <Link to={otherProjectsRoute}>
        <DashboardBox
          {...{
            ...props,
            className: clsx(props.className, '!p-4 flex flex-col items-center'),
          }}
        >
          <DashboardTitle className="mb-2">
            {t('discover.title')}
          </DashboardTitle>
          <img
            width={143}
            height={104}
            src="/images/dashboard/discover-projects.svg"
            alt="discover"
          />
        </DashboardBox>
      </Link>
      <Link to={otherProjectsRoute}>
        <Button className="w-full">{t('discover.explore')}</Button>
      </Link>
    </div>
  );
}

export default DashboardDiscoverProjects;
