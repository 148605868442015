import { useTranslation } from 'react-i18next';
import React from 'react';
import DashboardLayout, { DashboardItem } from '..';
import DashboardRegistrationIcon from '../svgs/registrations';
import DashboardUsersIcon from '../svgs/users';
import AuthGuard from '../../../../guards/auth-guard';
import { getDashboardRoute, adminUsersRoute } from '../../../../utils/routes';

interface Props {
  title: string;
  subtitle: string;
  selectedPath: string;
  children: React.ReactNode;
}

function AdminDashboard(props: Props) {
  const { t } = useTranslation('common');

  const items: DashboardItem[] = [
    {
      icon: (className: string) => (
        <DashboardRegistrationIcon className={className} />
      ),
      label: t('dashboard.items.registrations'),
      path: getDashboardRoute('admin'),
    },
    {
      icon: (className: string) => <DashboardUsersIcon className={className} />,
      label: t('dashboard.items.users'),
      path: adminUsersRoute,
    },
  ];

  return (
    <AuthGuard adminOnly>
      <DashboardLayout
        title={props.title}
        subtitle={props.subtitle}
        items={items}
        selectedPath={props.selectedPath}
      >
        {props.children}
      </DashboardLayout>
    </AuthGuard>
  );
}

export default AdminDashboard;
