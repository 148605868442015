import { PaginationResult } from './pagination-result.interface';
import { Tag } from './tag.interface';

export type RegistrationState =
  | 'applied'
  | 'accepted'
  | 'waitingForAccept'
  | 'rejected';
export type RegistrationType = 'company' | 'individual';

export const registrationStates: RegistrationState[] = [
  'applied',
  'accepted',
  'waitingForAccept',
  'rejected',
];

export const registrationTypes: RegistrationType[] = ['company', 'individual'];

export interface RegistrationItem {
  id: string;
  type: RegistrationType;
  state: RegistrationState;
  name?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  createdAt: string;
}

export interface GetRegistrationsResult
  extends PaginationResult<RegistrationItem> {}

export interface Registration extends RegistrationItem {
  contactEmail: string;
  phoneNumber?: string;
  website?: string;
  description: string;
  file: { path: string; size: number };
  walletAddress: string;
  tags: Tag[];
  rejectReason?: string;
  rejectedOn?: string;
  sme: boolean;
  tx?: string;
}
