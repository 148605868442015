import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventItem } from '../../../../interfaces/event.interface';
import { formatShortDate } from '../../../../utils/date';
import { eventRoute } from '../../../../utils/routes';
import Button from '../../../shared/button';
import ButtonTokensSmallIcon from '../../../shared/button/tokens-small';
import OptimizedImage from '../../../shared/image';
import Tags from '../../../shared/tags';
import UserName from '../../../shared/user-name';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: EventItem;
}

function EventItemEl(props: Props) {
  const { t } = useTranslation('event-states');
  const navigate = useNavigate();

  function onClick() {
    navigate(eventRoute(props.item.id));
  }

  const isCompleted =
    props.item.state === 'waitingForRating' ||
    props.item.state === 'rating' ||
    props.item.state === 'waitingForRatingDeadline' ||
    props.item.state === 'completed';

  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(
          'flex flex-col min-h-[220px] p-3 border border-grey-2 rounded-lg mb-2 relative cursor-pointer',
          props.className
        ),
      }}
      onClick={onClick}
    >
      <OptimizedImage
        className="w-full overflow-hidden rounded aspect-[164_93] mb-3"
        params={{ w: '300', ar: '164:93', fit: 'crop' }}
        src={props.item.image}
      />

      <Button
        className="absolute top-4 right-4 !px-2 !py-0.5 !text-[0.625rem] !pointer-events-none !cursor-auto shadow-lg"
        color="gradient"
        size="small"
        icon={() => (
          <ButtonTokensSmallIcon className="w-[10px] h-[10px] mr-1" />
        )}
      >
        {props.item.price}
      </Button>

      <div className="font-medium text-sm">{props.item.title}</div>
      <div className="mb-auto pt-3 pb-5">
        <Tags tags={props.item.tags} small />
      </div>

      {/* <Link href={userProfileRoute(props.item.event.user.id)}>
      </Link> */}
      <div className="flex">
        <div className="mr-1">
          {props.item.user.image && (
            <OptimizedImage
              className="w-[28px] h-[28px] rounded-full"
              params={{ w: '40', h: '40' }}
              src={props.item.user.image}
            />
          )}
          {!props.item.user.image && (
            <div className="w-[28px] h-[28px] rounded-full bg-grey-2" />
          )}
        </div>

        <div className="text-[0.625rem]">
          <div className="mb-[-2px]">
            <span className="text-font-grey mr-1">by</span>
            <span>
              <UserName obj={props.item.user} />
            </span>
          </div>
          <div className="text-font-grey">
            {formatShortDate(props.item.startDate)}
          </div>
        </div>
      </div>

      {isCompleted && (
        <div className="absolute top-4 left-4 px-2 py-0.5 text-[0.625rem] bg-blue rounded-full shadow-lg">
          {t('completed')}
        </div>
      )}
    </div>
  );
}

export default EventItemEl;
