export interface EventDetailIconItem {
  image: string;
  text: string;
}

export default function EventDetailIconItems(props: {
  items: EventDetailIconItem[];
}) {
  const items = props.items.map((item) => {
    return (
      <div
        key={item.text}
        className="flex items-center space-x-1.5 text-font-grey text-xs mr-3"
      >
        <img src={item.image} alt={item.text} width="14" height="14" />
        <span>{item.text}</span>
      </div>
    );
  });

  return <div className="flex flex-wrap">{items}</div>;
}
