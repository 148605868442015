import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import Web3Context from '../../../context/web3-context';
import {
  claimProjectReward,
  canClaimProjectReward,
  getClaimProjectRewardForwardRequest,
} from '../../../api/projects';
import AuthContext from '../../../context/auth-context';
import { ProjectDetail } from '../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../utils/contracts/edih-projects';
import Button from '../../shared/button';
import Notification from '../../shared/notification';
import { UncheckedJsonRpcSigner } from '../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../utils/web3';
import { sleep } from '../../../utils/utils';

export default function ProjectDetailClaimReward(props: {
  project: ProjectDetail;
  loading: boolean;
  onClaim: (tx: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error?: string) => void;
}) {
  const { t } = useTranslation('project-detail');
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  async function onClaim() {
    props.setLoading(true);
    props.setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanClaim();

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.claimProjectReward(
            props.project.contractId
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchClaimProjectRewardForwardRequest();
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await claimProjectReward(props.project.id, tx, signature);
          props.onClaim(tx!);
        } catch (err: any) {
          console.error('failed to claim', err);
          props.setError(t('claimReward.errors.claim'));
        }
        props.setLoading(false);
      } catch (err) {
        console.error('failed to send transaction', err);
        props.setError(t('claimReward.errors.transaction'));
        props.setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .claimProjectReward(user!.walletAddress, props.project.contractId)
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('claimed', tx);
      //     try {
      //       await claimProjectReward(props.project.id, tx);
      //       props.onClaim(tx);
      //     } catch (err: any) {
      //       console.error('failed to claim', err);
      //       props.setError(t('claimReward.errors.claim'));
      //     }
      //     props.setLoading(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     props.setError(t('claimReward.errors.transaction'));
      //     props.setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      props.setLoading(false);
    }
  }

  async function checkCanClaim() {
    try {
      await canClaimProjectReward(props.project.id);
    } catch (err) {
      props.setError(t('claimReward.errors.canClaim'));

      throw err;
    }
  }

  async function fetchClaimProjectRewardForwardRequest() {
    try {
      return getClaimProjectRewardForwardRequest(props.project.id);
    } catch (err) {
      props.setError(t('claimReward.errors.canClaim'));

      throw err;
    }
  }

  return (
    <>
      {!props.project.rewardClaim && (
        <Notification color="neutral">
          <div className="mb-2">{t('claimReward.msg')}</div>
          <Button
            size="small"
            color="secondary"
            onClick={onClaim}
            disabled={props.loading}
          >
            {t('claimReward.button')}
          </Button>
        </Notification>
      )}

      {props.project.rewardClaim &&
        props.project.rewardClaim.state === 'waiting' && (
          <Notification color="neutral">{t('claimingRewardMsg')}</Notification>
        )}

      {props.project.rewardClaim &&
        props.project.rewardClaim.state === 'claimed' && (
          <Notification color="success">
            {parse(
              t('rewardClaimedMsg', {
                reward: props.project.rewardClaim.reward,
                rating: props.project.rewardClaim.rating,
              })
            )}
          </Notification>
        )}
    </>
  );
}
