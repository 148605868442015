import clsx from 'clsx';
import DashboardBox from '../box';
import DashboardUserProfileTags from './hashtags';
import styles from './index.module.css';
import DashboardUserProfileInfos from './info';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../interfaces/user.interface';
import { userProfileRoute } from '../../../../utils/routes';
import OptimizedImage from '../../../shared/image';
import UserName from '../../../shared/user-name';
import UserOrganization from '../../../shared/user-organization';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
}

function DashboardUserProfile(props: Props) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  function onClickUser() {
    navigate(userProfileRoute(props.user.id));
  }

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(props.className, '!p-0 overflow-hidden'),
      }}
    >
      {props.user.backgroundImage && (
        <OptimizedImage
          onClick={onClickUser}
          params={{ ar: '338:100', fit: 'crop', w: '500' }}
          className={styles['background-image']}
          src={props.user.backgroundImage}
        />
      )}
      {!props.user.backgroundImage && (
        <div
          onClick={onClickUser}
          className={clsx(
            styles['background-image'],
            styles['default-background']
          )}
        />
      )}

      <div className="p-4 mt-[-40px]">
        <div
          className="flex border-b border-[#e0e0e0] pb-3 space-x-3 mb-3 cursor-pointer"
          onClick={onClickUser}
        >
          {props.user.image && (
            <OptimizedImage
              params={{ w: '90', h: '90' }}
              className={styles['image']}
              src={props.user.image}
            />
          )}
          {!props.user.image && (
            <div className={clsx(styles['image'], styles['default-image'])} />
          )}

          <div className="mt-8">
            <p className="text-xl font-medium">
              <UserName obj={props.user} />
            </p>
            <p className="text-sm text-font-grey">
              <span>
                <UserOrganization obj={props.user} />
              </span>
              {props.user.sme && <span className="pl-1">{t('sme')}</span>}

              {/* {props.user.organization && (
                <span>{props.user.organization}</span>
              )}
              {!props.user.organization && <UserRole role={props.user.role} />} */}
              {/* <UserRole role={props.user.role} /> */}
            </p>
          </div>
        </div>
        <div className="mb-4">
          <DashboardUserProfileInfos user={props.user} />
        </div>
        <div>
          <DashboardUserProfileTags tags={props.user.tags!} />
        </div>
      </div>
    </DashboardBox>
  );
}

export default DashboardUserProfile;
