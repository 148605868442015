import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { uploadToS3 } from '../../../../utils/utils';
import ImageCropModal from './crop-modal';
import { UploadRequestResponse } from '../../../../interfaces/upload-request-response';

function FormImageUpload(props: {
  aspectRatio: number;
  onChange: (value?: string) => void;
  value?: string;
  onError: (error: any) => void;
  el: (value?: string, loading?: boolean) => any;
  getUploadUrlApi: (file: File) => Promise<UploadRequestResponse>;
  disabled?: boolean;
}) {
  const { t } = useTranslation('common');

  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  function onUpload(file: File) {
    console.log('u', file);
    setFile(file);
  }

  async function onSubmit(blob: Blob) {
    setFile(undefined);
    setLoading(true);
    setError(undefined);
    try {
      const data = await props.getUploadUrlApi(file!);
      const url = await uploadToS3(blob, data);
      props.onChange(url);
    } catch (err: any) {
      console.log('failed to upload image', err);
      props.onError(err);
    }
    setLoading(false);
  }

  return (
    <>
      <FileUploader
        handleChange={onUpload}
        types={['jpeg', 'jpg', 'png']}
        disabled={props.disabled}
      >
        {props.el(props.value, loading)}
      </FileUploader>
      {file && (
        <ImageCropModal
          file={file}
          aspectRatio={props.aspectRatio}
          onSubmit={onSubmit}
          onError={(e) => setError(e)}
          onClose={() => setFile(undefined)}
        />
      )}
    </>
  );
}

export default FormImageUpload;
