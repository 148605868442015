import clsx from 'clsx';
import { ProjectCollaboratorInviteState } from '../../../../../../interfaces/project-collaborator-invite.interface';
import ProjectCollaboratorInviteStateEl from '../../../../../shared/project-collaborator-invite-state';

interface Props {
  state: ProjectCollaboratorInviteState;
}

const stateColors: { [key in ProjectCollaboratorInviteState]: string } = {
  waiting: 'text-black bg-grey-2',
  sent: 'text-black bg-grey-2',
  accepting: 'text-black bg-grey-2',
  accepted: 'text-white bg-success',
  rejecting: 'text-black bg-grey-2',
  rejected: 'text-white bg-danger',
};

function ProjectCollaboratorInviteItemElState(props: Props) {
  return (
    <p
      className={clsx(
        'absolute top-2 right-2 text-[0.5rem] px-2 py-1 rounded-full',
        stateColors[props.state]
      )}
    >
      <ProjectCollaboratorInviteStateEl state={props.state} />
    </p>
  );
}

export default ProjectCollaboratorInviteItemElState;
