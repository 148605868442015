import { useTranslation } from 'react-i18next';
import { ProjectCollaboratorRecommendationState } from '../../../interfaces/project-collaborator-recommendation.interface';

function ProjectCollaboratorRecommendationStateEl(props: {
  state: ProjectCollaboratorRecommendationState;
}) {
  const { t } = useTranslation('project-collaborator-recommendation-states');

  return <>{t(props.state)}</>;
}

export default ProjectCollaboratorRecommendationStateEl;
