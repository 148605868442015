import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Web3Context from '../../../../context/web3-context';
import {
  getEventToEdit,
  updateEvent,
  getEventDataHashForUpdate,
  getEventUpdate,
  getUpdateEventForwardRequest,
} from '../../../../api/events';
import UserDashboard from '../../../../components/core/dashboard-layout/user';
import EventForm, { EventFormData } from '../../../../components/events/form';
import Loading from '../../../../components/shared/loading';
import AuthContext from '../../../../context/auth-context';
import { EditEventResult } from '../../../../interfaces/event.interface';
import { loadEDIHEventsContract } from '../../../../utils/contracts/edih-events';
import { myEventsRoute, eventRoute } from '../../../../utils/routes';
import Notification from '../../../../components/shared/notification';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../utils/web3';
import { sleep } from '../../../../utils/utils';

export default function EditEventPage() {
  const { t } = useTranslation('edit-event');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [event, setEvent] = useState<EditEventResult>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [updateConfirmed, setUpdateConfirmed] = useState<boolean>(false);
  const { eventId } = useParams();
  const { user } = useContext(AuthContext);
  const { connect } = useContext(Web3Context);
  const waitForConfirmTimeout = useRef<any>();

  // poll for transaction confirm
  useEffect(() => {
    if (event && event.updates.length > 0 && !waitForConfirmTimeout.current) {
      startWaitingForTransactionConfirmation();
    }
  }, [event]);

  async function fetchEvent() {
    setError(undefined);
    try {
      let result = await getEventToEdit(eventId! as string);

      if (result.updates.length > 0) {
        result = {
          ...result,
          title: result.updates[0].title,
          description: result.updates[0].description,
          price: result.updates[0].price,
          deadline: new Date(result.updates[0].deadline),
          supply: result.updates[0].supply,
          startDate: new Date(result.updates[0].startDate),
          image: result.updates[0].image,
          location: result.updates[0].location,
          tags: result.updates[0].tags,
        };
      }

      console.log('event loaded', result);
      setEvent(result);
    } catch (err) {
      console.error('failed to get event', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (err.response!.data.code === 'eventNotFound') {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  async function onSubmit(formData: EventFormData) {
    console.log('submit!', formData);
    setSubmitting(true);
    setUpdateConfirmed(false);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });
      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          let hash = await fetchEventDataHash(formData);

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.updateEvent(
            event!.contractId,
            formData.price,
            formData.deadline,
            formData.supply,
            hash
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchUpdateEventForwardRequest(formData);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await updateEvent(event!.id, formData, tx, signature);
          await fetchEvent();
        } catch (err: any) {
          console.error('failed to update project', err);
          setError(t('errors.update'));
          setSubmitting(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('errors.transaction'));
        setSubmitting(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .updateEvent(
      //     user!.walletAddress,
      //     event!.contractId,
      //     formData.price,
      //     formData.deadline,
      //     formData.supply,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('updated', tx);
      //     try {
      //       await updateEvent(event!.id, formData, tx);
      //       await fetchEvent();
      //     } catch (err: any) {
      //       console.error('failed to update project', err);
      //       setError(t('errors.update'));
      //       setSubmitting(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('errors.transaction'));
      //     setSubmitting(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setSubmitting(false);
    }
  }

  async function fetchEventDataHash(formData: EventFormData) {
    try {
      let hash = await getEventDataHashForUpdate(event!.id, formData);
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchUpdateEventForwardRequest(formData: EventFormData) {
    try {
      return getUpdateEventForwardRequest(event!.id, formData);
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  useEffect(() => {
    fetchEvent();
  }, [eventId!]);

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      try {
        const result = await getEventUpdate(event!.updates[0].id);

        if (result.state === 'updated') {
          await fetchEvent();
          setUpdateConfirmed(true);
          setSubmitting(false);
          waitForConfirmTimeout.current = undefined;
        } else {
          startWaitingForTransactionConfirmation();
        }
      } catch (err: any) {
        if (err.response && err.response.status === 404) {
          await fetchEvent();
          setError(t('errors.waitForTransactionFail'));
          setSubmitting(false);
          waitForConfirmTimeout.current = undefined;
        } else {
          startWaitingForTransactionConfirmation();
        }
      }
    }, 5000);
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myEvents'),
          path: myEventsRoute,
        },
        {
          label: event ? event.title : '',
          path: event ? eventRoute(event.id) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.edit'),
        },
      ]}
      selectedPath={myEventsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!event && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {event && (
        <>
          {event.updates.length > 0 && (
            <Notification color="success">{t('pendingUpdate')}</Notification>
          )}
          {updateConfirmed && (
            <Notification color="success">{t('updateConfirmed')}</Notification>
          )}
          <EventForm
            event={event}
            onSubmit={onSubmit}
            disabled={submitting || event.updates.length > 0}
          />
        </>
      )}
    </UserDashboard>
  );
}
