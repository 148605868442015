import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardRegistrationIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.594 3.094H4.813a1.719 1.719 0 0 0-1.72 1.719v3.78a1.719 1.719 0 0 0 1.72 1.72h3.78a1.719 1.719 0 0 0 1.72-1.72v-3.78a1.719 1.719 0 0 0-1.72-1.72ZM8.25 8.25H5.156V5.156H8.25V8.25Zm8.938-5.156h-3.782a1.719 1.719 0 0 0-1.719 1.719v3.78a1.719 1.719 0 0 0 1.72 1.72h3.78a1.719 1.719 0 0 0 1.72-1.72v-3.78a1.718 1.718 0 0 0-1.72-1.72Zm-.344 5.156H13.75V5.156h3.094V8.25Zm-8.25 3.438H4.813a1.719 1.719 0 0 0-1.72 1.718v3.781a1.719 1.719 0 0 0 1.72 1.72h3.78a1.718 1.718 0 0 0 1.72-1.72v-3.78a1.719 1.719 0 0 0-1.72-1.72Zm-.344 5.156H5.156V13.75H8.25v3.094Zm8.938-5.157h-3.782a1.719 1.719 0 0 0-1.719 1.72v3.78a1.719 1.719 0 0 0 1.72 1.72h3.78a1.718 1.718 0 0 0 1.72-1.72v-3.78a1.718 1.718 0 0 0-1.72-1.72Zm-.344 5.157H13.75V13.75h3.094v3.094Z"
    />
  </svg>
);
export default DashboardRegistrationIcon;
