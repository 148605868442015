import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { addEventEntryScanner } from '../../../../../api/events-entry-scanners';
import { EventEntryScannerItem } from '../../../../../interfaces/event-entry-scanner.interface';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import FormInput from '../../../../shared/form/input';
import Notification from '../../../../shared/notification';

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required();

function EventDetailAddEntryScanner(props: {
  eventId: string;
  onAdd: (item: EventEntryScannerItem) => void;
}) {
  const { t } = useTranslation('manage-event');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onAdd(fields: any) {
    setLoading(true);
    setError(undefined);
    try {
      props.onAdd(await addEventEntryScanner(props.eventId, fields.email));
      reset();
    } catch (err) {
      console.error('failed to add event entry scanner', err);

      if (!axios.isAxiosError(err)) {
        setError(t('manageScanners.errors.add'));
      } else if (err.response!.data.code === 'emailTaken') {
        setError(t('manageScanners.errors.emailTaken'));
      } else if (err.response!.data.code === 'ownEmail') {
        setError(t('manageScanners.errors.ownEmail'));
      } else if (err.response!.data.code === 'eventState') {
        setError(t('manageScanners.errors.eventState'));
      } else {
        setError(t('manageScanners.errors.add'));
      }
    }
    setLoading(false);
  }

  return (
    <>
      {error && <Notification color="danger">{error}</Notification>}
      <form className="mb-10" onSubmit={handleSubmit(onAdd)}>
        <FormGroup>
          <div className="flex space-x-4">
            <FormInput
              className="flex-1"
              type="email"
              {...register('email')}
              placeholder={t('manageScanners.email')}
              error={errors.email !== undefined}
            />
            <Button disabled={loading}>{t('manageScanners.add')}</Button>
          </div>
        </FormGroup>
      </form>
    </>
  );
}

export default EventDetailAddEntryScanner;
