import clsx from 'clsx';
import parse from 'html-react-parser';
import styles from './index.module.css';

export interface AccordionItem {
  id: string;
  title: string;
  text: string;
  expanded?: boolean;
}

function AccordionItemEl(props: { item: AccordionItem; onToggle: () => void }) {
  return (
    <div
      id={props.item.id}
      className={clsx(
        'px-7 py-5 bg-white rounded-[10px] border',
        props.item.expanded ? 'border-secondary bg-[#fafafa]' : 'border-white'
      )}
    >
      <div
        className="flex items-center cursor-pointer"
        onClick={props.onToggle}
      >
        <div className="mr-auto">{parse(props.item.title)}</div>
        <img
          src={
            props.item.expanded
              ? '/images/dashboard/collapse.svg'
              : '/images/dashboard/expand.svg'
          }
          alt="control"
        />
      </div>
      {props.item.expanded && (
        <div className={clsx(styles.container)}>{parse(props.item.text)}</div>
      )}
    </div>
  );
}

export default AccordionItemEl;
