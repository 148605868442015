import { useContext, useEffect, useState } from 'react';
import WebPSupportContext from '../../../context/webp-support-context';

export interface ImageOptimizationParams {
  w?: string;
  h?: string;
  ar?: string;
  q?: string;
  fit?: string;
  crop?: string;
}

export type ImageBreakpointsMapping = {
  [key: number]: ImageOptimizationParams;
};

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  params?: ImageOptimizationParams;
  breakpoints?: ImageBreakpointsMapping;
}

function getImageSize(breakpoints: ImageBreakpointsMapping) {
  const keys = Object.keys(breakpoints).map((k) => +k);
  keys.sort((a, b) => b - a);

  for (const key of keys) {
    if (key < window.innerWidth) {
      return breakpoints[key];
    }
  }

  return breakpoints[keys[keys.length - 1]];
}

function OptimizedImage(props: Props) {
  const { supportsWebP } = useContext(WebPSupportContext);
  const [src, setSrc] = useState(getSrc());

  useEffect(() => {
    setSrc(getSrc());
  }, [props.src]);

  function getSrc() {
    let params = { ...props.params } || {};

    // get params of current breakpoint if set
    if (props.breakpoints) {
      const imageSize = getImageSize(props.breakpoints);
      params = { ...params, ...imageSize };
    }

    // upscale width depending on device pixel ratio
    if (params.w) {
      params.w = Math.ceil(+params.w * window.devicePixelRatio).toString();
    }

    // upscale height depending on device pixel ratio
    if (params.h) {
      params.h = Math.ceil(+params.h * window.devicePixelRatio).toString();
    }

    let query = new URLSearchParams({
      fm: supportsWebP ? 'webp' : 'png',
      // fm: supportsWebP ? 'webp' : 'png',
      ...params,
    }).toString();

    return `${process.env.REACT_APP_IMAGE_OPTIMIZATION_URL}/${props.src}?${query}`;
  }

  return (
    <img
      key={src}
      {...{ ...props, breakpoints: undefined, params: undefined }}
      src={src}
    />
  );
}

export default OptimizedImage;
