import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../button';
import Loading from '../../loading';
import IconInputWrapper from '../icon-input-wrapper';
import FormInput from '../input';
import SelectUserInputItem from './item';
import SearchIcon from './search.svg';
import { UserProfileItem } from '../../../../interfaces/user.interface';
import { userProfileRoute } from '../../../../utils/routes';
import UserProfileItemEl from '../../../users/item';

interface Props {
  placeholder?: string;
  noResultsText: string;
  errorText: string;
  changeText: string;
  error?: boolean;
  disabled?: boolean;
  searchApi: (search: string) => Promise<UserProfileItem[]>;
  onSelect: (item: UserProfileItem) => void;
  listMode?: boolean;
}

function SelectUserInput(props: Props) {
  const { t } = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<UserProfileItem>();
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState<
    UserProfileItem[] | undefined
  >();
  const timeoutRef = useRef<any>();
  const inputRef = useRef<any>();

  function onInput(event: any) {
    clearTimeout(timeoutRef.current);
    setError(undefined);
    setLoading(true);

    if (event.target.value === '') {
      setLoading(false);
      setSearchResults(undefined);
      return;
    }

    console.log('timeout search', event.target.value);

    timeoutRef.current = setTimeout(() => {
      search(event.target.value);
    }, +process.env.REACT_APP_SEARCH_TIMEOUT!);
  }

  async function search(search: string) {
    setError(undefined);
    console.log('start searching', search);

    try {
      const results = await props.searchApi(search);
      setSearchResults(results);
      setShowResults(true);

      console.log('found', results);
    } catch (err) {
      console.error('failed to search', err);
      setSearchResults(undefined);
      setError(props.errorText);
    }

    setLoading(false);
  }

  function onBlur() {
    setTimeout(() => {
      setShowResults(false);
    }, 250);
  }

  function onFocus() {
    setShowResults(true);
  }

  function onSelect(item: UserProfileItem) {
    if (props.listMode) {
      inputRef.current.value = '';
      setSearchResults(undefined);
    } else {
      setSelected(item);
    }

    props.onSelect(item);
  }

  return (
    <>
      {!selected && (
        <IconInputWrapper icon={SearchIcon} iconClassName="top-3.5 right-3.5">
          <FormInput
            ref={inputRef}
            className="w-full"
            placeholder={props.placeholder}
            onInput={onInput}
            onFocus={onFocus}
            onBlur={onBlur}
            error={props.error}
            disabled={props.disabled}
          />

          {showResults && (
            <div className="absolute top-12 left-0 right-0 bg-white shadow-lg rounded-b-lg z-30 max-h-[400px] overflow-auto">
              {!loading && searchResults && (
                <>
                  {searchResults.map((item) => (
                    <SelectUserInputItem
                      key={'search-profile-' + item.id}
                      item={item}
                      onSelect={onSelect}
                    />
                  ))}
                  {searchResults.length < 1 && (
                    <p className="text-sm p-6">{props.noResultsText}</p>
                  )}
                </>
              )}
              {error && <p className="text-sm text-danger p-4">{error}</p>}
              {loading && <Loading className="p-6 scale-75" centerY />}
            </div>
          )}
        </IconInputWrapper>
      )}
      {selected && (
        <div className="w-[190px] flex flex-col space-y-3">
          <a
            className="w-full"
            href={userProfileRoute(selected.id)}
            target="_blank"
          >
            <UserProfileItemEl item={selected} />
          </a>
          <Button
            size="small"
            color="secondary"
            disabled={props.disabled}
            onClick={() => setSelected(undefined)}
          >
            {props.changeText}
          </Button>
        </div>
      )}
    </>
  );
}

export default SelectUserInput;
