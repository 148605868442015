import { SignupWalletType } from '../../../../../pages/signup';

export interface SignupWalletTypeItem {
  title: string;
  text: string;
  subtext: string;
  icon: string;
  type: SignupWalletType;
}

function SignupWalletTypeItemEl(props: {
  item: SignupWalletTypeItem;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className="p-4 flex-1 cursor-pointer flex flex-col items-center"
      style={{
        backgroundImage: props.active
          ? 'url(/images/split/user-type/active.svg)'
          : 'url(/images/split/user-type/inactive.svg)',
      }}
      onClick={props.onClick}
    >
      <img
        src={'/images/split/wallet-type/' + props.item.icon}
        className="w-[56px] mb-3"
      />
      <div className="text-sm font-medium">{props.item.title}</div>
      <div className="text-[0.625rem]">{props.item.subtext}</div>
    </div>
  );
}

export default SignupWalletTypeItemEl;
