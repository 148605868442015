import { useTranslation } from 'react-i18next';
import { createContext } from 'react';
import { toast } from 'react-hot-toast';
import truncateEthAddress from 'truncate-eth-address';
import { getWalletAddress, getCurrentProviderService } from '../utils/web3';

interface Web3ContextType {
  connect: (options?: {
    hasToBeAddress?: string;
    noToast?: boolean;
  }) => Promise<string>;
}

const Web3Context = createContext<Web3ContextType>({
  connect: async () => '',
});

export function Web3ContextProvider(props: any) {
  const { t } = useTranslation('common');

  async function connect(
    options: { hasToBeAddress?: string; noToast?: boolean } = {}
  ) {
    try {
      const address = await getWalletAddress(
        process.env.REACT_APP_WEB3_NETWORK_ID!,
        options.hasToBeAddress
      );
      return address;
    } catch (err) {
      console.error('failed to connect', err);
      let errMsg = t(
        getCurrentProviderService() === 'metamask'
          ? 'web3.errors.connect'
          : 'web3.errors.connectMagic'
      );

      if (
        err === 'noMetamask' ||
        err === 'invalidNetwork' ||
        err === 'invalidAddress'
      ) {
        errMsg = t('web3.errors.' + err, {
          network: process.env.REACT_APP_WEB3_NETWORK_NAME,
          address: truncateEthAddress(options.hasToBeAddress || ''),
        });

        if (!options.noToast) {
          toast.error(errMsg, {
            duration: 5000,
          });
        }
      } else if (!options.noToast) {
        toast.error(errMsg, {
          duration: 5000,
        });
      }

      throw errMsg;
    }
  }

  return (
    <Web3Context.Provider
      value={{
        connect,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
}

export default Web3Context;
