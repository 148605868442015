import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

function FormError(props: Props) {
  let className = clsx('text-xs text-danger', props.className);

  return (
    <p {...props} className={className}>
      {props.children}
    </p>
  );
}

export default FormError;
