import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardMembersIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.758 13.487a5.5 5.5 0 1 0-7.078 0 8.613 8.613 0 0 0-3.394 2.75 1.031 1.031 0 1 0 1.663 1.22 6.532 6.532 0 0 1 10.54 0 1.032 1.032 0 0 0 1.663-1.22 8.622 8.622 0 0 0-3.394-2.75ZM3.78 9.281a3.437 3.437 0 1 1 6.875 0 3.437 3.437 0 0 1-6.875 0Zm17.712 8.394a1.032 1.032 0 0 1-1.442-.221 6.558 6.558 0 0 0-5.27-2.673 1.031 1.031 0 0 1 0-2.062 3.439 3.439 0 0 0 1.647-6.453 3.437 3.437 0 0 0-2.923-.178 1.032 1.032 0 1 1-.767-1.915 5.5 5.5 0 0 1 5.586 9.314 8.612 8.612 0 0 1 3.394 2.75 1.031 1.031 0 0 1-.225 1.438Z"
    />
  </svg>
);
export default DashboardMembersIcon;
