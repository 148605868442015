import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  RegistrationState,
  RegistrationItem,
} from '../../../../interfaces/registration.interface';
import { adminRegistrationRoute } from '../../../../utils/routes';
import MyTableRow from '../../../shared/table/row';
import UserName from '../../../shared/user-name';

const stateColors: {
  [key in RegistrationState]: string;
} = {
  applied: 'text-warning',
  waitingForAccept: 'text-success',
  accepted: 'text-success',
  rejected: 'text-danger',
};

function RegistrationItemRow(props: { item: RegistrationItem }) {
  const { t } = useTranslation('registrations');

  return (
    <MyTableRow>
      <td>
        <Link className="underline" to={adminRegistrationRoute(props.item.id)}>
          <UserName obj={props.item} />
        </Link>
      </td>
      <td>{props.item.email}</td>
      <td>{t('table.types.' + props.item.type)}</td>
      <td className={stateColors[props.item.state]}>
        {t('table.states.' + props.item.state)}
      </td>
    </MyTableRow>
  );
}

export default RegistrationItemRow;
