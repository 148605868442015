import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { GetMembersResult } from '../../../interfaces/user.interface';
import { userProfileRoute } from '../../../utils/routes';
import DashboardBox from '../../core/dashboard-layout/box';
import UserProfileItemEl from '../item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  result: GetMembersResult;
  onPageChange?: (page: number) => void;
  loading?: boolean;
  notFoundText?: string;
  url?: (userId: string) => string;
}

function MemberList(props: Props) {
  const { t } = useTranslation('members');

  const els = props.result.results.map((item) => (
    <Link
      key={'member-' + item.id}
      to={props.url ? props.url(item.id) : userProfileRoute(item.id)}
    >
      <UserProfileItemEl item={item} />
    </Link>
  ));

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(
          props.className,
          props.loading ? 'opacity-50 pointer-events-none' : undefined
        ),
      }}
    >
      {props.result.results.length < 1 && (
        <div className="py-4 text-sm">
          {props.notFoundText || t('notFound')}
        </div>
      )}
      {props.result.results.length > 0 && (
        <div className="grid grid-cols-5 gap-6 max-[1370px]:grid-cols-4 max-[1150px]:grid-cols-3 max-lg:grid-cols-4 max-[870px]:grid-cols-3 max-[680px]:grid-cols-2 max-[540px]:grid-cols-1">
          {els}
        </div>
      )}

      {props.result.pages > 1 && props.onPageChange && (
        <div className="flex justify-center mt-8">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex space-x-4 text-xs text-font-grey"
            activeClassName="text-font-black"
            nextClassName="text-font-black"
            previousClassName="text-font-black"
            nextLabel=">"
            previousLabel="<"
            onPageChange={(item) => props.onPageChange!(item.selected)}
            pageRangeDisplayed={5}
            pageCount={props.result.pages}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </DashboardBox>
  );
}

export default MemberList;
