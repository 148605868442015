import { EventEntryScannerItem } from '../interfaces/event-entry-scanner.interface';
import myAxios from '../utils/my-axios';

export const scanEventEntryFromOwner = async (
  entryToken: string
): Promise<{
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
}> => {
  const { data } = await myAxios.post(`/event-entry-scanners/scan`, {
    token: entryToken,
  });

  return data.data;
};

export const scanEventEntry = async (
  entryToken: string,
  scannerToken: string
): Promise<{
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
}> => {
  const { data } = await myAxios.post(
    `/event-entry-scanners/${scannerToken}/scan`,
    {
      token: entryToken,
    }
  );

  return data.data;
};

export interface VerifyEventEntryScannerResult {
  id: string;

  event: {
    id: string;
    title: string;
  };
}

export const verifyEventEntryScanner = async (
  scannerToken: string
): Promise<VerifyEventEntryScannerResult> => {
  const { data } = await myAxios.get(
    `/event-entry-scanners/${scannerToken}/verify`
  );

  return data.data;
};

export const deleteEventEntryScanner = async (id: string): Promise<void> => {
  const { data } = await myAxios.delete(`/event-entry-scanners/${id}`);

  return data.data;
};

export const addEventEntryScanner = async (
  eventId: string,
  email: string
): Promise<EventEntryScannerItem> => {
  const { data } = await myAxios.post(`/events/${eventId}/entry-scanners`, {
    email,
  });

  return data.data;
};

export const getEventEntryScanners = async (
  eventId: string
): Promise<EventEntryScannerItem[]> => {
  const { data } = await myAxios.get(`/events/${eventId}/entry-scanners`);

  return data.data;
};
