import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import AuthContext from '../../../../context/auth-context';
import {
  GetProjectMilestonesResult,
  ProjectMilestone,
} from '../../../../interfaces/project-milestones.interface';
import Button from '../../../shared/button';
import DateFormInput from '../../../shared/form/date-input';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import FormTextarea from '../../../shared/form/textarea';

export interface ProjectMilestoneFormData {
  title: string;
  description: string;
  deadline: string;
  files: string[];
}

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    deadline: yup.date().required(),
    files: yup.array().of(yup.string().required()).required(),
  })
  .required();

export interface ProjectMilestoneFormProps {
  data: GetProjectMilestonesResult;
  milestone?: ProjectMilestone;
  disabled?: boolean;
  onSubmit: (formData: ProjectMilestoneFormData) => void;
}

function ProjectMilestoneForm(props: ProjectMilestoneFormProps) {
  const { t } = useTranslation('project-milestone-form');
  const { user } = useContext(AuthContext);
  const [milestone, setMilestone] = useState(props.milestone);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { files: [] } });

  function onSubmit(values: any) {
    props.onSubmit(values);
  }

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    if (milestone) {
      setValue('title', milestone.title);
      setValue('description', milestone.description);
      setValue('deadline', new Date(milestone.deadline));
      console.log('set values', milestone);
    }
  }, [milestone]);

  console.log('errors?', errors);

  const minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormLabel required showRequiredInfo>
          {t('title')}
        </FormLabel>
        <FormInput
          {...register('title')}
          error={errors.title !== undefined}
          maxLength={200}
          disabled={props.disabled}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel required>{t('description')}</FormLabel>
        <FormTextarea
          {...register('description')}
          error={errors.description !== undefined}
          rows={6}
          maxLength={2000}
          disabled={props.disabled}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel required>{t('deadline')}</FormLabel>

        <Controller
          render={({ field }) => (
            <DateFormInput
              value={field.value}
              onChangeDate={field.onChange}
              error={errors.deadline !== undefined}
              disabled={props.disabled || props.milestone !== undefined}
              minDate={minDate}
            />
          )}
          control={control}
          name="deadline"
        />
        {props.milestone !== undefined && (
          <span className="text-xs">{t('deadlineDisabled')}</span>
        )}
      </FormGroup>
      {/* <FormGroup>
        <FormLabel>{t('files')}</FormLabel>
        <Controller
          render={({ field }) => (
            <ModalFiles
              title={t('files')}
              addText={t('addFile')}
              editable
              onChangeFormFiles={field.onChange}
              maxSize={+process.env.REACT_APP_PROJECT_MILESTONE_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_MILESTONE_MAX_FILE_SIZE!
              }
              getUploadUrlApi={(fileName: string) =>
                requestUploadForProjectMilestone(
                  props.data.project.id,
                  fileName
                )
              }
              requestDownloadApi={
                props.milestone
                  ? (path) =>
                      requestProjectMilestoneFileDownload(
                        props.milestone!.id,
                        path
                      )
                  : undefined
              }
              defaultFiles={milestone ? milestone.files : undefined}
              disabled={props.disabled}
            />
          )}
          control={control}
          name="files"
        />
      </FormGroup> */}
      <FormGroup>
        <Button disabled={props.disabled}>
          {props.milestone ? t('save') : t('create')}
        </Button>
      </FormGroup>
    </form>
  );
}

export default ProjectMilestoneForm;
