import dateFormat from 'dateformat';

export const formatDate = (date: string | Date) => {
  return dateFormat(new Date(date), 'dd. mmmm, yyyy');
};

export const formatShortDate = (date: string | Date) => {
  return dateFormat(new Date(date), 'mmm dd, yyyy');
};

export const formatDateTime = (date: string | Date) => {
  return dateFormat(new Date(date), 'dd. mmmm, yyyy - HH:MM');
};
