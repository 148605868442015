import { useTranslation } from 'react-i18next';
import { ProjectCollaboratorInviteState } from '../../../interfaces/project-collaborator-invite.interface';

function ProjectCollaboratorInviteStateEl(props: {
  state: ProjectCollaboratorInviteState;
}) {
  const { t } = useTranslation('project-collaborator-invite-states');

  return <>{t(props.state)}</>;
}

export default ProjectCollaboratorInviteStateEl;
