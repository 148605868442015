import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TransferRewardTokensModal from '../../transfer-reward-tokens-modal';
import { UserProfile } from '../../../../../interfaces/user.interface';
import Button from '../../../../shared/button';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  user: UserProfile;
}

function ProfileTransfer(props: Props) {
  const { t } = useTranslation('dashboard-profile');

  const [showTransferModal, setShowTransferModal] = useState(false);

  return (
    <>
      <Button
        {...({
          ...props,
          user: undefined,
        } as any)}
        size="small"
        color="secondary"
        onClick={() => setShowTransferModal(true)}
      >
        {t('transfer')}
      </Button>
      {showTransferModal && (
        <TransferRewardTokensModal
          user={props.user}
          onClose={() => setShowTransferModal(false)}
        />
      )}
    </>
  );
}

export default ProfileTransfer;
