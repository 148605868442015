import ProgressBar from '@ramonak/react-progress-bar';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { requestCacheDownload } from '../../../../../api/users';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  file: UploadedFile;
  editable?: boolean;
  onRemove: () => void;
  requestDownloadApi?: (path: string) => Promise<string>;
}

export interface UploadedFile {
  path: string;
  size: number;
  file?: File;
  progress?: { loaded: number; total: number };
}

function FileFormItem(props: Props) {
  const { t } = useTranslation('files-form');
  const [downloading, setDownloading] = useState(false);

  let icon: { src: string; class: string };

  let fileName = props.file.path.split('/').pop();

  if (!props.editable) {
    fileName = decodeURIComponent(fileName!.split('?')[0]);
  }

  const ext = props.file.path.split('.').pop()!;

  if (['png', 'jpeg', 'jpg'].includes(ext)) {
    icon = { src: '/images/dashboard/file/image.svg', class: 'bg-[#FF8989]' };
  } else {
    icon = {
      src: '/images/dashboard/file/document.svg',
      class: 'bg-[#89A3FF]',
    };
  }

  // download from cache if cache path, otherwise via provided api
  async function onClick() {
    if (props.file.file) return;

    setDownloading(true);
    try {
      let url: string;

      if (props.file.path.startsWith('cache/')) {
        url = await requestCacheDownload(props.file.path);
      } else {
        url = await props.requestDownloadApi!(props.file.path);
      }

      window.location.href = url;
    } catch (err) {
      console.error('failed to download', err);
      toast.error(t('errors.download'), { duration: 3000 });
    }
    setDownloading(false);
  }

  return (
    <div className="relative">
      <div
        {...props}
        className={clsx(
          'flex items-center border border-grey-2 rounded-lg p-2',
          props.file.file || downloading ? 'opacity-50' : 'cursor-pointer',
          props.className
        )}
        onClick={onClick}
      >
        <div className={clsx('p-2.5 mr-3 rounded-md shrink-0', icon.class)}>
          <img src={icon.src} width={26} height={26} alt="file" />
        </div>
        <div>
          <p className="text-xs break-all">{fileName}</p>
          <p className="text-[10px] text-font-grey">
            {prettyBytes(props.file.size)}
          </p>
        </div>
      </div>
      {props.editable && !props.file.file && (
        <div
          className="absolute top-0 right-0 p-1.5 cursor-pointer"
          onClick={props.onRemove}
        >
          <img
            src="/images/dashboard/file/remove.svg"
            width={14}
            height={14}
            alt="remove"
          />
        </div>
      )}

      {props.file.progress && (
        <ProgressBar
          className="absolute bottom-0 left-0 right-0 w-full"
          barContainerClassName="rounded-b-md"
          completed={props.file.progress.loaded / props.file.progress.total}
          maxCompleted={1}
          height="4px"
          isLabelVisible={false}
          transitionDuration="0.1s"
          bgColor="#FECA16"
        />
      )}
    </div>
  );
}

export default FileFormItem;
