import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getProjectCollaboratorResponse,
  requestProjectCollaboratorResponseFileDownload,
} from '../../../../../../api/projects-collaborator-responses';
import DashboardBox from '../../../../../../components/core/dashboard-layout/box';
import DashboardFiles from '../../../../../../components/core/dashboard-layout/files';
import DashboardGroup from '../../../../../../components/core/dashboard-layout/group';
import DashboardGroupLabel from '../../../../../../components/core/dashboard-layout/group/label';
import DashboardGroupValue from '../../../../../../components/core/dashboard-layout/group/value';
import DashboardUserProfile from '../../../../../../components/core/dashboard-layout/profile';
import DashboardTitle from '../../../../../../components/core/dashboard-layout/title';
import UserDashboard from '../../../../../../components/core/dashboard-layout/user';
import AcceptProjectCollaboratorResponse from '../../../../../../components/projects/collaborators/responses/accept';
import RejectProjectCollaboratorResponse from '../../../../../../components/projects/collaborators/responses/reject';
import Loading from '../../../../../../components/shared/loading';
import ProjectCollaboratorResponseStateEl from '../../../../../../components/shared/project-collaborator-response-state';
import AuthContext from '../../../../../../context/auth-context';
import { ProjectCollaboratorResponseDetail } from '../../../../../../interfaces/project-collaborator-response.interface';
import {
  myProjectsRoute,
  projectRoute,
  projectCollaboratorsRoute,
  projectCollaboratorApplicationsRoute,
  userProfileRoute,
} from '../../../../../../utils/routes';
import { getDisplayName } from '../../../../../../utils/utils';
import Notification from '../../../../../../components/shared/notification';

export default function ProjectCollaboratorResponsePage() {
  const { t } = useTranslation('project-collaborator-response');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [response, setResponse] = useState<ProjectCollaboratorResponseDetail>();
  const { user } = useContext(AuthContext);
  const { projectId, responseId } = useParams();

  async function fetchResponse() {
    setError(undefined);
    try {
      const result = await getProjectCollaboratorResponse(
        responseId! as string
      );
      console.log('response loaded', result);
      setResponse(result);
    } catch (err) {
      console.error('failed to get response', err);
      if (!axios.isAxiosError(err)) {
        setError(t('errors.load'));
      } else if (
        err.response!.data.code === 'projectCollaboratorResponseNotFound'
      ) {
        setError(t('errors.notFound'));
      } else if (err.response!.data.code === 'accessDenied') {
        setError(t('errors.accessDenied'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  useEffect(() => {
    fetchResponse();
  }, [responseId!]);

  function onAccept() {
    setResponse({ ...response!, state: 'accepting' });
  }

  function onReject(reason: string) {
    setResponse({ ...response!, rejectReason: reason, state: 'rejected' });
  }

  const isOwner = response && user && user.id === response.project.user.id;

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={
        user && response && response.user.id === user.id
          ? [
              {
                label: commonTranslations.t('dashboard.pathLabels.response'),
              },
            ]
          : [
              {
                label: commonTranslations.t('dashboard.pathLabels.myProjects'),
                path: myProjectsRoute,
              },
              {
                label: response ? response.project.title : '',
                path: projectRoute(projectId! as string),
              },
              {
                label: commonTranslations.t(
                  'dashboard.pathLabels.collaborators'
                ),
                path: projectCollaboratorsRoute(projectId! as string),
              },
              {
                label: commonTranslations.t('dashboard.pathLabels.responses'),
                path: projectCollaboratorApplicationsRoute(
                  projectId! as string
                ),
              },
            ]
      }
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}

      {!response && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {response && (
        <div className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1">
          <div className="flex flex-col">
            {response.state === 'waiting' && (
              <Notification color="neutral">{t('waiting')}</Notification>
            )}

            {response.state === 'accepting' && isOwner && (
              <Notification color="success">{t('accepting')}</Notification>
            )}
            {response.state === 'accepted' && (
              <Notification color="success">{t('accepted')}</Notification>
            )}
            {response.state === 'rejected' && (
              <Notification color="neutral">
                <div>{t('rejected')}</div>
                <div className="whitespace-pre-line mt-3">
                  {response.rejectReason}
                </div>
              </Notification>
            )}

            <DashboardBox className="mb-6">
              <DashboardTitle>{t('details')}</DashboardTitle>
              <DashboardGroup className="hidden max-xl:flex">
                <DashboardGroupLabel>{t('applicant')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <Link
                    to={userProfileRoute(response.user.id)}
                    className="text-secondary underline"
                  >
                    {getDisplayName(response.user)}
                  </Link>
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('state')}</DashboardGroupLabel>
                <DashboardGroupValue>
                  <ProjectCollaboratorResponseStateEl state={response.state} />
                </DashboardGroupValue>
              </DashboardGroup>
              <DashboardGroup>
                <DashboardGroupLabel>{t('message')}</DashboardGroupLabel>
                <DashboardGroupValue>{response.message}</DashboardGroupValue>
              </DashboardGroup>
            </DashboardBox>
            <DashboardFiles
              title={t('files')}
              addText={t('addFile')}
              maxSize={+process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILES!}
              maxFiles={
                +process.env.REACT_APP_PROJECT_COLLABORATOR_MAX_FILE_SIZE!
              }
              defaultFiles={response.files}
              requestDownloadApi={(path) =>
                requestProjectCollaboratorResponseFileDownload(
                  response.id,
                  path
                )
              }
            />
          </div>
          <div className="flex flex-col space-y-4">
            <DashboardUserProfile
              className="max-xl:hidden"
              user={response.user}
            />

            {response.state == 'sent' && isOwner && (
              <>
                <AcceptProjectCollaboratorResponse
                  response={response}
                  onAccept={onAccept}
                  onError={setError}
                />
                <RejectProjectCollaboratorResponse
                  response={response}
                  onReject={onReject}
                />
              </>
            )}
          </div>
        </div>
      )}
    </UserDashboard>
  );
}
