import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ModalFiles from '../../milestones/form/files';
import {
  startProjectCompletion,
  canStartProjectCompletion,
  getProjectDataHashForCompletion,
  requestUploadForProject,
  getStartProjectCompletionForwardRequest,
} from '../../../../api/projects';
import AuthContext from '../../../../context/auth-context';
import { ProjectDetail } from '../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import Button from '../../../shared/button';
import FormCheckbox from '../../../shared/form/checkbox';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import Loading from '../../../shared/loading';
import Web3Context from '../../../../context/web3-context';
import Notification from '../../../shared/notification';
import Modal from '../../../shared/modal';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../utils/web3';
import { sleep } from '../../../../utils/utils';

const schema = yup
  .object()
  .shape({
    files: yup.array().of(yup.string().required()).required(),
    filesPrivate: yup.boolean().default(false).required(),
  })
  .required();

function ProjectDetailStartCompletionModal(props: {
  project: ProjectDetail;
  onClose: () => void;
  onStart: (tx: string) => void;
}) {
  const { t } = useTranslation('project-detail');
  const [canStart, setCanStart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    checkCanStart();
  }, []);

  async function onStart(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      await checkCanStart();

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          const hash = await fetchProjectDataHash(values);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.markProjectForCompletion(
            props.project.contractId,
            hash
          );

          tx = result.hash;
        } else {
          const forwardRequest =
            await fetchStartProjectCompletionForwardRequest(values);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await startProjectCompletion(
            props.project.id,
            values.files,
            values.filesPrivate,
            tx,
            signature
          );
          props.onStart(tx!);
        } catch (err: any) {
          console.error('failed to start completion', err);
          setError(t('startCompletion.errors.start'));
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('startCompletion.errors.transaction'));
        setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();

      // edihProjectsContract
      //   .markProjectForCompletion(
      //     user!.walletAddress,
      //     props.project.contractId,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('started', tx);
      //     try {
      //       await startProjectCompletion(
      //         props.project.id,
      //         tx,
      //         values.files,
      //         values.filesPrivate
      //       );
      //       props.onStart(tx);
      //     } catch (err: any) {
      //       console.error('failed to start completion', err);
      //       setError(t('startCompletion.errors.start'));
      //     }
      //     setLoading(false);
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('startCompletion.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function checkCanStart() {
    try {
      await canStartProjectCompletion(props.project.id);
      setCanStart(true);
    } catch (err) {
      console.error('failed to check can start', err);
      if (
        axios.isAxiosError(err) &&
        err.response!.data.code === 'noCollaborators'
      ) {
        setError(t('startCompletion.errors.noCollaborators'));
      } else {
        setError(t('startCompletion.errors.canStart'));
      }

      if (canStart) {
        throw err;
      }
    }
  }

  async function fetchProjectDataHash(values: any) {
    try {
      const hash = await getProjectDataHashForCompletion(values.files);
      return hash;
    } catch (err) {
      setError(t('startCompletion.errors.fetchHash'));

      throw err;
    }
  }

  async function fetchStartProjectCompletionForwardRequest(values: any) {
    try {
      return getStartProjectCompletionForwardRequest(
        props.project.id,
        values.files
      );
    } catch (err) {
      setError(t('startCompletion.errors.fetchHash'));

      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}

      {!canStart && !error && (
        <div className="h-[100px]">
          <Loading fadeIn centerY />
        </div>
      )}

      {canStart && (
        <form onSubmit={handleSubmit(onStart)}>
          <FormGroup>
            <FormLabel>{t('startCompletion.files')}</FormLabel>
            <Controller
              render={({ field }) => (
                <ModalFiles
                  addText={t('startCompletion.addFile')}
                  editable
                  onChangeFormFiles={field.onChange}
                  maxSize={+process.env.REACT_APP_PROJECT_MAX_FILES!}
                  maxFiles={+process.env.REACT_APP_PROJECT_MAX_FILE_SIZE!}
                  getUploadUrlApi={(fileName: string) =>
                    requestUploadForProject(fileName)
                  }
                  disabled={loading}
                />
              )}
              control={control}
              name="files"
            />
            <Controller
              render={({ field }) => (
                <FormCheckbox
                  className="mt-3"
                  small
                  checkboxProps={{
                    onChange: (ev) => field.onChange(ev.target.checked),
                    checked: field.value === true,
                    name: 'filesPrivate',
                    disabled: loading,
                  }}
                >
                  {t('startCompletion.filesPrivate')}
                </FormCheckbox>
              )}
              control={control}
              name="filesPrivate"
            />
          </FormGroup>
          <FormGroup>
            <Button className="mt-4" disabled={loading}>
              {t('startCompletion.button')}
            </Button>
          </FormGroup>
        </form>
      )}
    </Modal>
  );
}

export default ProjectDetailStartCompletionModal;
