import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { GetProjectCollaboratorsResult } from '../../../../interfaces/project-collaborator.interface';
import {
  userProfileRoute,
  inviteCollaboratorRoute,
} from '../../../../utils/routes';
import DashboardBox from '../../../core/dashboard-layout/box';
import UserProfileItemEl from '../../../users/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projectId?: string;
  result: GetProjectCollaboratorsResult;
  createText?: string;
  notFoundText?: string;
  onPageChange?: (page: number) => void;
  loading?: boolean;
}

function ProjectCollaboratorList(props: Props) {
  const els = props.result.results.map((item) => (
    <Link key={'collab-' + item.id} to={userProfileRoute(item.user.id)}>
      <UserProfileItemEl item={item.user} />
    </Link>
  ));

  return (
    <DashboardBox
      className={clsx(
        props.loading ? 'opacity-50 pointer-events-none' : undefined
      )}
    >
      <div className="grid grid-cols-5 gap-6 max-[1370px]:grid-cols-4 max-[1150px]:grid-cols-3 max-lg:grid-cols-4 max-[870px]:grid-cols-3 max-[680px]:grid-cols-2 max-[540px]:grid-cols-1">
        {props.projectId && (
          <Link
            to={inviteCollaboratorRoute(props.projectId)}
            className="border border-grey-2 p-3 rounded-lg flex flex-col items-center justify-center min-h-[220px]"
          >
            <img
              className="mb-3"
              width={64}
              height={64}
              src="/images/create.svg"
              alt="create-project"
            />

            <div className="text-sm font-medium text-font-grey">
              {props.createText}
            </div>
          </Link>
        )}
        {els}
      </div>

      {!props.createText && props.result.results.length < 1 && (
        <div className="py-4 text-sm">{props.notFoundText}</div>
      )}

      {props.result.pages > 1 && props.onPageChange && (
        <div className="flex justify-center mt-8">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex space-x-4 text-xs text-font-grey"
            activeClassName="text-font-black"
            nextClassName="text-font-black"
            previousClassName="text-font-black"
            nextLabel=">"
            previousLabel="<"
            onPageChange={(item) => props.onPageChange!(item.selected)}
            pageRangeDisplayed={5}
            pageCount={props.result.pages}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </DashboardBox>
  );
}

export default ProjectCollaboratorList;
