import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import DashboardBox from '../box';
import DashboardTitle from '../title';
import DashboardOverallInformationItemEl from './item';
import AuthContext from '../../../../context/auth-context';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function DashboardOverallInformation(props: Props) {
  const { dashboardData } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');

  const items = [
    {
      title: t('info.projects'),
      icon: '/images/dashboard/info-1.svg',
      value: dashboardData!.projects.total,
      color: '#feca1680',
    },
    {
      title: t('info.events'),
      icon: '/images/dashboard/info-2.svg',
      value: dashboardData!.participatingEventsCount,
      color: '#d46f0780',
    },
    {
      title: t('info.recommendations'),
      icon: '/images/dashboard/info-3.svg',
      value: dashboardData!.recommendationsCount,
      color: '#4c3d3d80',
    },
  ].map((item) => (
    <DashboardOverallInformationItemEl key={item.title} item={item} />
  ));

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(props.className, '!p-4 h-[235px] flex flex-col'),
      }}
    >
      <DashboardTitle className="mb-8">{t('info.title')}</DashboardTitle>
      <div className="grid grid-cols-3 gap-4 flex-1">{items}</div>
    </DashboardBox>
  );
}

export default DashboardOverallInformation;
