import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  EventEntryState,
  EventEntryRowItem,
} from '../../../../../interfaces/event-entry.interface';
import { formatDate } from '../../../../../utils/date';
import { userProfileRoute } from '../../../../../utils/routes';
import ButtonTokensSmallIcon from '../../../../shared/button/tokens-small';
import MyTableRow from '../../../../shared/table/row';
import UserName from '../../../../shared/user-name';

const stateColors: {
  [key in EventEntryState]: string;
} = {
  waiting: 'text-font-black',
  transferring: 'text-font-black',
  idle: 'text-success',
  waitingForUse: 'text-success',
  used: 'text-success',
  verified: 'text-blue',
};

function EventEntryItemRow(props: { item: EventEntryRowItem }) {
  const { t } = useTranslation('event-entries');

  let stateLabel = t('states.idle');

  if (props.item.state === 'used') {
    stateLabel = t('states.used');
  } else if (props.item.state === 'verified') {
    stateLabel = t('states.verified');
  }

  return (
    <MyTableRow>
      <td>
        <Link className="underline" to={userProfileRoute(props.item.user.id)}>
          <UserName obj={props.item.user} />
        </Link>
      </td>
      <td>{props.item.user.email}</td>
      <td className="flex items-center space-x-1">
        <ButtonTokensSmallIcon className="w-[11px] h-[11px]" />
        <span>{props.item.price}</span>
      </td>
      <td>{formatDate(props.item.createdAt)}</td>
      <td className={stateColors[props.item.state]}>{stateLabel}</td>
    </MyTableRow>
  );
}

export default EventEntryItemRow;
