import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import ProjectRatingItemEl, { ProjectRatingItem } from './rating-item';
import Web3Context from '../../../../context/web3-context';
import {
  getRatableUsers,
  rateProject,
  canRateProject,
  getRateProjectForwardRequest,
} from '../../../../api/projects';
import AuthContext from '../../../../context/auth-context';
import { ProjectDetail } from '../../../../interfaces/project.interface';
import { loadEDIHProjectsContract } from '../../../../utils/contracts/edih-projects';
import { getDisplayName, sleep } from '../../../../utils/utils';
import Button from '../../../shared/button';
import Loading from '../../../shared/loading';
import Notification from '../../../shared/notification';
import Modal from '../../../shared/modal';
import { ProjectParticipantRating } from '../../../../interfaces/project-rating.interface';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';

function RateProjectModal(props: {
  project: ProjectDetail;
  onClose: () => void;
  onRate: (tx: string) => void;
}) {
  const { t } = useTranslation('project-detail');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);
  const [ratings, setRatings] = useState<ProjectRatingItem[]>();

  useEffect(() => {
    loadRatableUsers();
  }, []);

  async function loadRatableUsers() {
    try {
      const ratableUsers = await getRatableUsers(props.project.id);
      console.log('ratable users loaded', ratableUsers);
      setRatings(
        [
          {
            title: t('rate.timeRating'),
            rating: props.project.timeRating!,
            readonly: true,
          },
          {
            walletAddress: process.env.REACT_APP_EDIH_PROJECTS_CONTRACT_ADDRESS,
            title: t('rate.project'),
          },
        ].concat(
          ratableUsers
            .filter((r) => r.id !== user!.id)
            .map((r) => {
              return {
                ...r,
                userId: r.id,
                title: t('rate.user', { name: getDisplayName(r) }),
              };
            })
        ) as any
      );
    } catch (err) {
      console.error('failed to get ratable users', err);
      if (!axios.isAxiosError(err)) {
        setError(t('rate.errors.load'));
      } else if (err.response!.data.code === 'projectState') {
        setError(t('rate.errors.projectState'));
      } else if (err.response!.data.code === 'alreadyRated') {
        setError(t('rate.errors.alreadyRated'));
      } else if (err.response!.data.code === 'deadline') {
        setError(t('rate.errors.deadline'));
      } else {
        setError(t('errors.load'));
      }
    }
  }

  async function onSubmitRatings() {
    let walletAddresses: string[] = [];
    let walletAddressRatings: number[] = [];

    // ensure everyone has been rated
    for (const rating of ratings!) {
      if (!rating.rating || rating.rating === 0) {
        return setError(t('rate.errors.notRated'));
      }

      if (rating.walletAddress) {
        walletAddresses.push(rating.walletAddress);
        walletAddressRatings.push(rating.rating * 10);
      }
    }

    let projectRating = ratings![1].rating! * 10;
    let participantRatings = ratings!.slice(2).map((r) => {
      return { userId: r.userId, rating: r.rating! * 10 };
    });

    console.log('submit b', projectRating, participantRatings);

    setLoading(true);
    setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanRate();

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result = await edihProjectsContract.submitProjectRatings(
            props.project.contractId,
            walletAddresses,
            walletAddressRatings
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchRateProjectForwardRequest(
            projectRating,
            participantRatings as any
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await rateProject(
            props.project.id,
            projectRating,
            participantRatings as any,
            tx,
            signature
          );
          props.onRate(tx!);
          setLoading(false);
          props.onClose();
        } catch (err: any) {
          console.error('failed to approved', err);
          setError(t('rate.errors.rate'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('rate.errors.transaction'));
        setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .submitProjectRatings(
      //     user!.walletAddress,
      //     props.project.contractId,
      //     walletAddresses,
      //     walletAddressRatings
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('rated', tx);
      //     try {
      //       await rateProject(props.project.id, tx);
      //       props.onRate(tx);
      //       setLoading(false);
      //       props.onClose();
      //     } catch (err: any) {
      //       console.error('failed to approved', err);
      //       setError(t('rate.errors.rate'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('rate.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function checkCanRate() {
    try {
      await canRateProject(props.project!.id);
    } catch (err) {
      setError(t('rate.errors.canRate'));

      throw err;
    }
  }

  async function fetchRateProjectForwardRequest(
    projectRating: number,
    participantRatings: ProjectParticipantRating[]
  ) {
    try {
      return getRateProjectForwardRequest(
        props.project.id,
        projectRating,
        participantRatings
      );
    } catch (err) {
      setError(t('rate.errors.canRate'));

      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      {!ratings && !error && (
        <div className="[h-100px]">
          <Loading centerY />
        </div>
      )}
      {ratings && (
        <>
          <div className="font-medium mb-4 text-lg">{t('rate.title')}</div>
          {ratings.map((rating) => {
            return (
              <ProjectRatingItemEl
                key={'rating-' + rating.title}
                rating={rating}
                onRate={(newRating: number) =>
                  setRatings(
                    ratings.map((r) => {
                      if (r.walletAddress === rating.walletAddress) {
                        r.rating = newRating;
                      }
                      return r;
                    })
                  )
                }
              ></ProjectRatingItemEl>
            );
          })}
          <Button
            className="w-full mt-4"
            color="secondary"
            onClick={onSubmitRatings}
            disabled={loading}
          >
            {t('rate.submit')}
          </Button>
        </>
      )}
    </Modal>
  );
}

export default RateProjectModal;
