import { SignupDataType } from '../../../../../pages/signup';

export interface SignupUserTypeItem {
  title: string;
  text: string;
  icon: string;
  iconActive: string;
  type: SignupDataType;
}

function SignupUserTypeItemEl(props: {
  item: SignupUserTypeItem;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className="p-4 flex-1 cursor-pointer"
      style={{
        backgroundImage: props.active
          ? 'url(/images/split/user-type/active.svg)'
          : 'url(/images/split/user-type/inactive.svg)',
      }}
      onClick={props.onClick}
    >
      <div className="h-[20px] mb-2">
        <img
          src={
            '/images/split/user-type/' +
            (props.active ? props.item.iconActive : props.item.icon)
          }
          height="20px"
        />
      </div>
      <div className="text-sm font-medium">{props.item.title}</div>
      <div className="text-[0.625rem] text-font-grey">{props.item.text}</div>
    </div>
  );
}

export default SignupUserTypeItemEl;
