import { useTranslation } from 'react-i18next';
import Modal from '../../../shared/modal';
import Button from '../../../shared/button';

export type ScanEventEntryResultType = 'valid' | 'invalid';

function ScanEventEntryResultModal(props: {
  type: ScanEventEntryResultType;
  message: string;
  onClose: () => void;
}) {
  const { t } = useTranslation('common');

  return (
    <Modal className="w-full max-w-[400px]" onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <img
          className="w-[100px] mb-5"
          src={`/images/events/${props.type}-ticket.svg`}
        />
        <div className="mb-8">{props.message}</div>
        <Button color="secondary" onClick={props.onClose}>
          {t('close')}
        </Button>
      </div>
    </Modal>
  );
}

export default ScanEventEntryResultModal;
