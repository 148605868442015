import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DashboardBox from '../box';
import DashboardUserProfileTags from '../profile/hashtags';
import DashboardUserProfileInfos from '../profile/info';
import Endorse from './endorse';
import styles from './index.module.css';
import ProfileTransfer from './transfer';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../context/auth-context';
import { EndorsementState } from '../../../../interfaces/endorsement.interface';
import { UserProfile } from '../../../../interfaces/user.interface';
import { editProfileRoute } from '../../../../utils/routes';
import Button from '../../../shared/button';
import ButtonEditSmallIcon from '../../../shared/button/edit-small';
import OptimizedImage from '../../../shared/image';
import UserName from '../../../shared/user-name';
import UserOrganization from '../../../shared/user-organization';
import Notification from '../../../shared/notification';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: UserProfile;
  onEndorsementStateChanged: (state: EndorsementState) => void;
}

function DashboardUserProfileLarge(props: Props) {
  const { t } = useTranslation('dashboard-profile');
  const { user } = useContext(AuthContext);
  const [success, setSuccess] = useState<string>();

  function onEndorsementStateChanged(state: EndorsementState) {
    if (state === 'waiting') {
      setSuccess(t('endorsement.sent'));
    }

    props.onEndorsementStateChanged(state);
  }

  return (
    <>
      {success && <Notification color="success">{success}</Notification>}
      <DashboardBox
        {...{
          ...props,
          className: clsx(props.className, '!p-0 overflow-hidden'),
        }}
      >
        {props.user.backgroundImage && (
          <OptimizedImage
            className={styles['background-image']}
            params={{ w: '1200' }}
            src={props.user.backgroundImage}
          />
        )}
        {!props.user.backgroundImage && (
          <div
            className={clsx(
              styles['background-image'],
              styles['default-background']
            )}
          />
        )}

        <div className="p-8 mt-[-80px] max-[660px]:p-5 max-[660px]:mt-[-65px]">
          <div className="flex items-start">
            {props.user.image && (
              <OptimizedImage
                className={styles['image']}
                params={{ w: '150', h: '150' }}
                src={props.user.image}
              />
            )}
            {!props.user.image && (
              <div className={clsx(styles['image'], styles['default-image'])} />
            )}
            <div className="ml-auto mt-16 space-x-2">
              {user && props.user.id === user!.id && (
                <Link to={editProfileRoute}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="outline"
                    icon={() => <ButtonEditSmallIcon className="mr-2" />}
                  >
                    {t('edit')}
                  </Button>
                </Link>
              )}
              {user && props.user.id !== user!.id && (
                <>
                  <Endorse
                    user={props.user}
                    onEndorsementStateChanged={onEndorsementStateChanged}
                  />
                  <ProfileTransfer user={props.user} />
                </>
              )}
            </div>
          </div>

          <div className="mt-8 max-[660px]:mt-5">
            <div className="grid grid-cols-2 gap-28 max-[900px]:grid-cols-1 max-[900px]:gap-4">
              <div className="space-y-3">
                <p className="text-xl font-medium">
                  <UserName obj={props.user} />
                </p>
                <p className="text-sm text-font-grey">
                  <UserOrganization obj={props.user} />
                  {/* {props.user.organization && (
                    <span>{props.user.organization}</span>
                  )}
                  {!props.user.organization && (
                    <UserRole role={props.user.role} />
                  )} */}
                </p>
                <p className="text-sm whitespace-pre-line">
                  {props.user.description || '-'}
                </p>
              </div>
              <div>
                <div className="mb-4">
                  <DashboardUserProfileInfos user={props.user} />
                </div>
                <div>
                  <DashboardUserProfileTags tags={props.user.tags!} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardBox>
    </>
  );
}

export default DashboardUserProfileLarge;
