import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { GetMyEventsFilter, getMyEvents } from '../../../api/events';
import DashboardSearch from '../../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../components/core/dashboard-layout/search-filter/modal/item';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import EventList from '../../../components/events/list';
import Loading from '../../../components/shared/loading';
import { GetEventsResult } from '../../../interfaces/event.interface';
import { myEventsRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

const filterStates = {
  active: ['active'],
  completed: [
    'waitingForRating',
    'rating',
    'waitingForRatingDeadline',
    'completed',
  ],
};

export default function MyEventsPage() {
  const { t } = useTranslation('my-events');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEventsResult>();
  const [filter, setFilter] = useState<GetMyEventsFilter>({});

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('states.title'),
      key: 'states',
      items: [
        { label: t('states.all') },
        { label: t('states.active'), value: 'active' },
        { label: t('states.completed'), value: 'completed' },
      ],
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    setLoading(true);
    try {
      console.log('search with filter', filter);
      const result = await getMyEvents(page, filter);
      setData(result);
    } catch (err) {
      console.error('failed to get events', err);
      setError(t('errors.load'));
    }
    setLoading(false);
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({
      ...filter,
      states: value === '' ? undefined : (filterStates as any)[value as any],
    });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      selectedPath={myEventsRoute}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myEvents'),
        },
      ]}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1200px] flex flex-col min-h-full">
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <EventList
            result={data}
            createText={t('createText')}
            onPageChange={(page: number) => fetchItems(page)}
            loading={loading}
          />
        </div>
      )}
    </UserDashboard>
  );
}
