import { useTranslation } from 'react-i18next';
import { FileUploader } from 'react-drag-drop-files';
import toast from 'react-hot-toast';

function FileFormAdd(props: {
  addText: string;
  onAdd: (files: File[]) => void;
  maxSize: number;
}) {
  const { t } = useTranslation('files-form');

  function onUpload(files: File[]) {
    props.onAdd(files);
  }

  function onSizeError(file: File) {
    toast.error(
      t('errors.maxSize', {
        file: file.name,
        maxSize: props.maxSize,
      }),
      { duration: 4000 }
    );
  }

  return (
    <FileUploader
      handleChange={onUpload}
      multiple
      types={['jpeg', 'jpg', 'png', 'pdf', 'doc', 'xls', 'ppt', 'mp4', 'wav']}
      maxSize={props.maxSize}
      onSizeError={onSizeError}
    >
      <div className="flex items-center border border-grey-2 rounded-lg p-2 cursor-pointer">
        <div
          className={
            'flex justify-center items-center p-2.5 mr-3 rounded-md bg-[#D9D9D9]'
          }
        >
          <img
            src="/images/dashboard/file/add.svg"
            width={26}
            height={26}
            alt="add"
          />
        </div>
        <p className="text-xs">{props.addText}</p>
      </div>
    </FileUploader>
  );
}

export default FileFormAdd;
