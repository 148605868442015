import { Tag } from '../interfaces/tag.interface';
import myAxios from '../utils/my-axios';

export const getTags = async (): Promise<Tag[]> => {
  const { data } = await myAxios.get('/tags');

  return data.data;
};

export const getOtherProjectTags = async (): Promise<Tag[]> => {
  const { data } = await myAxios.get('/tags/other-projects');

  return data.data;
};

export const getOtherEventTags = async (): Promise<Tag[]> => {
  const { data } = await myAxios.get('/tags/other-events');

  return data.data;
};
