import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import EventItemEl from '../../list/item';
import EventEntryQrCodeModal from './qr-code-modal';
import EventEntryItemElRedeem from './redeem';
import EventEntryItemElTransfer from './transfer';
import { EventEntryItem } from '../../../../interfaces/event-entry.interface';
import Button from '../../../shared/button';
import Notification from '../../../shared/notification';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: EventEntryItem;
  onUse: (entries: { id: string }[], tx: string) => void;
  onTransfer: (entries: { id: string }[], tx: string) => void;
}

function EventEntryItemEl(props: Props) {
  const { t } = useTranslation('event-entry-list');
  const [showQrCode, setShowQrCode] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div
        {...{
          ...props,
          item: undefined,
          className: clsx(props.className, 'flex flex-col'),
        }}
      >
        {error && <Notification color="danger">{error}</Notification>}
        <EventItemEl item={props.item.event} className="flex-1" />

        {props.item.state === 'idle' && props.item.event.state === 'active' && (
          <div className="flex flex-col space-y-2">
            <EventEntryItemElRedeem
              item={props.item}
              setError={setError}
              setLoading={setLoading}
              loading={loading}
              onUse={props.onUse}
            />
            <EventEntryItemElTransfer
              item={props.item}
              setError={setError}
              setLoading={setLoading}
              loading={loading}
              onTransfer={props.onTransfer}
            />
          </div>
        )}

        {props.item.state === 'waitingForUse' && (
          <Button className="w-full" size="medium" disabled>
            {t('redeeming')}
          </Button>
        )}

        {props.item.state === 'transferring' && (
          <Button className="w-full" size="medium" disabled>
            {t('transferring')}
          </Button>
        )}

        {(props.item.state === 'used' || props.item.state === 'verified') && (
          <Button
            className="w-full"
            size="medium"
            color="secondary"
            onClick={() => setShowQrCode(true)}
          >
            {t('qrcode')}
          </Button>
        )}
      </div>

      {showQrCode && (
        <EventEntryQrCodeModal
          entryToken={props.item.entryToken!}
          onClose={() => setShowQrCode(false)}
        />
      )}
    </>
  );
}

export default EventEntryItemEl;
