import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import ProjectItemEl from './item';
import { Link } from 'react-router-dom';
import { GetProjectsResult } from '../../../interfaces/project.interface';
import { createProjectRoute } from '../../../utils/routes';
import DashboardBox from '../../core/dashboard-layout/box';
import DashboardTitle from '../../core/dashboard-layout/title';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  result: GetProjectsResult;
  createText?: string;
  onPageChange?: (page: number) => void;
  loading?: boolean;
  notFoundText?: string;
}

function ProjectList(props: Props) {
  const els = props.result.results.map((item) => (
    <div
      key={'project-' + item.id}
      className="border border-grey-2 p-3 rounded-lg"
    >
      <ProjectItemEl item={item} />
    </div>
  ));

  return (
    <DashboardBox
      className={clsx(
        props.loading ? 'opacity-50 pointer-events-none' : undefined
      )}
    >
      {props.title && <DashboardTitle>{props.title}</DashboardTitle>}
      <div className="grid grid-cols-5 gap-6 max-[1370px]:grid-cols-4 max-[1150px]:grid-cols-3 max-lg:grid-cols-4 max-[870px]:grid-cols-3 max-[680px]:grid-cols-2 max-[540px]:grid-cols-1">
        {props.createText && (
          <Link
            to={createProjectRoute}
            className="border border-grey-2 p-3 rounded-lg flex flex-col items-center justify-center min-h-[220px]"
          >
            <img
              className="mb-3"
              width={64}
              height={64}
              src="/images/create.svg"
              alt="create-project"
            />

            <div className="text-sm font-medium text-font-grey">
              {props.createText}
            </div>
          </Link>
        )}
        {els}
      </div>

      {!props.createText && props.result.results.length < 1 && (
        <div className="py-4 text-sm">{props.notFoundText}</div>
      )}

      {props.result.pages > 1 && props.onPageChange && (
        <div className="flex justify-center mt-8">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex space-x-4 text-xs text-font-grey"
            activeClassName="text-font-black"
            nextClassName="text-font-black"
            previousClassName="text-font-black"
            nextLabel=">"
            previousLabel="<"
            onPageChange={(item) => props.onPageChange!(item.selected)}
            pageRangeDisplayed={5}
            pageCount={props.result.pages}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </DashboardBox>
  );
}

export default ProjectList;
