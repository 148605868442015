import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '../../../../../shared/modal';
import Button from '../../../../../shared/button';
import FormGroup from '../../../../../shared/form/group';
import FormLabel from '../../../../../shared/form/label';
import FormSelect from '../../../../../shared/form/select';

function EventEntryUseModal(props: {
  usableAmount: number;
  onClose: () => void;
  onSubmit: (amount: number) => void;
}) {
  const { t } = useTranslation('event-entry-list');
  const [amount, setAmount] = useState(1);

  return (
    <Modal className="w-full max-w-[350px]" onClose={props.onClose}>
      {/* <ModalTitle>{t('amountSelectTitle')}</ModalTitle> */}
      <FormGroup className="items-start mb-6">
        <FormLabel>{t('amountSelect')}</FormLabel>
        <FormSelect
          className="min-w-[89px]"
          onChange={(event) => setAmount(+event.currentTarget.value)}
        >
          {Array.from(Array(props.usableAmount).keys()).map((i) => (
            <option key={'amount-' + i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </FormSelect>
      </FormGroup>
      <Button className="w-full" onClick={() => props.onSubmit(amount)}>
        {t('redeem')}
      </Button>
    </Modal>
  );
}

export default EventEntryUseModal;
