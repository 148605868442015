import * as React from 'react';

interface Props extends React.HTMLAttributes<SVGElement> {}

const DashboardFAQIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.375 15.469a1.375 1.375 0 1 1-2.75 0 1.375 1.375 0 0 1 2.75 0ZM20.281 11A9.282 9.282 0 1 1 11 1.719 9.292 9.292 0 0 1 20.281 11Zm-2.062 0A7.219 7.219 0 1 0 11 18.219 7.227 7.227 0 0 0 18.219 11ZM11 5.5c-2.085 0-3.781 1.542-3.781 3.438v.343a1.031 1.031 0 0 0 2.062 0v-.344c0-.757.774-1.374 1.719-1.374.945 0 1.719.617 1.719 1.375 0 .757-.774 1.374-1.719 1.374a1.031 1.031 0 0 0-1.031 1.032v.687a1.031 1.031 0 0 0 2.04.22c1.596-.402 2.772-1.735 2.772-3.313C14.781 7.042 13.085 5.5 11 5.5Z"
    />
  </svg>
);
export default DashboardFAQIcon;
