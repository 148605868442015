import { createContext, useState } from 'react';

interface WebPSupportContextType {
  supportsWebP?: boolean;
}

const WebPSupportContext = createContext<WebPSupportContextType>({
  supportsWebP: false,
});

export function WebPSupportContextProvider(props: any) {
  const [supportsWebP, setSupportsWebP] = useState<undefined | boolean>(true);

  // useEffect(() => {
  //   const elem = document.createElement('canvas');
  //   if (!!(elem.getContext && elem.getContext('2d'))) {
  //     // was able or not to get WebP representation
  //     setSupportsWebP(
  //       elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  //     );
  //   }
  //   // very old browser like IE 8, canvas not supported
  //   setSupportsWebP(false);
  // }, []);

  return (
    <WebPSupportContext.Provider
      value={{
        supportsWebP,
      }}
    >
      {supportsWebP !== undefined && props.children}
    </WebPSupportContext.Provider>
  );
}

export default WebPSupportContext;
