import clsx from 'clsx';
import ProjectMilestoneItemEl from './item';
import { ProjectMilestone } from '../../../../interfaces/project-milestones.interface';
import DashboardBox from '../../../core/dashboard-layout/box';
import DashboardTitle from '../../../core/dashboard-layout/title';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  milestones: ProjectMilestone[];
  noResultsText: string;
  onClickItem: (milestone: ProjectMilestone) => void;
  full?: boolean;
}

function ProjectMilestoneList(props: Props) {
  const els = props.milestones.map((item, i) => (
    <ProjectMilestoneItemEl
      className="max-[500px]:!min-h-[200px]"
      // className="w-[146px]"
      key={'milestone-' + item.id}
      item={item}
      onClick={() => props.onClickItem(item)}
    />
  ));

  return (
    <DashboardBox
      {...{
        ...props,
        className: clsx(props.className, 'overflow-auto'),
      }}
    >
      <DashboardTitle>{props.title}</DashboardTitle>
      {els.length > 0 && (
        <div
          className={clsx(
            'grid gap-6',
            !props.full
              ? 'grid-cols-5 max-[1500px]:grid-cols-4 max-[1400px]:grid-cols-3 max-[1300px]:grid-cols-5 max-[1120px]:grid-cols-4 max-[1023px]:grid-cols-5 max-[850px]:grid-cols-4 max-[675px]:grid-cols-3 max-[500px]:grid-cols-1'
              : 'grid-cols-7 max-[1450px]:grid-cols-6 max-[1320px]:grid-cols-5 max-[1150px]:grid-cols-4 max-[750px]:grid-cols-3 max-[550px]:grid-cols-2 max-[430px]:grid-cols-1'
          )}
        >
          {els}
        </div>
      )}
      {/* {els.length > 0 && <div className="flex space-x-4">{els}</div>} */}
      {els.length < 1 && (
        <div className="text-sm flex items-center py-8">
          {props.noResultsText}
        </div>
      )}
    </DashboardBox>
  );
}

export default ProjectMilestoneList;
