import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Tag } from '../../../interfaces/tag.interface';
import { requestImageUploadForEvent } from '../../../api/events';
import AuthContext from '../../../context/auth-context';
import { EditEventResult } from '../../../interfaces/event.interface';
import DashboardBox from '../../core/dashboard-layout/box';
import DashboardUserProfile from '../../core/dashboard-layout/profile';
import DashboardTitle from '../../core/dashboard-layout/title';
import Button from '../../shared/button';
import DateFormInput from '../../shared/form/date-input';
import FormGroup from '../../shared/form/group';
import FormImageUpload from '../../shared/form/image-upload';
import FormImageUploadBox from '../../shared/form/image-upload/box';
import FormInput from '../../shared/form/input';
import FormLabel from '../../shared/form/label';
import LocationInput from '../../shared/form/location-input';
import TagsInput from '../../shared/form/tags-input';
import FormTextarea from '../../shared/form/textarea';

export interface EventFormData {
  title: string;
  description: string;
  image: string;
  deadline: Date;
  startDate: Date;
  supply: number;
  price: number;
  location: string;
  tags: Tag[];
}

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    description: yup.string().required(),
    deadline: yup.date().required(),
    startDate: yup.date().required(),
    supply: yup.number().integer().min(1).required(),
    price: yup.number().integer().min(1).required(),
    location: yup.string().required(),
    image: yup.string().required(),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required(),
        })
      )
      .min(1)
      .max(5)
      .required(),
  })
  .required();

function EventForm(props: {
  event?: EditEventResult;
  disabled?: boolean;
  price?: number;
  onSubmit: (formData: EventFormData) => void;
}) {
  const { t } = useTranslation('event-form');
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(values: any) {
    props.onSubmit({ ...values, tags: values.tags.map((tag: Tag) => tag.id) });
  }

  // prefill form with already entered data if loaded by going back
  useEffect(() => {
    if (props.event) {
      setValue('title', props.event.title);
      setValue('description', props.event.description);
      setValue('deadline', new Date(props.event.deadline));
      setValue('startDate', new Date(props.event.startDate));
      setValue('price', props.event.price);
      setValue('supply', props.event.supply);
      setValue('location', props.event.location);
      setValue('image', props.event.image);
      setValue('tags', props.event.tags);
    }
  }, [props.event]);

  const minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  console.log('errors?', errors);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full max-w-[1200px] grid grid-cols-[1fr_338px] gap-6 max-xl:grid-cols-1"
    >
      <div className="flex flex-col space-y-6">
        <DashboardBox>
          <DashboardTitle>
            {props.event ? t('editTitle') : t('createTitle')}
          </DashboardTitle>

          <FormGroup>
            <FormLabel required showRequiredInfo>
              {t('title')}
            </FormLabel>
            <FormInput
              {...register('title')}
              error={errors.title !== undefined}
              maxLength={200}
              disabled={props.disabled}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel required>{t('location')}</FormLabel>
            <Controller
              render={({ field }) => (
                <LocationInput
                  onPlaceSelected={(place) => {
                    console.log('myplace', place);
                    field.onChange(place.formatted_address);
                  }}
                  error={errors.location !== undefined}
                  disabled={props.disabled}
                  defaultValue={field.value}
                />
              )}
              control={control}
              name="location"
            />
          </FormGroup>

          <div className="grid grid-cols-3 gap-4 max-[1400px]:grid-cols-2 max-[555px]:grid-cols-1 max-[555px]:gap-0">
            <FormGroup>
              <FormLabel required>{t('startDate')}</FormLabel>

              <Controller
                render={({ field }) => (
                  <DateFormInput
                    value={field.value}
                    onChangeDate={field.onChange}
                    error={errors.deadline !== undefined}
                    disabled={props.disabled}
                    minDate={minDate}
                    showTimeInput
                  />
                )}
                control={control}
                name="startDate"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel required>{t('deadline')}</FormLabel>

              <Controller
                render={({ field }) => (
                  <DateFormInput
                    value={field.value}
                    onChangeDate={field.onChange}
                    error={errors.deadline !== undefined}
                    disabled={props.disabled}
                    minDate={minDate}
                    showTimeInput
                  />
                )}
                control={control}
                name="deadline"
              />
            </FormGroup>
          </div>

          <div className="grid grid-cols-3 gap-4 max-[1400px]:grid-cols-2 max-[555px]:grid-cols-1 max-[555px]:gap-0">
            <FormGroup>
              <FormLabel required>{t('price')}</FormLabel>

              <FormInput
                {...register('price')}
                type="number"
                error={errors.price !== undefined}
                placeholder="1"
                min="1"
                disabled={props.disabled}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel required>{t('supply')}</FormLabel>

              <FormInput
                {...register('supply')}
                type="number"
                error={errors.supply !== undefined}
                placeholder="1"
                min="1"
                disabled={props.disabled}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <FormLabel required>{t('description')}</FormLabel>
            <FormTextarea
              {...register('description')}
              error={errors.description !== undefined}
              rows={6}
              maxLength={2000}
              disabled={props.disabled}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel required>{t('image')}</FormLabel>
            <div className="w-full max-w-[400px]">
              <Controller
                control={control}
                name="image"
                render={({ field: { onChange, value } }) => (
                  <FormImageUpload
                    aspectRatio={538 / 327}
                    value={value || undefined}
                    onChange={onChange}
                    onError={(error: any) => {}}
                    disabled={props.disabled}
                    el={(value?: string, loading?: boolean) => {
                      return (
                        <FormImageUploadBox
                          className="w-full aspect-[538/327]"
                          value={value}
                          loading={loading || props.disabled}
                          imageWidth={600}
                          error={errors.image !== undefined}
                          text={t('addImage')}
                        />
                      );
                    }}
                    getUploadUrlApi={(file) =>
                      requestImageUploadForEvent(file.name)
                    }
                  />
                )}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <FormLabel required>{t('tags')}</FormLabel>
            <Controller
              render={({ field }) => (
                <TagsInput
                  tags={field.value}
                  onChange={field.onChange}
                  placeholder={t('tagsPlaceholder')}
                  error={errors.tags !== undefined}
                  disabled={props.disabled}
                />
              )}
              control={control}
              name="tags"
            />
            {errors.tags && errors.tags.type === 'min' && (
              <div className="text-sm text-danger">{t('errors.tagsMin')}</div>
            )}
            {errors.tags && errors.tags.type === 'max' && (
              <div className="text-sm text-danger">{t('errors.tagsMax')}</div>
            )}
          </FormGroup>
        </DashboardBox>
      </div>
      <div className="flex flex-col space-y-4">
        <DashboardUserProfile user={user!} className="max-xl:hidden" />
        <Button
          disabled={props.disabled}
          className="flex items-center justify-center"
        >
          {props.event ? (
            t('save')
          ) : (
            <>
              <span className="pr-1">{t('create')}</span>
              <span className="pr-1">({props.price!}</span>
              <img
                src="/images/price.svg"
                width="12"
                height="12"
                alt="create"
              />
              <span>)</span>
            </>
          )}
        </Button>
      </div>
    </form>
  );
}

export default EventForm;
