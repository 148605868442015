import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  current: number;
  max: number;
}

function ProgressLines(props: Props) {
  let className = clsx(`flex space-x-4`, props.className);

  const lines = Array.from(Array(props.max).keys()).map((i) => {
    return (
      <div
        key={'progress-line-' + i}
        className={
          'rounded flex-1 h-1.5 ' +
          (props.current === i
            ? 'bg-gradient-to-r from-primary to-secondary'
            : 'bg-[#EEF0F6]')
        }
      ></div>
    );
  });

  return (
    <div {...props} className={className}>
      {lines}
    </div>
  );
}

export default ProgressLines;
