import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Web3Context from '../../../../../context/web3-context';
import {
  rejectProjectCollaboratorInvite,
  getRejectProjectCollaboratorInviteDataHash,
  getRejectProjectCollaboratorInviteForwardRequest,
} from '../../../../../api/projects-collaborator-invites';
import AuthContext from '../../../../../context/auth-context';
import { ProjectCollaboratorInviteDetail } from '../../../../../interfaces/project-collaborator-invite.interface';
import { loadEDIHProjectsContract } from '../../../../../utils/contracts/edih-projects';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import FormLabel from '../../../../shared/form/label';
import FormTextarea from '../../../../shared/form/textarea';

import Notification from '../../../../shared/notification';
import Modal from '../../../../shared/modal';
import {
  generateTypedSignature,
  getCurrentProviderService,
  getProvider,
} from '../../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../../classes/unchecked-json-rpc-signer';
import { sleep } from '../../../../../utils/utils';

const schema = yup
  .object()
  .shape({
    reason: yup.string().required(),
  })
  .required();

function RejectProjectCollaboratorInviteModal(props: {
  invite: ProjectCollaboratorInviteDetail;
  onClose: () => void;
  onReject: (reason: string) => void;
}) {
  const { t } = useTranslation('project-collaborator-invite');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: { reason: string }) {
    console.log('submit!', values);
    setLoading(true);
    setError(undefined);
    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          const hash = await fetchDataHash(values.reason);

          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihProjectsContract = await loadEDIHProjectsContract(signer);
          const result =
            await edihProjectsContract.rejectProjectCollaboratorInvite(
              props.invite.project.contractId,
              hash
            );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchRejectForwardRequest(values.reason);
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await rejectProjectCollaboratorInvite(
            props.invite.id,
            tx,
            signature,
            values.reason
          );
          props.onReject(values.reason);
        } catch (err: any) {
          console.error('failed to reject invite', err);
          setError(t('reject.errors.reject'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('reject.errors.transaction'));
        setLoading(false);
      }

      // const edihProjectsContract = await loadEDIHProjectsContract();
      // edihProjectsContract
      //   .rejectProjectCollaboratorInvite(
      //     user!.walletAddress,
      //     props.invite.project.contractId,
      //     hash
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('rejected', tx);
      //     try {
      //       await rejectProjectCollaboratorInvite(
      //         props.invite.id,
      //         tx,
      //         values.reason
      //       );
      //       props.onReject(values.reason);
      //     } catch (err: any) {
      //       console.error('failed to reject invite', err);
      //       setError(t('reject.errors.reject'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('reject.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function fetchDataHash(reason: string) {
    try {
      const hash = await getRejectProjectCollaboratorInviteDataHash(
        props.invite.id,
        reason
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  async function fetchRejectForwardRequest(reason: string) {
    try {
      let hash = await getRejectProjectCollaboratorInviteForwardRequest(
        props.invite.id,
        reason
      );
      return hash;
    } catch (err) {
      setError(t('errors.fetchHash'));

      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('reject.reason')}</FormLabel>
          <FormTextarea
            {...register('reason')}
            error={errors.reason !== undefined}
            rows={6}
            disabled={loading}
          />
        </FormGroup>
        <Button className="mt-3 w-full" color="secondary" disabled={loading}>
          {t('reject.button')}
        </Button>
      </form>
    </Modal>
  );
}

export default RejectProjectCollaboratorInviteModal;
