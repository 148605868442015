import clsx from 'clsx';
import styles from './index.module.css';

function MyTable(props: {
  headers: string[];
  className?: string;
  children: any;
}) {
  const els = props.headers.map((header) => {
    return (
      <th
        key={header}
        className="font-normal text-sm text-font-grey pb-1 px-1.5"
      >
        {header}
      </th>
    );
  });

  return (
    <>
      <table
        className={clsx(
          'border-collapse w-full text-left border-b border-grey-2',
          props.className,
          styles.table
        )}
      >
        <thead>
          <tr className="px-1 whitespace-nowrap">{els}</tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </>
  );
}

export default MyTable;
