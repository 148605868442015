import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  onSearch: (search: string) => void;
}

function DashboardSearch(props: Props) {
  const inputRef = useRef<any>();
  const timeoutRef = useRef<any>();

  const { t } = useTranslation('common');

  let className = clsx(
    'flex align-items-center bg-white px-3 py-2.5 rounded-lg',
    props.className
  );

  function onInput(event: any) {
    clearTimeout(timeoutRef.current);

    console.log('timeout search', event.target.value);

    timeoutRef.current = setTimeout(() => {
      onSearch();
    }, +process.env.REACT_APP_SEARCH_TIMEOUT!);
  }

  function onSearch() {
    props.onSearch(
      inputRef.current.value === '' ? undefined : inputRef.current.value
    );

    clearTimeout(timeoutRef.current);
  }

  return (
    <div {...props} className={className}>
      <img
        className="mr-3"
        width={18}
        height={18}
        src="/images/dashboard/search.svg"
        alt="search"
        onClick={onSearch}
      />
      <input
        ref={inputRef}
        className="text-sm outline-none flex-1 placeholder:text-font-grey"
        placeholder={props.placeholder || t('search')}
        onInput={onInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      />
    </div>
  );
}

export default DashboardSearch;
