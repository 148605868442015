import { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import { GetProjectsResult } from '../../../interfaces/project.interface';
import { participatingProjectsOfUserRoute } from '../../../utils/routes';
import HorizontalProjectList from '../../projects/horizontal-list';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  userId: string;
  result: GetProjectsResult;
  notFoundText?: string;
  viewMoreText: string;
  loading?: boolean;
  small?: boolean;
}

function ParticipatingProjectsOfUser(props: Props) {
  const [projects, setProjects] = useState<GetProjectsResult>(props.result);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setProjects(props.result);
  }, [props.result]);

  // async function loadProjects() {
  //   setLoading(true);
  //   try {
  //     const result = await getParticipatingProjectsOfUser(
  //       props.userId,
  //       projects.page + 1,
  //       {}
  //     );
  //     setProjects({
  //       ...result,
  //       results: projects.results.concat(...result.results),
  //     });

  //     console.log('participating projects loaded', projects);
  //   } catch (err: any) {
  //     console.error('failed to load participating projects', err);
  //   }
  //   setLoading(false);
  // }

  function onViewMore() {
    navigate(participatingProjectsOfUserRoute(props.userId));
    // if (props.viewMoreOnNewPage) {
    // } else {
    //   loadProjects();
    // }
  }

  return (
    <>
      {projects && projects.results.length > 0 && (
        <HorizontalProjectList
          className={props.className}
          result={projects}
          title={props.title}
          notFoundText={props.notFoundText || ''}
          viewMoreText={props.viewMoreText}
          onViewMore={onViewMore}
          // loading={props.loading || loading}
          small={props.small}
        />
      )}
    </>
  );
}

export default ParticipatingProjectsOfUser;
