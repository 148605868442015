import { EventFormData } from '../components/events/form';
import {
  EventItem,
  EventState,
  GetEventsResult,
  EventDetail,
  EditEventResult,
  EventToManage,
  EventUpdate,
} from '../interfaces/event.interface';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import { ProjectUpdate } from '../interfaces/project.interface';
import { UploadRequestResponse } from '../interfaces/upload-request-response';
import myAxios from '../utils/my-axios';

export const requestImageUploadForEvent = async (
  fileName: string
): Promise<UploadRequestResponse> => {
  const { data } = await myAxios.post('/events/request-image-upload', {
    fileName,
  });

  return data.data;
};

export const getEventDataHashForCreation = async (
  formData: EventFormData
): Promise<string> => {
  const { data } = await myAxios.post('/events/data-hash', formData);

  return data.data;
};

export const getEventDataHashForUpdate = async (
  eventId: string,
  formData: EventFormData
): Promise<string> => {
  const { data } = await myAxios.post(`/events/${eventId}/data-hash`, formData);

  return data.data;
};

export const getCreateEventForwardRequest = async (
  formData: EventFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    '/events/create-forward-request',
    formData
  );

  return data.data;
};

export const createEvent = async (
  formData: EventFormData,
  tx?: string,
  signature?: string
): Promise<EventItem> => {
  const { data } = await myAxios.post('/events', {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export const getUpdateEventForwardRequest = async (
  eventId: string,
  formData: EventFormData
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/update-forward-request`,
    formData
  );

  return data.data;
};

export const updateEvent = async (
  eventId: string,
  formData: EventFormData,
  tx?: string,
  signature?: string
): Promise<ProjectUpdate> => {
  const { data } = await myAxios.patch(`/events/${eventId}`, {
    ...formData,
    tx,
    signature,
  });

  return data.data;
};

export interface GetMyEventsFilter {
  search?: string;
  states?: EventState[];
}

export const getMyEvents = async (
  page: number,
  filter: GetMyEventsFilter
): Promise<GetEventsResult> => {
  const { data } = await myAxios.post('/events/my', {
    page,
    ...filter,
  });

  return data.data;
};

export interface GetOtherEventsFilter {
  search?: string;
  states?: EventState[];
  tags?: string[];
}

export const getOtherEvents = async (
  page: number,
  filter: GetOtherEventsFilter
): Promise<GetEventsResult> => {
  const { data } = await myAxios.post('/events/other', {
    page,
    ...filter,
  });

  return data.data;
};

export const canEndEvent = async (eventId: string): Promise<void> => {
  const { data } = await myAxios.get(`/events/${eventId}/can-end`);

  return data.data;
};

export const getEndEventForwardRequest = async (
  eventId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(`/events/${eventId}/end-forward-request`);
  return data.data;
};

export const endEvent = async (
  eventId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/end`, {
    tx,
    signature,
  });

  return data.data;
};

export const getEvent = async (eventId: string): Promise<EventDetail> => {
  const { data } = await myAxios.get(`/events/${eventId}`);

  return data.data;
};

export const getEventToEdit = async (
  eventId: string
): Promise<EditEventResult> => {
  const { data } = await myAxios.get(`/events/${eventId}/edit`);

  return data.data;
};

export const getEventToManage = async (
  eventId: string
): Promise<EventToManage> => {
  const { data } = await myAxios.get(`/events/${eventId}/manage`);

  return data.data;
};

export const canRateEvent = async (eventId: string): Promise<void> => {
  const { data } = await myAxios.get(`/events/${eventId}/can-rate`);

  return data.data;
};

export const getRateEventForwardRequest = async (
  eventId: string,
  rating: number
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/rate-forward-request`,
    {
      rating,
    }
  );

  return data.data;
};

export const rateEvent = async (
  eventId: string,
  rating: number,
  tx?: string,
  signature?: string,
  feedback?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/rate`, {
    rating,
    feedback,
    tx,
    signature,
  });

  return data.data;
};

export const canCheckEventRatingDeadline = async (
  eventId: string
): Promise<void> => {
  const { data } = await myAxios.get(
    `/events/${eventId}/can-check-rating-deadline`
  );

  return data.data;
};

export const getCheckEventRatingDeadlineForwardRequest = async (
  eventId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/events/${eventId}/check-rating-deadline-forward-request`
  );

  return data.data;
};

export const checkEventRatingDeadline = async (
  eventId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/events/${eventId}/check-rating-deadline`,
    {
      tx,
      signature,
    }
  );

  return data.data;
};

export const canClaimEventReward = async (eventId: string): Promise<void> => {
  const { data } = await myAxios.get(`/events/${eventId}/can-claim-reward`);

  return data.data;
};

export const getClaimEventRewardForwardRequest = async (
  eventId: string
): Promise<ForwardRequestTypedData> => {
  const { data } = await myAxios.get(
    `/events/${eventId}/claim-reward-forward-request`
  );

  return data.data;
};

export const claimEventReward = async (
  eventId: string,
  tx?: string,
  signature?: string
): Promise<void> => {
  const { data } = await myAxios.post(`/events/${eventId}/claim-reward`, {
    tx,
    signature,
  });

  return data.data;
};

export const getEventUpdate = async (
  eventUpdateId: string
): Promise<EventUpdate> => {
  const { data } = await myAxios.get(`/events/updates/${eventUpdateId}`);

  return data.data;
};
