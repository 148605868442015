import clsx from 'clsx';
import React from 'react';

export interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const FormInput = React.forwardRef((props: FormInputProps, ref?: any) => {
  let className = clsx(
    'border outline-none p-[0.813rem] px-3 text-font-black rounded text-sm placeholder:text-font-grey',
    props.error ? 'border-red-500 bg-red-100' : 'border-grey',
    props.className
  );

  return (
    <input {...{ ...props, error: undefined }} className={className} ref={ref}>
      {props.children}
    </input>
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
