import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from 'react';
import {
  GetMyEventEntriesFilter,
  getMyEventEntries,
  getSpecificEventEntries,
} from '../../../api/events-entries';
import DashboardSearch from '../../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../components/core/dashboard-layout/search-filter/modal/item';
import UserDashboard from '../../../components/core/dashboard-layout/user';
import EventEntryList from '../../../components/events/entries';
import Loading from '../../../components/shared/loading';
import { GetEventEntriesResult } from '../../../interfaces/event-entry.interface';
import { otherEventsRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

const eventEntriesStateLabels = {
  idle: ['idle', 'waitingForUse'],
  used: ['used'],
  verified: ['verified'],
};

export default function MyEventEntriesPage() {
  const { t } = useTranslation('my-event-entries');
  const commonTranslations = useTranslation('common');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEventEntriesResult>();
  const [filter, setFilter] = useState<GetMyEventEntriesFilter>({});
  const pollTimeout = useRef<any>();

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('states.title'),
      key: 'states',
      items: [
        { label: t('states.all') },
        { label: t('states.idle'), value: 'idle' },
        { label: t('states.used'), value: 'used' },
        { label: t('states.verified'), value: 'verified' },
      ],
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    setLoading(true);
    try {
      const result = await getMyEventEntries(page, filter);
      setData(result);
    } catch (err) {
      console.error('failed to get entries', err);
      setError(t('errors.load'));
    }
    setLoading(false);
  }

  function onUse(entries: { id: string }[], tx: string) {
    setData({
      ...data!,
      results: data!.results.map((result) => {
        if (entries.find((e) => e.id === result.id)) {
          result.state = 'waitingForUse';
        }

        return result;
      }),
    });
  }

  function onTransfer(entries: { id: string }[], tx: string) {
    setData({
      ...data!,
      results: data!.results.map((result) => {
        if (entries.find((e) => e.id === result.id)) {
          result.state = 'transferring';
        }

        return result;
      }),
    });
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({
      ...filter,
      states:
        value === ''
          ? undefined
          : (eventEntriesStateLabels as any)[value as any],
    });
  }

  useEffect(() => {
    if (!data) return;
    if (
      data.results.find(
        (e) => e.state === 'waitingForUse' || e.state === 'transferring'
      ) &&
      !pollTimeout.current
    ) {
      startPolling();
    } else if (
      pollTimeout.current &&
      !data.results.find(
        (e) => e.state === 'waitingForUse' || e.state === 'transferring'
      )
    ) {
      stopPolling();
    }
  }, [data]);

  function startPolling() {
    console.log('initialize polling!!');
    pollTimeout.current = setInterval(() => {
      fetchWaitingForUseEntries();
    }, 5000);
  }

  function stopPolling() {
    console.log('stop polling!!!');
    clearInterval(pollTimeout.current);
    pollTimeout.current = undefined;
  }

  async function fetchWaitingForUseEntries() {
    try {
      const ids = data!.results
        .filter(
          (e) => e.state === 'waitingForUse' || e.state === 'transferring'
        )
        .map((e) => e.id);

      if (ids.length > 0) {
        console.log('fetch ids', ids);
        const entries = await getSpecificEventEntries(ids);

        // update specific event entries
        let updatedEntries = data!.results.map((result) => {
          const entry = entries.find((e) => e.id === result.id);

          if (entry) {
            return entry;
          }

          return result;
        });

        // if not all ids were fetched, then a transfer ocurred, so remove affected event entries
        for (const id of ids) {
          if (!entries.find((e) => e.id === id)) {
            updatedEntries = updatedEntries.filter((e) => e.id !== id);
          }
        }

        setData({
          ...data!,
          results: updatedEntries,
        });
      }
    } catch (err) {
      console.error('failed to fetch waiting for use entries');
    }
  }

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myEntries'),
        },
      ]}
      selectedPath={otherEventsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1100px] flex flex-col min-h-full">
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <EventEntryList
            result={data}
            onPageChange={(page: number) => fetchItems(page)}
            loading={loading}
            onUse={onUse}
            onTransfer={onTransfer}
            notFoundText={t('notFound')}
          />
        </div>
      )}
    </UserDashboard>
  );
}
