import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from 'react';
import { getEndorsements } from '../../api/endorsements';
import UserDashboard from '../../components/core/dashboard-layout/user';
import Endorsements from '../../components/endorsements';
import Loading from '../../components/shared/loading';
import { GetEndorsementsResult } from '../../interfaces/endorsement.interface';
import { endorsementsRoute } from '../../utils/routes';
import Notification from '../../components/shared/notification';

export default function EndorsementsPage() {
  const { t } = useTranslation('endorsements');
  const [endorsements, setEndorsements] = useState<GetEndorsementsResult>();
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [claimed, setClaimed] = useState<boolean>(false);
  const waitForConfirmTimeout = useRef<any>();
  const commonTranslations = useTranslation('common');

  useEffect(() => {
    loadEndorsements(page);
  }, [page]);

  // poll for transaction confirm
  useEffect(() => {
    if (
      endorsements &&
      endorsements.transaction.state === 'claiming' &&
      !waitForConfirmTimeout.current
    ) {
      startWaitingForTransactionConfirmation();
    }
  }, [endorsements]);

  useEffect(() => {
    loadEndorsements(page);
  }, [page]);

  async function loadEndorsements(page: number, waitForConfirmFetch?: boolean) {
    setLoading(true);
    setError(undefined);
    try {
      const result = await getEndorsements(page);

      if (waitForConfirmFetch) {
        setEndorsements({
          ...result!,
          endorsements: endorsements!.endorsements,
        });
      } else if (endorsements) {
        setEndorsements({
          ...endorsements,
          endorsements: {
            ...result.endorsements,
            results: endorsements.endorsements.results.concat(
              ...result.endorsements.results
            ),
          },
        });
      } else {
        setEndorsements(result);
      }
      console.log('endorsements loaded', result);
      return result;
    } catch (err) {
      console.error('failed to load endorsements', err);
      setError(t('errors.load'));
    }

    setLoading(false);
  }

  function onClaimed() {
    setEndorsements({
      ...endorsements,
      transaction: { ...endorsements?.transaction, state: 'claiming' },
    } as any);
    setClaimed(true);
  }

  function startWaitingForTransactionConfirmation() {
    waitForConfirmTimeout.current = setTimeout(async () => {
      console.log('wait...');
      const result = await loadEndorsements(page, true);

      if (result && result.transaction.state === 'claimable') {
        setError(t('errors.waitForTransactionFail'));
      } else if (!result || result.transaction.state === 'claiming') {
        startWaitingForTransactionConfirmation();
      }
    }, 5000);
  }

  function onLoadMore() {
    // if errored then reload same page, otherwise next
    if (error) {
      loadEndorsements(page);
    } else {
      setPage(page + 1);
    }
  }

  useEffect(() => {
    return () => {
      if (waitForConfirmTimeout.current) {
        clearTimeout(waitForConfirmTimeout.current);
      }
      console.log('cancel poll', waitForConfirmTimeout.current);
    };
  }, []);

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.endorsements'),
        },
      ]}
      selectedPath={endorsementsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {claimed && <Notification color="success">{t('claimSent')}</Notification>}
      {endorsements && (
        <Endorsements
          endorsements={endorsements}
          onClaimed={onClaimed}
          onLoadMore={onLoadMore}
          loadingMore={loading}
        />
      )}
      {!endorsements && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
    </UserDashboard>
  );
}
