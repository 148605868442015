import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { GetUsersFilter, getUsers } from '../../../api/users';
import UserItemRow from '../../../components/admin/users/item';
import AdminDashboard from '../../../components/core/dashboard-layout/admin';
import DashboardBox from '../../../components/core/dashboard-layout/box';
import DashboardSearch from '../../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../components/core/dashboard-layout/search-filter/modal/item';
import MyTable from '../../../components/shared/table';
import { GetUsersResult } from '../../../interfaces/user.interface';
import { adminUsersRoute } from '../../../utils/routes';
import Notification from '../../../components/shared/notification';

export default function UsersPage() {
  const { t } = useTranslation('users');
  const [error, setError] = useState<string>();
  const [data, setData] = useState<GetUsersResult>();
  const [filter, setFilter] = useState<GetUsersFilter>({});

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('table.roles.title'),
      key: 'roles',
      items: t('table.roles.values', { returnObjects: true }),
    },
    {
      title: t('table.whitelisted.title'),
      key: 'whitelisted',
      items: t('table.whitelisted.values', { returnObjects: true }),
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    try {
      console.log('search with filter', filter);
      const result = await getUsers(page, filter);
      setData(result);
    } catch (err) {
      console.error('failed to get users', err);
      setError(t('errors.load'));
    }
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    setFilter({
      ...filter,
      [key]: value === 'all' || value === '' ? undefined : value,
    });
  }

  return (
    <AdminDashboard
      title={t('dashboard.title')}
      subtitle={t('dashboard.subtitle')}
      selectedPath={adminUsersRoute}
    >
      {data && (
        <div className="max-w-[1200px] flex flex-col min-h-full">
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <DashboardBox className="flex-1 flex flex-col">
            {error && <Notification color="danger">{error}</Notification>}

            <div
              className="overflow-auto"
              style={{
                maxWidth: 'calc(100vw - 72px)',
              }}
            >
              <MyTable
                className="mb-5"
                headers={t('table.headers', { returnObjects: true }) as any}
              >
                {data.results.map((item) => (
                  <UserItemRow key={item.id} item={item} />
                ))}
              </MyTable>
            </div>

            <div className="flex justify-center mt-auto">
              <ReactPaginate
                breakLabel="..."
                containerClassName="flex space-x-4 text-xs text-font-grey"
                activeClassName="text-font-black"
                nextClassName="text-font-black"
                previousClassName="text-font-black"
                nextLabel=">"
                previousLabel="<"
                onPageChange={(item) => fetchItems(item.selected)}
                pageRangeDisplayed={5}
                pageCount={data.pages}
                renderOnZeroPageCount={null}
              />
            </div>
          </DashboardBox>
        </div>
      )}
    </AdminDashboard>
  );
}
