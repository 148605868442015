import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import EventDetailEntryScannersModal from './list-modal';
import { EventToManage } from '../../../interfaces/event.interface';
import Button from '../../shared/button';

export default function EventDetailEntryScanners(props: {
  event: EventToManage;
  loading: boolean;
}) {
  const { t } = useTranslation('manage-event');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        className="min-w-[188px]"
        color="blue"
        size="medium"
        disabled={props.loading}
        onClick={() => setShowModal(true)}
      >
        {t('manageScanners.button')}
      </Button>
      {showModal && (
        <EventDetailEntryScannersModal
          event={props.event}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}
