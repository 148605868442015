import { PaginationResult } from './pagination-result.interface';
import { UserProfile, UserProfileItem } from './user.interface';

export const projectCollaboratorInviteStates: ProjectCollaboratorInviteState[] =
  ['waiting', 'sent', 'accepting', 'accepted', 'rejecting', 'rejected'];

export type ProjectCollaboratorInviteState =
  | 'waiting'
  | 'sent'
  | 'accepting'
  | 'accepted'
  | 'rejecting'
  | 'rejected';

export interface ProjectCollaboratorInviteDetail {
  id: string;
  project: {
    id: string;
    title: string;
    user: UserProfile;
    contractId: string;
  };
  user: UserProfileItem;
  state: ProjectCollaboratorInviteState;
  message: string;
  deadline: string;
  tx: string;
  files: { path: string; size: number }[];
  rejectReason?: string;
  createdAt: Date;
}

export interface GetProjectCollaboratorInvitesResult
  extends PaginationResult<ProjectCollaboratorInviteItem> {
  project: {
    userId: string;
    title: string;
  };
}

export interface ProjectCollaboratorInviteItem {
  id: string;
  state: ProjectCollaboratorInviteState;
  createdAt: Date;
  user: UserProfileItem;
}
