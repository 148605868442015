import { useEffect, useState } from 'react';
import HorizontalMemberList from '..';
import { useNavigate } from 'react-router-dom';
import { GetEndorsementsForUserResult } from '../../../../../api/endorsements';
import { endorsersOfUserRoute } from '../../../../../utils/routes';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  userId: string;
  result: GetEndorsementsForUserResult;
  notFoundText?: string;
  viewMoreText: string;
  loading?: boolean;
  // viewMoreOnNewPage?: boolean;
}

function EndorsementsForMember(props: Props) {
  const [endorsements, setEndorsements] =
    useState<GetEndorsementsForUserResult>(props.result);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setEndorsements(props.result);
  }, [props.result]);

  // async function loadEndorsements() {
  //   setLoading(true);
  //   try {
  //     const result = await getEndorsementsForUser(
  //       props.userId,
  //       endorsements.page + 1,
  //       {}
  //     );
  //     setEndorsements({
  //       ...result,
  //       results: endorsements.results.concat(...result.results),
  //     });

  //     console.log('endorsements for user loaded', result);
  //   } catch (err: any) {
  //     console.error('failed to load endorsements for user', err);
  //   }
  //   setLoading(false);
  // }

  function onViewMore() {
    // loadEndorsements();
    navigate(endorsersOfUserRoute(props.userId));

    // if (props.viewMoreOnNewPage) {
    //   router.push(participatingProjectsRoute);
    // } else {
    //   loadEndorsements();
    // }
  }

  return (
    <>
      {endorsements && endorsements.results.length > 0 && (
        <HorizontalMemberList
          result={{
            ...endorsements,
            results: endorsements.results.map((item) => item.sender),
          }}
          title={props.title}
          notFoundText={props.notFoundText || ''}
          viewMoreText={props.viewMoreText}
          onViewMore={onViewMore}
          loading={props.loading || loading}
        />
      )}
    </>
  );
}

export default EndorsementsForMember;
