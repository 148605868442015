import clsx from 'clsx';
import ProjectCollaboratorInviteItemElState from './state';
import { Link } from 'react-router-dom';
import { ProjectCollaboratorInviteItem } from '../../../../../interfaces/project-collaborator-invite.interface';
import { projectCollaboratorInviteRoute } from '../../../../../utils/routes';
import UserProfileItemEl from '../../../../users/item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projectId: string;
  item: ProjectCollaboratorInviteItem;
}

function ProjectCollaboratorInviteItemEl(props: Props) {
  return (
    <div
      {...{
        ...props,
        item: undefined,
        className: clsx(props.className, 'relative'),
      }}
    >
      <Link to={projectCollaboratorInviteRoute(props.projectId, props.item.id)}>
        <UserProfileItemEl item={props.item.user} />
      </Link>
      <ProjectCollaboratorInviteItemElState state={props.item.state} />
    </div>
  );
}

export default ProjectCollaboratorInviteItemEl;
