import { useTranslation } from 'react-i18next';
import { Tag } from '../../../../interfaces/tag.interface';
import {
  SupportedLang,
  getPropByLang,
} from '../../../../translations/i18n-settings';
import FormCheckbox from '../checkbox';

function TagsCheckList(props: {
  onChange: (selectedTagIds: string[]) => void;
  tags: Tag[];
  selectedTagIds: string[];
  disabled?: boolean;
}) {
  const { i18n } = useTranslation();

  function onChange(tag: Tag, checked: boolean) {
    console.log('changed', tag, checked);
    if (checked) {
      props.onChange(props.selectedTagIds.concat(tag.id));
    } else {
      props.onChange(props.selectedTagIds.filter((t) => t !== tag.id));
    }
  }

  const els = props.tags.map((tag) => (
    <FormCheckbox
      key={tag.id}
      checkboxProps={{
        value: tag.id,
        onChange: (ev) => onChange(tag, ev.target.checked),
        defaultChecked:
          props.selectedTagIds.findIndex((t) => t === tag.id) >= 0,
      }}
    >
      <span>{getPropByLang(i18n.language as SupportedLang, tag)}</span>
    </FormCheckbox>
  ));

  return <div className="flex flex-col space-y-3">{els}</div>;
}

export default TagsCheckList;
