import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import EndorseModal from './modal';
import { EndorsementState } from '../../../../../interfaces/endorsement.interface';
import { UserProfile } from '../../../../../interfaces/user.interface';
import Button from '../../../../shared/button';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  user: UserProfile;
  onEndorsementStateChanged: (state: EndorsementState) => void;
}

function Endorse(props: Props) {
  const { t } = useTranslation('dashboard-profile');
  const [showEndorseModal, setShowEndorseModal] = useState(false);
  // const { connect } = useContext(Web3Context);

  // const [loading, setLoading] = useState(false);

  // async function onEndorse() {
  //   setLoading(true);

  //   try {
  //     // get and validate wallet address
  //     const address = await connect({ hasToBeAddress: user!.walletAddress });

  //     // call setUser function in EDIH contract
  //     const edihContract = await loadEDIHContract();
  //     console.log('loaded contract');
  //     edihContract
  //       .endorse(address, props.user.walletAddress)
  //       .on('transactionHash', async (tx: string) => {
  //         console.log('endorsed', tx);
  //         try {
  //           await endorse(props.user.id, tx);
  //         } catch (err: any) {
  //           console.error('failed to log endorse', err);
  //         }
  //         props.onEndorsementStateChanged('waiting');
  //         setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         console.error('failed to send transaction', err);
  //         toast.error(t('endorsement.errors.transaction'), { duration: 3000 });
  //         setLoading(false);
  //       });
  //     console.log('submitted');
  //   } catch (err) {
  //     console.log('failed to connect', err);
  //     setLoading(false);
  //   }
  // }

  function onEndorse() {
    props.onEndorsementStateChanged('waiting');
    setShowEndorseModal(false);
  }

  return (
    <>
      {!props.user.endorsementState && (
        <Button
          {...({
            ...props,
            user: undefined,
            endorsementState: undefined,
          } as any)}
          disabled={props.user.endorsementState === 'waiting'}
          size="small"
          onClick={() => setShowEndorseModal(true)}
        >
          {t('endorsement.endorse')}
        </Button>
      )}
      {(props.user.endorsementState === 'waiting' ||
        props.user.endorsementState === 'confirmed') && (
        <Button
          {...({
            ...props,
            user: undefined,
            endorsementState: undefined,
          } as any)}
          size="small"
          color="secondary"
          disabled
        >
          {props.user.endorsementState === 'confirmed'
            ? t('endorsement.confirmed')
            : t('endorsement.waiting')}
        </Button>
      )}
      {showEndorseModal && (
        <EndorseModal
          userId={props.user.id}
          walletAddress={props.user.walletAddress}
          userTags={props.user.tags!}
          onEndorse={onEndorse}
          onClose={() => setShowEndorseModal(false)}
        />
      )}
    </>
  );
}

export default Endorse;
