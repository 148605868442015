import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  fadeIn?: boolean;
  centerY?: boolean;
}

function Loading(props: Props) {
  let className = clsx(
    'w-full h-full relative',
    props.fadeIn ? 'animate-fade-in' : undefined,
    props.className
  );

  return (
    <div {...props} className={className}>
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 -translate-x-1/2',
          props.centerY ? '-translate-y-1/2' : '-translate-y-24'
        )}
      >
        <img
          className="animate-spin"
          src="/images/loading.png"
          width={30}
          height={30}
          alt="loading"
        />
      </div>
    </div>
  );
}

export default Loading;
