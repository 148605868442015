import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  GetProjectCollaboratorRecommendationsFilter,
  getProjectCollaboratorRecommendations,
} from '../../../../../api/projects-collaborator-recommendations';
import DashboardSearch from '../../../../../components/core/dashboard-layout/search';
import DashboardSearchFilter from '../../../../../components/core/dashboard-layout/search-filter';
import { DashboardSearchFilterItem } from '../../../../../components/core/dashboard-layout/search-filter/modal/item';
import UserDashboard from '../../../../../components/core/dashboard-layout/user';
import ProjectCollaboratorRecommendationList from '../../../../../components/projects/collaborators/recommendations';
import Button from '../../../../../components/shared/button';
import Loading from '../../../../../components/shared/loading';
import {
  GetProjectCollaboratorRecommendationsResult,
  projectCollaboratorRecommendationStates,
} from '../../../../../interfaces/project-collaborator-recommendation.interface';
import {
  myProjectsRoute,
  projectRoute,
  projectCollaboratorsRoute,
  projectCollaboratorPlatformRecommendationsRoute,
} from '../../../../../utils/routes';
import Notification from '../../../../../components/shared/notification';

export default function ProjectCollaboratorRecommendationsPage() {
  const { t } = useTranslation('project-collaborator-recommendations');
  const commonTranslations = useTranslation('common');
  const statesTranslations = useTranslation(
    'project-collaborator-recommendation-states'
  );
  const [error, setError] = useState<string>();
  const [data, setData] =
    useState<GetProjectCollaboratorRecommendationsResult>();
  const [filter, setFilter] =
    useState<GetProjectCollaboratorRecommendationsFilter>({});

  const { projectId } = useParams();

  const filterItems: DashboardSearchFilterItem[] = [
    {
      title: t('states.title'),
      key: 'state',
      items: [{ label: t('states.all') }].concat(
        ...projectCollaboratorRecommendationStates.map((state) => {
          return { label: statesTranslations.t(state), value: state };
        })
      ),
    },
  ];

  useEffect(() => {
    fetchItems(0);
  }, [filter]);

  async function fetchItems(page: number) {
    setError(undefined);
    try {
      console.log('search with filter', filter);
      const result = await getProjectCollaboratorRecommendations(
        page,
        projectId! as string,
        filter
      );
      console.log('loaded', result);
      setData(result);
    } catch (err) {
      console.error('failed to get project invites', err);
      setError(t('errors.load'));
    }
  }

  function onSearch(search: string) {
    setFilter({ ...filter, search });
  }

  function onFilterChange(key: string, value?: string) {
    console.log('change filter to', {
      ...filter,
      [key]: value === '' ? undefined : value,
    });
    setFilter({ ...filter, [key]: value === '' ? undefined : value });
  }

  return (
    <UserDashboard
      title={t('dashboard.title')}
      path={[
        {
          label: commonTranslations.t('dashboard.pathLabels.myProjects'),
          path: myProjectsRoute,
        },
        {
          label: data ? data.project.title : '',
          path: data ? projectRoute(projectId! as string) : undefined,
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.collaborators'),
          path: projectCollaboratorsRoute(projectId! as string),
        },
        {
          label: commonTranslations.t('dashboard.pathLabels.recommendations'),
        },
      ]}
      selectedPath={myProjectsRoute}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {!data && !error && (
        <div className="w-full h-full">
          <Loading fadeIn />
        </div>
      )}
      {data && (
        <div className="max-w-[1113px] flex flex-col min-h-full">
          <div className="flex justify-end mb-3">
            <Link
              to={projectCollaboratorPlatformRecommendationsRoute(
                projectId! as string
              )}
            >
              <Button size="small" className="min-w-[150px]">
                {t('platformRecommendations')}
              </Button>
            </Link>
          </div>
          <div className="flex space-x-4 mb-5">
            <DashboardSearch className="flex-1" onSearch={onSearch} />
            <DashboardSearchFilter
              filters={filterItems}
              values={filter}
              modalClassName="min-w-[200px]"
              onFilterChange={onFilterChange}
            />
          </div>
          <ProjectCollaboratorRecommendationList
            projectId={projectId! as string}
            result={data}
            notFoundText={t('notFoundText')}
            onPageChange={(page: number) => fetchItems(page)}
          />
        </div>
      )}
    </UserDashboard>
  );
}
