import { yupResolver } from '@hookform/resolvers/yup';
import { Rating, Star } from '@smastrom/react-rating';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Web3Context from '../../../../context/web3-context';
import {
  rateEvent,
  canRateEvent,
  getRateEventForwardRequest,
} from '../../../../api/events';
import AuthContext from '../../../../context/auth-context';
import { EventDetail } from '../../../../interfaces/event.interface';
import { loadEDIHEventsContract } from '../../../../utils/contracts/edih-events';
import Button from '../../../shared/button';
import FormError from '../../../shared/form/error';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import FormTextarea from '../../../shared/form/textarea';
import Modal from '../../../shared/modal';
import Notification from '../../../shared/notification';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';
import {
  getCurrentProviderService,
  getProvider,
  generateTypedSignature,
} from '../../../../utils/web3';
import { sleep } from '../../../../utils/utils';

const schema = yup
  .object()
  .shape({
    rating: yup.number().min(1).required(),
    feedback: yup.string().nullable(),
  })
  .required();

function RateEventModal(props: {
  event: EventDetail;
  onClose: () => void;
  onRate: (tx: string, rating: number) => void;
}) {
  const { t } = useTranslation('event-detail');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(formValues: any) {
    setLoading(true);
    setError(undefined);

    try {
      await connect({ hasToBeAddress: user!.walletAddress });

      let tx: string | undefined;
      let signature: string | undefined;

      try {
        if (getCurrentProviderService() === 'metamask') {
          await checkCanRate();

          // getProvider().getSigner()
          const signer = await new UncheckedJsonRpcSigner(
            getProvider(),
            user!.walletAddress
          );

          const edihEventsContract = await loadEDIHEventsContract(signer);
          const result = await edihEventsContract.rateEvent(
            props.event.contractId!,
            formValues.rating * 10
          );

          tx = result.hash;
        } else {
          const forwardRequest = await fetchRateEventForwardRequest(
            formValues.rating * 10
          );
          console.log('forwardr', forwardRequest);

          signature = await generateTypedSignature(
            forwardRequest,
            user!.walletAddress
          );

          console.log('sig', signature);
        }

        try {
          await rateEvent(
            props.event.id,
            formValues.rating * 10,
            tx,
            signature,
            formValues.feedback
          );
          props.onRate(tx!, formValues.rating);
          setLoading(false);
          props.onClose();
        } catch (err: any) {
          console.error('failed to rate', err);
          setError(t('rate.errors.rate'));
          setLoading(false);
        }
      } catch (err) {
        console.error('failed to send transaction', err);
        setError(t('rate.errors.transaction'));
        setLoading(false);
      }

      // const edihEventsContract = await loadEDIHEventsContract();
      // edihEventsContract
      //   .rateEvent(
      //     user!.walletAddress,
      //     props.event.contractId!,
      //     formValues.rating * 10
      //   )
      //   .on('transactionHash', async (tx: string) => {
      //     console.log('rated', tx);
      //     try {
      //       await rateEvent(
      //         props.event.id,
      //         formValues.rating * 10,
      //         tx,
      //         formValues.feedback
      //       );
      //       props.onRate(tx, formValues.rating);
      //       setLoading(false);
      //       props.onClose();
      //     } catch (err: any) {
      //       console.error('failed to rate', err);
      //       setError(t('rate.errors.rate'));
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.error('failed to send transaction', err);
      //     setError(t('rate.errors.transaction'));
      //     setLoading(false);
      //   });
    } catch (err: any) {
      console.log('failed to connect', err);
      setLoading(false);
    }
  }

  async function checkCanRate() {
    try {
      await canRateEvent(props.event!.id);
    } catch (err) {
      setError(t('rate.errors.canRate'));

      throw err;
    }
  }

  async function fetchRateEventForwardRequest(rating: number) {
    try {
      return getRateEventForwardRequest(props.event!.id, rating);
    } catch (err) {
      throw err;
    }
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>{t('rate.rating')}</FormLabel>
          <Controller
            render={({ field }) => (
              <Rating
                className="max-w-[150px] mb-2"
                value={field.value as any}
                onChange={field.onChange}
                itemStyles={{
                  itemShapes: Star,
                  activeFillColor: '#FECA16',
                  activeStrokeColor: '#FECA16',
                  inactiveFillColor: '#ffffff',
                  inactiveStrokeColor: '#FECA16',
                  itemStrokeWidth: 1,
                }}
              />
            )}
            control={control}
            name="rating"
          />
          {errors.rating !== undefined && (
            <FormError>{t('rate.errors.noRating')}</FormError>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>{t('rate.feedback')}</FormLabel>
          <FormTextarea {...register('feedback')} rows={6} disabled={loading} />
        </FormGroup>
        <Button className="w-full mt-4" color="secondary" disabled={loading}>
          {t('rate.button')}
        </Button>
      </form>
    </Modal>
  );
}

export default RateEventModal;
