import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import EventEntryTransferModal from './transfer-modal';
import { getUsableEventEntries } from '../../../../../api/events-entries';
import { EventEntryItem } from '../../../../../interfaces/event-entry.interface';
import Button from '../../../../shared/button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: EventEntryItem;
  setError: (error?: string) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  onTransfer: (entries: { id: string }[], tx: string) => void;
}

function EventEntryItemElTransfer(props: Props) {
  const { t } = useTranslation('event-entry-list');
  const [usableAmount, setUsableAmount] = useState<number | undefined>();

  async function onTransfer() {
    props.setError(undefined);
    props.setLoading(true);
    try {
      const result = await getUsableEventEntries(props.item.event.id);
      console.log('usable entries loaded', result);

      setUsableAmount(result.length);
    } catch (err) {
      console.error('failed to load entries', err);
    }
    props.setLoading(false);
  }

  function onTransferred(entries: { id: string }[], tx: string) {
    setUsableAmount(undefined);
    props.onTransfer(entries, tx);
  }

  return (
    <>
      <Button
        className="w-full"
        size="medium"
        color="secondary"
        variant="outline"
        onClick={onTransfer}
        disabled={props.item.event.state !== 'active' || props.loading}
      >
        {t('transfer.button')}
      </Button>
      {usableAmount && (
        <EventEntryTransferModal
          item={props.item}
          usableAmount={usableAmount}
          onTransfer={onTransferred}
          onClose={() => setUsableAmount(undefined)}
        />
      )}
    </>
  );
}

export default EventEntryItemElTransfer;
