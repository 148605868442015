import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { acceptRegistration } from '../../../../api/registrations';
import { Registration } from '../../../../interfaces/registration.interface';
import { loadEDIHContract } from '../../../../utils/contracts/edih';
import Button from '../../../shared/button';
import FormCheckbox from '../../../shared/form/checkbox';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import TagsCheckList from '../../../shared/form/tags-checklist';
import Modal from '../../../shared/modal';
import Web3Context from '../../../../context/web3-context';
import Notification from '../../../shared/notification';
import { getProvider } from '../../../../utils/web3';
import { UncheckedJsonRpcSigner } from '../../../../classes/unchecked-json-rpc-signer';

const schema = yup
  .object()
  .shape({
    transferTokens: yup.bool().default(true),
    tokens: yup.number().when((values, schema, options) => {
      return options.parent.transferTokens === true
        ? yup.number().integer().min(1).required().default(10)
        : yup.mixed();
    }),
    endorse: yup.bool().default(false),
    endorsedTags: yup.array().when((values, schema, options) => {
      return options.parent!.endorse === true
        ? yup.array().min(1).of(yup.string().required()).required()
        : yup.mixed();
    }),
  })
  .required();

function AcceptRegistrationModal(props: {
  registration: Registration;
  onAccept: (tx: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation('registration');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { connect } = useContext(Web3Context);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { transferTokens: true, tokens: 10 },
  });

  async function onAccept(values: any) {
    console.log('accpet values', values);
    setLoading(true);
    setError(undefined);

    try {
      // ensure admin address
      await connect({
        hasToBeAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
      });

      const signer = await new UncheckedJsonRpcSigner(
        getProvider(),
        process.env.REACT_APP_ADMIN_WALLET_ADDRESS!
      );

      // call setUser function in EDIH contract
      const edihContract = await loadEDIHContract(signer);
      const tx = await edihContract.addUser(
        props.registration.walletAddress,
        '',
        values.tokens || 0,
        values.endorse
      );

      console.log('success', tx);

      try {
        await acceptRegistration(
          props.registration.id,
          tx.hash,
          values.endorse ? values.endorsedTags : []
        );
        props.onAccept(tx.hash);
        setLoading(false);
      } catch (err: any) {
        showAcceptError(
          err,
          t('errors.transactionFailed', { reason: err.message })
        );
      }

      // edihContract
      //   .addUser(
      //     address,
      //     props.registration.walletAddress,
      //     '',
      //     values.tokens || 0,
      //     values.endorse
      //   )
      //   .on('transactionHash', async (hash: string) => {
      //     console.log('onaccept', hash);
      //     try {
      //       await acceptRegistration(
      //         props.registration.id,
      //         hash,
      //         values.endorse ? values.endorsedTags : []
      //       );
      //       props.onAccept(hash);
      //       setLoading(false);
      //     } catch (err: any) {
      //       showAcceptError(
      //         err,
      //         t('errors.transactionFailed', { reason: err.message })
      //       );
      //     }
      //   })
      //   .catch((err: any) => {
      //     showAcceptError(
      //       err,
      //       t('errors.transactionFailed', { reason: err.message })
      //     );
      //   });
    } catch (err) {
      showAcceptError(err, t('errors.accept', { reason: err }));
    }
  }

  function showAcceptError(err: any, message: string) {
    console.error('accept error', err);
    setError(message);
    setLoading(false);
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  const [transferTokens, endorse] = watch(['transferTokens', 'endorse']);
  console.log('errors', transferTokens);
  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {error && <Notification color="danger">{error}</Notification>}
      <form onSubmit={handleSubmit(onAccept)}>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <FormCheckbox
                checkboxProps={{
                  onChange: (ev) => field.onChange(ev.target.checked),
                  checked: field.value === true,
                  name: 'transferTokens',
                }}
              >
                {t('acceptModal.tokens')}
              </FormCheckbox>
            )}
            control={control}
            name="transferTokens"
          />

          {transferTokens && (
            <div>
              <FormInput
                {...register('tokens', { valueAsNumber: true })}
                className="w-[100px]"
                type="number"
                error={errors.tokens !== undefined}
                placeholder="1"
                min="1"
                disabled={loading}
              />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <FormCheckbox
                checkboxProps={{
                  onChange: (ev) => field.onChange(ev.target.checked),
                  checked: field.value === true,
                  name: 'endorse',
                }}
              >
                {t('acceptModal.endorse')}
              </FormCheckbox>
            )}
            control={control}
            name="endorse"
          />

          {endorse && (
            <div className="ml-8 pt-1">
              <Controller
                render={({ field }) => (
                  <TagsCheckList
                    tags={props.registration.tags}
                    selectedTagIds={field.value || []}
                    disabled={loading}
                    onChange={(tagIds: string[]) => field.onChange(tagIds)}
                  />
                )}
                control={control}
                name="endorsedTags"
              />
              {errors.endorsedTags && (
                <div className="text-danger">
                  {t('acceptModal.errors.tagsMin')}
                </div>
              )}
            </div>
          )}
        </FormGroup>
        <Button className="mt-3 w-full" disabled={loading}>
          {t('acceptModal.accept')}
        </Button>
      </form>
    </Modal>
  );
}

export default AcceptRegistrationModal;
